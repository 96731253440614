import { UnauthorizedError, ForbiddenError } from '@raydiant/api-client-js';
import { matchPath } from 'react-router';
import { Channel } from 'redux-saga';
import {
  actionChannel,
  fork,
  Pattern,
  put,
  takeEvery,
  all,
} from 'redux-saga/effects';
import { PayloadAction } from 'typesafe-actions/dist/type-helpers';
import * as userActions from '../actions/user';
import history from '../history';
import * as paths from '../routes/paths';
import { getRemoteAssistUser } from '../utilities';

// TODO: This logic is duplicated in globalErrorHandler.
// Changes to this file here must be made there as well.

const kickUserOut = function* () {
  const { pathname, search, hash } = history.location;
  const backTo = [pathname, search, hash].join('');

  const isPathUnprotectedPage = Object.values(paths).some(
    (path) =>
      path.meta.unprotected &&
      !!matchPath(pathname, { path: path.pattern, exact: true }),
  );

  if (!isPathUnprotectedPage) {
    yield put(userActions.logoutUser({ backTo }));
  }
};

const isPayloadUnauthorizedError = ({ payload }: PayloadAction<string, any>) =>
  payload instanceof UnauthorizedError;

const watchUnauthorizedRequests = function* () {
  const unauthorizedErrorActions: Channel<
    PayloadAction<string, UnauthorizedError>
  > = yield actionChannel(isPayloadUnauthorizedError as Pattern);

  yield takeEvery(unauthorizedErrorActions, kickUserOut);
};

const resetRemoteAssistUser = function* () {
  yield put(userActions.remoteAssist(''));
};

const isPayloadRemoteAssistForbiddenError = ({
  payload,
}: PayloadAction<string, any>) =>
  payload instanceof ForbiddenError && !!getRemoteAssistUser();

const watchForbiddenRequests = function* () {
  const remoteAssistForbiddenActions: Channel<
    PayloadAction<string, ForbiddenError>
  > = yield actionChannel(isPayloadRemoteAssistForbiddenError as Pattern);

  yield takeEvery(remoteAssistForbiddenActions, resetRemoteAssistUser);
};

export default all([
  fork(watchUnauthorizedRequests),
  fork(watchForbiddenRequests),
]);
