import { createStyles } from '../../components/withStyles';
import { Theme } from 'raydiant-elements/theme';

export default function makeStyles(_: Theme) {
  return createStyles({
    paper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
  });
}
