import { EventEmitter } from 'events';

type ChargebeeClientInstance = any;
type ChargebeePortalInstance = any;

export type ChargebeePortalSession = {
  id: string;
  token: string;
  access_url: string;
  status: string;
  created_at: number;
  expires_at: number;
  object: string;
  customer_id: string;
};

// resolves on timeout after ms (homemade bluebird.delay)
const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

class ChargebeeClient extends EventEmitter {
  site: string;
  client?: ChargebeeClientInstance;
  portal?: ChargebeePortalInstance;

  constructor(site: string) {
    super();
    this.site = site;
  }

  async initScript() {
    if (document.querySelector('[data-chargebee-script]')) return this;

    // inject chargebee script into head
    const script = document.createElement('script');
    script.setAttribute('data-chargebee-script', '');
    script.setAttribute('data-cb-site', this.site);
    // TODO: We are using a patched version of Chargebee's JS client to
    // workaround an error on iOS. The error occurs when opening the portal.
    // A ticket has been submitted through their support chat. Use the real
    // chargebee client when the issue is fixed. To view the fix open
    // chargebee-patched.js and search for "PATCHED".
    // Chargebee ticket reference (credentials in 1password):
    // https://support.chargebee.com/support/tickets/162157
    // script.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
    script.setAttribute('src', '/chargebee-patched.js');
    document.head.append(script);

    const waitForChargebee = async () => {
      if (window.Chargebee) return;
      await delay(0);
      await waitForChargebee();
    };

    await waitForChargebee();
  }

  async initClient() {
    if (this.client) return this;
    await this.initScript();
    const { Chargebee } = window;
    this.client = Chargebee.init({ site: this.site });
    return this;
  }

  async initPortal() {
    if (this.portal) return this;
    await this.initClient();
    this.portal = this.client.createChargebeePortal();
    return this;
  }

  async openPortal(chargebeePortalSession: ChargebeePortalSession) {
    await this.initPortal();
    this.client.setPortalSession(chargebeePortalSession);
    this.portal.open({
      loaded: () => this.emit('loaded'),
      close: () => this.emit('close'),
    });
    this.once('close', () => this.client.logout());
    await new Promise((resolve) => this.once('loaded', resolve));
    return this;
  }
}

export default ChargebeeClient;
