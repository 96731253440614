import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import * as presentationPageActions from './actions';
import PresentationPage from './PresentationPage';
import reducer from './reducer';
import presentationPageSagas from './sagas';

const presentationPageReducer = persistReducer(
  {
    key: 'PresentationPage',
    whitelist: ['unsavedPresentations', 'unsavedThemes'],
    storage,
  },
  reducer,
);

export default PresentationPage;
export {
  presentationPageSagas,
  presentationPageReducer,
  presentationPageActions,
};
