import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) => {
  const modalWidth = 390;
  const modalTopOffset = 84;
  const modalLeftOffset = 404;

  return createStyles({
    scrollContents: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },

    modal: {
      top: modalTopOffset,
      left: modalLeftOffset,
      bottom: theme.spacing(2),
      width: modalWidth,
    },
  });
});
