import {
  createAction,
  createAsyncAction,
  createStandardAction,
} from 'typesafe-actions';
import {
  PlaybackReport,
  GeneratePlaybackReportInput,
} from '@raydiant/api-client-js';

export const fetchPlaybackReports = createAction('@PLAYBACK_REPORTS/FETCH');

export const fetchPlaybackReportsAsync = createAsyncAction(
  '@PLAYBACK_REPORTS/FETCH_REQUEST',
  '@PLAYBACK_REPORTS/FETCH_SUCCESS',
  '@PLAYBACK_REPORTS/FETCH_FAILURE',
)<void, PlaybackReport[], Error>();

export const generatePlaybackReport = createStandardAction(
  '@PLAYBACK_REPORTS/GENERATE',
)<
  GeneratePlaybackReportInput,
  {
    onSuccess: (playbackReport: PlaybackReport) => void;
  }
>();

export const generatePlaybackReportAsync = createAsyncAction(
  '@PLAYBACK_REPORTS/GENERATE_REQUEST',
  '@PLAYBACK_REPORTS/GENERATE_SUCCESS',
  '@PLAYBACK_REPORTS/GENERATE_FAILURE',
)<void, PlaybackReport, Error>();

export const clearPlaybackReports = createAction('@PLAYBACK_REPORTS/CLEAR');
