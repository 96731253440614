import IconButton from '@material-ui/core/IconButton';
import MenuSharp from '@material-ui/icons/Menu';
import Menu from 'raydiant-elements/core/Menu';
import Hidden from 'raydiant-elements/layout/Hidden';
import Spacer from 'raydiant-elements/layout/Spacer';
import * as React from 'react';
import history from '../../history';
import * as routes from '../../routes';
import NavLink from '../NavLink';

interface NavigationProps {
  title?: string;
  backTo?: string;
}

interface NavigationState {
  open: boolean;
}

class Navigation extends React.Component<NavigationProps, NavigationState> {
  state: NavigationState = {
    open: false,
  };

  renderDesktopMenu() {
    return (
      <Menu>
        <Menu.Header>
          <Menu.Logo
            width={80}
            href={routes.home()}
            onClick={() => history.push(routes.home())}
          />
          <div style={{ flex: 1, maxWidth: 120 }} />
          <NavLink to={routes.screens()} path={routes.screens.pattern}>
            {(navLinkProps) => <Menu.Item {...navLinkProps} label="Screens" />}
          </NavLink>
          <NavLink to={routes.library()} path={routes.library.pattern}>
            {(navLinkProps) => <Menu.Item {...navLinkProps} label="Library" />}
          </NavLink>
          <NavLink to={routes.account()} path={routes.account.pattern}>
            {(navLinkProps) => <Menu.Item {...navLinkProps} label="Account" />}
          </NavLink>
          <Spacer />
          <Menu.Item
            classes={{ root: 'intercom-live-support' }}
            label="Live Support"
            onClick={() => {}}
          />
          <Menu.Item
            href="https://raydiant.com/help"
            label="Knowledge Base"
            target="_blank"
          />
        </Menu.Header>
      </Menu>
    );
  }

  renderMobileMenu() {
    const { title, backTo } = this.props;
    const { open } = this.state;

    return (
      <Menu>
        <Menu.Header>
          {backTo ? (
            <Menu.Back href={backTo} onClick={() => history.push(backTo)} />
          ) : (
            <Menu.Logo
              square
              href={routes.home()}
              onClick={() => history.push(routes.home())}
            />
          )}
          <Spacer />
          {title && <Menu.Title label={title} />}
          <IconButton
            color="inherit"
            edge="end"
            size="small"
            onClick={() => this.setState({ open: !open })}
          >
            <MenuSharp color="inherit" />
          </IconButton>
        </Menu.Header>
        <Menu.Drawer open={open}>
          <NavLink to={routes.screens()} path={routes.screens.pattern}>
            {(navLinkProps) => (
              <Menu.DrawerItem {...navLinkProps} label="Screens" />
            )}
          </NavLink>
          <NavLink to={routes.library()} path={routes.library.pattern}>
            {(navLinkProps) => (
              <Menu.DrawerItem {...navLinkProps} label="Library" />
            )}
          </NavLink>
          <NavLink to={routes.account()} path={routes.account.pattern}>
            {(navLinkProps) => (
              <Menu.DrawerItem {...navLinkProps} label="Account" />
            )}
          </NavLink>
          <Menu.DrawerItem
            classes={{ root: 'intercom-live-support' }}
            label="Live Support"
            onClick={() => {}}
          />
          <Menu.DrawerItem
            href="https://raydiant.com/help"
            label="Knowledge Base"
            target="_blank"
          />
        </Menu.Drawer>
      </Menu>
    );
  }

  render() {
    return (
      <>
        <Hidden xsDown>{this.renderDesktopMenu()}</Hidden>
        <Hidden smUp>{this.renderMobileMenu()}</Hidden>
      </>
    );
  }
}

export default Navigation;
