import React, { FC } from 'react';
import cn from 'classnames';
import { useDrop, DropTargetMonitor } from 'react-dnd';
import { ItemTypes, DragItem } from '../DragLayer/DragItemTypes';
import useStyles from './DropZone.styles';

export interface DropZoneProps {
  onDrop?: (nodeIds: string[]) => void;
  onCanDrop?: (nodeIds: string[]) => boolean;
}

export const DropZone: FC<DropZoneProps> = ({
  onDrop,
  onCanDrop = () => true,
  children,
}) => {
  const classes = useStyles();

  const [{ isOver }, dropRef] = useDrop({
    accept: Object.values(ItemTypes),
    drop: (item: DragItem) => {
      if (onDrop && isOver) {
        onDrop(item.stack);
      }
    },
    collect: (monitor: DropTargetMonitor) => {
      const item = monitor.getItem() as DragItem | null;
      if (!item) return { isOver: false };
      return {
        isOver: monitor.isOver({ shallow: true }) && onCanDrop(item.stack),
      };
    },
  });

  return (
    <div ref={dropRef} className={cn(classes.root, isOver && classes.isOver)}>
      {children}
    </div>
  );
};

export default DropZone;
