import React, { useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Heading from 'raydiant-elements/core/Heading';
import Button from 'raydiant-elements/core/Button';
import Link from 'raydiant-elements/core/Link';

interface ReadResourceWarningProps {
  resourceType: 'presentation' | 'playlist';
  ownerResourceType: 'presentation' | 'playlist';
  open: boolean;
  onConfirm: ((result: boolean) => void) | null;
}

const ReadResourceWarning = ({
  resourceType,
  ownerResourceType,
  open,
  onConfirm,
}: ReadResourceWarningProps) => {
  const handleCancel = useCallback(() => {
    if (!onConfirm) return;
    onConfirm(false);
  }, [onConfirm]);

  const handleOk = useCallback(() => {
    if (!onConfirm) return;
    onConfirm(true);
  }, [onConfirm]);

  return (
    <Dialog open={open}>
      <DialogContent>
        <Heading size={2} gutterBottom>
          Wait!
        </Heading>
        The owner of this {ownerResourceType} will not be able to see the{' '}
        {resourceType} you have added in their builder, and it will also not
        play on their screen. We suggest consulting{' '}
        <Link
          target="_blank"
          href="https://support.raydiant.com/hc/en-us/articles/360054074733"
        >
          this support article
        </Link>{' '}
        on the behaviors of resource sharing to help you achieve your desired
        result.
      </DialogContent>
      <DialogActions>
        <Button label="Cancel" onClick={handleCancel} />
        <Button label="Assign anyway" color="primary" onClick={handleOk} />
      </DialogActions>
    </Dialog>
  );
};

export default ReadResourceWarning;
