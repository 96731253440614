import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import AddContentPage from './AddContentPage';
import reducer from './reducer';
import addContentPageSagas from './sagas';

const addContentPageReducer = persistReducer(
  {
    key: 'AddContentPage',
    whitelist: ['sortOptions'],
    storage,
  },
  reducer,
);

export default AddContentPage;
export { addContentPageSagas, addContentPageReducer };
