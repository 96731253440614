import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { buttonReset, textTruncate } from 'raydiant-elements/mixins';

export default makeStyles((theme: Theme) => {
  const lightNavy = '#225887';
  const editableOnline = lightNavy;
  const editableOffline = '#5E656A';
  const notEditableOnline = '#CFDEE7';
  const notEditableOffline = '#AFB6BB';
  const white = '#ffffff';

  return createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      background: theme.palette.background.paper,
      transition: 'none', // Prevent box-shadow animation on select.
      position: 'relative',

      '&:last-child': {
        marginBottom: 0,
      },

      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(0.5),
        flexDirection: 'column',
      },

      '&$editable$online$selected': {
        boxShadow: `inset 0px 0px 0px 6px ${editableOnline}`,
      },
      '&$editable$online $info': {
        backgroundColor: editableOnline,
        color: white,
      },

      '&$editable$offline$selected': {
        boxShadow: `inset 0px 0px 0px 6px ${editableOffline}`,
      },
      '&$editable$offline $info': {
        backgroundColor: editableOffline,
        color: white,
      },

      '&$notEditable$online$selected': {
        boxShadow: `inset 0px 0px 0px 6px ${notEditableOnline}`,
      },
      '&$notEditable$online $info': {
        backgroundColor: notEditableOnline,
      },

      '&$notEditable$offline$selected': {
        boxShadow: `inset 0px 0px 0px 6px ${notEditableOffline}`,
      },
      '&$notEditable$offline $info': {
        backgroundColor: notEditableOffline,
      },
    },

    editable: {},
    notEditable: {},
    editablePlaylist: {},
    notEditablePlaylist: {},
    online: {},
    offline: {},
    info: {},
    selected: {},

    actions: {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(6),
      fontSize: 24, // Sets icon size.
      alignItems: 'flex-start',

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1, 0),
      },
    },

    action: {
      ...buttonReset(),
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),

      '&:hover': {
        color: theme.palette.primary.main,
      },

      '&:disabled': {
        cursor: 'not-allowed',
        color: 'rgba(0, 0, 0, 0.2)',

        '&:hover': {
          color: 'rgba(0, 0, 0, 0.2)',
        },
      },
    },

    iconWithLabel: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& > span': {
        marginTop: theme.spacing(0.25),
        fontSize: theme.fontSizes.xxs,
        letterSpacing: 0.06,
      },
    },

    publishLock: {
      fontSize: 24, // Sets settings icon size
    },

    editPlaylist: {
      flex: '1 0 288px',
      maxWidth: 288,

      [theme.breakpoints.down('xs')]: {
        flex: 1,
        maxWidth: 'none',
      },
    },

    editPlaylistIcon: {
      // Fix size / alignment.
      transform: 'scale(1.25)',
    },

    playlist: {
      ...textTruncate(),
      marginLeft: theme.spacing(2),
      fontSize: theme.fontSizes.xl,
      fontWeight: 300,
      lineHeight: 1.09,
      letterSpacing: 0.25,

      [theme.breakpoints.down('xs')]: {
        fontSize: theme.fontSizes.lg,
      },
    },

    selectedPlaylist: {
      color: theme.palette.primary.main,
    },

    publish: {
      padding: theme.spacing(1),
    },
  });
});
