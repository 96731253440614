import { useMemo } from 'react';
import useThemes from './useThemes';

export default function useTheme(themeId: string | undefined) {
  const query = useThemes();
  return {
    ...query,
    data: useMemo(() => {
      if (!query.data || !themeId) return;
      return query.data.find((t) => t.id === themeId);
    }, [query.data, themeId]),
  };
}
