import React, { useEffect, useState, useMemo } from 'react';
import { Playlist } from '@raydiant/api-client-js';
import deepEqual from 'fast-deep-equal';
import RuleTokenInput, {
  tokenizeRule,
  validateTokens,
  RuleToken,
  isInitialRuleToken,
} from '../../components/RuleTokenInput';
import usePlaylistPageContext from './usePlaylistPageContext';
import PlaylistModalBody from './PlaylistModalBody';
import PlaylistModalFooter from './PlaylistModalFooter';
import RuleEvaluationInput from '../../components/RuleEvaluationInput';
import useCurrentUserRoles from '../../hooks/useCurrentUserRoles';

interface PlaylistModalRulesProps {
  playlist: Playlist;
  onClose: () => void;
}

const PlaylistModalRules = ({ playlist, onClose }: PlaylistModalRulesProps) => {
  const { updatePlaylist } = usePlaylistPageContext();

  // State

  const [tokens, setTokens] = useState<RuleToken[]>(
    tokenizeRule(playlist.rule),
  );

  const [isRuleOnItems, setIsRulesOnItems] = useState(playlist.isRuleOnItems);
  const { rulesAndTags } = useCurrentUserRoles();

  const [rule, errors] = validateTokens(tokens);
  const isInitialToken = tokens.length === 1 && isInitialRuleToken(tokens[0]);

  // Memoizers

  const isDirty = useMemo(() => {
    return (
      !deepEqual(rule, playlist.rule) ||
      isRuleOnItems !== playlist.isRuleOnItems
    );
  }, [rule, playlist, isRuleOnItems]);

  // Callbacks

  const handleDone = () => {
    if (isInitialToken) {
      updatePlaylist(playlist.id, { rule: null, isRuleOnItems });
    } else {
      updatePlaylist(playlist.id, { rule, isRuleOnItems });
    }
    onClose();
  };

  // Effects

  // Update state when playlist changes.
  useEffect(() => {
    setTokens(tokenizeRule(playlist.rule));
    setIsRulesOnItems(playlist.isRuleOnItems);
  }, [playlist]);

  // Render

  const enableDoneButton = isDirty && (errors.length === 0 || isInitialToken);

  return (
    <>
      <PlaylistModalBody>
        {rulesAndTags && (
          <RuleEvaluationInput
            value={isRuleOnItems}
            onChange={(_event, value) => {
              setIsRulesOnItems(value);
            }}
          ></RuleEvaluationInput>
        )}
        <RuleTokenInput value={tokens} errors={errors} onChange={setTokens} />
      </PlaylistModalBody>

      <PlaylistModalFooter
        enableDone={enableDoneButton}
        onCancel={onClose}
        onDone={handleDone}
      />
    </>
  );
};

export default PlaylistModalRules;
