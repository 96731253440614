import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { ActionType, getType } from 'typesafe-actions';
import * as analyticsActions from '../actions/analytics';

export type AnalyticsActions = ActionType<typeof analyticsActions>;

export type AnalyticsState = {
  utm: {
    source: string;
    medium: string;
    campaign: string;
  };
};

const initialAnalyticsState: AnalyticsState = {
  utm: {
    source: '',
    medium: '',
    campaign: '',
  },
};

const reducer = (
  state = initialAnalyticsState,
  action: AnalyticsActions,
): AnalyticsState => {
  switch (action.type) {
    case getType(analyticsActions.setUTMParameters):
      // State is reset to initial values when the user is kicked out to
      // the login page. We want to persist the utm parameters when that
      // happens so we mutate initial state to include the utm parameters.
      initialAnalyticsState.utm = action.payload;
      return { ...state, utm: action.payload };

    case getType(analyticsActions.clearUTMParameters):
      return { ...state, utm: initialAnalyticsState.utm };

    default:
      return state;
  }
};

export default persistReducer(
  {
    key: 'analytics',
    whitelist: ['utm'],
    storage,
  },
  reducer,
);
