import { Playlist } from '@raydiant/api-client-js';

export function removeDeletedItems(playlist: Playlist): Playlist {
  const playlistWithoutDeleted = { ...playlist };

  playlistWithoutDeleted.items = playlist.items.filter((item) => {
    if (item.presentation) {
      return item.presentation.resource.deletedAt === null;
    }
    if (item.playlist) {
      return item.playlist.resource.deletedAt === null;
    }
    return false;
  });

  return playlistWithoutDeleted;
}
