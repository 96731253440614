import * as React from 'react';
import { Route } from 'react-router';
import history from '../../history';

interface NavLinkRenderProps {
  active: boolean;
  href: string;
  onClick: () => void;
}

interface NavLinkProps {
  to: string;
  exact?: boolean;
  children: (props: NavLinkRenderProps) => React.ReactNode;
  path?: string;
}

class NavLink extends React.Component<NavLinkProps> {
  render() {
    const { to, exact, children, path, ...rest } = this.props;

    return (
      <Route
        path={path}
        exact={exact}
        children={({ match }: { match: boolean }) =>
          children({
            ...rest,
            active: match,
            href: to,
            onClick: () => history.push(to),
          })
        }
      />
    );
  }
}

export default NavLink;
