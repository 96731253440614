import React from 'react';
import Row from 'raydiant-elements/layout/Row';
import { Theme } from '@raydiant/api-client-js';
import ThemeSwatch from './ThemeSwatch';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme as RETheme } from 'raydiant-elements/theme';
import { textTruncate } from 'raydiant-elements/mixins';

interface ThemeItemProps {
  className?: string;
  theme: Theme;
}

const ThemeItem = ({ className, theme }: ThemeItemProps) => {
  const classes = useStyles();
  return (
    <Row halfMargin center className={className}>
      <ThemeSwatch theme={theme} />
      <div className={classes.label}>{theme.name}</div>
    </Row>
  );
};

const useStyles = makeStyles((_: RETheme) => {
  return createStyles({
    label: {
      ...textTruncate(),
    },
  });
});

export default ThemeItem;
