import { ActionType, getType } from 'typesafe-actions';
import { Presentation, AffectedDevice, Theme } from '@raydiant/api-client-js';
import * as presActions from '../../actions/presentations';
import * as actions from './actions';
import getUnsavedPresentationId from './getUnsavedPresentationId';
import getUnsavedThemeId from './getUnsavedThemeId';
import { SortThemeOptions } from '../../utilities';

export type PresentationPageActions = ActionType<
  typeof actions & typeof presActions
>;

export type PresentationPageState = Readonly<{
  loadingStatus: 'loading' | 'success' | 'error';
  unsavedPresentations: {
    [key: string]: Presentation;
  };
  unsavedThemes: {
    [key: string]: Theme;
  };
  affectedDevices: AffectedDevice[];
  themeSortOptions: SortThemeOptions;
  themeSearchQuery: string;
}>;

const initialPresentationPageState: PresentationPageState = {
  loadingStatus: 'loading',
  unsavedPresentations: {},
  unsavedThemes: {},
  affectedDevices: [],
  themeSortOptions: { property: 'name', direction: 'asc' },
  themeSearchQuery: '',
};

export default function presentationPageReducer(
  state = initialPresentationPageState,
  action: PresentationPageActions,
): PresentationPageState {
  switch (action.type) {
    case getType(actions.loadPresentationPageAsync.request):
      return { ...state, loadingStatus: 'loading' };

    case getType(actions.loadPresentationPageAsync.success):
      return {
        ...state,
        loadingStatus: 'success',
        affectedDevices: action.payload.affectedDevices,
      };

    case getType(actions.loadPresentationPageAsync.failure):
      return {
        ...state,
        loadingStatus: 'error',
      };

    case getType(actions.setUnsavedPresentation):
      return {
        ...state,
        unsavedPresentations: {
          ...state.unsavedPresentations,
          [getUnsavedPresentationId(action.payload)]: action.payload,
        },
      };

    case getType(actions.clearUnsavedPresentation):
      const unsavedPresentations = { ...state.unsavedPresentations };
      delete unsavedPresentations[action.payload];

      return {
        ...state,
        unsavedPresentations,
      };

    case getType(actions.clearAllUnsavedPresentations): {
      return {
        ...state,
        unsavedPresentations: {},
      };
    }

    case getType(actions.setUnsavedTheme):
      return {
        ...state,
        unsavedThemes: {
          ...state.unsavedThemes,
          [getUnsavedThemeId(action.payload)]: action.payload,
        },
      };

    case getType(actions.clearUnsavedTheme):
      const unsavedThemes = { ...state.unsavedThemes };
      delete unsavedThemes[action.payload];

      return {
        ...state,
        unsavedThemes,
      };

    case getType(actions.clearAllUnsavedThemes): {
      return {
        ...state,
        unsavedThemes: {},
      };
    }

    case getType(actions.setThemeSortOptions):
      return {
        ...state,
        themeSortOptions: action.payload,
      };

    case getType(actions.setThemeSearchQuery):
      return {
        ...state,
        themeSearchQuery: action.payload,
      };

    default:
      return state;
  }
}
