import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import { Application } from '@raydiant/api-client-js';
import * as applicationActions from '../../actions/applications';

export type ApplicationActions = ActionType<typeof applicationActions>;

export type ApplicationsState = Readonly<{
  byId: {
    [applicationId: string]: Application;
  };
}>;

const initialApplicationsState: ApplicationsState = {
  byId: {},
};

export default function applicationsReducer(
  state = initialApplicationsState,
  action: ApplicationActions,
): ApplicationsState {
  switch (action.type) {
    // fetchApplicationsAsync
    case getType(applicationActions.fetchApplicationsAsync.success): {
      const applications = action.payload;
      return produce(state, (draftState) => {
        applications.forEach((application) => {
          draftState.byId[application.id] = application;
        });
      });
    }

    default: {
      return state;
    }
  }
}
