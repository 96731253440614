import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FilterListIcon from '@material-ui/icons/FilterList';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Button from 'raydiant-elements/core/Button';
import Form from 'raydiant-elements/core/Form';
import AlertIcon from 'raydiant-elements/core/AlertIcon';
import InputLabel from 'raydiant-elements/core/InputLabel';
import Select from 'raydiant-elements/core/Select';
import Text from 'raydiant-elements/core/Text';
import SwapVertReverseIcon from 'raydiant-elements/icons/SwapVertReverse';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import Row from 'raydiant-elements/layout/Row';
import Spacer from 'raydiant-elements/layout/Spacer';
import Column from 'raydiant-elements/layout/Column';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { textTruncate, buttonReset } from 'raydiant-elements/mixins';
import { Theme } from 'raydiant-elements/theme';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Resource, ResourceACL } from '@raydiant/api-client-js';
import { selectOtherDomainProfiles } from '../../selectors/v2/domains';
import Popover from '../Popover';
import {
  domainRoleLabels,
  canEditResource,
  SortProfileOptions,
} from '../../utilities';
import {
  selectSharingSortOptions,
  selectSharingSearchQuery,
  selectSharingProfileResults,
} from './selectors';
import * as actions from './actions';

interface ShareResourceProps {
  resource: Resource;
  resourceType: 'screen' | 'theme';
  onShare: (profileId: string) => Promise<void>;
  onUnshare: (resourceACL: ResourceACL) => Promise<void>;
}

const ShareResource = ({
  resource,
  resourceType,
  onShare,
  onUnshare,
}: ShareResourceProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Selectors

  const otherDomainProfiles = useSelector(selectOtherDomainProfiles);
  const profileResults = useSelector(selectSharingProfileResults);
  const sortOptions = useSelector(selectSharingSortOptions);
  const searchQuery = useSelector(selectSharingSearchQuery);

  // State

  const [shareWithProfileId, setShareWithProfileId] = useState<string | null>(
    null,
  );
  const [shareStatus, setShareStatus] = useState<
    '' | 'pending' | 'success' | 'error'
  >('');
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [isSortMode, setIsSortMode] = useState<boolean>(false);
  const [selectedACL, setSelectedACL] = useState<ResourceACL | null>(null);
  const [confirmationEl, setConfirmationEl] = useState<Element | null>(null);

  // Memoizers

  const hasSharedProfiles = useMemo(() => {
    return otherDomainProfiles.some((profile) =>
      canEditResource(profile, resource),
    );
  }, [otherDomainProfiles, resource]);

  const sharedWithProfiles = useMemo(() => {
    return profileResults.filter((profile) =>
      canEditResource(profile, resource),
    );
  }, [profileResults, resource]);

  // Callbacks

  const shareResource = useCallback(async () => {
    if (!shareWithProfileId) return;
    setShareStatus('pending');

    try {
      await onShare(shareWithProfileId);
      setShareStatus('success');
      setShareWithProfileId(null);
    } catch (err) {
      setShareStatus('error');
    }
  }, [onShare, shareWithProfileId]);

  const unshareResource = useCallback(async () => {
    if (!selectedACL) return;

    setSelectedACL(null);
    setConfirmationEl(null);

    try {
      await onUnshare(selectedACL);
    } catch (err) {
      console.error(err);
    }
  }, [selectedACL, onUnshare]);

  const toggleSort = useCallback(
    (property: SortProfileOptions['property']) => {
      let direction: SortProfileOptions['direction'] = 'asc';

      if (property === sortOptions.property) {
        direction = sortOptions.direction === 'asc' ? 'desc' : 'asc';
      }

      dispatch(actions.setSortOptions({ property, direction }));
      setIsSortMode(false);
    },
    [dispatch, sortOptions],
  );

  const setSearchQuery = useCallback(
    (value: string) => {
      dispatch(actions.setSearchQuery(value));
    },
    [dispatch],
  );

  const closeConfirmation = useCallback(() => {
    setSelectedACL(null);
    setConfirmationEl(null);
  }, [setSelectedACL, setConfirmationEl]);

  // Side-effects

  // Reset search query when exiting search mode.
  useEffect(() => {
    if (isSearchMode) return;
    dispatch(actions.setSearchQuery(''));
  }, [isSearchMode, dispatch]);

  // Render

  const getResourceACL = (profileId: string) => {
    return resource.r.resourceACLs.find(
      (acl) => acl.grantProfileId === profileId,
    );
  };

  let sortLabel = 'Sort';
  if (sortOptions.property === 'name') {
    sortLabel = `${sortLabel}: Name`;
  } else if (sortOptions.property === 'domainRole') {
    sortLabel = `${sortLabel}: Role`;
  }

  const isSortByName = sortOptions.property === 'name';
  const isSortByRole = sortOptions.property === 'domainRole';
  const isReverseSort = sortOptions.direction === 'desc';

  return (
    <>
      <Form onSubmit={shareResource}>
        <Column doubleMargin>
          <div>
            <InputLabel>Share with account</InputLabel>
            <Row halfMargin>
              <Select
                value={shareWithProfileId ?? ''}
                onChange={setShareWithProfileId}
              >
                <option></option>
                {otherDomainProfiles.map((profile) => (
                  <option
                    key={profile.id}
                    value={profile.id}
                    disabled={canEditResource(profile, resource)}
                  >
                    {profile.name}
                  </option>
                ))}
              </Select>
              <Button
                label="Share"
                color="progress"
                type="submit"
                disabled={!shareWithProfileId || shareStatus === 'pending'}
              />
            </Row>
          </div>
          {hasSharedProfiles && (
            <div>
              <InputLabel>Accounts with edit or higher access</InputLabel>

              <ActionBar className={classes.actions}>
                <ActionBar.Select
                  icon={<FilterListIcon />}
                  label={sortLabel}
                  open={isSortMode}
                  onOpen={setIsSortMode}
                >
                  <ActionBar.SelectOption
                    icon={
                      isSortByName && isReverseSort ? (
                        <SwapVertReverseIcon />
                      ) : (
                        <SwapVertIcon />
                      )
                    }
                    label="Name"
                    selected={isSortByName}
                    onClick={() => toggleSort('name')}
                  />
                  <ActionBar.SelectOption
                    icon={
                      isSortByRole && isReverseSort ? (
                        <SwapVertReverseIcon />
                      ) : (
                        <SwapVertIcon />
                      )
                    }
                    label="Role"
                    selected={isSortByRole}
                    onClick={() => toggleSort('domainRole')}
                  />
                </ActionBar.Select>

                <Spacer />

                {isSearchMode ? (
                  <ActionBar.Input
                    autoFocus
                    label="Search"
                    maxWidth={145}
                    icon={
                      <ActionBar.Action
                        icon={<CloseIcon />}
                        onClick={() => setIsSearchMode(false)}
                      />
                    }
                    value={searchQuery}
                    onChange={(value) => setSearchQuery(value)}
                  />
                ) : (
                  <ActionBar.Action
                    icon={<SearchIcon />}
                    onClick={() => setIsSearchMode(true)}
                  />
                )}
              </ActionBar>

              {sharedWithProfiles.map((profile) => {
                const acl = getResourceACL(profile.id);
                return (
                  <Row key={profile.id} halfMargin className={classes.acl}>
                    <div className={classes.aclName}>{profile.name}</div>
                    <div className={classes.aclRole}>
                      {domainRoleLabels[profile.domainRole]}
                    </div>
                    <button
                      className={classes.remove}
                      disabled={!acl}
                      onClick={(event) => {
                        if (!acl) return;
                        setSelectedACL(acl);
                        setConfirmationEl(event.currentTarget);
                      }}
                    >
                      <HighlightOffIcon />
                    </button>
                  </Row>
                );
              })}
            </div>
          )}
        </Column>
      </Form>

      <Popover
        variant="alert"
        open={!!selectedACL}
        anchorEl={confirmationEl}
        onClose={closeConfirmation}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Column>
          <Row halfMargin>
            <AlertIcon color="warning" />
            <Text>
              Removing access will no longer allow{' '}
              <strong>{selectedACL?.r.grantProfile?.name}</strong> to make
              changes to this {resourceType}.
            </Text>
          </Row>
          <Row>
            <Spacer />
            <Button label="Cancel" onClick={closeConfirmation} fullWidth />
            <Button
              fullWidth
              color="destructive"
              label="Remove Access"
              onClick={unshareResource}
            />
          </Row>
        </Column>
      </Popover>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    popover: {
      '& $popoverPaper': {
        padding: theme.spacing(2),
        maxWidth: 300,
      },
    },

    popoverPaper: {},

    acl: {
      alignItems: 'center',
    },

    aclName: {
      ...textTruncate(),
      flex: 1,
      fontSize: theme.fontSizes.lg,
      letterSpacing: 0.15,
    },

    aclRole: {
      flex: 1,
      fontSize: theme.fontSizes.md,
      color: 'rgba(0, 0, 0, 0.45)',
      fontWeight: 500,
      letterSpacing: 0.1,
    },

    remove: {
      ...buttonReset(),
      display: 'flex',
      color: theme.palette.text.secondary,
      opacity: 0.6,

      '&:hover': {
        opacity: 1,
      },

      '&:disabled': {
        opacity: 0,
        cursor: 'default',
      },
    },

    actions: {
      marginBottom: theme.spacing(1),
    },
  });
});

export default ShareResource;
