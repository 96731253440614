import React, { useEffect, useRef, useCallback } from 'react';
import {
  PresentationWindow,
  PlaylistWindowManagerState,
} from '@raydiant/playlist-window-manager';
import IFrameRenderer from '@raydiant/playlist-window-manager/build/IFrameRenderer';
import * as RE from '@raydiant/playlist-rule-engine';
import {
  mapPresentationToV2,
  mapApplicationVersionToV2,
  PlaylistPlaybackContent,
  Presentation,
} from '@raydiant/api-client-js';
import apiClient from '../../clients/miraClient';
import deepEqual from 'fast-deep-equal';
import * as A from '../../clients/mira/types/Application';
import * as P from '../../clients/mira/types/Presentation';
import * as T from '../../clients/mira/types/Theme';
import {
  collectApplicationVariables,
  hasPresentationChanged,
  hasThemeChanged,
} from '../../utilities';
import combinePlaybackContent from './combinePlaybackContent';
import logger from '../../logger';
import { BuilderState } from '../../types';

interface PresentationLoaderProps {
  presentation: Presentation;
  appVersion: A.ApplicationVersion;
  theme?: T.Theme;
  builderState?: BuilderState;
  onPresentationProperties?: (
    properties: P.PresentationProperty[],
    strings: Record<string, string>,
  ) => void;
}

const PresentationLoader = ({
  presentation,
  appVersion,
  theme,
  builderState,
  onPresentationProperties,
}: PresentationLoaderProps) => {
  const windowRef = useRef<PresentationWindow | null>(null);
  const prevPresentationRef = useRef<P.Presentation | null>(null);
  const prevAppVersionRef = useRef<A.ApplicationVersion | null>(null);
  const prevThemeRef = useRef<T.Theme | undefined | null>(null);
  const prevBuilderStateRef = useRef<BuilderState | undefined | null>(null);

  const playbackContentCacheRef = useRef<{
    [playlistId: string]: PlaylistPlaybackContent;
  }>({});
  const rendererRef = useRef<IFrameRenderer | null>(null);

  // Create the renderer when the container has been added to the DOM.
  const createRenderer = useCallback((node) => {
    if (node) {
      rendererRef.current = new IFrameRenderer(node);
    }
  }, []);

  // Remove the presentation window when unmounting.
  useEffect(() => {
    return () => {
      if (windowRef.current) {
        windowRef.current.remove();
      }

      if (rendererRef.current) {
        rendererRef.current.destroy();
      }
    };
  }, []);

  // Create window manager and rule enegine state from playback content.
  const getPresentationWindowState = useCallback(async () => {
    const playlistAppVars = collectApplicationVariables(
      'playlist',
      presentation,
      appVersion,
    );

    const allPlaybackContent = await Promise.all(
      playlistAppVars.map(async ({ applicationVariable: playlistId }) => {
        if (!playlistId) return {};

        // Cache playback content per playlist for the lifetime of the component.
        let playbackContent: PlaylistPlaybackContent =
          playbackContentCacheRef.current[playlistId];

        if (!playbackContent) {
          playbackContent = await apiClient.getPlaylistPlaybackContent(
            playlistId,
          );
          playbackContentCacheRef.current[playlistId] = playbackContent;
        }

        return playbackContent;
      }),
    );

    const {
      presentations = {},
      playlists = {},
      applicationVersions = {},
      applications = {},
      themes = {},
    } = combinePlaybackContent(allPlaybackContent);

    const ruleEngineState = new RE.State(
      RE.mapPlaylistsFromAPI(playlists),
      RE.mapPresentationsFromAPI(
        presentations,
        applicationVersions,
        applications,
      ),
      {},
    );

    const playlistWindowManagerState = new PlaylistWindowManagerState({
      presentations,
      applicationVersions,
      themes,
      publishedAt: '',
    });

    return { ruleEngineState, playlistWindowManagerState };
  }, [presentation, appVersion]);

  // Load the presentation window and run getProperties.
  const loadPresentationWindow = useCallback(async () => {
    if (!rendererRef.current) return;

    const { ruleEngineState, playlistWindowManagerState } =
      await getPresentationWindowState();

    const presentationWindow = new PresentationWindow({
      isPreview: true,
      windowId: 'preview',
      builderState,
      presentation: mapPresentationToV2(presentation),
      applicationVersion: mapApplicationVersionToV2(appVersion),
      theme,
      playlistWindowManagerState,
      ruleEngineState,
      renderer: rendererRef.current,
      logger: {
        debug: () => {},
        info: () => {},
        warn: () => {},
        error: (err) => {
          logger.warn(`Error from app preview: ${err}`);
        },
      },
      backgroundColor: 'black',
      appRendererUrl: '/app-renderer/1.4.4',
      onPresentationProperties,
      publishedAt: '',
    });

    windowRef.current = presentationWindow;

    try {
      await presentationWindow.load();

      // Avoid race condition issue with loadPresentationWindow being invoked twice, first with
      // an empty builderState and later with builderState.
      // See https://github.com/mirainc/mira-dash/pull/1253 for more info.
      if (windowRef.current !== presentationWindow) return;

      presentationWindow.show(false);

      // Play presentation.
      presentationWindow.play();
      // Evaluate conditional controls after loading.
      presentationWindow.runGetProperties(builderState);
    } catch (err) {
      console.error(`Error loading app preview: ${err}`);
    }
  }, [
    appVersion,
    builderState,
    presentation,
    theme,
    onPresentationProperties,
    getPresentationWindowState,
  ]);

  // Update presentation window
  const updatePresentationWindow = useCallback(async () => {
    const presentationWindow = windowRef.current;
    if (!presentationWindow) return;

    const { ruleEngineState, playlistWindowManagerState } =
      await getPresentationWindowState();

    presentationWindow.setPresentation(
      mapPresentationToV2(presentation),
      playlistWindowManagerState,
      ruleEngineState,
    );
    presentationWindow.setTheme(theme);
    presentationWindow.setBuilderState(builderState);
    presentationWindow.reload();

    // Evaluate conditional controls after updating.
    presentationWindow.runGetProperties(builderState);
  }, [presentation, theme, getPresentationWindowState, builderState]);

  // Create or update the presentation window.
  useEffect(() => {
    if (!rendererRef.current) return;

    if (windowRef.current) {
      // Update presentation window.
      const presentationWindow = windowRef.current;

      const didPresentationChange =
        prevPresentationRef.current !== null
          ? hasPresentationChanged(
              presentation,
              prevPresentationRef.current,
              appVersion,
            )
          : false;

      const didAppVersionChange =
        prevAppVersionRef.current !== null
          ? prevAppVersionRef.current.id !== appVersion.id
          : false;

      const didThemeChange =
        prevThemeRef.current !== null && prevThemeRef.current && theme
          ? hasThemeChanged(prevThemeRef.current, theme)
          : false;

      const didBuilderStateChange =
        prevBuilderStateRef.current !== null
          ? !deepEqual(prevBuilderStateRef.current, builderState)
          : false;

      // Update the presentation window if the presentation, app version or theme
      // changes otherwise create a new presentation window.
      if (
        didPresentationChange ||
        didAppVersionChange ||
        didThemeChange ||
        didBuilderStateChange
      ) {
        // Recreate the presentation window if the app version changed, otherwise rerender
        // the preview with the updated presentation and theme.
        if (didAppVersionChange) {
          presentationWindow.remove();
          loadPresentationWindow();
        } else if (
          didPresentationChange ||
          didThemeChange ||
          didBuilderStateChange
        ) {
          // Rerender presentation window with updated presentation, state, theme and builder state.
          updatePresentationWindow();
        }
      }
    } else {
      // Create and load the presentation window.
      loadPresentationWindow();
    }

    // Set previous refs.
    prevPresentationRef.current = presentation;
    prevAppVersionRef.current = appVersion;
    prevThemeRef.current = theme;
    prevBuilderStateRef.current = builderState;
  }, [
    presentation,
    appVersion,
    theme,
    builderState,
    loadPresentationWindow,
    updatePresentationWindow,
  ]);

  return <div ref={createRenderer} />;
};

export default PresentationLoader;
