import { createAsyncAction, createStandardAction } from 'typesafe-actions';

export const loadApplicationsPage = createStandardAction(
  'LOAD_APPLICATIONS_PAGE',
)();

export const loadApplicationsPageAsync = createAsyncAction(
  'LOAD_APPLICATIONS_PAGE_REQUEST',
  'LOAD_APPLICATIONS_PAGE_SUCCESS',
  'LOAD_APPLICATIONS_PAGE_FAILURE',
)<void, void, Error>();
