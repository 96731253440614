import deepEqual from 'fast-deep-equal';
import { Theme } from '@raydiant/api-client-js';

const getComparableProps = (theme: Theme) => ({
  name: theme.name,
  backgroundColor: theme.backgroundColor,
  backgroundImage: theme.backgroundImage,
  backgroundImagePortrait: theme.backgroundImagePortrait,
  headingTextColor: theme.headingTextColor,
  headingFont: theme.headingFont,
  heading2TextColor: theme.heading2TextColor,
  heading2Font: theme.heading2Font,
  bodyTextColor: theme.bodyTextColor,
  bodyFont: theme.bodyFont,
  borderColor: theme.borderColor,
  presentationId: theme.presentationId,
});

export default function hasThemeChanged(prevTheme: Theme, nextTheme: Theme) {
  return !deepEqual(
    getComparableProps(prevTheme),
    getComparableProps(nextTheme),
  );
}
