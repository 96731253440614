import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { SortFolderOptions } from '../../utilities';

export type PlaylistSelectorActions = ActionType<typeof actions>;

export type PlaylistSelectorState = Readonly<{
  sortOptions: SortFolderOptions;
  searchQuery: string;
}>;

const initialPlaylistSelectorState: PlaylistSelectorState = {
  sortOptions: {
    property: 'name',
    direction: 'asc',
  },
  searchQuery: '',
};

export default function playlistSelectorReducer(
  state = initialPlaylistSelectorState,
  action: PlaylistSelectorActions,
): PlaylistSelectorState {
  switch (action.type) {
    case getType(actions.setSortOptions):
      return {
        ...state,
        sortOptions: action.payload,
      };

    case getType(actions.setSearchQuery):
      return {
        ...state,
        searchQuery: action.payload,
      };

    default:
      return state;
  }
}
