import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as folderActions from '../../actions/folders';
import * as presActions from '../../actions/presentations';
import * as playlistActions from '../../actions/playlists';
import * as F from '../../clients/mira/types/Folder';
import * as R from '../../clients/mira/types/Resource';

export type FolderActions = ActionType<typeof folderActions>;
export type PresentationActions = ActionType<typeof presActions>;
export type PlaylistActions = ActionType<typeof playlistActions>;

export type FoldersState = Readonly<{
  byId: {
    [folderId: string]: F.Folder;
  };
  virtualById: {
    [folderId: string]: F.VirtualFolder;
  };
  statusById: {
    [folderId: string]: 'fetching' | 'updating' | 'success' | 'error';
  };
}>;

const initialFoldersState: FoldersState = {
  byId: {},
  virtualById: {},
  statusById: {},
};

const setNestedFolders = (
  state: FoldersState = initialFoldersState,
  folder: F.Folder | F.VirtualFolder,
) => {
  folder.folders.forEach((nestedFolder) => {
    const currNestedFolder = state.byId[nestedFolder.id];

    // The API doesn't return folder items in nested folders. This prevents
    // overriding an already fetched folder's items with an empty array.
    const presentations = currNestedFolder?.presentations || [];
    const playlists = currNestedFolder?.playlists || [];
    const folders = currNestedFolder?.folders || [];
    const devices = currNestedFolder?.devices || [];

    // The API doesn't return paths for folder items but we can construct
    // it from the parent folder's path.
    const path =
      'path' in folder
        ? [...folder.path, { id: nestedFolder.id, name: nestedFolder.name }]
        : [{ id: nestedFolder.id, name: nestedFolder.name }];

    state.byId[nestedFolder.id] = {
      ...nestedFolder,
      path,
      presentations,
      playlists,
      folders,
      devices,
    };
  });
};

interface ResourceObject {
  id: string;
  resource: R.Resource;
}

const getFoldersForMove = <T extends ResourceObject>(
  state: FoldersState,
  resourceObject: T,
  parentFolderId: string | null,
): {
  source: F.Folder | F.VirtualFolder | undefined;
  destination: F.Folder | F.VirtualFolder | undefined;
} => {
  const currParentFolderId = resourceObject.resource.parentFolderId;

  const source = currParentFolderId
    ? state.byId[currParentFolderId]
    : state.virtualById.library;

  const destination = parentFolderId
    ? state.byId[parentFolderId]
    : state.virtualById.library;

  return { source, destination };
};

export default function foldersReducer(
  state = initialFoldersState,
  action: FolderActions | PresentationActions | PlaylistActions,
): FoldersState {
  switch (action.type) {
    // fetchFolderAsync
    case getType(folderActions.fetchFolderAsync.request): {
      return produce(state, (draftState) => {
        draftState.statusById[action.payload] = 'fetching';
      });
    }

    case getType(folderActions.fetchFolderAsync.success): {
      const folder = action.payload;
      return produce(state, (draftState) => {
        setNestedFolders(draftState, folder);
        draftState.statusById[folder.id] = 'success';
        draftState.byId[folder.id] = folder;
      });
    }

    case getType(folderActions.fetchFolderAsync.failure): {
      return produce(state, (draftState) => {
        draftState.statusById[action.payload] = 'error';
      });
    }

    // createFolderAsync
    case getType(folderActions.createFolderAsync.success): {
      return produce(state, (draftState) => {
        draftState.byId[action.payload.id] = action.payload;
      });
    }

    // updateFolderAsync
    case getType(folderActions.updateFolderAsync.request): {
      return produce(state, (draftState) => {
        draftState.statusById[action.payload] = 'updating';
      });
    }
    case getType(folderActions.updateFolderAsync.success): {
      return produce(state, (draftState) => {
        draftState.byId[action.payload.id] = action.payload;
        draftState.statusById[action.payload.id] = 'success';
      });
    }

    // deleteFolderAsync
    case getType(folderActions.deleteFolderAsync.request): {
      return produce(state, (draftState) => {
        delete draftState.byId[action.payload];
      });
    }

    // fetchVirtualFolderAsync
    case getType(folderActions.fetchVirtualFolderAsync.success): {
      const { id, ...folder } = action.payload;
      return produce(state, (draftState) => {
        setNestedFolders(draftState, folder);
        draftState.virtualById[id] = folder;
      });
    }

    // movePresentationToFolderAsync
    case getType(presActions.movePresentationToFolderAsync.request): {
      const { presentation, parentFolderId } = action.payload;

      return produce(state, (draftState) => {
        const { source, destination } = getFoldersForMove(
          draftState,
          presentation,
          parentFolderId,
        );

        if (source) {
          source.presentations = source.presentations.filter(
            (p) => p.id !== presentation.id,
          );
        }

        if (destination) {
          destination.presentations.push(presentation);
        }
      });
    }

    // movePlaylistToFolderAsync
    case getType(playlistActions.movePlaylistToFolderAsync.request): {
      const { playlist, parentFolderId } = action.payload;

      return produce(state, (draftState) => {
        const { source, destination } = getFoldersForMove(
          draftState,
          playlist,
          parentFolderId,
        );

        if (source) {
          source.playlists = source.playlists.filter(
            (p) => p.id !== playlist.id,
          );
        }

        if (destination) {
          destination.playlists.push(playlist);
        }
      });
    }

    // moveFolderToFolderAsync
    case getType(folderActions.moveFolderToFolderAsync.request): {
      const { folder, parentFolderId } = action.payload;

      return produce(state, (draftState) => {
        const draftFolder = draftState.byId[folder.id];
        if (!draftFolder) return;
        draftFolder.resource.parentFolderId = parentFolderId;

        const { source, destination } = getFoldersForMove(
          draftState,
          folder,
          parentFolderId,
        );

        if (source) {
          source.folders = source.folders.filter((p) => p.id !== folder.id);
        }

        if (destination) {
          destination.folders.push(folder);
        }
      });
    }

    default: {
      return state;
    }
  }
}
