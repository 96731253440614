import { Resource } from '@raydiant/api-client-js';

export function getDuplicateName(
  sourceName: string,
  otherNames: string[],
): string {
  const duplicateIndexes: boolean[] = [];
  // Remove _Copy[N] to get the original source name. This prevents _Copy1_Copy1.
  const originalSourceName = sourceName.replace(/_Copy[0-9+]?$/, '');
  // Include source name in the full list of names to check for copies.
  const allNames = [sourceName, ...otherNames];
  // Create an array of all used duplicate numbers.
  //  ie. A folder with Copy1, Copy2 and Copy4 would produce this array:
  //      [undefined, true, true, undefined, true]
  for (const name of allNames) {
    const match = name.match(
      new RegExp(`${originalSourceName}_Copy([0-9+]?)$`),
    );

    if (match && match[1]) {
      const index = parseInt(match[1], 10);
      if (!isNaN(index)) {
        duplicateIndexes[index] = true;
      }
    }
  }

  // If there are no copies than create Copy1 not Copy0.
  let duplicateIndex = duplicateIndexes.length || 1;

  // Find the first unused index if there is one. Start at i=1 because
  // we don't want to create Copy0.
  for (let i = 1; i < duplicateIndexes.length; i += 1) {
    if (duplicateIndexes[i] === undefined) {
      duplicateIndex = i;
      break;
    }
  }

  return `${originalSourceName}_Copy${duplicateIndex}`;
}

export default function creeateDuplicateResourceName(
  sourceName: string,
  otherResources: Array<{ name: string; resource: Resource }>,
): string {
  const folderItemNames: string[] = [];

  for (const { name, resource } of otherResources) {
    if (resource.deletedAt === null) {
      folderItemNames.push(name);
    }
  }

  return getDuplicateName(sourceName, folderItemNames);
}
