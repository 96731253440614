import qs from 'querystring';
import { all, fork, put } from 'redux-saga/effects';
import { setUTMParameters } from '../../actions/analytics';
import logger from '../../logger';

const queryParamToString = (value: string | string[]) =>
  Array.isArray(value) ? value[0] || '' : value;

function* checkUTMParametrs() {
  const {
    utm_source: source,
    utm_medium: medium,
    utm_campaign: campaign,
  } = qs.parse(window.location.search.replace('?', ''));

  if (source && medium && campaign) {
    logger.info(
      `UTM parameters detected: source=${source}, medium=${medium}, campaign=${campaign},`,
    );
    yield put(
      setUTMParameters({
        source: queryParamToString(source),
        medium: queryParamToString(medium),
        campaign: queryParamToString(campaign),
      }),
    );
  }
}

export default all([fork(checkUTMParametrs)]);
