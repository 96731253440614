import { Playlist, PlaylistItem, Presentation } from '@raydiant/api-client-js';
import deepEqual from 'fast-deep-equal';
import { isNewId } from '../../../utilities/identifiers';
import { areTagsEqual } from '../../../utilities/tagUtils';
import {
  ItemIDPath,
  ItemIndexPath,
  PlaylistIDPath,
  State,
  Errors,
  ErrorCode,
  UpdatePlaylistWithId,
  UpdatePresentationWithId,
} from '../playlistPageTypes';
import keys from './stateKeys';

export const deepCopy = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export const serializeIDPath = (path: ItemIDPath) => path.join('.');
export const deserializeIDPath = (pathStr: string): ItemIDPath =>
  pathStr.split('.');

export const isIDPathEqual = (pathA: ItemIDPath, pathB: ItemIDPath) => {
  return serializeIDPath(pathA) === serializeIDPath(pathB);
};

export const serializeIndexPath = (path: ItemIndexPath) => path.join('.');
export const deserializeIndexPath = (pathStr: string): ItemIndexPath =>
  pathStr.split('.').map(Number);

export const compareIndexPath = (
  pathA: ItemIndexPath,
  pathB: ItemIndexPath,
) => {
  for (let i = 0; i < Math.max(pathA.length, pathB.length); i++) {
    const pathAValue = pathA[i];
    const pathBValue = pathB[i];

    if (pathAValue === undefined) return -1;
    if (pathBValue === undefined) return 1;

    if (pathAValue < pathBValue) return -1;
    if (pathAValue > pathBValue) return 1;
  }

  return 0;
};

export const isIndexPathEqual = (
  pathA: ItemIndexPath,
  pathB: ItemIndexPath,
) => {
  return compareIndexPath(pathA, pathB) === 0;
};

export const isIndexPathDescendent = (
  pathA: ItemIndexPath,
  pathB: ItemIndexPath,
) => {
  if (pathA.length < pathB.length) {
    return pathA.every((pathAValue, i) => pathAValue === pathB[i]);
  }
  return false;
};

export const orderByIndexPathAsc = <T extends { indexPath: ItemIndexPath }>(
  value: T[],
) => {
  return value.sort(({ indexPath: pathA }, { indexPath: pathB }) => {
    if (pathA.length !== pathB.length) {
      return pathA.length - pathB.length;
    } else {
      let pathASum = 0;
      let pathBSum = 0;
      for (let i = 0; i < pathA.length; i++) {
        if (pathA[i] > pathB[i]) {
          return 1;
        }
        pathASum += pathA[i];
        pathBSum += pathB[i];
      }
      return pathASum - pathBSum;
    }
  });
};

export const incrementObjectValue = (
  obj: Record<string, number>,
  key: string,
) => {
  if (!obj[key]) {
    obj[key] = 0;
  }
  obj[key] = obj[key] + 1;
};

export const decrementObjectValue = (
  obj: Record<string, number>,
  key: string,
) => {
  if (obj[key]) {
    obj[key] -= 1;
    if (obj[key] === 0) {
      delete obj[key];
    }
  }
};

// Merges params (a partial playlist) into a playlist.
export const mergePlaylists = (
  playlist: Playlist,
  params: UpdatePlaylistWithId,
): Playlist => {
  return {
    ...playlist,
    ...params,
    resource: {
      // Merge saved resource with unsaved resource.
      ...playlist.resource,
      ...params.resource,
      r: {
        ...playlist.resource.r,
        // Override saved resource tags with unsaved resource tags. Unsaved resource tags don't have
        // an id, createdAt or resourceId set yet so to make sure we return a full playlist type we set
        // them to empty strings. They are eventually set by the API.
        tags: params.resource?.r?.tags
          ? params.resource.r.tags.map((t) => ({
              ...t,
              id: '',
              createdAt: '',
              resourceId: '',
            }))
          : playlist.resource.r.tags,
      },
    },
  };
};

export const mergePresentationTags = (
  presentation: Presentation,
  params: UpdatePresentationWithId,
): Presentation => {
  return {
    ...presentation,
    ...params,
    resource: {
      // Merge saved resource with unsaved resource.
      ...presentation.resource,
      ...params.resource,
      r: {
        // Override saved resource tags with unsaved resource tags. Unsaved resource tags don't have
        // an id, createdAt or resourceId set yet so to make sure we return a full playlist type we set
        // them to empty strings. They are eventually set by the API.
        ...presentation.resource.r,
        tags: params.resource?.r?.tags
          ? params.resource.r.tags.map((t) => ({
              ...t,
              id: '',
              createdAt: '',
              resourceId: '',
            }))
          : presentation.resource.r.tags,
      },
    },
  };
};

export type GetFullPlaylistsByIdOptions = Pick<
  State,
  'savedPlaylistsById' | 'updatedPlaylistsById' | 'newPlaylistsById'
>;

// This function returns a map of playlists by their id. Only playlists that have been fetched with their
// playlist items will be part of this map. Playlists that appear in playlist items (nested playlists) are
// not full playlists becuase they don't contain their playlist items. Nested playlists must be fetched
// from the API directly in order to become full playlists. This map can be used to traverse playlists with
// unsaved changed to find a specific playlist item.
export const getFullPlaylistsById = ({
  savedPlaylistsById,
  updatedPlaylistsById,
  newPlaylistsById,
}: GetFullPlaylistsByIdOptions) => {
  const fullPlaylistsById: Record<string, Playlist> = {
    ...savedPlaylistsById,
    ...newPlaylistsById,
  };

  for (const [id, partialPlaylist] of Object.entries(updatedPlaylistsById)) {
    // Update params are partial playlists so we can only combine updated params if there's
    // a saved version of the playlist. A saved playlist may not exist if an update was made
    // and the page was refreshed since the update is persisted to session storage.
    if (fullPlaylistsById[id]) {
      fullPlaylistsById[id] = mergePlaylists(
        fullPlaylistsById[id],
        partialPlaylist,
      );
    }
  }

  return fullPlaylistsById;
};

const getPlaylistItemWithIterator = <T>(
  playlistsById: Record<string, Playlist>,
  rootPlaylistId: string,
  iterator: T[],
  lookup: (key: T, items: PlaylistItem[]) => PlaylistItem | undefined,
): [PlaylistItem | undefined, ItemIDPath | undefined] => {
  if (!rootPlaylistId) return [undefined, undefined];
  const rootPlaylist = playlistsById[rootPlaylistId];
  if (!rootPlaylist) return [undefined, undefined];

  // Traverse nested playlists to find the item at the provided path.
  let items = rootPlaylist.items;
  let playlistItem: PlaylistItem | undefined;
  let idPath: ItemIDPath = [];
  for (const key of iterator) {
    playlistItem = lookup(key, items);

    if (!playlistItem) break;

    idPath.push(playlistItem.id);

    if (playlistItem.playlistId) {
      const nestedPlaylist = playlistsById[playlistItem.playlistId];
      if (nestedPlaylist) {
        items = nestedPlaylist.items;
      }
    }
  }

  return [playlistItem, idPath];
};

export const getPlaylistItemAtIndexPath = (
  playlistsById: Record<string, Playlist>,
  rootPlaylistId: string,
  path: ItemIndexPath,
) => {
  return getPlaylistItemWithIterator(
    playlistsById,
    rootPlaylistId,
    path,
    (index, items) => items[index],
  );
};

export const getPlaylistItemAtIDPath = (
  playlistsById: Record<string, Playlist>,
  rootPlaylistId: string,
  path: ItemIDPath,
) => {
  return getPlaylistItemWithIterator(
    playlistsById,
    rootPlaylistId,
    path,
    (id, items) => items.find((item) => item.id === id),
  );
};

export const getParentPlaylistAtIndexPath = (
  playlistsById: Record<string, Playlist>,
  rootPlaylistId: string,
  path: ItemIndexPath,
) => {
  let playlist: Playlist | undefined;

  const parentPath = path.slice(0, -1);
  if (parentPath.length === 0) {
    playlist = playlistsById[rootPlaylistId];
  } else {
    const [parentPlaylistItem] = getPlaylistItemAtIndexPath(
      playlistsById,
      rootPlaylistId,
      parentPath,
    );

    if (parentPlaylistItem?.playlistId) {
      playlist = playlistsById[parentPlaylistItem.playlistId];
    }
  }

  return playlist;
};

// Returns true if this item causes a cyclic playlist (ie. nested1 -> nested 2 -> nested1).
// This playlist is a cycle if the playlist id path contains the playlist id.
export const isCycle = (playlistId: string, playlistIdPath: PlaylistIDPath) => {
  return playlistIdPath.indexOf(playlistId) !== -1;
};

export const traversePlaylistNoCycle = (
  playlistsById: Record<string, Playlist>,
  rootPlaylistId: string,
  onItem: (meta: {
    item: PlaylistItem;
    itemPlaylist: Playlist | null;
    itemIdPath: ItemIDPath;
    itemIndexPath: ItemIndexPath;
    itemPlaylistIdPath: PlaylistIDPath;
    itemCausesCyclicPlaylist: boolean;
  }) => void,
) => {
  const traversePlaylist = (
    playlist: Playlist,
    idPath: ItemIDPath = [],
    indexPath: ItemIndexPath = [],
    playlistIdPath: PlaylistIDPath = [],
  ) => {
    playlist.items.forEach((item, index) => {
      const itemIndexPath = [...indexPath, index];
      const itemIdPath = [...idPath, item.id];
      const itemPlaylistIdPath = [...playlistIdPath, playlist.id];

      // Use the playlist in playlistsById to get the latest updates.
      const itemPlaylist = item.playlistId
        ? playlistsById[item.playlistId]
        : null;

      const itemCausesCyclicPlaylist =
        !!item.playlistId && isCycle(item.playlistId, itemPlaylistIdPath);

      onItem({
        item,
        itemPlaylist,
        itemIndexPath,
        itemIdPath,
        itemPlaylistIdPath,
        itemCausesCyclicPlaylist,
      });

      // Protect against infinite recursion by not traversing cyclic playlists.
      if (item.playlistId && !itemCausesCyclicPlaylist) {
        const nestedPlaylist = playlistsById[item.playlistId];
        if (nestedPlaylist) {
          traversePlaylist(
            nestedPlaylist,
            itemIdPath,
            itemIndexPath,
            itemPlaylistIdPath,
          );
        }
      }
    });
  };

  const rootPlaylist = playlistsById[rootPlaylistId];
  if (rootPlaylist) {
    traversePlaylist(rootPlaylist);
  }
};

export const setIndexes = (state: State, rootPlaylistId: string): State => {
  const itemIndexesByItemIdPath: Record<string, ItemIndexPath> = {};
  const itemIdsByPresentationId: Record<string, ItemIDPath[]> = {};
  const itemIdsByPlaylistId: Record<string, ItemIDPath[]> = {};

  traversePlaylistNoCycle(
    getFullPlaylistsById(state),
    rootPlaylistId,
    ({ itemIdPath, itemIndexPath, item }) => {
      // Item id paths can only have one item index path.
      itemIndexesByItemIdPath[serializeIDPath(itemIdPath)] = itemIndexPath;
      // Presentations can have multiple index paths (we need to track this for delete).
      if (item.presentationId) {
        if (!itemIdsByPresentationId[item.presentationId]) {
          itemIdsByPresentationId[item.presentationId] = [];
        }
        itemIdsByPresentationId[item.presentationId].push(itemIdPath);
      }
      // Playlists can be ad multiple index paths (we need to track this for delete).
      if (item.playlistId) {
        if (!itemIdsByPlaylistId[item.playlistId]) {
          itemIdsByPlaylistId[item.playlistId] = [];
        }
        itemIdsByPlaylistId[item.playlistId].push(itemIdPath);
      }
    },
  );

  state.itemIndexesByItemIdPath = itemIndexesByItemIdPath;
  state.itemIdsByPresentationId = itemIdsByPresentationId;
  state.itemIdsByPlaylistId = itemIdsByPlaylistId;

  return state;
};

export const isExpanded = (state: State, path: ItemIDPath) => {
  const pathKey = serializeIDPath(path);
  return !!state.expandedItems[pathKey];
};

export const expandPlaylist = (
  state: State,
  path: ItemIDPath,
  playlistId: string,
) => {
  const pathKey = serializeIDPath(path);
  state.expandedItems[pathKey] = true;
  // Track the expanded playlists ids so we can fetch them on page load.
  incrementObjectValue(state.expandedPlaylistIds, playlistId);
};

export const collapsePlaylist = (
  state: State,
  path: ItemIDPath,
  playlistId: string,
) => {
  const pathKey = serializeIDPath(path);
  delete state.expandedItems[pathKey];
  decrementObjectValue(state.expandedPlaylistIds, playlistId);
};

export const ensureUnsavedPlaylist = (
  state: State,
  playlistId: string,
): UpdatePlaylistWithId | undefined => {
  if (isNewId(playlistId)) {
    // Return the new playlist.
    return state.newPlaylistsById[playlistId];
  } else {
    // Create an updated playlist if one doesn't exist.
    if (!state.updatedPlaylistsById[playlistId]) {
      state.updatedPlaylistsById[playlistId] = { id: playlistId };
    }
    return state.updatedPlaylistsById[playlistId];
  }
};

export const ensureUnsavedPresentation = (
  state: State,
  presentationId: string,
): UpdatePresentationWithId | undefined => {
  if (!state.updatedPresentationsById[presentationId]) {
    state.updatedPresentationsById[presentationId] = { id: presentationId };
  }

  return state.updatedPresentationsById[presentationId];
};

export const ensureUnsavedPlaylistItems = (
  unsavedPlaylist: UpdatePlaylistWithId,
  savedPlaylist: Playlist,
) => {
  unsavedPlaylist.items = unsavedPlaylist.items ?? [...savedPlaylist.items];
  return unsavedPlaylist.items;
};

export const processPendingItems = (state: State) => {
  const pendingItemEntries = Object.entries(state.pendingPlaylistItems);
  let didProcessItems = false;
  for (const [playlistId, items] of pendingItemEntries) {
    const savedPlaylist = state.savedPlaylistsById[playlistId];
    // If the saved playlist doesn't exist then we can't process it's pending items.
    if (!savedPlaylist) continue;

    let unsavedPlaylist = ensureUnsavedPlaylist(state, playlistId);
    if (!unsavedPlaylist) continue;

    const unsavedPlaylistItems = ensureUnsavedPlaylistItems(
      unsavedPlaylist,
      savedPlaylist,
    );
    // Set unsaved playlist with added pending items.
    unsavedPlaylistItems.unshift(...items);

    // Clear pending items for playlist.
    delete state.pendingPlaylistItems[playlistId];

    didProcessItems = true;
  }

  // Set is dirty if items were moved from pending to unsaved playlist.
  if (didProcessItems) {
    state.isDirtyById = getIsDirtyById(state);
  }
};

export type GetIsDirtyByIdOptions = Pick<
  State,
  | 'savedPlaylistsById'
  | 'savedPresentationsById'
  | 'savedPartialPlaylistsById'
  | 'updatedPlaylistsById'
  | 'updatedPresentationsById'
  | 'newPlaylistsById'
>;

export const getIsDirtyById = ({
  savedPlaylistsById,
  savedPresentationsById,
  savedPartialPlaylistsById,
  updatedPlaylistsById,
  updatedPresentationsById,
  newPlaylistsById,
}: GetIsDirtyByIdOptions) => {
  const isDirtyById: Record<string, true> = {};

  const setIsDirty = (playlist: { id: string }) => {
    isDirtyById[playlist.id] = true;
  };

  // Calculate dirty state for new playlists.
  for (const newPlaylist of Object.values(newPlaylistsById)) {
    setIsDirty(newPlaylist);
  }

  // Calculate dirty state for updated playlists.

  for (const updatedPlaylist of Object.values(updatedPlaylistsById)) {
    if (!updatedPlaylist) continue;

    const savedPlaylist =
      savedPlaylistsById[updatedPlaylist.id] ??
      savedPartialPlaylistsById[updatedPlaylist.id];

    // Return true if saved playlist doesn't exist because it's a new playlist.
    if (!savedPlaylist) {
      setIsDirty(updatedPlaylist);
      continue;
    }

    if (
      'name' in updatedPlaylist &&
      updatedPlaylist.name !== savedPlaylist.name
    ) {
      setIsDirty(updatedPlaylist);
      continue;
    }

    if (updatedPlaylist.items) {
      // We only need to compare the item ids to know if playlist items have changed.
      const updatedPlaylistItems = updatedPlaylist.items.map((item) => item.id);
      const savedPlaylistItems = savedPlaylist.items.map((item) => item.id);

      if (!deepEqual(updatedPlaylistItems, savedPlaylistItems)) {
        setIsDirty(updatedPlaylist);
        continue;
      }
    }

    // Calculate dirty state for updated playlist schedules.

    if (
      'startDatetime' in updatedPlaylist &&
      updatedPlaylist.startDatetime !== savedPlaylist.startDatetime
    ) {
      setIsDirty(updatedPlaylist);
      continue;
    }

    if (
      'endDatetime' in updatedPlaylist &&
      updatedPlaylist.endDatetime !== savedPlaylist.endDatetime
    ) {
      setIsDirty(updatedPlaylist);
      continue;
    }

    if (
      'tzid' in updatedPlaylist &&
      updatedPlaylist.tzid !== savedPlaylist.tzid
    ) {
      setIsDirty(updatedPlaylist);
      continue;
    }

    if (
      'scheduleType' in updatedPlaylist &&
      updatedPlaylist.scheduleType !== savedPlaylist.scheduleType
    ) {
      setIsDirty(updatedPlaylist);
      continue;
    }

    if (
      'recurrenceRule' in updatedPlaylist &&
      !deepEqual(updatedPlaylist.recurrenceRule, savedPlaylist.recurrenceRule)
    ) {
      setIsDirty(updatedPlaylist);
      continue;
    }

    if (
      'rule' in updatedPlaylist &&
      !deepEqual(updatedPlaylist.rule, savedPlaylist.rule)
    ) {
      setIsDirty(updatedPlaylist);
      continue;
    }

    // Calculate dirty state for updated playlist tags.

    if (updatedPlaylist.resource?.r?.tags) {
      const updatedTags = updatedPlaylist.resource?.r?.tags || [];
      const savedTags = savedPlaylist.resource.r.tags;
      if (!areTagsEqual(updatedTags, savedTags)) {
        setIsDirty(updatedPlaylist);
        continue;
      }
    }
    if (
      updatedPlaylist.isRuleOnItems !== undefined &&
      savedPlaylist.isRuleOnItems !== updatedPlaylist.isRuleOnItems
    ) {
      setIsDirty(updatedPlaylist);
      continue;
    }
  }

  // Calculate dirty state for updated presentations
  for (const updatedPresentation of Object.values(updatedPresentationsById)) {
    if (!updatedPresentation) continue;
    const savedPresentation =
      savedPresentationsById[updatedPresentation.id] ??
      savedPartialPlaylistsById[updatedPresentation.id];
    // Calculate dirty state for updated playlist tags.

    if (updatedPresentation.resource?.r?.tags) {
      const updatedTags = updatedPresentation.resource?.r?.tags || [];
      const savedTags = savedPresentation.resource.r.tags;
      if (!areTagsEqual(updatedTags, savedTags)) {
        setIsDirty(updatedPresentation);
        continue;
      }
    }
  }

  return isDirtyById;
};

export const getErrorsById = (
  playlistsById: Record<string, Playlist>,
  rootPlaylistId: string,
) => {
  const errorsById: Record<string, Errors> = {};

  const setPlaylistError = (
    playlistId: string,
    errorKey: string,
    errorCode: ErrorCode,
  ) => {
    if (!errorsById[playlistId]) {
      errorsById[playlistId] = {};
    }

    errorsById[playlistId][errorKey] = errorCode;
  };

  const setItemError = (
    itemIdPath: ItemIDPath,
    errorKey: string,
    errorCode: ErrorCode,
  ) => {
    const itemIdKey = serializeIDPath(itemIdPath);

    if (!errorsById[itemIdKey]) {
      errorsById[itemIdKey] = {};
    }

    errorsById[itemIdKey][errorKey] = errorCode;
  };

  const validatePlaylist = (playlist: Playlist) => {
    if (!playlist.name) {
      setPlaylistError(playlist.id, keys.name(), 'invalid');
    }
  };

  // Validate root playlist.
  const rootPlaylist = playlistsById[rootPlaylistId];
  if (rootPlaylist) {
    validatePlaylist(rootPlaylist);
  }

  // Traverse and validate playlist items.
  traversePlaylistNoCycle(
    playlistsById,
    rootPlaylistId,
    ({ itemPlaylist, itemIdPath, itemCausesCyclicPlaylist }) => {
      if (itemPlaylist) {
        validatePlaylist(itemPlaylist);

        if (itemCausesCyclicPlaylist) {
          setItemError(itemIdPath, keys.item(), 'cycle');
        }
      }
    },
  );

  return errorsById;
};

export const getIndexPath = (
  { itemIndexesByItemIdPath }: State,
  path: ItemIDPath,
) => {
  return itemIndexesByItemIdPath[serializeIDPath(path)] || [];
};

export const getOrderedSelectedPaths = ({
  selectedItems,
  itemIndexesByItemIdPath,
}: State) => {
  const selectedPaths = Object.keys(selectedItems).map((pathStr) => ({
    indexPath: itemIndexesByItemIdPath[pathStr],
    idPath: deserializeIDPath(pathStr),
  }));
  return orderByIndexPathAsc(selectedPaths);
};

export const setSavedPlaylist = (state: State, playlist: Playlist) => {
  state.savedPlaylistsById[playlist.id] = playlist;
  for (const item of playlist.items) {
    if (item.playlist) {
      // Nested playlists contain empty items and need to be fetched separately.
      // We use savedPlaylistsById to know whether or not we've fetched the full playlist
      // in order to queue up pending items to be added to a playlist after being fetched.
      // This happens when you drag items into a nested playlist that hasn't been fetched yet.
      // Here we add nested playlists to savedPartialPlaylistsById, which is used to compute isDirty.
      // If the nested playlist is already in savedPlaylistsById that means we've already fetched it
      // and we don't need to add it to savedPartialPlaylistsById.
      if (state.savedPlaylistsById[item.playlist.id]) continue;
      state.savedPartialPlaylistsById[item.playlist.id] = item.playlist;
    } else if (item.presentation) {
      state.savedPresentationsById[item.presentation.id] = item.presentation;
    }
  }

  // We don't need to store the partial playlist anymore once we've fetched the full playlist.
  delete state.savedPartialPlaylistsById[playlist.id];
};

export const setErrors = (state: State, rootPlaylistId: string): State => {
  state.errorsById = getErrorsById(getFullPlaylistsById(state), rootPlaylistId);

  return state;
};

export const setDirty = (state: State): State => {
  state.isDirtyById = getIsDirtyById(state);
  return state;
};

export const removePlaylistItem = (
  state: State,
  itemIdPath: ItemIDPath,
  rootPlaylistId: string,
): State => {
  const itemIdPathKey = serializeIDPath(itemIdPath);
  const itemIndexPath = state.itemIndexesByItemIdPath[itemIdPathKey];

  const playlist = getParentPlaylistAtIndexPath(
    getFullPlaylistsById(state),
    rootPlaylistId,
    itemIndexPath,
  );

  if (!playlist) return state;

  let unsavedPlaylist = ensureUnsavedPlaylist(state, playlist.id);
  if (!unsavedPlaylist) return state;

  const unsavedPlaylistItems = ensureUnsavedPlaylistItems(
    unsavedPlaylist,
    playlist,
  );

  const itemIndex = itemIndexPath[itemIndexPath.length - 1];
  unsavedPlaylistItems.splice(itemIndex, 1);

  // Unselect removed item.
  delete state.selectedItems[itemIdPathKey];

  return state;
};
