import { Theme } from '@raydiant/api-client-js';
import { createSelector } from 'reselect';
import { RootState } from '../../reducers';
import { groupResourcesByOwner } from '../../utilities';

export interface ThemesById {
  [themeId: string]: Theme | undefined;
}

export const selectThemesById = (state: RootState): ThemesById =>
  state.v2Themes.byId;

const themeOrder = [
  'Clean',
  'Front Page',
  'Reserved',
  'Seattle',
  'Showroom',
  'Fresh',
  'X-Ray',
  'Solid',
  'Refresh',
  'Blueprint',
  'Chalkboard',
  'Parade',
  'Woodwork',
  'Fashion',
  'Golden',
  'Slate',
  'Grill',
];

export const selectSortedThemes = (state: RootState) =>
  state.themes.items.sort(
    (a, b) => themeOrder.indexOf(a.name) - themeOrder.indexOf(b.name),
  );

export const selectThemesByOwner = createSelector(
  [selectThemesById],
  (themesById) => {
    return groupResourcesByOwner({
      themes: Object.values(themesById) as Theme[],
    });
  },
);

export const selectThemesByPresentationId = createSelector(
  [selectThemesById],
  (themesById) => {
    const themesByPresentation: Record<string, Theme[]> = {};

    Object.values(themesById).forEach((theme) => {
      if (!theme) return;
      if (!theme.presentationId) return;

      if (!themesByPresentation[theme.presentationId]) {
        themesByPresentation[theme.presentationId] = [];
      }

      themesByPresentation[theme.presentationId].push(theme);
    });

    return themesByPresentation;
  },
);
