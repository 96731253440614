import Heading5 from 'raydiant-elements/typography/Heading5';
import Text from 'raydiant-elements/typography/Text';
import Input from 'raydiant-elements/core/Input';
import InputLabel from 'raydiant-elements/core/InputLabel';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Form from 'raydiant-elements/core/Form';
import Link from 'raydiant-elements/core/Link';
import Button from 'raydiant-elements/core/Button';
import Column from 'raydiant-elements/layout/Column';
import Row from 'raydiant-elements/layout/Row';
import React, { FC, useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NotFoundError, ForbiddenError } from '@raydiant/api-client-js';
import miraClient from '../../clients/miraClient';
import { getRemoteAssistUser } from '../../utilities';
import logger from '../../logger';
import * as userActions from '../../actions/user';

interface RemoteAssistanceProps {}

const RemoteAssistance: FC<RemoteAssistanceProps> = () => {
  const dispatch = useDispatch();
  const currentRemoteAssistanceUser = getRemoteAssistUser();

  // State

  const [remoteAssistanceEmail, setRemoteAssistanceEmail] = useState<
    string | null
  >(null);
  const [remoteAssistStatus, setRemoteAssistStatus] = useState<
    '' | 'pending' | 'success' | 'error'
  >('');
  const [remoteAssistError, setRemoteAssistError] = useState<
    '' | 'notFound' | 'forbidden'
  >('');

  // Callbacks

  const remoteAssistance = useCallback(async () => {
    if (!remoteAssistanceEmail) return;

    setRemoteAssistStatus('pending');
    try {
      // Check if user is valid using the remote assist endpoint to validate the
      // email address. We don't care about the response here since remote assisting
      // actually happens on the next page load.
      await miraClient.remoteAssist(remoteAssistanceEmail);
      dispatch(userActions.remoteAssist(remoteAssistanceEmail));
      setRemoteAssistStatus('success');
    } catch (err) {
      logger.error(err);
      if (err instanceof NotFoundError) {
        setRemoteAssistError('notFound');
      } else if (err instanceof ForbiddenError) {
        setRemoteAssistError('forbidden');
      }
      setRemoteAssistStatus('error');
    }
  }, [dispatch, remoteAssistanceEmail]);

  const remoteAssistanceUser = useCallback(() => {
    dispatch(userActions.remoteAssist(''));
  }, [dispatch]);

  // Side-effects

  // Reset error when remote assistance email changes.
  useEffect(() => {
    setRemoteAssistStatus('');
  }, [remoteAssistanceEmail]);

  // Render

  return (
    <>
      <Heading5 overline>Remote Assistance</Heading5>
      <Column>
        {currentRemoteAssistanceUser && (
          <Text small>
            You are remote assisting {currentRemoteAssistanceUser}.<br />
            <Link onClick={remoteAssistanceUser}>Leave remote assistance.</Link>
          </Text>
        )}

        <Form onSubmit={remoteAssistance}>
          <InputLabel error={remoteAssistStatus === 'error'}>
            Target email
          </InputLabel>
          <Row halfMargin>
            <Input
              type="email"
              onChange={setRemoteAssistanceEmail}
              value={remoteAssistanceEmail ?? ''}
              error={remoteAssistStatus === 'error'}
            />
            <Button
              label="Remote Assist"
              color="progress"
              type="submit"
              disabled={
                !remoteAssistanceEmail || remoteAssistStatus === 'pending'
              }
            />
          </Row>
          {remoteAssistStatus === 'error' && (
            <InputHelperText error indent>
              {remoteAssistError === 'notFound' && 'User does not exist'}
              {remoteAssistError === 'forbidden' &&
                'Remote assistance not enabled for this user'}
              {remoteAssistError === '' && 'Failed to remote assist'}
            </InputHelperText>
          )}
        </Form>
      </Column>
    </>
  );
};

export default RemoteAssistance;
