import { Presentation, ApiClientError } from '@raydiant/api-client-js';
import { UseQueryOptions } from 'react-query';
import raydiant from '../clients/raydiant';
import useProtectedQuery from './useProtectedQuery';
import { keys } from '../queryClient';

export default function usePresentation(
  presentationId: string | undefined,
  opts: UseQueryOptions<Presentation, ApiClientError> = {},
) {
  const query = useProtectedQuery<Presentation, ApiClientError>(
    presentationId ? keys.presentation(presentationId) : [],
    async () => {
      if (!presentationId) {
        throw new Error('usePresentation is missing presentationId');
      }
      const presentations = await raydiant.getPresentations({
        ids: [presentationId],
      });

      const presentation = presentations.find((pl) => pl.id === presentationId);
      if (!presentation) {
        throw new Error(
          'usePresentation could not find presentation in result.',
        );
      }
      return presentation;
    },
    {
      enabled: !!presentationId && opts.enabled,
      refetchOnMount: opts.refetchOnMount,
      refetchInterval: opts.refetchInterval,
      onSuccess: opts.onSuccess,
    },
  );

  return query;
}
