import { Presentation } from '@raydiant/api-client-js';
import { LocalFileUploads } from '../types';
import { isFileUploading, hasFileUploadError } from './fileUploadUtils';

export const getPresentationFileStatus = (
  presentation: Presentation,
  localUploads: LocalFileUploads,
) => {
  if (!presentation.fileUploads) {
    return { isUploading: false, hasError: false };
  }

  return {
    isUploading: presentation.fileUploads.some((fileUpload) =>
      isFileUploading(fileUpload, localUploads),
    ),
    hasError: presentation.fileUploads.some((fileUpload) =>
      hasFileUploadError(fileUpload, localUploads),
    ),
  };
};
