import concat from 'lodash/concat';
import unionBy from 'lodash/unionBy';
import without from 'lodash/without';
import { ActionType, getType } from 'typesafe-actions';
import * as deviceActions from '../actions/devices';
import * as D from '../clients/mira/types/Device';

export type DeviceActions = ActionType<typeof deviceActions>;

export type DevicesState = Readonly<{
  items: D.Device[];
  isFetching: boolean;
  isSaving: boolean;
  error: string;
  itemsFetching: string[];
  itemsSaving: string[];
  isRegistering: boolean;
  registrationError: string;
}>;

const initialDevicesState: DevicesState = {
  items: [],
  isFetching: false,
  isSaving: false,
  error: '',
  itemsFetching: [],
  itemsSaving: [],
  isRegistering: false,
  registrationError: '',
};

export default function devicesReducer(
  state = initialDevicesState,
  action: DeviceActions,
): DevicesState {
  switch (action.type) {
    // Fetch all
    case getType(deviceActions.fetchDevicesAsync.request):
      return { ...state, isFetching: true, error: '' };
    case getType(deviceActions.fetchDevicesAsync.success):
      return { ...state, isFetching: false, items: action.payload };
    case getType(deviceActions.fetchDevicesAsync.failure):
      return { ...state, isFetching: false, error: action.payload.message };

    // Fetch single
    case getType(deviceActions.fetchDeviceAsync.request):
      return {
        ...state,
        itemsFetching: concat(state.itemsFetching, action.payload),
        error: '',
      };
    case getType(deviceActions.fetchDeviceAsync.success):
      return {
        ...state,
        itemsFetching: without(state.itemsFetching, action.payload.id),
        items: unionBy([action.payload], state.items, 'id'),
      };
    case getType(deviceActions.fetchDeviceAsync.failure):
      return {
        ...state,
        itemsFetching: without(state.itemsFetching, action.payload.id),
        error: action.payload.error.message,
      };

    // Update
    case getType(deviceActions.updateDeviceAsync.request):
      return {
        ...state,
        itemsSaving: concat(state.itemsSaving, action.payload.id),
        error: '',
      };

    // Update, publish and restart
    case getType(deviceActions.publishDeviceAsync.request):
      return {
        ...state,
        itemsSaving: concat(state.itemsSaving, action.payload),
        error: '',
      };

    // Success with device data return from the API
    case getType(deviceActions.updateDeviceAsync.success):
      return {
        ...state,
        itemsSaving: without(state.itemsSaving, action.payload.id),
        items: unionBy([action.payload], state.items, 'id'),
      };

    case getType(deviceActions.updateDeviceAsync.failure):
      return {
        ...state,
        itemsSaving: without(state.itemsSaving, action.payload.id),
        error: action.payload.error.message,
      };

    // Register
    case getType(deviceActions.registerDeviceAsync.request):
      return { ...state, isRegistering: true, registrationError: '' };
    case getType(deviceActions.registerDeviceAsync.success):
      return {
        ...state,
        isRegistering: false,
        registrationError: '',
        items: unionBy([action.payload], state.items, 'id'),
      };
    case getType(deviceActions.registerDeviceAsync.failure):
      return {
        ...state,
        isRegistering: false,
        registrationError: action.payload.error.message,
      };
    case getType(deviceActions.clearRegisterDeviceError):
      return { ...state, registrationError: '' };

    default:
      return state;
  }
}
