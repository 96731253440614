import {
  Presentation,
  ApplicationVariables,
  ApplicationVersion,
  PresentationProperty,
} from '@raydiant/api-client-js';
import { Path, PresentationUpload } from '../types';
import isPathEqual from './isPathEqual';

const getFileUrl = (
  fileProp: any, // eslint-disable-line
  path: Path,
  localUploads: PresentationUpload[],
): string => {
  const localUpload = localUploads.find((lu) => isPathEqual(lu.path, path));
  if (localUpload) return localUpload.localUrl;
  return fileProp ? fileProp.url || '' : '';
};

const hasAppVarChanges = (
  properties: PresentationProperty[],
  prevAppVars: ApplicationVariables,
  nextAppVars: ApplicationVariables,
  localUploads: PresentationUpload[],
  path: Path,
): boolean => {
  for (const prop of properties) {
    const prevAppVar = prevAppVars[prop.name];
    const nextAppVar = nextAppVars[prop.name];
    const propPath = [...path, prop.name];

    if (prop.type === 'file') {
      const prevFileUrl = getFileUrl(prevAppVar, propPath, localUploads);
      const nextFileUrl = getFileUrl(nextAppVar, propPath, localUploads);

      if (prevFileUrl !== nextFileUrl) {
        return true;
      }
    } else if (prop.type === 'array') {
      const prevArray = prevAppVar || [];
      const nextArray = nextAppVar || [];

      if (prevArray.length !== nextArray.length) {
        return true;
      }

      for (let i = 0; i < nextArray.length; i += 1) {
        if (
          prop.properties &&
          hasAppVarChanges(
            prop.properties,
            prevAppVar[i],
            nextAppVar[i],
            localUploads,
            [...propPath, i],
          )
        ) {
          return true;
        }
      }
    } else if (prop.type === 'selection' && prop.multiple) {
      const prevArray = prevAppVar || [];
      const nextArray = nextAppVar || [];

      if (
        prevArray.length !== nextArray.length ||
        prevArray.some((value: any) => !nextArray.includes(value)) // eslint-disable-line
      ) {
        return true;
      }
    } else if (prevAppVar !== nextAppVar) {
      return true;
    }
  }
  return false;
};

export default function hasPresentationChanged(
  prevPres: Presentation,
  nextPres: Presentation,
  appVersion: ApplicationVersion,
  localUploads: PresentationUpload[] = [],
) {
  // Enable save when new application version is released.
  if (prevPres.appVersionId !== nextPres.appVersionId) {
    return true;
  }
  // There are no changes, no need to compare.
  if (!prevPres) {
    return false;
  }
  // Check if presentation name or duration has changed.
  if (
    prevPres.name !== nextPres.name ||
    prevPres.duration !== nextPres.duration ||
    prevPres.themeId !== nextPres.themeId
  ) {
    return true;
  }
  // Check if app vars have changed.
  return hasAppVarChanges(
    appVersion.presentationProperties,
    prevPres.applicationVariables,
    nextPres.applicationVariables,
    localUploads,
    ['applicationVariables'],
  );
}
