import { createStandardAction, createAsyncAction } from 'typesafe-actions';
import { SortProfileOptions } from '../../utilities';

export const loadAccountPage = createStandardAction('@ACCOUNT_PAGE/LOAD')();

export const loadAccountPageAsync = createAsyncAction(
  '@ACCOUNT_PAGE/LOAD_REQUEST',
  '@ACCOUNT_PAGE/LOAD_SUCCESS',
  '@ACCOUNT_PAGE/LOAD_FAILURE',
)<void, void, Error>();

export const setSortOptions = createStandardAction(
  '@ACCOUNT_PAGE/SET_MEMBER_LIST_SORT_OPTIONS',
)<SortProfileOptions>();

export const setSearchQuery = createStandardAction(
  '@ACCOUNT_PAGE/SET_MEMBER_LIST_SEARCH_QUERY',
)<string>();
