import * as PL from '../clients/mira/types/Playlist';
import createDefaultResource from './createDefaultResource';

export const DEFAULT_PLAYLIST_NAME = 'New Playlist';

export default function createDefaultPlaylist(
  opts: DeepPartial<PL.Playlist> = {},
): PL.Playlist {
  return {
    id: '',
    name: DEFAULT_PLAYLIST_NAME,
    items: [],
    isRuleOnItems: false,
    scheduleType: 'join',
    description: '',
    tzid: null,
    startDatetime: null,
    endDatetime: null,
    recurrenceRule: null,
    rule: null,
    ...opts,
    resource: createDefaultResource(opts.resource),
  };
}
