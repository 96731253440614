import {
  Rule,
  isRuleOperatorType,
  isRuleGroupType,
  RuleGroupType,
} from '@raydiant/playlist-rule-engine';
import {
  RuleSourceID,
  RuleOperatorID,
  getRuleSourceIdForVariable,
} from './ruleTokenInputData';

export interface RuleTokenOpenBracket {
  type: 'open';
}

export interface RuleTokenCloseBracket {
  type: 'close';
}

export interface RuleTokenRule {
  type: 'rule';
  source1?: RuleSourceID;
  operator?: RuleOperatorID;
  source2?: RuleSourceID;
}

export interface RuleTokenAnd {
  type: 'and';
}

export interface RuleTokenOr {
  type: 'or';
}

export type RuleToken =
  | RuleTokenOpenBracket
  | RuleTokenCloseBracket
  | RuleTokenRule
  | RuleTokenAnd
  | RuleTokenOr;

export const tokenizeRule = (rootRule: Rule | null): RuleToken[] => {
  const tokens: RuleToken[] = [];

  const traverseRule = (
    rule: Rule,
    parentGroup: RuleGroupType | null = null,
  ) => {
    if (isRuleGroupType(rule)) {
      // We don't want to show open or close brackets for the top-level root,
      // only for nested rules.
      if (parentGroup) {
        tokens.push({ type: 'open' });
      }

      rule.group.forEach((nestedRule, index) => {
        if (index !== 0) {
          tokens.push({ type: rule.type });
        }
        traverseRule(nestedRule, rule);
      });
      if (parentGroup) {
        tokens.push({ type: 'close' });
      }
    } else if (isRuleOperatorType(rule)) {
      const source1 =
        'var' in rule.left ? getRuleSourceIdForVariable(rule.left) : null;
      const source2 =
        'var' in rule.right ? getRuleSourceIdForVariable(rule.right) : null;

      if (source1 && source2) {
        tokens.push({ type: 'rule', operator: rule.type, source1, source2 });
      } else {
        // The user can only select valid sources so this should happen but warn just in case.
        console.warn(
          `Invalid rule source found when evaluting rule: ${JSON.stringify(
            rule,
          )}`,
        );
      }
    }
  };

  if (rootRule) {
    traverseRule(rootRule);
  }

  return tokens;
};

const ruleErrorCodes = {
  missingSource1: {
    message: 'Missing Source 1.',
  },
  missingOperator: {
    message: 'Missing Compare.',
  },
  missingSource2: {
    message: 'Missing Source 2.',
  },
  andOrMismatch: {
    message: 'And & Or must be seperated by brackets.',
  },
  invalidClose: {
    message: 'Invalid rule.',
  },
  missingOpenBracket: {
    message: 'Missing Opening Bracket.',
  },
  missingCloseBracket: {
    message: 'Missing Closing Bracket.',
  },
  missingNextRuleOrOpenBracket: {
    message: 'Should be followed by a Rule or Bracket.',
  },
  missingPreviousRuleOrCloseBracket: {
    message: 'Should be preceded by a Rule or Bracket.',
  },
  missingNextAndOr: {
    message: 'Missing Joiner.',
  },
};

export type RuleErrorCode = keyof typeof ruleErrorCodes;

export interface RuleError {
  index: number;
  error: RuleErrorCode;
}

export type PatrialRuleGroupType = Partial<RuleGroupType> &
  Pick<RuleGroupType, 'group'>;

export interface RuleStackItem {
  openIndex: number | null;
  ruleGroup: PatrialRuleGroupType | RuleGroupType;
}

const isValidRuleGroup = (
  ruleGroup: PatrialRuleGroupType | RuleGroupType,
): ruleGroup is RuleGroupType => {
  return 'type' in ruleGroup;
};

const isRuleOrOpenBracket = (
  token: RuleToken,
): token is RuleTokenRule | RuleTokenOpenBracket | RuleTokenCloseBracket => {
  return token.type === 'rule' || token.type === 'open';
};

const isRuleOrCloseBracket = (
  token: RuleToken,
): token is RuleTokenRule | RuleTokenOpenBracket | RuleTokenCloseBracket => {
  return token.type === 'rule' || token.type === 'close';
};

export const validateTokens = (
  tokens: RuleToken[],
): [Rule | null, RuleError[]] => {
  const errors: RuleError[] = [];
  const ruleStack: RuleStackItem[] = [];
  const rootRuleGroup: PatrialRuleGroupType = { group: [] };

  let currentRuleGroup = rootRuleGroup;
  ruleStack.push({ openIndex: null, ruleGroup: currentRuleGroup });

  tokens.forEach((token, index) => {
    const nextToken: RuleToken | undefined = tokens[index + 1];
    const prevToken: RuleToken | undefined = tokens[index - 1];

    if (token.type === 'open') {
      const nestedRuleGroup = { group: [] };
      currentRuleGroup = nestedRuleGroup;

      ruleStack.push({ openIndex: index, ruleGroup: nestedRuleGroup });
    } else if (token.type === 'close') {
      // Group is closed, pop current rule group off the stack.
      const currentRuleStackItem = ruleStack.pop();

      if (currentRuleStackItem) {
        const prevRuleStackItem = ruleStack[ruleStack.length - 1];

        if (prevRuleStackItem) {
          const completedRuleGroup = currentRuleStackItem.ruleGroup;
          const parentRuleGroup = prevRuleStackItem.ruleGroup;

          if (isValidRuleGroup(completedRuleGroup)) {
            parentRuleGroup.group.push(completedRuleGroup);
          } else if (completedRuleGroup.group.length === 1) {
            // If the completed rule group only has one rule in the group then
            // unnest the group by adding the single rule to the parent group.
            parentRuleGroup.group.push(completedRuleGroup.group[0]);
          } else if (completedRuleGroup.group.length !== 0) {
            errors.push({ index, error: 'invalidClose' });
          }

          // Set the current rule group to the previous (parent) rule group.
          currentRuleGroup = parentRuleGroup;
        } else {
          errors.push({ index, error: 'missingOpenBracket' });
        }
      } else {
        errors.push({ index, error: 'invalidClose' });
      }
    } else if (token.type === 'rule') {
      if (!token.source1) {
        errors.push({ index, error: 'missingSource1' });
      } else if (!token.operator) {
        errors.push({ index, error: 'missingOperator' });
      } else if (!token.source2) {
        errors.push({ index, error: 'missingSource2' });
      } else {
        currentRuleGroup.group.push({
          type: token.operator,
          left: { var: token.source1.split('.') },
          right: { var: token.source2.split('.') },
        });
      }

      if (nextToken && nextToken.type === 'rule') {
        errors.push({ index, error: 'missingNextAndOr' });
      }
    } else if (token.type === 'and' || token.type === 'or') {
      if (!currentRuleGroup.type) {
        currentRuleGroup.type = token.type;
      } else if (currentRuleGroup.type !== token.type) {
        errors.push({ index, error: 'andOrMismatch' });
      }

      if (!nextToken || !isRuleOrOpenBracket(nextToken)) {
        errors.push({ index, error: 'missingNextRuleOrOpenBracket' });
      } else if (!prevToken || !isRuleOrCloseBracket(prevToken)) {
        errors.push({ index, error: 'missingPreviousRuleOrCloseBracket' });
      }
    } else {
      // The user isn't allowed to enter an invalid token type so we can't surface a helpful
      // error message. This log is here just completeness and shouldn't happen.
      console.warn(
        `Invalid token type found when validating tokens: ${JSON.stringify(
          token,
        )}`,
      );
    }
  });

  // If there are any rule groups left on the stack besiders the root rule then
  // the rule is invalid, likely because an end bracket was not found for the
  // corresponding open bracket. Add an error at the index of the opening bracket
  // for the rule group.
  for (const { openIndex } of ruleStack) {
    // The root rule always exists in the stack with an openIndex of null.
    if (openIndex !== null) {
      errors.push({ index: openIndex, error: 'missingCloseBracket' });
    }
  }

  // Return null rule with errors if there are any errors.
  if (errors.length > 0) {
    return [null, errors];
  }

  // If the root rule group is empty, return a null rule.
  if (rootRuleGroup.group.length === 0) {
    return [null, []];
  }

  // If the root rule group only has one item it, return the
  // single item as the rule instead of the group.
  if (rootRuleGroup.group.length === 1) {
    return [rootRuleGroup.group[0], []];
  }

  // Return the root rule group if it's valid.
  if (isValidRuleGroup(rootRuleGroup)) {
    return [rootRuleGroup, []];
  }

  // Throw an error if the rule group isn't valid. If this happens then
  // it likely means we are forgetting an error case above.
  throw new Error('Failed to validate rule tokens');
};

export const getRuleErrorMessage = (code: RuleErrorCode) => {
  return ruleErrorCodes[code].message;
};

export const updateTokenAtIndex = (
  tokens: RuleToken[],
  updatedToken: RuleToken,
  updateIndex: number,
) => {
  return tokens.map((token, index) =>
    index === updateIndex ? updatedToken : token,
  );
};

export const insertTokenAtIndex = (
  tokens: RuleToken[],
  newToken: RuleToken,
  newIndex: number,
) => {
  const updatedTokens = [...tokens];
  updatedTokens.splice(newIndex, 0, newToken);
  return updatedTokens;
};

export const removeTokenAtIndex = (
  tokens: RuleToken[],
  deleteIndex: number,
) => {
  const updatedTokens = [...tokens];
  updatedTokens.splice(deleteIndex, 1);
  return updatedTokens;
};

export const initialRuleToken: RuleToken = { type: 'rule' };

export const isInitialRuleToken = (token: RuleToken) => {
  return token === initialRuleToken;
};
