import { Playlist, Presentation } from '@raydiant/api-client-js';
import { QueryClient } from 'react-query';
import * as keys from './queryKeys';

export function setPlaylistQueryCache(
  queryClient: QueryClient,
  playlist: Playlist,
) {
  queryClient.setQueryData(keys.playlist(playlist.id), playlist);
}

export function setPresentationQueryCache(
  queryClient: QueryClient,
  presentation: Presentation,
) {
  queryClient.setQueryData(keys.presentation(presentation.id), presentation);
}
