import { RootState } from '../../reducers';

export const selectLastLoadedDate = (state: RootState) =>
  state.addContentPage.lastLoadedDate;

export const selectSortOptions = (state: RootState) =>
  state.addContentPage.sortOptions;

export const selectSearchQuery = (state: RootState) =>
  state.addContentPage.searchQuery;

export const selectSelectedItems = (state: RootState) =>
  state.addContentPage.selectedItems;

export const selectItemsToAdd = (state: RootState) =>
  state.addContentPage.itemsToAdd;
