import React, { FC } from 'react';
import PaperModal from 'raydiant-elements/core/PaperModal';
import CloseIcon from '@material-ui/icons/Close';
import Button from 'raydiant-elements/core/Button';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import Spacer from 'raydiant-elements/layout/Spacer';

export interface PlaylistModalFooterProps {
  enableDone: boolean;
  onCancel: () => void;
  onDone: () => void;
}

const PlaylistModalFooter: FC<PlaylistModalFooterProps> = ({
  enableDone,
  onCancel,
  onDone,
}) => {
  return (
    <PaperModal.Footer>
      <ActionBar.Action
        color="primaryText"
        icon={<CloseIcon />}
        label="Cancel"
        onClick={onCancel}
        fullWidth
      />

      <Spacer />
      <Button fullWidth color="primary" onClick={onDone} disabled={!enableDone}>
        Done
      </Button>
    </PaperModal.Footer>
  );
};

export default PlaylistModalFooter;
