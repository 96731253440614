import { useLayoutEffect } from 'react';
import usePlaylist from '../../hooks/usePlaylist';
import { isNewId } from '../../utilities/identifiers';
import usePlaylistPageContext from './usePlaylistPageContext';

export interface UsePlaylistPagePlaylistOptions {
  enabled?: boolean;
}

export default function usePlaylistPagePlaylist(
  playlistId: string,
  opts: UsePlaylistPagePlaylistOptions,
) {
  const page = usePlaylistPageContext();

  // Queries

  // Fetch playlist items when expanded if it's not a new playlist.
  const {
    status,
    data: fullPlaylist,
    ...rest
  } = usePlaylist(playlistId, {
    enabled: opts.enabled && !isNewId(playlistId),
  });

  // Effects

  // Update page state with fetched playlist.
  const setSavedPlaylist = page.setSavedPlaylist;
  // Add fetched playlist for page state. This copies the playlist from the react-query cache into
  // page state, creating two sources of truths. We need to do this so that the page reducer has access
  // to the original playlists. Maybe there's a better way to use the react-query data directly in the
  // usePlaylistPageState reducer?
  useLayoutEffect(() => {
    if (!fullPlaylist) return;
    setSavedPlaylist(fullPlaylist);
  }, [fullPlaylist, setSavedPlaylist]);

  return {
    ...rest,
    status,
    data: page.getPlaylist(playlistId),
  };
}
