import React, { ReactNode, useRef, useEffect, useState } from 'react';
import PaperModal from 'raydiant-elements/core/PaperModal';
import TagIcon from 'raydiant-elements/icons/Tag';
import RuleIcon from 'raydiant-elements/icons/Rule';
import CalendarClockIcon from 'raydiant-elements/icons/CalendarClock';
import { Playlist, Presentation } from '@raydiant/api-client-js';
import usePlaylistPageContext from './usePlaylistPageContext';
import PlaylistModalSchedule from './PlaylistModalSchedule';
import PlaylistModalRules from './PlaylistModalRules';
import PlaylistModalTags from './PlaylistModalTags';
import PresentationModalTags from './PresentationModalTags';

const PlaylistItemModal = () => {
  const {
    classes,
    state: { modalItemPath, modalItem, modalItemMode },
    getPlaylist,
    getPresentation,
    closeModal,
  } = usePlaylistPageContext();

  const isOpen = modalItemPath !== null;

  // State

  // Use state to store the playlist to render in the modal. This is used to prevent
  // ensure the same playlist is used for the lifetime that the modal is open. This
  // prevents issues with changes being reverted after expanding the nested playlist
  // that's rendered in the modal.
  const [playlist, setPlaylist] = useState<Playlist | null>(null);
  const [presentation, setPresentation] = useState<Presentation | null>(null);

  // Refs

  const prevIsOpenRef = useRef(isOpen);
  const modalItemRef = useRef(modalItem);

  // Effects

  // Set the playlist when modal is opened or when the selected playlist changes.
  useEffect(() => {
    const didModalOpen = isOpen && !prevIsOpenRef.current;
    const didSelectedItemChange = modalItem?.id !== modalItemRef.current?.id;

    if (didModalOpen || didSelectedItemChange) {
      if (modalItem?.playlistId) {
        const playlist = getPlaylist(modalItem.playlistId);
        setPresentation(null); //need to clear any presentations currently being set
        setPlaylist(playlist ? { ...playlist } : null);
      } else if (modalItem?.presentationId) {
        const presentation = getPresentation(modalItem.presentationId);
        setPlaylist(null); //need to clear any playlists currently being set
        setPresentation(presentation ? { ...presentation } : null);
      }
    }

    prevIsOpenRef.current = isOpen;
    modalItemRef.current = modalItem;
  }, [isOpen, getPlaylist, modalItem, getPresentation]);

  // Render

  let title: string = '';
  let titleIcon: ReactNode = null;
  let content: ReactNode = null;

  if (playlist) {
    if (modalItemMode === 'schedule') {
      title = `Schedule: ${playlist.name}`;
      titleIcon = <CalendarClockIcon />;
      content = (
        <PlaylistModalSchedule playlist={playlist} onClose={closeModal} />
      );
    } else if (modalItemMode === 'rules') {
      title = `Rules: ${playlist.name}`;
      titleIcon = <RuleIcon />;
      content = <PlaylistModalRules playlist={playlist} onClose={closeModal} />;
    } else if (modalItemMode === 'tags') {
      title = `Tags: ${playlist.name}`;
      titleIcon = <TagIcon />;
      content = <PlaylistModalTags playlist={playlist} onClose={closeModal} />;
    }
  } else if (presentation) {
    if (modalItemMode === 'tags') {
      title = `Tags: ${presentation.name}`;
      titleIcon = <TagIcon />;
      content = (
        <PresentationModalTags
          presentation={presentation}
          onClose={closeModal}
        />
      );
    }
  }

  return (
    <PaperModal
      title={title}
      titleIcon={titleIcon}
      color="light"
      className={classes.playlistModal}
      open={isOpen}
      onClose={closeModal}
      showClose={false}
    >
      {content}
    </PaperModal>
  );
};

export default PlaylistItemModal;
