import React, {
  FC,
  forwardRef,
  useRef,
  useContext,
  useEffect,
  ReactNode,
} from 'react';
import GridContext from './GridContext';

export interface GridItemProps {
  nodeId?: string;
  children?: ReactNode; // This is needed because of forwardRef
}

const GridItem: FC<GridItemProps> = ({ nodeId, children }, ref) => {
  const rootRef = useRef<HTMLDivElement | null>(null);

  const { gridItemClassName, addNodeToNodeMap, removeNodeFromNodeMap } =
    useContext(GridContext);

  // Add grid item to parent node map.
  useEffect(() => {
    if (nodeId && rootRef.current) {
      addNodeToNodeMap(nodeId, rootRef.current);
    }
    return () => {
      if (nodeId) {
        removeNodeFromNodeMap(nodeId);
      }
    };
  }, [addNodeToNodeMap, removeNodeFromNodeMap, nodeId]);

  return (
    <div
      className={gridItemClassName}
      ref={(innerRef) => {
        rootRef.current = innerRef;
        if (ref) {
          ref.current = innerRef;
        }
      }}
    >
      {children}
    </div>
  );
};

export default forwardRef(GridItem);
