import { eventChannel } from 'redux-saga';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import {
  locationChanged,
  LocationChangedPayload,
  update,
} from '../actions/history';
import history from '../history';

export const historyLocationChannel = eventChannel<LocationChangedPayload>(
  (emitter) =>
    history.listen((location, action) => {
      emitter({ location, action });
    }),
);

function* watchHistoryLocation() {
  yield takeEvery(historyLocationChannel, function* ({ location, action }) {
    yield put(locationChanged({ location, action }));
  });
}

function* watchHistoryUpdate() {
  yield takeEvery<ActionType<typeof update>>(getType(update), ({ payload }) => {
    if (payload) {
      if (payload.replace) {
        history.replace(payload.replace);
      } else if (payload.push) {
        history.push(payload.push);
      }
    }
  });
}

export default all([fork(watchHistoryLocation), fork(watchHistoryUpdate)]);
