import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import { Theme } from '@raydiant/api-client-js';
import * as themeActions from '../../actions/themes';

export type ThemeActions = ActionType<typeof themeActions>;

export type ThemesState = Readonly<{
  byId: {
    [themeId: string]: Theme;
  };
}>;

const initialThemesState: ThemesState = {
  byId: {},
};

export default function themesReducer(
  state = initialThemesState,
  action: ThemeActions,
): ThemesState {
  switch (action.type) {
    // getThemesAsync
    case getType(themeActions.getThemesAsync.success): {
      const themes = action.payload;
      return produce(state, (draftState) => {
        themes.forEach((theme) => {
          draftState.byId[theme.id] = theme;
        });
      });
    }

    // createThemeAsync
    case getType(themeActions.createThemeAsync.success): {
      return produce(state, (draftState) => {
        draftState.byId[action.payload.id] = action.payload;
      });
    }

    // updateThemeAsync
    case getType(themeActions.updateThemeAsync.success): {
      return produce(state, (draftState) => {
        draftState.byId[action.payload.id] = action.payload;
      });
    }

    // deleteThemeAsync
    case getType(themeActions.deleteThemeAsync.request): {
      return produce(state, (draftState) => {
        delete draftState.byId[action.payload];
      });
    }

    // addThemeResourceACL
    case getType(themeActions.addThemeResourceACL): {
      return produce(state, (draftState) => {
        const { themeId, resourceACL } = action.payload;
        const theme = draftState.byId[themeId];
        if (!theme) return;
        theme.resource.r.resourceACLs.push(resourceACL);
      });
    }

    // removeThemeResourceACL
    case getType(themeActions.removeThemeResourceACL): {
      return produce(state, (draftState) => {
        const { themeId, aclId } = action.payload;
        const theme = draftState.byId[themeId];
        if (!theme) return;

        theme.resource.r.resourceACLs = theme.resource.r.resourceACLs.filter(
          (acl) => acl.id !== aclId,
        );
      });
    }

    default: {
      return state;
    }
  }
}
