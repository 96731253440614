// NOTE: This is a legacy import, use ./raydiant

import ApiClient, { RequestOptions, URL } from '@raydiant/api-client-js';
import config from '../config';
import { getMasqueradeUser, getRemoteAssistUser } from '../utilities';
import auth0Client from './auth0Client';
import * as U from './mira/types/User';

// Allow setting access token with a query parameter. This is useful to bypass
// Auth0 in development.
let accessTokenOverride: string | null = null;
const urlParams = new URLSearchParams(window.location.search);
if (urlParams.get('token')) {
  accessTokenOverride = urlParams.get('token');
  urlParams.delete('token');
}

// Prevent duplicate masquerade calls by re-using the same promise
// during parallel requests.
let masqueradePromise: Promise<U.MasqueradeResponse> | null = null;
// Prevent duplicate remote assist calls by re-using the same promise
// during parallel requests.
let remoteAssistPromise: Promise<U.RemoteAssistResponse> | null = null;

export const getAccessToken = async (requestOptions?: RequestOptions<any>) => {
  if (accessTokenOverride) {
    return accessTokenOverride;
  }

  // We don't want to use the masquerade token for the masquerade endpoint
  // or we'll end up in an infinite loop fetching the token.
  const isMasqueradeRequest = requestOptions?.url === URL.masquerade();
  const masqueradeUser = getMasqueradeUser();

  if (masqueradeUser && !isMasqueradeRequest) {
    if (!masqueradePromise) {
      masqueradePromise = apiClient.masquerade(masqueradeUser); // eslint-disable-line @typescript-eslint/no-use-before-define
    }
    const { token } = await masqueradePromise;
    masqueradePromise = null;
    return token;
  }

  // We don't want to use the remote assist token for the remote assist endpoint
  // or we'll end up in an infinite loop fetching the token.
  const isRemoteAssistRequest =
    requestOptions?.url === URL.supportRemoteAssist();
  const remoteAssistUser = getRemoteAssistUser();

  if (remoteAssistUser && !isRemoteAssistRequest) {
    if (!remoteAssistPromise) {
      remoteAssistPromise = apiClient.remoteAssist(remoteAssistUser); // eslint-disable-line @typescript-eslint/no-use-before-define
    }
    const { token } = await remoteAssistPromise;
    remoteAssistPromise = null;
    return token;
  }

  return await auth0Client.getTokenSilently();
};

const apiClient: ApiClient = new ApiClient({
  baseUrl: config.miraApiUrl,
  getAccessToken,
  fetcher: window.fetch.bind(window),
});

export default apiClient;
