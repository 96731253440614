import { RootState } from '../../reducers';

export const selectLastLoadedDate = (state: RootState) =>
  state.libraryPage.lastLoadedDate;

export const selectMainSortOptions = (state: RootState) =>
  state.libraryPage.mainSortOptions;

export const selectSideSortOptions = (state: RootState) =>
  state.libraryPage.sideSortOptions;

export const selectSearchQuery = (state: RootState) =>
  state.libraryPage.searchQuery;

export const selectSelectedNodeIds = (state: RootState) =>
  state.libraryPage.selectedNodeIds;
