import { Store } from 'redux';
import { SagaMiddleware } from 'redux-saga';
import { RootState } from '../reducers';
import configureStoreDev from './configureStore.dev';
import configureStoreProd from './configureStore.prod';

export interface StoreExtended extends Store<RootState> {
  run: SagaMiddleware<{}>['run'];
}

const configureStore = (initialState?: RootState) => {
  return process.env.NODE_ENV === 'production'
    ? configureStoreProd(initialState)
    : configureStoreDev(initialState);
};

export default configureStore;
