import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) => {
  return createStyles({
    register: {
      width: '100%',
      maxWidth: 400,
      borderRadius: theme.borderRadius.lg,
      padding: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  });
});
