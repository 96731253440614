import { Profile, Theme } from '@raydiant/api-client-js';
import { ThemesById } from '../selectors/v2/themes';

export default function getDefaultThemeForUser(
  currentUser: Profile | undefined,
  themesById: ThemesById,
) {
  let defaultTheme: Theme | undefined;

  const themes = Object.values(themesById);

  if (currentUser) {
    // Use the latest user theme as the default.
    defaultTheme = themes.find((t) => {
      return (
        !!t &&
        !t.presentationId && // Don't user one-off themes as default.
        t.resource.profile.id === currentUser.id &&
        t.resource.deletedAt === null
      );
    });
  }

  if (!defaultTheme) {
    // Default to Clean theme if user does not have any custom themes.
    defaultTheme = themes.find((t) => t?.name === 'Clean');
  }

  return defaultTheme;
}
