import React, { FC } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const OpenBracketIcon: FC = () => {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path d="M10 21.578c1.261 0 2.204-.442 2.828-1.326.623-.884 1.02-2.002 1.189-3.354.169-1.352.254-3.048.254-5.09 0-2.092-.085-3.815-.254-5.167-.169-1.352-.566-2.463-1.19-3.334C12.205 2.436 11.261 2 10 2v1.813c.52 0 .91.339 1.17 1.014.26.676.429 1.567.507 2.672.078 1.105.117 2.542.117 4.31 0 1.742-.039 3.165-.117 4.27-.078 1.105-.25 1.992-.517 2.662-.266.67-.653 1.004-1.16 1.004v1.833z" />
    </SvgIcon>
  );
};

export default OpenBracketIcon;
