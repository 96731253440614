import { ActionType, getType } from 'typesafe-actions';
import * as applicationPageActions from './actions';

export type ApplicationPageActions = ActionType<typeof applicationPageActions>;

export type ApplicationPageState = Readonly<{
  isLoading: boolean;
  error: string;
}>;

const initialApplicationPageState: ApplicationPageState = {
  isLoading: false,
  error: '',
};

export default function applicationPageReducer(
  state = initialApplicationPageState,
  action: ApplicationPageActions,
): ApplicationPageState {
  switch (action.type) {
    case getType(applicationPageActions.loadApplicationPageAsync.request):
      return { ...state, isLoading: true, error: '' };

    case getType(applicationPageActions.loadApplicationPageAsync.success):
      return { ...state, isLoading: false };

    case getType(applicationPageActions.loadApplicationPageAsync.failure):
      return {
        ...state,
        isLoading: false,
        error: action.payload.message,
      };

    default:
      return state;
  }
}
