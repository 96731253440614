const chargebeeSite = process.env.REACT_APP_CHARGEBEE_SITE || 'raydiant-test';
const chargebeeApiUrl = `https://${chargebeeSite}.chargebee.com`;
const appPreviewBlacklist: Record<string, string[]> = {
  iOS: ['News'],
};

if (!process.env.REACT_APP_LOGGLY_CUSTOMER_TOKEN) {
  throw new Error(
    'Missing environment variable: REACT_APP_LOGGLY_CUSTOMER_TOKEN',
  );
}

const config = {
  logglyCustomerToken: process.env.REACT_APP_LOGGLY_CUSTOMER_TOKEN,
  service: process.env.REACT_APP_SERVICE || 'raydiant-dash',
  environment: process.env.REACT_APP_ENVIRONMENT || 'local',
  revision: process.env.REACT_APP_REVISION,
  sessionCookieKey:
    process.env.REACT_APP_SESSION_COOKIE_KEY || 'raydiant-dash-session',
  miraApiUrl:
    process.env.REACT_APP_API_URL || 'https://api.staging.raydiant.com',
  disableFlushLogs:
    !process.env.REACT_APP_ENVIRONMENT ||
    process.env.REACT_APP_ENVIRONMENT === 'test' ||
    process.env.REACT_APP_ENVIRONMENT === 'local',
  disableConsoleLogs:
    process.env.REACT_APP_ENVIRONMENT === 'staging' ||
    process.env.REACT_APP_ENVIRONMENT === 'production',
  connectivityWizardPlaybookId: 43303,
  fileUploadPollMS: 5000,
  devicePublishPollMS: 5000,
  legacyMinDuration: 5,
  chargebeeSite,
  chargebeeApiUrl,
  SYBSoundZonePollMS: 60000,
  appPreviewBlacklist,
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  auth0DbConnection: process.env.REACT_APP_AUTH0_DB_CONNECTION || '',
  auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  versionCookieKey:
    process.env.REACT_APP_VERSION_COOKIE_KEY || 'raydiant-dash-version',
  systemThemesProfileId: process.env.REACT_APP_SYSTEM_THEMES_PROFILE_ID,
  flyersAppId: process.env.REACT_APP_FLYERS_APP_ID,
  videosAppId: process.env.REACT_APP_VIDEOS_APP_ID,
  weatherAppId: process.env.REACT_APP_WEATHER_APP_ID,
  picturesAppId: process.env.REACT_APP_PICTURES_APP_ID,
  bluejeansAppId: process.env.REACT_APP_BLUEJEANS_APP_ID,
  pmwAppId: process.env.REACT_APP_PMW_APP_ID,
  sybAppId: process.env.REACT_APP_SYB_APP_ID,
  spAppId: process.env.REACT_APP_SP_APP_ID,
  tbAppId: process.env.REACT_APP_TB_APP_ID,
  hooplaAppId: process.env.REACT_APP_HOOPLA_APP_ID,
  xiteAppId: process.env.REACT_APP_XITE_APP_ID,
  screenfeedAppId: process.env.REACT_APP_SCREENFEED_APP_ID,
  kioskAppId: process.env.REACT_APP_KIOSK_APP_ID,
  youtubeAppId: process.env.REACT_APP_YOUTUBE_APP_ID,
  presenterAppId: process.env.REACT_APP_PRESENTER_APP_ID,
  cloverAppId: process.env.REACT_APP_CLOVER_APP_ID,
};

export default config;
