import React, { FC, forwardRef, useCallback } from 'react';
import { Folder } from '@raydiant/api-client-js';
import { useDispatch } from 'react-redux';
import * as folderActions from '../../actions/folders';
import LibraryResourceCard from '../LibraryResourceCard';
import useStyles from './FolderCard.styles';
import FolderCardOutline from './FolderCardOutline';
import FolderCardThumbnail from './FolderCardThumbnail';

interface FolderCardProps {
  folder: Folder;
  mode?: 'select' | 'edit' | 'manage';
  isSelected?: boolean;
  isLocked?: boolean;
  showSelect?: boolean;
  onSelect: () => void;
  onClick: () => void;
}

const FolderCard: FC<FolderCardProps> = (
  {
    folder,
    mode = 'edit',
    isSelected = false,
    isLocked = false,
    showSelect,
    onSelect,
    onClick,
  },
  ref,
) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Callbacks

  const deleteFolder = useCallback(() => {
    dispatch(folderActions.deleteAllFolders([folder.id], {}));
  }, [folder, dispatch]);

  // Render

  return (
    <LibraryResourceCard
      ref={ref}
      mode={mode}
      name={folder.name}
      resource={folder.resource}
      isSelected={isSelected}
      isLocked={isLocked}
      showSelect={showSelect}
      onSelect={onSelect}
      onOverlayClick={onClick}
      hideCopy
      onDelete={deleteFolder}
    >
      {({ isDark }) => (
        <>
          <FolderCardThumbnail isDark={isDark} />
          {isSelected && <FolderCardOutline className={classes.outline} />}
        </>
      )}
    </LibraryResourceCard>
  );
};

export default forwardRef(FolderCard);
