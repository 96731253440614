import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

const dropShadowFilter = `drop-shadow(0px 2px 1px rgba(0,0,0,0.2)) drop-shadow(0px 1px 3px rgba(0,0,0,0.14)) drop-shadow(0px 3px 6px rgba(0,0,0,0.12))`;

export default makeStyles((theme: Theme) => {
  return createStyles({
    thumbnailImage: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundClip: 'padding-box',
      backgroundImage: 'url(https://assets.raydiant.com/folder-thumbnail.svg)',
      filter: dropShadowFilter,
    },

    thumbnailImageDark: {
      filter: `${dropShadowFilter} brightness(0.5)`,
    },

    outline: {
      position: 'absolute',
      top: -4,
      left: -4,
      width: 'calc(100% + 8px)',
      height: 'calc(100% + 8px)',
      pointerEvents: 'none',
    },

    icon: {
      width: '100%',
      height: '100%',
    },
  });
});
