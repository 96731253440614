import { all, call, fork, put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as pairingCodeActions from '../actions/pairingCodes';
import * as D from '../clients/mira/types/Device';
import miraClient from '../clients/miraClient';
import logger from '../logger';

type GeneratePairingCodeAction = ReturnType<
  typeof pairingCodeActions.generatePairingCode
>;

const generatePairingCode = function* (deviceId: string) {
  try {
    yield put(pairingCodeActions.generatePairingCodeAsync.request(deviceId));

    const pairingCode: D.SYBPairingCode = yield call(() =>
      miraClient.generateSYBPairingCode(deviceId),
    );

    yield put(pairingCodeActions.generatePairingCodeAsync.success(pairingCode));

    return pairingCode;
  } catch (error) {
    logger.error(error);
    yield put(
      pairingCodeActions.generatePairingCodeAsync.failure({
        id: deviceId,
        error,
      }),
    );
  }
};

const watchGeneratePairingCode = function* () {
  while (true) {
    const { payload }: GeneratePairingCodeAction = yield take(
      getType(pairingCodeActions.generatePairingCode),
    );
    yield fork(generatePairingCode, payload);
  }
};

export default all([fork(watchGeneratePairingCode)]);
