import * as React from 'react';
import ContentModal from '../ContentModal';
import Navigation from '../Navigation';

interface PageProps {
  title?: string;
  backTo?: string;
  hideNavigation?: boolean;
}

const Page: React.SFC<PageProps> = ({
  title,
  backTo,
  hideNavigation,
  children,
}) => (
  <>
    {!hideNavigation && <Navigation backTo={backTo} title={title} />}
    {children}
    <ContentModal />
  </>
);

export default Page;
