import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import * as F from '../clients/mira/types/Folder';

export interface MetaFolder {
  onCreate?: (folder: F.Folder) => void;
  onUpdate?: (folder: F.Folder) => void;
  onMove?: () => void;
  onDelete?: () => void;
}

export const createFolder = createStandardAction('@FOLDER/CREATE')<
  F.CreateFolder,
  MetaFolder
>();

export const createFolderAsync = createAsyncAction(
  '@FOLDER/CREATE_REQUEST',
  '@FOLDER/CREATE_SUCCESS',
  '@FOLDER/CREATE_FAILURE',
)<F.CreateFolder, F.Folder, Error>();

export const updateFolder = createStandardAction('@FOLDER/UPDATE')<
  Partial<F.Folder> & { id: string },
  MetaFolder
>();

export const updateFolderAsync = createAsyncAction(
  '@FOLDER/UPDATE_REQUEST',
  '@FOLDER/UPDATE_SUCCESS',
  '@FOLDER/UPDATE_FAILURE',
)<string, F.Folder, Error>();

export const fetchFoldersAsync = createAsyncAction(
  '@FOLDER/FETCH_ALL_REQUEST',
  '@FOLDER/FETCH_ALL_SUCCESS',
  '@FOLDER/FETCH_ALL_FAILURE',
)<void, F.Folder[], Error>();

export const fetchFolder = createStandardAction('@FOLDER/FETCH')<
  string,
  MetaFolder
>();

export const fetchFolderAsync = createAsyncAction(
  '@FOLDER/FETCH_REQUEST',
  '@FOLDER/FETCH_SUCCESS',
  '@FOLDER/FETCH_FAILURE',
)<string, F.Folder, string>();

export const fetchVirtualFolderAsync = createAsyncAction(
  '@FOLDER/VIRTUAL_FETCH_REQUEST',
  '@FOLDER/VIRTUAL_FETCH_SUCCESS',
  '@FOLDER/VIRTUAL_FETCH_FAILURE',
)<string, F.VirtualFolder & { id: string }, Error>();

export const moveAllItemsToFolder = createStandardAction('@FOLDER/MOVE_ALL')<
  {
    presentationIds?: string[];
    playlistIds?: string[];
    folderIds?: string[];
    parentFolderId: string | null;
  },
  MetaFolder
>();

export const moveFolderToFolderAsync = createAsyncAction(
  '@FOLDER/MOVE_REQUEST',
  '@FOLDER/MOVE_SUCCESS',
  '@FOLDER/MOVE_FAILURE',
)<
  {
    folder: F.Folder;
    parentFolderId: string | null;
  },
  string,
  string
>();

export const deleteFolder = createStandardAction('@FOLDER/DELETE')<
  string,
  MetaFolder
>();

export const deleteFolderAsync = createAsyncAction(
  '@FOLDER/DELETE_REQUEST',
  '@FOLDER/DELETE_SUCCESS',
  '@FOLDER/DELETE_FAILURE',
)<string, string, Error>();

export const deleteAllFolders = createStandardAction('@FOLDER/DELETE_ALL')<
  string[],
  MetaFolder
>();

export const fetchLibraryFolder = createStandardAction(
  '@FOLDER/FETCH_LIBRARY',
)();
