import * as PL from '../clients/mira/types/Playlist';

export default function searchPlaylists<T extends PL.Playlist>(
  playlists: T[],
  searchQuery: string,
) {
  const searchQueryInsenstive = searchQuery.toLowerCase().trim();
  return playlists.filter((pl) =>
    pl.name.toLowerCase().includes(searchQueryInsenstive),
  );
}
