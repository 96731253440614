import { batchActions } from 'redux-batched-actions';
import { all, call, fork, put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as userActions from '../../actions/user';
import * as domainActions from '../../actions/domains';
import * as themeActions from '../../actions/themes';
import * as U from '../../clients/mira/types/User';
import * as DM from '../../clients/mira/types/Domain';
import * as T from '../../clients/mira/types/Theme';
import miraClient from '../../clients/miraClient';
import logger from '../../logger';
import * as actions from './actions';

const watchLoadAccountPage = function* () {
  while (true) {
    yield take(getType(actions.loadAccountPage));

    try {
      yield put(actions.loadAccountPageAsync.request());

      const [profile, themes, domain]: [
        U.Profile,
        T.Theme[],
        DM.Domain | null,
      ] = yield all([
        call(() => miraClient.getProfile()),
        call(() => miraClient.getThemes()),
        call(() => miraClient.getDomain()),
      ]);

      yield put(
        batchActions([
          actions.loadAccountPageAsync.success(),
          userActions.getProfileAsync.success(profile),
          themeActions.getThemesAsync.success(themes),
          ...(domain ? [domainActions.fetchDomainAsync.success(domain)] : []),
        ]),
      );
    } catch (error) {
      logger.error(error);
      yield put(actions.loadAccountPageAsync.failure(error));
    }
  }
};

export default all([fork(watchLoadAccountPage)]);
