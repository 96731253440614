import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

export default function useDuplicateSnackbar() {
  const { enqueueSnackbar } = useSnackbar();

  const enqueueDuplicateSnackbar = useCallback(
    (isDuplicatingAnotherUsersResource: boolean) => {
      const message = isDuplicatingAnotherUsersResource
        ? 'Successfully duplicated to your Library'
        : 'Duplication successful';

      enqueueSnackbar(message, { variant: 'success' });
    },
    [enqueueSnackbar],
  );

  return { enqueueDuplicateSnackbar };
}
