import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import Button from 'raydiant-elements/core/Button';
import Text from 'raydiant-elements/core/Text';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Row from 'raydiant-elements/layout/Row';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export type LoadingStatus = 'idle' | 'loading' | 'success' | 'error';

export interface LoadingButtonProps {
  color?: 'default' | 'progress' | 'primary';
  iconAlignment?: 'start' | 'end';
  status?: LoadingStatus;
  label: string;
  icon?: React.ReactNode;
  loadingLabel?: string;
  successLabel?: string;
  errorLabel?: string;
  fullWidth: boolean;
  disabled?: boolean;
  delay?: number;
  onClick: () => void;
}

const LoadingButton = ({
  color = 'progress',
  status = 'idle',
  label,
  icon,
  iconAlignment,
  loadingLabel,
  successLabel,
  errorLabel,
  fullWidth,
  disabled,
  delay = 5000,
  onClick,
}: LoadingButtonProps) => {
  const classes = useStyles();

  // State

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>('idle');

  // Effects

  const prevStatus = useRef<LoadingStatus>(status);
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (status === 'loading') {
      setLoadingStatus('loading');
    } else if (status === 'success') {
      if (prevStatus.current === 'loading') {
        setLoadingStatus('success');
        timeout = setTimeout(() => {
          setLoadingStatus('idle');
        }, delay);
      }
    } else if (status === 'error') {
      if (prevStatus.current === 'loading') {
        setLoadingStatus('error');
        timeout = setTimeout(() => {
          setLoadingStatus('idle');
        }, delay);
      }
    }

    prevStatus.current = status;

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [status, delay]);

  // Render

  if (loadingStatus === 'loading') {
    return (
      <Row
        halfMargin
        center
        className={cn(
          classes.status,
          classes.primary,
          fullWidth && classes.fullWidth,
        )}
      >
        <Text xsmall>{loadingLabel}</Text>
        <CircularProgress color="inherit" size={20} />
      </Row>
    );
  }

  if (loadingStatus === 'success') {
    return (
      <Row
        halfMargin
        center
        className={cn(
          classes.status,
          classes.primary,
          fullWidth && classes.fullWidth,
        )}
      >
        <Text xsmall>{successLabel}</Text>
        <CheckCircleIcon className={classes.success} />
      </Row>
    );
  }

  if (loadingStatus === 'error') {
    return (
      <Row
        halfMargin
        center
        className={cn(
          classes.status,
          classes.error,
          fullWidth && classes.fullWidth,
        )}
      >
        <Text xsmall>{errorLabel}</Text>
        <ErrorIcon className={classes.error} />
      </Row>
    );
  }

  return (
    <Button
      color={color}
      label={label}
      icon={icon}
      iconAlignment={iconAlignment}
      onClick={onClick}
      fullWidth={fullWidth}
      disabled={disabled}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    status: {
      color: theme.palette.primary.main,
      // Copied from Button styles to match alignment.
      height: 40,

      [theme.breakpoints.down('xs')]: {
        height: 'auto',
      },
    },

    fullWidth: {
      width: '100%',
      flexShrink: 1,
      justifyContent: 'center',
    },

    primary: {
      color: theme.palette.primary.main,
    },

    muted: {
      color: theme.palette.text.secondary,
    },

    success: {
      color: theme.palette.progress.main,
    },

    error: {
      color: theme.palette.error.main,
    },
  });
});

export default LoadingButton;
