import { createStandardAction, createAsyncAction } from 'typesafe-actions';
import { Application } from '../clients/mira/types/Application';

export const fetchApplications = createStandardAction('@APPLICATIONS/FETCH')();

export const fetchApplicationsAsync = createAsyncAction(
  '@APPLICATIONS/FETCH_REQUEST',
  '@APPLICATIONS/FETCH_SUCCESS',
  '@APPLICATIONS/FETCH_FAILURE',
)<void, Application[], Error>();
