import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { Playlist, Device } from '@raydiant/api-client-js';

export const fetchPlaylists = createStandardAction('@PLAYLISTS/FETCH')<
  string[] | undefined
>();

export interface MetaPlaylist {
  folderId?: string | null; // The folder to add the playlist to after create.
  deviceId?: string; // The device to assign the playlist to after create.
  onCreate?: (playlist: Playlist) => void;
  onUpdate?: (playlist: Playlist) => void;
  onDelete?: () => void;
  onMove?: () => void;
}

export const fetchPlaylistsAsync = createAsyncAction(
  '@PLAYLISTS/FETCH_REQUEST',
  '@PLAYLISTS/FETCH_SUCCESS',
  '@PLAYLISTS/FETCH_FAILURE',
)<void, Playlist[], Error>();

export type CreatePlaylistPayload = Playlist;
export const createPlaylist = createStandardAction('@PLAYLISTS/CREATE')<
  CreatePlaylistPayload,
  MetaPlaylist
>();

export type CreateDevicePlaylistPayload = {
  playlist: Playlist;
  device: Device;
};
export const createPlaylistForDevice = createStandardAction(
  '@PLAYLISTS/CREATE_FOR_DEVICE',
)<CreateDevicePlaylistPayload>();

export type CreateNestedPlaylistPayload = {
  playlist: Playlist;
  parentPlaylist: Playlist;
};
export const createNestedPlaylist = createStandardAction(
  '@PLAYLISTS/CREATE_NESTED',
)<CreateNestedPlaylistPayload, MetaPlaylist>();

export const createPlaylistAsync = createAsyncAction(
  '@PLAYLISTS/CREATE_REQUEST',
  '@PLAYLISTS/CREATE_SUCCESS',
  '@PLAYLISTS/CREATE_FAILURE',
)<Playlist, Playlist, Error>();

export type UpdatePlaylistPayload = Partial<Playlist> & { id: string };
export const updatePlaylist = createStandardAction('@PLAYLISTS/UPDATE')<
  UpdatePlaylistPayload,
  MetaPlaylist
>();

export const updatePlaylistAsync = createAsyncAction(
  '@PLAYLISTS/UPDATE_REQUEST',
  '@PLAYLISTS/UPDATE_SUCCESS',
  '@PLAYLISTS/UPDATE_FAILURE',
)<Partial<Playlist> & { id: string }, Playlist, Error>();

export const deletePlaylist = createStandardAction('@PLAYLISTS/DELETE')<
  string,
  MetaPlaylist
>();

export const deletePlaylistAsync = createAsyncAction(
  '@PLAYLISTS/DELETE_REQUEST',
  '@PLAYLISTS/DELETE_SUCCESS',
  '@PLAYLISTS/DELETE_FAILURE',
)<string, string, Error>();

export const deleteAllPlaylists = createStandardAction('@PLAYLISTS/DELETE_ALL')<
  string[],
  MetaPlaylist
>();

export const movePlaylistToFolderAsync = createAsyncAction(
  '@PLAYLIST/MOVE_REQUEST',
  '@PLAYLIST/MOVE_SUCCESS',
  '@PLAYLIST/MOVE_FAILURE',
)<
  {
    playlist: Playlist;
    parentFolderId: string | null;
  },
  string,
  string
>();

export const copyPlaylist = createStandardAction('@PLAYLIST/COPY')<
  { playlistId: string; copyName?: string },
  {
    onSuccess?: (playlist: Playlist) => void;
  }
>();

export const copyPlaylistAsync = createAsyncAction(
  '@PLAYLIST/COPY_REQUEST',
  '@PLAYLIST/COPY_SUCCESS',
  '@PLAYLIST/COPY_FAILURE',
)<void, Playlist, Error>();
