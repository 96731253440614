import React, { FC, useCallback, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import { Playlist, Folder, VirtualFolder } from '@raydiant/api-client-js';
import * as playlistActions from '../../actions/playlists';
import { getDuplicateResourceName } from '../../utilities';
import useDuplicateSnackbar from '../../hooks/useDuplicateSnackbar';
import { selectUserProfile } from '../../selectors/user';
import LibraryResourceCard from '../LibraryResourceCard';
import useStyles from './PlaylistCard.styles';
import PlaylistCardOutline from './PlaylistCardOutline';
import PlaylistCardThumbnail from './PlaylistCardThumbnail';

interface PlaylistCardProps {
  playlist: Playlist;
  mode?: 'select' | 'edit' | 'manage';
  folder: Folder | VirtualFolder;
  isSelected?: boolean;
  isLocked?: boolean;
  showSelect?: boolean;
  onSelect: () => void;
  onEdit: () => void;
}

const PlaylistCard: FC<PlaylistCardProps> = (
  {
    playlist,
    folder,
    mode = 'edit',
    isSelected = false,
    isLocked = false,
    showSelect,
    onSelect,
    onEdit,
  },
  ref,
) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueDuplicateSnackbar } = useDuplicateSnackbar();

  // Selectors

  const currentUser = useSelector(selectUserProfile);

  // Callbacks

  const copyPlaylist = useCallback(() => {
    const isDuplicatingAnotherUsersResource =
      playlist.resource.profile.id !== currentUser?.id;

    dispatch(
      playlistActions.copyPlaylist(
        {
          playlistId: playlist.id,
          copyName: getDuplicateResourceName(playlist.name, folder.playlists),
        },
        {
          onSuccess: () =>
            enqueueDuplicateSnackbar(isDuplicatingAnotherUsersResource),
        },
      ),
    );
  }, [playlist, folder, dispatch, enqueueDuplicateSnackbar, currentUser]);

  const deletePlaylist = useCallback(() => {
    dispatch(playlistActions.deleteAllPlaylists([playlist.id], {}));
  }, [playlist, dispatch]);

  // Render

  return (
    <LibraryResourceCard
      ref={ref}
      mode={mode}
      name={playlist.name}
      icon={<PlaylistPlayIcon className={classes.icon} />}
      resource={playlist.resource}
      isSelected={isSelected}
      isLocked={isLocked}
      showSelect={showSelect}
      onSelect={onSelect}
      onEdit={onEdit}
      onCopy={copyPlaylist}
      onDelete={deletePlaylist}
    >
      {({ isDark }) => (
        <>
          <PlaylistCardThumbnail isDark={isDark} />
          {isSelected && <PlaylistCardOutline className={classes.outline} />}
        </>
      )}
    </LibraryResourceCard>
  );
};

export default forwardRef(PlaylistCard);
