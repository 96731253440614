import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { PlaylistPageQueryParams } from './playlistPageTypes';

export default function usePlaylistPageQueryParams(): PlaylistPageQueryParams {
  const location = useLocation();

  return useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return {
      applicationId: searchParams.get('applicationId') ?? undefined,
      folderId: searchParams.get('folderId') ?? undefined,
      selectionId: searchParams.get('selectionId') ?? undefined,
      saveTo: searchParams.get('saveTo') ?? '',
      backTo: searchParams.get('backTo') ?? '',
      backToLabel: searchParams.get('backToLabel') ?? '',
      previewMode:
        searchParams.get('previewMode') === 'vertical'
          ? 'vertical'
          : 'horizontal',
      didSave: searchParams.get('didSave') === 'true',
      sessionId: searchParams.get('sessionId') ?? undefined,
      playlistId: searchParams.get('playlistId') ?? undefined,
      deviceId: searchParams.get('deviceId') ?? undefined,
      addItemsAfterPath: searchParams.get('addItemsAfterPath') ?? undefined,
    };
  }, [location.search]);
}
