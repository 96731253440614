import Heading1 from 'raydiant-elements/typography/Heading1';
import Heading2 from 'raydiant-elements/typography/Heading2';
import Link from 'raydiant-elements/core/Link';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Center from 'raydiant-elements/layout/Center';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import auth0Client from '../../clients/auth0Client';
import Page from '../../components/Page';
import history from '../../history';
import logger from '../../logger';
import * as paths from '../../routes/paths';

interface LoginCallbackPageProps extends RouteComponentProps {}

interface LoginCallbackPageState {
  didError: boolean;
}

class LoginCallbackPage extends React.Component<
  LoginCallbackPageProps,
  LoginCallbackPageState
> {
  state = {
    didError: false,
  };

  async componentDidMount() {
    try {
      const { appState } = await auth0Client.handleRedirectCallback();
      const backTo = (appState && appState.backTo) || paths.home();
      history.replace(backTo);
    } catch (err) {
      this.setState({ didError: true });
      logger.error(err);
    }
  }

  render() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const error = searchParams.get('error');
    const { didError } = this.state;
    return (
      <Page>
        <Center>
          {didError && (
            <>
              <Heading1>
                {error === 'unauthorized'
                  ? 'Your account has been suspended.'
                  : 'Oops! Something went wrong.'}
              </Heading1>
              <Heading2>
                Please contact{' '}
                <Link href="mailto:support@raydiant.com">
                  support@raydiant.com
                </Link>
                .
              </Heading2>
            </>
          )}
          {!didError && <CircularProgress size={30} />}
        </Center>
      </Page>
    );
  }
}

export default LoginCallbackPage;
