import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import LibraryPage from './LibraryPage';
import reducer from './reducer';
import libraryPageSagas from './sagas';

const libraryPageReducer = persistReducer(
  {
    key: 'LibraryPage',
    whitelist: ['mainSortOptions', 'sideSortOptions'],
    storage,
  },
  reducer,
);

export default LibraryPage;
export { libraryPageSagas, libraryPageReducer };
