import Link from 'raydiant-elements/core/Link';
import Avatar from 'raydiant-elements/core/Avatar';
import PaperModal from 'raydiant-elements/core/PaperModal';
import Text from 'raydiant-elements/core/Text';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import CollapsableSelectField from 'raydiant-elements/core/CollapsableSelectField';
import Heading5 from 'raydiant-elements/typography/Heading5';
import Title from 'raydiant-elements/typography/Title';
import Row from 'raydiant-elements/layout/Row';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import React, { FC, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from '../../clients/mira/types/Resource';
import * as DM from '../../clients/mira/types/Domain';
import { selectUserProfile } from '../../selectors/user';
import * as domainActions from '../../actions/domains';
import { canEditDomainRole, domainRoleLabels } from '../../utilities';

interface MemberAccountEditProps {
  profile: R.ResourceProfile;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(0.5),
    },

    helperText: {
      marginTop: '-0.25em',
    },

    roleDescription: {
      '& ul': {
        margin: 0,
        padding: 0,
      },

      '& ul > li:not(:last-child)': {
        marginBottom: theme.spacing(0.5),
      },
    },
  }),
);

const domainRoleDescriptions: { [key: string]: React.ReactNode } = {
  superadmin: (
    <ul>
      <li>View, edit and delete their own resources.</li>
      <li>View, edit and delete resources owned by any user in the domain.</li>
      <li>
        Invite other users to join the domain and assign roles to any user.
      </li>
    </ul>
  ),
  admin: (
    <ul>
      <li>View, edit and delete their own resources.</li>
      <li>
        View and edit resources owned by any user in the domain, but{' '}
        <em>cannot</em> delete resources owned by other users.
      </li>
      <li>
        Invite other users to join the domain and assign roles to any user.
      </li>
    </ul>
  ),
  standard: (
    <ul>
      <li>View, edit and delete their own resources.</li>
      <li>
        View resources owned by any user in the domain, but <em>cannot</em>{' '}
        delete and <em>cannot</em> edit resources owned by other users.
      </li>
      <li>
        May view, edit, but <em>cannot</em> delete resources that have been
        shared by another user.
      </li>
    </ul>
  ),
  restricted: (
    <ul>
      <li>View, edit and delete their own resources.</li>
      <li>
        May view, edit, but <em>cannot</em> delete resources that have been
        shared by another user.
      </li>
      <li>
        If a Super Administrator / Administrator / Standard User adds a
        presentation or playlist to a playlist owned by a Restricted User, the
        Restricted User <em>cannot</em> remove them from that playlist.
      </li>
    </ul>
  ),
};

const MemberAccountEdit: FC<MemberAccountEditProps> = ({ profile }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Selectors

  const currentUser = useSelector(selectUserProfile);

  // State

  const [expandedRole, setExpandedRole] = useState<string>(profile.domainRole);

  // Callbacks

  const setRole = useCallback(
    (updatedDomainRole: string) => {
      dispatch(
        domainActions.setProfileDomainRole({
          profileId: profile.id,
          domainId: profile.domainId,
          domainRole: updatedDomainRole as DM.DomainRole,
        }),
      );
    },
    [dispatch, profile],
  );

  // Side-effects

  // Reset expanded role when domainRole changes.
  useEffect(() => {
    setExpandedRole(profile.domainRole);
  }, [profile]);

  // Render

  const isRoleEditable = currentUser && canEditDomainRole(currentUser, profile);

  return (
    <>
      <PaperModal.Body>
        <Row halfMargin>
          <Avatar thumbnailUrl={profile.thumbnailUrl} name={profile.name} />
          <div>
            <Title className={classes.title}>{profile.name}</Title>
            <Heading5>{domainRoleLabels[profile.domainRole]}</Heading5>
          </div>
        </Row>
      </PaperModal.Body>
      <Scrollable>
        <PaperModal.Body>
          <Text bold>Account email</Text>
          <br />
          <Text large>{profile.email}</Text>

          <br />
          <br />

          <Heading5 overline>Role Management</Heading5>
          <CollapsableSelectField
            disabled={!isRoleEditable}
            value={profile.domainRole}
            onChange={setRole}
          >
            {Object.entries(domainRoleLabels).map(([value, label]) => (
              <CollapsableSelectField.Option
                className={classes.roleDescription}
                key={value}
                value={value}
                label={label}
                expanded={expandedRole === value}
                onClick={() => setExpandedRole(value)}
              >
                {domainRoleDescriptions[value]}
              </CollapsableSelectField.Option>
            ))}
          </CollapsableSelectField>
          <br />
          <br />

          <Link underline href="mailto:support@raydiant.com">
            Need to remove this account?
          </Link>
          <InputHelperText className={classes.helperText}>
            Contact support for assistance
          </InputHelperText>
        </PaperModal.Body>
      </Scrollable>
    </>
  );
};

export default MemberAccountEdit;
