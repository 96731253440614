import * as querystring from 'querystring';
import isViewportAbove from '../utilities/isViewportAbove';
import { createSessionId } from '../utilities/identifiers';
import createPath from './createPath';

interface PathOptions {
  useContentModal?: boolean;
  contentModalBasePath?: string;
}

const getContentModalBasePath = ({ pathname, search }: Location) => {
  return `${pathname}${search}`;
};

const maybeForwardToContentModal = (
  path: string,
  useContentModal: boolean = isViewportAbove('sm'),
  basePath: string = getContentModalBasePath(window.location),
) => {
  if (useContentModal && !window.localStorage.getItem('disableContentModal')) {
    const [pathname, search = ''] = basePath.split('?');
    // Override the existing contentPath if it exists.
    const qs = {
      ...querystring.parse(search),
      contentPath: path,
    };
    return `${pathname}?${querystring.stringify(qs)}`;
  }
  return path;
};

// Base paths

export const home = createPath('/', () => '/');
export const indexHtml = createPath('/index.html', () => '/index.html');

// Login paths

export const login = createPath(
  '/login',
  (queryParams: { backTo?: string } = {}) =>
    `/login?${querystring.stringify({ backTo: queryParams.backTo })}`,
  { unprotected: true },
);

export const loginCallback = createPath(
  '/login-callback',
  () => `/login-callback`,
  { unprotected: true },
);

export const signup = createPath(
  '/signup',
  (queryParams: { backTo?: string } = {}) =>
    `/signup?${querystring.stringify({ backTo: queryParams.backTo })}`,
  { unprotected: true },
);

// TODO: Legacy changePassword redirect, remove after enterprise migration is complete.
export const legacyChangePassword = createPath(
  '/changePassword',
  () => `/changePassword`,
  { unprotected: true },
);

// Screens paths

export const screens = createPath('/screens', () => '/screens');

export const screenSettings = createPath(
  '/screens/:deviceId/settings',
  (deviceId: string) => `/screens/${deviceId}/settings`,
);

export const screenPlaylist = createPath(
  '/screens/:deviceId/playlist',
  (deviceId: string) => `/screens/${deviceId}/playlist`,
);

// Library paths
export const library = createPath('/library', () => `/library`);

export const libraryFolder = createPath(
  '/library/folders/:folderId',
  (folderId: string) => `/library/folders/${folderId}`,
);

export const libraryProfile = createPath(
  '/library/profiles/:profileId',
  (profileId: string) => `/library/profiles/${profileId}`,
);

// Presentation paths
// TODO: Since nested playlists this page can also select playlists. A better name or it would
// be `add-content`but this page will eventually be removed in favour of selecting content
// with an inline library on the builder page.

export interface PresentationsQueryParams {
  folderId?: string;
  profileId?: string;
  selectionId?: string;
  playlistId?: string;
  backTo: string;
  saveTo: string;
  addToLabel: string;
  sessionId?: string;
  createPlaylist?: boolean;
}
export const presentations = createPath(
  '/presentations',
  (queryParams: PresentationsQueryParams, opts: PathOptions = {}) =>
    maybeForwardToContentModal(
      `/presentations?${querystring.stringify({
        folderId: queryParams.folderId,
        profileId: queryParams.profileId,
        selectionId: queryParams.selectionId,
        playlistId: queryParams.playlistId,
        backTo: queryParams.backTo,
        saveTo: queryParams.saveTo,
        addToLabel: queryParams.addToLabel,
        sessionId: queryParams.sessionId,
        createPlaylist: queryParams.createPlaylist,
      })}`,
      opts.useContentModal,
      opts.contentModalBasePath,
    ),
);

export interface EditPresentationQueryParams {
  backTo: string;
  backToLabel: string;
  saveTo: string;
  previewMode?: string;
  selectionId?: string;
  didSave?: boolean;
  sessionId?: string;
}

export const editPresentation = createPath(
  '/presentations/:presentationId',
  (presentationId: string, queryParams: EditPresentationQueryParams) =>
    `/presentations/${presentationId}?${querystring.stringify({
      backTo: queryParams.backTo,
      backToLabel: queryParams.backToLabel,
      saveTo: queryParams.saveTo,
      previewMode: queryParams.previewMode,
      selectionId: queryParams.selectionId,
      didSave: queryParams.didSave,
      sessionId: queryParams.sessionId,
    })}`,
);

export const editPresentationEditTheme = createPath(
  '/presentations/:presentationId/themes/:themeId',
  (
    presentationId: string,
    themeId: string,
    queryParams: EditPresentationQueryParams,
  ) =>
    `/presentations/${presentationId}/themes/${themeId}?${querystring.stringify(
      {
        backTo: queryParams.backTo,
        backToLabel: queryParams.backToLabel,
        saveTo: queryParams.saveTo,
        previewMode: queryParams.previewMode,
        selectionId: queryParams.selectionId,
        didSave: queryParams.didSave,
        sessionId: queryParams.sessionId,
      },
    )}`,
);

export const editPresentationNewTheme = createPath(
  '/presentations/:presentationId/themes/new',
  (presentationId: string, queryParams: EditPresentationQueryParams) =>
    `/presentations/${presentationId}/themes/new?${querystring.stringify({
      backTo: queryParams.backTo,
      backToLabel: queryParams.backToLabel,
      saveTo: queryParams.saveTo,
      previewMode: queryParams.previewMode,
      selectionId: queryParams.selectionId,
      didSave: queryParams.didSave,
      sessionId: queryParams.sessionId,
    })}`,
);

export const editPresentationManageThemes = createPath(
  '/presentations/:presentationId/themes',
  (presentationId: string, queryParams: EditPresentationQueryParams) =>
    `/presentations/${presentationId}/themes?${querystring.stringify({
      backTo: queryParams.backTo,
      backToLabel: queryParams.backToLabel,
      saveTo: queryParams.saveTo,
      previewMode: queryParams.previewMode,
      selectionId: queryParams.selectionId,
      didSave: queryParams.didSave,
      sessionId: queryParams.sessionId,
    })}`,
);

export interface NewPresentationQueryParams {
  applicationId: string;
  backTo: string;
  backToLabel: string;
  saveTo: string;
  previewMode?: string;
  selectionId?: string;
  folderId?: string;
  didSave?: boolean;
  sessionId?: string;
  version?: string;
}

export const newPresentation = createPath(
  '/presentations/new',
  (queryParams: NewPresentationQueryParams) =>
    `/presentations/new?${querystring.stringify({
      applicationId: queryParams.applicationId,
      backTo: queryParams.backTo,
      backToLabel: queryParams.backToLabel,
      saveTo: queryParams.saveTo,
      previewMode: queryParams.previewMode,
      selectionId: queryParams.selectionId,
      folderId: queryParams.folderId,
      didSave: queryParams.didSave,
      sessionId: queryParams.sessionId,
      version: queryParams.version,
    })}`,
);

export const newPresentationEditTheme = createPath(
  '/presentations/new/themes/:themeId',
  (themeId: string, queryParams: NewPresentationQueryParams) =>
    `/presentations/new/themes/${themeId}?${querystring.stringify({
      applicationId: queryParams.applicationId,
      backTo: queryParams.backTo,
      backToLabel: queryParams.backToLabel,
      saveTo: queryParams.saveTo,
      previewMode: queryParams.previewMode,
      selectionId: queryParams.selectionId,
      folderId: queryParams.folderId,
      didSave: queryParams.didSave,
      sessionId: queryParams.sessionId,
      version: queryParams.version,
    })}`,
);

export const newPresentationNewTheme = createPath(
  '/presentations/new/themes/new',
  (queryParams: NewPresentationQueryParams) =>
    `/presentations/new/themes/new?${querystring.stringify({
      applicationId: queryParams.applicationId,
      backTo: queryParams.backTo,
      backToLabel: queryParams.backToLabel,
      saveTo: queryParams.saveTo,
      previewMode: queryParams.previewMode,
      selectionId: queryParams.selectionId,
      folderId: queryParams.folderId,
      didSave: queryParams.didSave,
      sessionId: queryParams.sessionId,
      version: queryParams.version,
    })}`,
);

export const newPresentationManageThemes = createPath(
  '/presentations/new/themes',
  (queryParams: NewPresentationQueryParams) =>
    `/presentations/new/themes?${querystring.stringify({
      applicationId: queryParams.applicationId,
      backTo: queryParams.backTo,
      backToLabel: queryParams.backToLabel,
      saveTo: queryParams.saveTo,
      previewMode: queryParams.previewMode,
      selectionId: queryParams.selectionId,
      folderId: queryParams.folderId,
      didSave: queryParams.didSave,
      sessionId: queryParams.sessionId,
      version: queryParams.version,
    })}`,
);

// Application paths

export interface ApplicationsQueryParams {
  backTo: string;
  selectionId?: string;
  folderId?: string;
  sessionId?: string;
  createPlaylistTo?: string;
}
export const applications = createPath(
  '/applications',
  (queryParams: ApplicationsQueryParams, opts: PathOptions = {}) =>
    maybeForwardToContentModal(
      `/applications?${querystring.stringify({
        backTo: queryParams.backTo,
        selectionId: queryParams.selectionId,
        folderId: queryParams.folderId,
        sessionId: queryParams.sessionId,
        createPlaylistTo: queryParams.createPlaylistTo,
      })}`,
      opts.useContentModal,
      opts.contentModalBasePath,
    ),
);

export const application = createPath(
  '/applications/:applicationId',
  (applicationId: string) => `/apps/${applicationId}`,
);

// Account paths

export const account = createPath('/account', () => `/account`);

export const accountDomainMembers = createPath(
  '/account/domain-members/:profileId',
  (profileId: string) => `/account/domain-members/${profileId}`,
);

// Unsubscribe paths

export interface UnsubscribeQueryParams {
  userId: string;
}
export const unsubscribe = createPath(
  '/unsubscribe',
  (queryParams: UnsubscribeQueryParams) =>
    `/unsubscribe?${querystring.stringify({ userId: queryParams.userId })}`,
  { unprotected: true },
);

// Playlist paths

export interface PlaylistQueryParams {
  backTo: string;
  backToLabel: string;
  saveTo: string;
  previewMode?: string;
  didSave?: boolean;
  deviceId?: string;
  folderId?: string;
  sessionId?: string;
  addItemsAfterPath?: string;
}

export interface PlaylistScheduleQueryParams extends PlaylistQueryParams {
  cancelTo: string;
}

export interface EditPlaylistQueryParams extends PlaylistQueryParams {}

export const editPlaylist = createPath(
  '/playlists/:playlistId',
  (playlistId: string, queryParams: PlaylistQueryParams) =>
    `/playlists/${playlistId}?${querystring.stringify({
      // TODO: Make sessionId required?
      sessionId: queryParams.sessionId || createSessionId(),
      deviceId: queryParams.deviceId,
      folderId: queryParams.folderId,
      previewMode: queryParams.previewMode,
      backToLabel: queryParams.backToLabel,
      backTo: queryParams.backTo,
      saveTo: queryParams.saveTo,
      addItemsAfterPath: queryParams.addItemsAfterPath,
      // TODO: I don't think we need this anymore
      didSave: queryParams.didSave,
    })}`,
);

// TODO: This path is unused when nested playlists is enabled for all users.
export const editPlaylistSchedule = createPath(
  '/playlists/:playlistId/schedule',
  (playlistId: string, queryParams: PlaylistQueryParams) =>
    `/playlists/${playlistId}/schedule?${querystring.stringify({
      backTo: queryParams.backTo,
      backToLabel: queryParams.backToLabel,
      saveTo: queryParams.saveTo,
      previewMode: queryParams.previewMode,
      didSave: queryParams.didSave,
      deviceId: queryParams.deviceId,
      folderId: queryParams.folderId,
      sessionId: queryParams.sessionId,
    })}`,
);

// TODO: This path is unused when nested playlists is enabled for all users.
export const editPlaylistEditSchedule = createPath(
  '/playlists/:parentPlaylistId/schedule/:playlistId',
  (
    parentPlaylistId: string,
    playlistId: string,
    queryParams: PlaylistScheduleQueryParams,
  ) =>
    `/playlists/${parentPlaylistId}/schedule/${playlistId}?${querystring.stringify(
      {
        backTo: queryParams.backTo,
        backToLabel: queryParams.backToLabel,
        saveTo: queryParams.saveTo,
        previewMode: queryParams.previewMode,
        didSave: queryParams.didSave,
        cancelTo: queryParams.cancelTo,
        deviceId: queryParams.deviceId,
        folderId: queryParams.folderId,
      },
    )}`,
);

// TODO: This path is unused when nested playlists is enabled for all users.
export const editPlaylistNewSchedule = createPath(
  '/playlists/:parentPlaylistId/schedule/new',
  (parentPlaylistId: string, queryParams: PlaylistScheduleQueryParams) =>
    `/playlists/${parentPlaylistId}/schedule/new?${querystring.stringify({
      backTo: queryParams.backTo,
      backToLabel: queryParams.backToLabel,
      saveTo: queryParams.saveTo,
      previewMode: queryParams.previewMode,
      cancelTo: queryParams.cancelTo,
      deviceId: queryParams.deviceId,
      folderId: queryParams.folderId,
    })}`,
);

export interface NewPlaylistQueryParams extends PlaylistQueryParams {
  playlistId: string;
}

export const newPlaylist = createPath(
  '/playlists/new',
  (queryParams: NewPlaylistQueryParams) =>
    `/playlists/new?${querystring.stringify({
      playlistId: queryParams.playlistId,
      sessionId: queryParams.sessionId || createSessionId(),
      deviceId: queryParams.deviceId,
      folderId: queryParams.folderId,
      previewMode: queryParams.previewMode,
      backTo: queryParams.backTo,
      backToLabel: queryParams.backToLabel,
      saveTo: queryParams.saveTo,
      addItemsAfterPath: queryParams.addItemsAfterPath,
    })}`,
);

// TODO: This path is unused when nested playlists is enabled for all users.
export const newPlaylistSchedule = createPath(
  '/playlists/new/schedule',
  (queryParams: NewPlaylistQueryParams) =>
    `/playlists/new/schedule?${querystring.stringify({
      backTo: queryParams.backTo,
      backToLabel: queryParams.backToLabel,
      saveTo: queryParams.saveTo,
      previewMode: queryParams.previewMode,
      deviceId: queryParams.deviceId,
      folderId: queryParams.folderId,
    })}`,
);

// TODO: This path is unused when nested playlists is enabled for all users.
export const newPlaylistEditSchedule = createPath(
  '/playlists/new/schedule/:playlistId',
  (playlistId: string, queryParams: PlaylistScheduleQueryParams) =>
    `/playlists/new/schedule/${playlistId}?${querystring.stringify({
      backTo: queryParams.backTo,
      backToLabel: queryParams.backToLabel,
      saveTo: queryParams.saveTo,
      didSave: queryParams.didSave,
      previewMode: queryParams.previewMode,
      cancelTo: queryParams.cancelTo,
      deviceId: queryParams.deviceId,
      folderId: queryParams.folderId,
    })}`,
);

// TODO: This path is unused when nested playlists is enabled for all users.
export const newPlaylistNewSchedule = createPath(
  '/playlists/new/schedule/new',
  (queryParams: PlaylistScheduleQueryParams) =>
    `/playlists/new/schedule/new?${querystring.stringify({
      backTo: queryParams.backTo,
      backToLabel: queryParams.backToLabel,
      saveTo: queryParams.saveTo,
      previewMode: queryParams.previewMode,
      cancelTo: queryParams.cancelTo,
      deviceId: queryParams.deviceId,
      folderId: queryParams.folderId,
    })}`,
);

// Enterprise

export interface EnterpriseWelcomeQueryParms {
  domainName: string;
}

export const enterpriseWelcome = createPath(
  '/enterprise-welcome',
  (queryParams: EnterpriseWelcomeQueryParms) =>
    `/enterprise-welcome?domain_name=${queryParams.domainName}`,
  { unprotected: true },
);

// Activate

export const activate = createPath(
  '/activate/:code',
  (code: string) => `/activate/${code}`,
);
