import { ActionType, getType } from 'typesafe-actions';
import { PlaylistItem } from '@raydiant/api-client-js';
import * as actions from './actions';
import { SortFolderOptions, NodeType } from '../../utilities';

export type AddContentPageActions = ActionType<typeof actions>;

export type AddContentPageState = Readonly<{
  lastLoadedDate: string;
  sortOptions: SortFolderOptions;
  searchQuery: string;
  error: string;
  selectedItems: {
    [selectionId: string]: Array<{ id: string; type: NodeType }>;
  };
  itemsToAdd: {
    [selectionId: string]: PlaylistItem[];
  };
}>;

const initialAddContentPageState: AddContentPageState = {
  lastLoadedDate: '',
  sortOptions: {
    property: 'name',
    direction: 'asc',
  },
  searchQuery: '',
  error: '',
  selectedItems: {},
  itemsToAdd: {},
};

export default function addContentPageReducer(
  state = initialAddContentPageState,
  action: AddContentPageActions,
): AddContentPageState {
  switch (action.type) {
    case getType(actions.loadAddContentPageAsync.request):
      return {
        ...state,
        error: '',
      };

    case getType(actions.loadAddContentPageAsync.success):
      return {
        ...state,
        lastLoadedDate: new Date().toISOString(),
      };

    case getType(actions.loadAddContentPageAsync.failure):
      return {
        ...state,
        error: action.payload.message,
      };

    case getType(actions.setSortOptions):
      return {
        ...state,
        sortOptions: action.payload,
      };

    case getType(actions.setSearchQuery):
      return {
        ...state,
        searchQuery: action.payload,
      };

    // selectItem
    case getType(actions.selectItem): {
      const { selectionId, item } = action.payload;
      const presentationsForSelection = state.selectedItems[selectionId] || [];

      const hasItem = !!presentationsForSelection.find(
        ({ id, type }) => id === item.id && type === item.type,
      );
      if (hasItem) return state;

      return {
        ...state,
        selectedItems: {
          ...state.selectedItems,
          [selectionId]: [...presentationsForSelection, item],
        },
      };
    }

    // unselectItem
    case getType(actions.unselectItem): {
      const { selectionId, item } = action.payload;
      const presentationsForSelection = state.selectedItems[selectionId] || [];

      const hasItem = !!presentationsForSelection.find(
        ({ id }) => id === item.id,
      );
      if (hasItem) return state;

      return {
        ...state,
        selectedItems: {
          ...state.selectedItems,
          [selectionId]: presentationsForSelection.filter(
            ({ id }) => id !== item.id,
          ),
        },
      };
    }

    // setSelectedItems
    case getType(actions.setSelectedItems): {
      const { selectionId, items } = action.payload;

      return {
        ...state,
        selectedItems: {
          ...state.selectedItems,
          [selectionId]: items,
        },
      };
    }

    // clearSelectedItems
    case getType(actions.clearSelectedItems): {
      const { selectionId } = action.payload;
      const selectedItems = { ...state.selectedItems };
      delete selectedItems[selectionId];

      return {
        ...state,
        selectedItems,
      };
    }

    // setItemsToAdd
    case getType(actions.setItemsToAdd): {
      const { selectionId, items } = action.payload;

      const selectedItems = { ...state.selectedItems };
      delete selectedItems[selectionId];

      return {
        ...state,
        itemsToAdd: {
          ...state.itemsToAdd,
          [selectionId]: items,
        },
        selectedItems,
      };
    }

    // clearItemsToAdd
    case getType(actions.clearItemsToAdd): {
      const { selectionId } = action.payload;
      const itemsToAdd = { ...state.itemsToAdd };
      delete itemsToAdd[selectionId];

      return {
        ...state,
        itemsToAdd,
      };
    }

    default:
      return state;
  }
}
