import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { CreatePresentation, Presentation } from '@raydiant/api-client-js';
import { PresentationFile } from '../types';

export interface MetaPresentation {
  fileUploads?: PresentationFile[];
  folderId?: string;
  onSave?: (presentation: Presentation) => void;
  onDelete?: () => void;
  onMove?: () => void;
}

export const fetchPresentations = createStandardAction(
  '@PRESENTATION/FETCH_PRESENTATIONS',
)();

export const fetchPresentationsAsync = createAsyncAction(
  '@PRESENTATION/FETCH_REQUEST',
  '@PRESENTATION/FETCH_SUCCESS',
  '@PRESENTATION/FETCH_FAILURE',
)<void, Presentation[], Error>();

export const deleteAllPresentations = createStandardAction(
  '@PRESENTATION/DELETE_ALL',
)<string[], MetaPresentation>();

export const deletePresentationAsync = createAsyncAction(
  '@PRESENTATION/DELETE_REQUEST',
  '@PRESENTATION/DELETE_SUCCESS',
  '@PRESENTATION/DELETE_FAILURE',
)<string, string, Error>();

export const savePresentation = createStandardAction('@PRESENTATION/SAVE')<
  Presentation & { id?: string },
  MetaPresentation
>();

export const createPresentation = createStandardAction('@PRESENTATION/CREATE')<
  CreatePresentation,
  {
    onSuccess?: (presentation: Presentation) => void;
    fileUploads?: PresentationFile[];
    folderId?: string | null;
  }
>();

export const createPresentationAsync = createAsyncAction(
  '@PRESENTATION/CREATE_REQUEST',
  '@PRESENTATION/CREATE_SUCCESS',
  '@PRESENTATION/CREATE_FAILURE',
)<void, Presentation, Error>();

export const updatePresentationAsync = createAsyncAction(
  '@PRESENTATION/UPDATE_REQUEST',
  '@PRESENTATION/UPDATE_SUCCESS',
  '@PRESENTATION/UPDATE_FAILURE',
)<Presentation, Presentation, Error>();

export const clearPresentationError = createStandardAction(
  '@PRESENTATION/CLEAR_ERROR',
)();

export const movePresentationToFolderAsync = createAsyncAction(
  '@PRESENTATION/MOVE_REQUEST',
  '@PRESENTATION/MOVE_SUCCESS',
  '@PRESENTATION/MOVE_FAILURE',
)<
  {
    presentation: Presentation;
    parentFolderId: string | null;
  },
  string,
  string
>();

export const pollPresentationsWithFileUploads = createStandardAction(
  '@PRESENTATION/POLL_PRESENTATIONS_WITH_FILE_UPLOADS',
)<string[]>();

export const stopPollingPresentations = createStandardAction(
  '@PRESENTATION/STOP_POLLING_PRESENTATIONS',
)();

export const copyPresentation = createStandardAction('@PRESENTATION/COPY')<
  { presentationId: string; copyName?: string },
  {
    onSuccess?: (presentation: Presentation) => void;
  }
>();

export const copyPresentationAsync = createAsyncAction(
  '@PRESENTATION/COPY_REQUEST',
  '@PRESENTATION/COPY_SUCCESS',
  '@PRESENTATION/COPY_FAILURE',
)<void, Presentation, Error>();
