import { createSelector } from 'reselect';
import { ResourceProfile, Theme } from '@raydiant/api-client-js';
import { RootState } from '../../reducers';
import { selectThemesByOwner } from '../../selectors/v2/themes';
import { selectUserProfile } from '../../selectors/user';
import { selectOtherDomainProfiles } from '../../selectors/v2/domains';
import { searchThemes, sortThemes } from '../../utilities';
import config from '../../config';

export const selectLoadingStatus = (state: RootState) =>
  state.presentationPage.loadingStatus;

export const selectUnsavedPresentations = (state: RootState) =>
  state.presentationPage.unsavedPresentations;

export const selectUnsavedThemes = (state: RootState) =>
  state.presentationPage.unsavedThemes;

export const selectAffectedDevices = (state: RootState) =>
  state.presentationPage.affectedDevices;

export const selectThemeSortOptions = (state: RootState) =>
  state.presentationPage.themeSortOptions;

export const selectThemeSearchQuery = (state: RootState) =>
  state.presentationPage.themeSearchQuery;

export const selectThemeResults = createSelector(
  [selectThemesByOwner, selectThemeSortOptions, selectThemeSearchQuery],
  (themesByOwner, sortOptions, searchQuery) => {
    const themesByOwnerResults: {
      [profileId: string]: { profile: ResourceProfile; themes: Theme[] };
    } = {};

    Object.entries(themesByOwner).forEach(
      ([profileId, { profile, themes }]) => {
        const searchedThemes = searchThemes(themes, searchQuery);
        themesByOwnerResults[profileId] = {
          profile,
          themes: sortThemes(searchedThemes, sortOptions),
        };
      },
    );

    return themesByOwnerResults;
  },
);

export const selectUserThemes = createSelector(
  [selectUserProfile, selectThemeResults],
  (currentUser, themesByOwnerResults) => {
    if (!currentUser) return [];
    return (themesByOwnerResults[currentUser.id]?.themes || []).filter(
      // Filter out deleted.
      (theme) => theme.resource.deletedAt === null,
    );
  },
);

export const selectSystemThemes = createSelector(
  [selectThemeResults],
  (themesByOwnerResults) => {
    if (!config.systemThemesProfileId) return [];
    return (
      themesByOwnerResults[config.systemThemesProfileId]?.themes || []
    ).filter((theme) => theme.resource.deletedAt === null);
  },
);

export const selectDomainThemesByProfile = createSelector(
  [selectOtherDomainProfiles, selectThemeResults],
  (domainProfiles, themesByOwnerResults) => {
    return domainProfiles.map((profile) => {
      return {
        profile,
        themes: (themesByOwnerResults[profile.id]?.themes || []).filter(
          // Filter out deleted and one-off themes.
          (theme) => theme.resource.deletedAt === null && !theme.presentationId,
        ),
      };
    });
  },
);
