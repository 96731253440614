import { all, call, fork, put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as domainActions from '../actions/domains';
import * as DM from '../clients/mira/types/Domain';
import * as R from '../clients/mira/types/Resource';
import miraClient from '../clients/miraClient';
import logger from '../logger';

type UpdateDomainAction = ReturnType<typeof domainActions.updateDomain>;

type SetProfileDomainRoleAction = ReturnType<
  typeof domainActions.setProfileDomainRole
>;

const fetchDomain = function* () {
  try {
    yield put(domainActions.fetchDomainAsync.request());
    const domain: DM.Domain = yield call(() => miraClient.getDomain());
    yield put(domainActions.fetchDomainAsync.success(domain));

    return domain;
  } catch (error) {
    logger.error(error);
    yield put(domainActions.fetchDomainAsync.failure(error));
  }
};

const watchFetchDomain = function* () {
  while (true) {
    yield take(getType(domainActions.fetchDomain));
    yield fork(fetchDomain);
  }
};

const updateDomain = function* (domain: DM.Domain) {
  try {
    yield put(domainActions.updateDomainAsync.request());
    const updatedDomain: DM.Domain = yield call(() =>
      miraClient.updateDomain(domain),
    );
    yield put(domainActions.updateDomainAsync.success(updatedDomain));

    return domain;
  } catch (error) {
    logger.error(error);
    yield put(domainActions.updateDomainAsync.failure(error));
  }
};

const watchUpdateDomain = function* () {
  while (true) {
    const action: UpdateDomainAction = yield take(
      getType(domainActions.updateDomain),
    );
    yield fork(updateDomain, action.payload);
  }
};

const setProfileDomainRole = function* (
  domainId: string,
  profileId: string,
  domainRole: DM.DomainRole,
) {
  try {
    yield put(
      domainActions.setProfileDomainRoleAsync.request({
        domainId,
        profileId,
        domainRole,
      }),
    );
    const profile: R.ResourceProfile = yield call(() =>
      miraClient.setProfileDomainRole(profileId, domainRole),
    );
    yield put(domainActions.setProfileDomainRoleAsync.success(profile));
  } catch (error) {
    logger.error(error);
    yield put(domainActions.setProfileDomainRoleAsync.failure(error));
  }
};

const watchSetProfileDomainRole = function* () {
  while (true) {
    const action: SetProfileDomainRoleAction = yield take(
      getType(domainActions.setProfileDomainRole),
    );
    const { domainId, profileId, domainRole } = action.payload;
    yield fork(setProfileDomainRole, domainId, profileId, domainRole);
  }
};

export default all([
  fork(watchFetchDomain),
  fork(watchUpdateDomain),
  fork(watchSetProfileDomainRole),
]);
