import Popover from '@material-ui/core/Popover';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import Link from 'raydiant-elements/core/Link';
import Row from 'raydiant-elements/layout/Row';
import Column from 'raydiant-elements/layout/Column';
import moment from 'moment';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import {
  buttonReset,
  marginBetweenChildrenVertical,
} from 'raydiant-elements/mixins';
import { Theme } from 'raydiant-elements/theme';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Device, RecentDeviceError } from '@raydiant/api-client-js';
import { ContentWarning } from './getDeviceContentWarning';
import { selectProofOfPublishEnabled } from '../../selectors/user';
import * as paths from '../../routes/paths';
import * as presentationPageActions from '../PresentationPage/actions';

interface DeviceStatusPopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  device: Device;
  isOnline: boolean;
  lastMetricTime?: string;
  contentWarning: ContentWarning | null;
  deviceError: RecentDeviceError | null;
  onClose: () => void;
}

const formatDate = (dateStr: string) => (
  <>
    <span style={{ whiteSpace: 'nowrap' }}>
      {moment(dateStr).format('h:mm a')}
    </span>
    ,{' '}
    <span style={{ whiteSpace: 'nowrap' }}>
      {moment(dateStr).format('MMM D')}
    </span>
  </>
);

const DeviceStatusPopover = ({
  open,
  anchorEl,
  device,
  isOnline,
  lastMetricTime,
  contentWarning,
  deviceError,
  onClose,
}: DeviceStatusPopoverProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const isProofOfPublishEnabled = useSelector(selectProofOfPublishEnabled);

  const openPresentationBuilder = useCallback(
    (presentationId: string) => {
      dispatch(
        presentationPageActions.clearUnsavedPresentation(presentationId),
      );

      history.push(
        paths.editPresentation(presentationId, {
          backTo: paths.screens(),
          backToLabel: 'Back to Screens',
          saveTo: paths.screens(),
        }),
      );
    },
    [dispatch, history],
  );

  return (
    <Popover
      className={classes.popover}
      classes={{ paper: classes.popoverPaper }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Row halfMargin>
        <div className={classes.messages}>
          {isOnline && (
            <Row halfMargin>
              <CheckCircleIcon className={classes.icon} />
              <div className={classes.message}>Connectivity is good!</div>
            </Row>
          )}
          {!isOnline && (
            <Row halfMargin>
              <ErrorIcon className={classes.icon} />
              {lastMetricTime && (
                <div className={classes.message}>
                  Device offline since {formatDate(lastMetricTime)}
                </div>
              )}
              {!lastMetricTime && (
                <div className={classes.message}>Device offline</div>
              )}
            </Row>
          )}
          {deviceError && (
            <Column>
              <Row halfMargin>
                <ErrorIcon className={classes.icon} />
                <div className={classes.message}>
                  Error at {formatDate(deviceError.timestamp)}:{' '}
                  <Link
                    onClick={() =>
                      openPresentationBuilder(deviceError.presentationId)
                    }
                  >
                    {deviceError.presentationName}
                  </Link>
                </div>
              </Row>
              <Link
                className={classes.cta}
                target="_blank"
                href="https://support.raydiant.com/hc/en-us/articles/360058191672"
              >
                Learn more
              </Link>
            </Column>
          )}
          {/* Only show content warning if there is no device error to 
          have a maximum of 2 items in the popover. */}
          {contentWarning && !deviceError && (
            <Column>
              <Row halfMargin>
                <ErrorIcon className={classes.icon} />
                <div className={classes.message}>{contentWarning.message}</div>
              </Row>

              <Link
                className={classes.cta}
                target="_blank"
                href={
                  device.model === 'Poly'
                    ? // TODO: Replace article with Poly upgrade article
                      'https://support.raydiant.com/hc/en-us/articles/4401806369556'
                    : 'https://support.raydiant.com/hc/en-us/articles/360054538031#limitations'
                }
              >
                Learn more
              </Link>
            </Column>
          )}
          {isProofOfPublishEnabled && (
            <>
              {!contentWarning && !deviceError && isOnline && (
                <Row halfMargin>
                  <CheckCircleIcon className={classes.icon} />
                  <div className={classes.message}>Playback is good!</div>
                </Row>
              )}
              {!contentWarning && !deviceError && !isOnline && (
                <Row halfMargin>
                  <ErrorIcon className={classes.icon} />
                  <div className={classes.message}>
                    Playback status is unknown
                  </div>
                </Row>
              )}
            </>
          )}
        </div>
        <button className={classes.closeButton} onClick={onClose}>
          <CloseIcon fontSize="small" />
        </button>
      </Row>
    </Popover>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    popover: {
      backgroundColor: theme.modal.overlayBackground,

      '& $popoverPaper': {
        borderRadius: theme.borderRadius.md,
        border: `4px solid #225887`,
        padding: theme.spacing(1),
        maxWidth: 300,
        maxHeight: 'none',
        color: theme.palette.text.secondary,
        // These remove an unwanted scroll bar for some reason
        overflowX: 'initial',
        overflowY: 'initial',
      },
    },

    popoverPaper: {},

    icon: {
      opacity: 0.6,
    },

    messages: {
      ...marginBetweenChildrenVertical(theme.spacing(1)),
    },

    message: {
      fontSize: theme.fontSizes.md,
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },

    cta: {
      paddingLeft: 30, // Align with messages
    },

    closeButton: {
      ...buttonReset(),
      position: 'relative',
      display: 'flex',
      color: theme.palette.text.secondary,
      opacity: 0.6,

      '&:hover': {
        opacity: 1,
      },
      // Expand hit area.
      '&:after': {
        content: "' '",
        position: 'absolute',
        top: '-20%',
        left: '-20%',
        width: '140%',
        height: '140%',
      },
    },
  });
});

export default DeviceStatusPopover;
