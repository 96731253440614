import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Presentation } from '@raydiant/api-client-js';
import { selectLocalUploads } from '../selectors/fileUploads';
import { getPresentationFileStatus } from '../utilities/presentationUtils';
import usePresentation from '../hooks/usePresentation';

export default function usePresentationFileStatus(presentation: Presentation) {
  const localUploads = useSelector(selectLocalUploads);

  const initialStatus = getPresentationFileStatus(presentation, localUploads);

  const [isUploading, setIsUploading] = useState(initialStatus.isUploading);

  // Poll if still uploading and set status and set state with the updated status.
  // TODO: This will create a poll for each presentation that is being uploaded. Consider
  // implementing a way to poll multiple presentation ids with a single polling class.
  usePresentation(presentation.id, {
    enabled: isUploading,
    refetchInterval: 5000,
    onSuccess: (presentation) => {
      const updatedStatus = getPresentationFileStatus(
        presentation,
        localUploads,
      );
      setIsUploading(updatedStatus.isUploading);
    },
  });

  return {
    isUploading,
    hasError: initialStatus.hasError,
  };
}
