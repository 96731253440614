import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import * as DM from '../clients/mira/types/Domain';
import * as R from '../clients/mira/types/Resource';

export const fetchDomain = createStandardAction('@DOMAIN/FETCH')();

export const fetchDomainAsync = createAsyncAction(
  '@DOMAIN/FETCH_REQUEST',
  '@DOMAIN/FETCH_SUCCESS',
  '@DOMAIN/FETCH_FAILURE',
)<void, DM.Domain, Error>();

export const updateDomain = createStandardAction('@DOMAIN/UPDATE')<DM.Domain>();

export const updateDomainAsync = createAsyncAction(
  '@DOMAIN/UPDATE_REQUEST',
  '@DOMAIN/UPDATE_SUCCESS',
  '@DOMAIN/UPDATE_FAILURE',
)<void, DM.Domain, Error>();

export const setProfileDomainRole = createStandardAction(
  '@DOMAIN/SET_PROFILE_DOMAIN_ROLE',
)<{ domainId: string; profileId: string; domainRole: DM.DomainRole }>();

export const setProfileDomainRoleAsync = createAsyncAction(
  '@DOMAIN/SET_PROFILE_DOMAIN_ROLE_REQUEST',
  '@DOMAIN/SET_PROFILE_DOMAIN_ROLE_SUCCESS',
  '@DOMAIN/SET_PROFILE_DOMAIN_ROLE_FAILURE',
)<
  { domainId: string; profileId: string; domainRole: DM.DomainRole },
  R.ResourceProfile,
  Error
>();
