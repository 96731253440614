import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import * as D from '../clients/mira/types/Device';

export const generatePairingCode = createStandardAction(
  'GENERATE_PAIRING_CODE',
)<string>();

export const generatePairingCodeAsync = createAsyncAction(
  'GENERATE_PAIRING_CODE_REQUEST',
  'GENERATE_PAIRING_CODE_SUCCESS',
  'GENERATE_PAIRING_CODE_FAILURE',
)<string, D.SYBPairingCode, { id: string; error: Error }>();
