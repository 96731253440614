import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { textTruncate } from 'raydiant-elements/mixins';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {},

    labelWithButton: {
      alignItems: 'center',

      '& > span': {
        ...textTruncate(),
        flex: 1,
      },
    },

    button: {
      background: '#fff',
      border: `1px solid ${theme.palette.progress.main}`,
      borderRadius: theme.borderRadius.sm,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(2),

      '& svg': {
        fill: theme.palette.progress.main,
      },

      '&:hover': {
        borderColor: theme.palette.progress.dark,
      },
    },
  });
});
