import unionBy from 'lodash/unionBy';

export interface CollectionItem {
  id: string;
}

// Replaces an item in an array by id, will add it if it doesn't exist.
export const updateItemInCollection = <T extends CollectionItem>(
  state: T[],
  item: T,
) => unionBy([item], state, 'id');

// Merges in an item into the array by id, will add it if it doesn't exist.
export const mergeItemInCollection = <T extends CollectionItem>(
  state: T[],
  item: T,
) => {
  const existingItem = state.find((i) => i.id === item.id);
  return unionBy([{ ...existingItem, ...item }], state, 'id');
};
