import { useMemo } from 'react';
import { useQueryClient, UseQueryOptions } from 'react-query';
import { Playlist, ApiClientError } from '@raydiant/api-client-js';
import raydiant from '../clients/miraClient';
import useProtectedQuery from './useProtectedQuery';
import { keys } from '../queryClient';
import { setPlaylistQueryCache } from '../queryClient';
import { removeDeletedItems } from '../utilities/playlistUtils';

export default function usePlaylists(
  playlistIds: string[] | undefined,
  opts: UseQueryOptions<Playlist[], ApiClientError> = {},
) {
  const queryClient = useQueryClient();

  // Array item order matters in react-qery so convert playlistIds to an object to avoid
  // refetching the same playlistIds if they are a different order.
  const playlistsById = useMemo(() => {
    if (!playlistIds) return {};
    return playlistIds.reduce<Record<string, true>>((acc, playlistId) => {
      acc[playlistId] = true;
      return acc;
    }, {});
  }, [playlistIds]);

  const query = useProtectedQuery<Playlist[], ApiClientError>(
    keys.playlistsById(playlistsById),
    async () => {
      const playlists = playlistIds
        ? await raydiant.getPlaylists({ ids: playlistIds })
        : [];

      return playlists.map(removeDeletedItems);
    },
    {
      enabled: playlistIds !== undefined && opts.enabled,
      onSuccess: (playlists) => {
        // Set query data for each playlist to prevent refetching a single playlist.
        playlists.forEach((playlist) => {
          setPlaylistQueryCache(queryClient, playlist);
        });
      },
    },
  );

  return query;
}
