import { AffectedDevice, ApiClientError } from '@raydiant/api-client-js';
import raydiant from '../clients/miraClient';
import useProtectedQuery from './useProtectedQuery';
import { keys } from '../queryClient';

export interface UseAffectedDevicesOptions {
  enabled?: boolean;
}

export default function useAffectedDevices(
  resources: {
    presentationIds: string[];
    playlistIds: string[];
    folderIds: string[];
  },
  opts: UseAffectedDevicesOptions = {},
) {
  const query = useProtectedQuery<AffectedDevice[], ApiClientError>(
    keys.affectedDevices(resources),
    () => raydiant.getAffectedDevices(resources),
    { enabled: opts.enabled },
  );

  return query;
}
