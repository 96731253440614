import { createAsyncAction, createStandardAction } from 'typesafe-actions';

export interface UploadFileActionProps {
  fileUploadId: string;
  uploadUrl: string;
  localUrl: string;
  file: File;
  presentationId: string;
  path: Array<string | number>;
}

export const uploadFile =
  createStandardAction('UPLOAD_FILE')<UploadFileActionProps>();

export const uploadFileAsync = createAsyncAction(
  'UPLOAD_FILE_REQUEST',
  'UPLOAD_FILE_SUCCESS',
  'UPLOAD_FILE_FAILURE',
)<
  {
    fileUploadId: string;
    presentationId: string;
    path: Array<string | number>;
    localUrl: string;
  },
  { fileUploadId: string },
  { fileUploadId: string; error: Error }
>();
