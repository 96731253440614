import * as React from 'react';
import Paper from 'raydiant-elements/core/Paper';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Center from 'raydiant-elements/layout/Center';
import { useParams, useHistory } from 'react-router-dom';
import Page from '../../components/Page';
import RegisterDevice from '../../components/RegisterDevice';
import * as paths from '../../routes/paths';
import useStyles from './ActivatePage.styles';

const ActivatePage = () => {
  const classes = useStyles();
  const params = useParams<{ code?: string }>();
  const history = useHistory();
  const code = (params.code || '').toUpperCase();

  return (
    <Page>
      <Scrollable>
        <Center>
          <Paper className={classes.register} color="light" elevation={3}>
            <RegisterDevice
              title="Let’s activate your screen!"
              defaultCode={code}
              onRegister={() => history.push(paths.screens())}
            />
          </Paper>
        </Center>
      </Scrollable>
    </Page>
  );
};

export default ActivatePage;
