import orderBy from 'lodash/orderBy';
import * as R from '../clients/mira/types/Resource';
import * as U from '../clients/mira/types/User';

export interface SortProfileOptions {
  property: 'name' | 'email' | 'domainRole';
  direction: 'asc' | 'desc';
}

const domainRoleSortMap: { [role: string]: number } = {
  superadmin: 0,
  admin: 1,
  standard: 2,
  restricted: 3,
};

export const sortProfiles = (
  profiles: Array<U.Profile | R.ResourceProfile>,
  { property, direction }: SortProfileOptions,
) => {
  if (property === 'name') {
    return orderBy(profiles, (p) => p.name.toLowerCase(), [direction]);
  }

  if (property === 'email') {
    return orderBy(profiles, (p) => p.email.toLowerCase(), [direction]);
  }

  if (property === 'domainRole') {
    return orderBy(
      profiles,
      [(p) => domainRoleSortMap[p.domainRole] || -1, (p) => p.name],
      [direction, 'asc'],
    );
  }

  return profiles;
};
