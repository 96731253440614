import Link from 'raydiant-elements/core/Link';
import { testAttr } from 'raydiant-elements/helpers';
import { Theme } from 'raydiant-elements/theme';
import Heading2 from 'raydiant-elements/typography/Heading2';
import * as React from 'react';
import history from '../../history';
import * as paths from '../../routes/paths';
import withStyles, { createStyles, WithStyles } from '../withStyles';
import UnsubscribeConfirmationTitle from './UnsubscribeConfirmationTitle';

export interface UnsubscribeConfirmationProps
  extends WithStyles<typeof styles> {
  error?: boolean;
  children?: React.ReactNode;
}

const UnsubscribeConfirmation: React.SFC<UnsubscribeConfirmationProps> = ({
  error = false,
  classes,
}) => {
  if (error) {
    return (
      <div
        className={classes.root}
        {...testAttr('unsubscribe-confirmation-error')}
      >
        <UnsubscribeConfirmationTitle>
          Oops! Something went wrong.
        </UnsubscribeConfirmationTitle>
        <Heading2>
          Sorry, we could not unsubscribe you from offline device notifications.
        </Heading2>
        <br />
        <Heading2>
          Please contact{' '}
          <Link href="mailto:support@raydiant.com">support@raydiant.com</Link>.
        </Heading2>
      </div>
    );
  }

  return (
    <div
      className={classes.root}
      {...testAttr('unsubscribe-confirmation-success')}
    >
      <UnsubscribeConfirmationTitle>
        Successfully unsubscribed.
      </UnsubscribeConfirmationTitle>
      <Heading2>
        You will no longer receive notifications when your ScreenRays go
        offline.
      </Heading2>
      <br />
      <Heading2>
        To re-subscribe,{' '}
        <Link
          href={paths.account()}
          onClick={() => history.push(paths.account())}
        >
          go to your account page
        </Link>
        .
      </Heading2>
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      [theme.breakpoints.down('xs')]: {
        alignItems: 'flex-start',
      },
    },
  });

export default withStyles(styles)(UnsubscribeConfirmation);
