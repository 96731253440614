import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import { PlaybackReport } from '@raydiant/api-client-js';
import * as playbackReportActions from '../../actions/playbackReports';

export type PlaybackReportActions = ActionType<typeof playbackReportActions>;

export type DevicesState = Readonly<{
  byId: {
    [reportId: string]: PlaybackReport;
  };
}>;

const initialDevicesState: DevicesState = {
  byId: {},
};

export default function playbackReports(
  state = initialDevicesState,
  action: PlaybackReportActions,
): DevicesState {
  switch (action.type) {
    // fetchPlaybackReportsAsync
    case getType(playbackReportActions.fetchPlaybackReportsAsync.success): {
      const playbackReports = action.payload;
      return produce(state, (draftState) => {
        playbackReports.forEach((report) => {
          draftState.byId[report.id] = report;
        });
      });
    }

    // generatePlaybackReportAsync
    case getType(playbackReportActions.generatePlaybackReportAsync.success): {
      const report = action.payload;
      return produce(state, (draftState) => {
        draftState.byId[report.id] = report;
      });
    }

    // clearPlaybackReports
    case getType(playbackReportActions.clearPlaybackReports): {
      return produce(state, (draftState) => {
        draftState.byId = {};
      });
    }

    default: {
      return state;
    }
  }
}
