import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    toggle: {
      display: 'flex',
      width: '100%',
      '& > button': {
        borderColor: theme.input.border,
        flexBasis: '50%',
        height: '50px',
        padding: '0px',
      },
    },

    toggleText: {
      color: theme.input.foreground,
      fontSize: theme.fontSizes.xs,
      textTransform: 'initial',
    },
  }),
);
