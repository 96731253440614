import { ActionType, getType } from 'typesafe-actions';
import * as soundZoneActions from '../actions/soundZones';
import { SoundZone } from '../types';
import { mergeItemInCollection } from './shared/collections';
import {
  RequestState,
  setRequestItem,
  setRequestItemFailure,
  setRequestItemSuccess,
} from './shared/requests';

export type SoundZoneActions = ActionType<typeof soundZoneActions>;

export type SoundZonesState = Readonly<{
  items: SoundZone[];
  itemsFetchStatus: RequestState[];
  itemsPairingCodeStatus: RequestState[];
}>;

const initialSoundZonesState: SoundZonesState = {
  items: [],
  itemsFetchStatus: [],
  itemsPairingCodeStatus: [],
};

export default function soundZonesReducer(
  state = initialSoundZonesState,
  action: SoundZoneActions,
): SoundZonesState {
  switch (action.type) {
    // Fetch sound zone
    case getType(soundZoneActions.fetchSoundZoneAsync.request):
      return {
        ...state,
        itemsFetchStatus: setRequestItem(
          state.itemsFetchStatus,
          action.payload,
        ),
      };

    case getType(soundZoneActions.fetchSoundZoneAsync.success):
      return {
        ...state,
        itemsFetchStatus: setRequestItemSuccess(
          state.itemsFetchStatus,
          action.payload.id,
        ),
        items: action.payload.name
          ? mergeItemInCollection(state.items, action.payload)
          : state.items,
      };

    case getType(soundZoneActions.fetchSoundZoneAsync.failure):
      return {
        ...state,
        itemsFetchStatus: setRequestItemFailure(
          state.itemsFetchStatus,
          action.payload.id,
          action.payload.error.message,
        ),
      };

    default:
      return state;
  }
}
