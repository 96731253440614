import { ActionType, getType } from 'typesafe-actions';
import * as applicationActions from '../actions/applications';
import { Application } from '../clients/mira/types/Application';

export type ApplicationActions = ActionType<typeof applicationActions>;

export type ApplicationsState = Readonly<{
  items: Application[];
  isFetching: boolean;
  error: string;
}>;

const initialApplicationsState: ApplicationsState = {
  items: [],
  isFetching: false,
  error: '',
};

export default function applicationsReducer(
  state = initialApplicationsState,
  action: ApplicationActions,
): ApplicationsState {
  switch (action.type) {
    case getType(applicationActions.fetchApplicationsAsync.request):
      return { ...state, isFetching: true, error: '' };
    case getType(applicationActions.fetchApplicationsAsync.success):
      return {
        ...state,
        items: action.payload,
        isFetching: false,
      };
    case getType(applicationActions.fetchApplicationsAsync.failure):
      return {
        ...state,
        isFetching: false,
        error: action.payload.message,
      };
    default:
      return state;
  }
}
