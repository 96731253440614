import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import ThemeSelector from 'raydiant-elements/core/ThemeSelector';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import useQueryParams from './useQueryParams';

interface ThemeManagerFooterActionsProps {
  isDirty: boolean;
}

const ThemeManagerFooterActions = ({
  isDirty,
}: ThemeManagerFooterActionsProps) => {
  const history = useHistory();
  const queryParams = useQueryParams();

  // Callbacks

  const goBack = useCallback(() => {
    history.push(queryParams.backTo);
  }, [queryParams.backTo, history]);

  // Render

  return (
    <ThemeSelector color="light">
      <ActionBar variant="footer">
        <ActionBar.Action
          color="error"
          icon={<CancelIcon />}
          label="Cancel"
          disabled={isDirty}
          onClick={goBack}
        />
        <ActionBar.Action
          color="success"
          icon={<CheckCircleIcon />}
          label="Done"
          disabled={!isDirty}
          onClick={goBack}
        />
      </ActionBar>
    </ThemeSelector>
  );
};

export default ThemeManagerFooterActions;
