import { RootState } from '../../reducers';
import * as P from '../../clients/mira/types/Presentation';
import { LocalFileUploads } from '../../types';
import { getPresentationFileStatus } from '../../utilities/presentationUtils';

export interface PresentationsById {
  [presentationId: string]: P.Presentation | undefined;
}

export const selectPresentationsById = (state: RootState): PresentationsById =>
  state.v2Presentations.byId;

export const selectFileStatus = (
  presentation: P.Presentation,
  localUploads: LocalFileUploads,
) => {
  return getPresentationFileStatus(presentation, localUploads);
};
