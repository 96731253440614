import { nanoid } from 'nanoid';

export function createSessionId() {
  // Generate a unique id of length 6. Session keys are meant to be used as
  // unique keys for storing form state in session storage. These keys are exposed
  // in the URL so we are optimizing for URL length instead of collision probablity.
  return nanoid(5);
}

const newIdPrefix = 'new-';
const newIdRegex = new RegExp(`^${newIdPrefix}.+`);

export function createNewId() {
  return `${newIdPrefix}${createSessionId()}`;
}

export function isNewId(id: string) {
  return newIdRegex.test(id);
}
