import { createStandardAction } from 'typesafe-actions';

export const expandNode = createStandardAction(
  '@LIBRARY_TREE/EXPAND_NODE',
)<string>();

export const collapseNode = createStandardAction(
  '@LIBRARY_TREE/COLLAPSE_NODE',
)<string>();

export const setSelectedNodeIds = createStandardAction(
  '@LIBRARY_TREE/SET_SELECTED_NODE_IDS',
)<string[]>();

export const clearSelectedNodeIds = createStandardAction(
  '@LIBRARY_TREE/CLEAR_SELECTED_NODE_IDS',
)();
