import LibraryBooksSharpIcon from '@material-ui/icons/LibraryBooksSharp';
import MenuItem from '@material-ui/core/MenuItem';
import Skeleton from '@material-ui/lab/Skeleton';
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  ReactNode,
} from 'react';
import moment from 'moment-timezone';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import Heading from 'raydiant-elements/core/Heading';
import InputLabel from 'raydiant-elements/core/InputLabel';
import Text from 'raydiant-elements/core/Text';
import Link from 'raydiant-elements/core/Link';
import SelectField from 'raydiant-elements/core/SelectField';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import DateField from 'raydiant-elements/core/DateField';
import Column from 'raydiant-elements/layout/Column';
import Row from 'raydiant-elements/layout/Row';
import Spacer from 'raydiant-elements/layout/Spacer';
import OptionHeader from '../../components/OptionHeader';
import OptionDivider from '../../components/OptionDivider';
import LoadingButton from '../../components/LoadingButton';
import * as deviceActions from '../../actions/devices';
import * as playbackReportActions from '../../actions/playbackReports';
import { selectDomainForCurrentUser } from '../../selectors/v2/domains';
import { selectDevicesById } from '../../selectors/v2/devices';
import { selectUserProfile } from '../../selectors/user';
import { selectPlaybackReportsById } from '../../selectors/v2/playbackReports';
import { isNotNullOrUndefined } from '../../utilities';
import {
  selectReportableDevices,
  selectReportableDevicesByProfile,
} from './selectors';

const maxDayRange = 31;

const sources = {
  allMyDevices: 'allMyDevices',
  allDomainDevices: 'allDomainDevices',
  device: (deviceId: string) => `device|${deviceId}`,
  profile: (profileId: string) => `profile|${profileId}`,
};

const PlaybackReports = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Selectors

  const userDevices = useSelector(selectReportableDevices);
  const devicesByProfile = useSelector(selectReportableDevicesByProfile);
  const playbackReportsById = useSelector(selectPlaybackReportsById);
  const currentUser = useSelector(selectUserProfile);
  const domain = useSelector(selectDomainForCurrentUser);
  const devicesById = useSelector(selectDevicesById);

  // State

  const [source, setSource] = useState<string>('');
  const [start, setStart] = useState<string | null>(null);
  const [end, setEnd] = useState<string | null>(null);
  const [requestReportStatus, setRequestReportStatus] = useState<
    '' | 'pending' | 'success'
  >('');

  // Memoizers

  const [minDate, maxDate] = useMemo(() => {
    return [undefined, moment().format('L')];
  }, []);

  const dayRange = useMemo(() => {
    if (start === null || end === null) return 0;
    const startDate = moment(start).startOf('day');
    const endDate = moment(end).endOf('day');
    // Note: moment.diff(date, 'days') returns an integer but we want a float.
    return endDate.diff(startDate) / 1000 / 60 / 60 / 24;
  }, [start, end]);

  const activeReport = useMemo(() => {
    // We only allow generating one report at a time but return the most
    // recent report just in case.
    const reports = Object.values(playbackReportsById);
    return reports
      .filter(isNotNullOrUndefined)
      .sort((a, b) => b.createdAt.localeCompare(a.createdAt))[0];
  }, [playbackReportsById]);

  const reportFiles = useMemo(() => {
    if (!activeReport) return [];
    return [...activeReport.r.playbackReportFiles].sort(
      (a, b) => a.pageNumber - b.pageNumber,
    );
  }, [activeReport]);

  const [deviceOptions, deviceOptionLabelsByValue] = useMemo(() => {
    let options: ReactNode[] = [];
    let optionLabelsByValue: { [value: string]: string } = {};

    if (userDevices.length > 0) {
      options.push(<OptionHeader key="myDevices" label="My devices" />);

      userDevices.forEach((device) => {
        const value = sources.device(device.id);
        optionLabelsByValue[value] = device.name;
        options.push(
          <MenuItem key={device.id} value={value}>
            {optionLabelsByValue[value]}
          </MenuItem>,
        );
      });

      optionLabelsByValue[sources.allMyDevices] = 'All my devices';
      options.push(<OptionDivider key="divider-1" />);
      options.push(
        <MenuItem key="allMyDevices" value={sources.allMyDevices}>
          <Row>
            <Text>{optionLabelsByValue[sources.allMyDevices]}</Text>
            <Spacer />
            <Text muted>{userDevices.length}</Text>
          </Row>
        </MenuItem>,
      );
    }

    if (
      currentUser &&
      ['admin', 'superadmin'].includes(currentUser.domainRole) &&
      devicesByProfile.length > 0
    ) {
      options.push(<OptionDivider key="divider-2" />);

      const domainDevicesCount =
        userDevices.length +
        devicesByProfile.reduce((acc, { devices }) => acc + devices.length, 0);

      optionLabelsByValue[sources.allDomainDevices] =
        'All my devices + shared devices';
      options.push(
        <MenuItem key="allDomainDevices" value={sources.allDomainDevices}>
          <Row>
            <Text>{optionLabelsByValue['allDomainDevices']}</Text>
            <Spacer />
            <Text muted>{domainDevicesCount}</Text>
          </Row>
        </MenuItem>,
      );

      devicesByProfile.forEach(({ profile, devices }) => {
        if (devices.length > 0) {
          options.push(<OptionHeader key={profile.id} label={profile.name} />);
        }

        devices.forEach((device) => {
          const value = sources.device(device.id);
          optionLabelsByValue[value] = device.name;
          options.push(
            <MenuItem key={device.id} value={value}>
              {optionLabelsByValue[value]}
            </MenuItem>,
          );
        });
      });
    }

    return [options, optionLabelsByValue];
  }, [userDevices, devicesByProfile, currentUser]);

  const isGenerating =
    requestReportStatus === 'pending' || activeReport?.status === 'generating';

  const isDoneGenerating = activeReport?.status === 'done';

  const didErrorGenerating = activeReport?.status === 'error';

  // Callbacks

  const renderSelectValue = useCallback(
    (value: any) => {
      if (value) {
        return <Text>{deviceOptionLabelsByValue[value]}</Text>;
      }
      return <Text muted>Select source</Text>;
    },
    [deviceOptionLabelsByValue],
  );

  const generateReport = useCallback(() => {
    const deviceIds: string[] = [];

    dispatch(playbackReportActions.clearPlaybackReports());

    let reportName = 'PlaybackLogs';

    if (source === sources.allDomainDevices && domain) {
      reportName = `${reportName}_domain-${domain.name.trim()}`;
      userDevices.forEach((device) => deviceIds.push(device.id));
      devicesByProfile.forEach(({ devices }) =>
        devices.forEach((device) => deviceIds.push(device.id)),
      );
    } else if (source === sources.allMyDevices && currentUser) {
      reportName = `${reportName}_account-${currentUser.name.trim()}`;
      userDevices.forEach((device) => deviceIds.push(device.id));
    } else if (source.includes('device|')) {
      const [, deviceId] = source.split('|');
      const device = devicesById[deviceId];
      if (device) {
        reportName = `${reportName}_device-${device.name.trim()}`;
        deviceIds.push(deviceId);
      }
    }

    if (deviceIds.length > 0 && start && end) {
      const startDate = moment(start).startOf('day');
      const endDate = moment(end).endOf('day');

      const formatDate = (date: moment.Moment) => {
        return `${date.format('MMM')}${date.format('DD')},${date.format(
          'YYYY',
        )}`;
      };

      reportName = `${reportName}_${formatDate(startDate)}-${formatDate(
        endDate,
      )}`;

      setRequestReportStatus('pending');

      dispatch(
        playbackReportActions.generatePlaybackReport(
          {
            name: reportName,
            deviceIds,
            start: startDate.toISOString(),
            end: endDate.toISOString(),
            rowsPerPage: 0, // Set to 0 to use the API default.
          },
          {
            onSuccess: () => {
              setRequestReportStatus('success');
            },
          },
        ),
      );
    }
  }, [
    source,
    start,
    end,
    domain,
    currentUser,
    userDevices,
    devicesByProfile,
    devicesById,
    dispatch,
  ]);

  // Effects

  // Fetch devices on load.
  useEffect(() => {
    dispatch(deviceActions.fetchDevices());
  }, [dispatch]);

  // Fetch playback reports on load.
  useEffect(() => {
    dispatch(playbackReportActions.fetchPlaybackReports());
  }, [dispatch]);

  // Re-fetch playback reports if still generating.
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isGenerating) {
      timeout = setTimeout(() => {
        dispatch(playbackReportActions.fetchPlaybackReports());
      }, 5000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [dispatch, playbackReportsById, isGenerating]);

  // Render

  const isDayRangeNegative = dayRange < 0;
  const isDayRangeGreaterThanMax = dayRange > maxDayRange;
  const isInvalidDayRange = isDayRangeNegative || isDayRangeGreaterThanMax;
  const isGenerateReportDisabled =
    !source || !start || !end || isInvalidDayRange;

  let dayRangeHelperText: React.ReactNode = (
    <>
      Need a different range?{' '}
      <Link href="mailto:support@raydiant.com">Contact us</Link>
    </>
  );

  if (isDayRangeNegative) {
    dayRangeHelperText = 'End time must be later than start time';
  } else if (isDayRangeGreaterThanMax) {
    dayRangeHelperText = `This exceeds ${maxDayRange} day max. Try generating multiple reports`;
  }

  return (
    <>
      <Heading size={5} overline gutterBottom>
        Playback Reports
      </Heading>
      <Column doubleMargin>
        <Column>
          <SelectField
            label="Report Source"
            value={source}
            native={false}
            onChange={setSource}
            displayEmpty
            renderValue={renderSelectValue}
            helperText="This is the device, account or domain"
          >
            {deviceOptions.length > 0 ? (
              deviceOptions
            ) : (
              <MenuItem disabled>
                <em>No devices</em>
              </MenuItem>
            )}
          </SelectField>

          <div>
            <Row>
              <DateField
                label="From"
                placeholder="Start date"
                value={start}
                minDate={minDate}
                maxDate={maxDate}
                onChange={setStart}
                error={isInvalidDayRange}
              />
              <DateField
                label="To"
                placeholder="End date"
                value={end}
                minDate={minDate}
                maxDate={maxDate}
                onChange={setEnd}
                error={isInvalidDayRange}
              />
            </Row>
            <InputHelperText indent error={isInvalidDayRange}>
              {dayRangeHelperText}
            </InputHelperText>
          </div>
        </Column>

        <LoadingButton
          fullWidth
          label="Generate report"
          loadingLabel="Generating..."
          successLabel="Report generated!"
          errorLabel="Report generation failed"
          icon={<LibraryBooksSharpIcon />}
          status={
            isGenerating
              ? 'loading'
              : isDoneGenerating
              ? 'success'
              : didErrorGenerating
              ? 'error'
              : 'idle'
          }
          disabled={isGenerateReportDisabled}
          onClick={generateReport}
        />

        {isGenerating && (
          <Column>
            <div>
              <InputLabel>We're on it!</InputLabel>
              <div className={classes.callout}>
                But we're gonna need a minute or ten, depending on the size of
                the report. Don't worry, you can leave and come back later, and
                we should have the report ready for you to download.
              </div>
            </div>
            <Skeleton animation="wave" height={24} />
          </Column>
        )}

        {isDoneGenerating && (
          <Column>
            <div>
              <InputLabel>Report ready to download!</InputLabel>
              <div className={classes.callout}>
                Done! Click the below .csv to download. You can open it in a
                spreadsheet application, we recommend Excel as it can
                accommodate larger files with up to about 1,000,000 rows. Google
                Sheets works as well but can only handle about 40,000 rows.
              </div>
            </div>
            {reportFiles.map((prFile, index) => {
              const fileUrl = `https://${activeReport?.destinationBucket}.s3-us-west-2.amazonaws.com/${prFile.destinationKey}`;
              const [, fileName] = prFile.destinationKey
                .replace('.csv', '')
                .split('/');
              return (
                <div key={index}>
                  <Link href={fileUrl}>{fileName}</Link>
                </div>
              );
            })}
          </Column>
        )}

        {didErrorGenerating && (
          <Column>
            <div>
              <InputLabel error>Something went wrong</InputLabel>
              <div className={cn(classes.callout, classes.error)}>
                Hm, sorry not sure what happened. Suggest you try again, and if
                you experience the same issue please reach out to support.
              </div>
            </div>
          </Column>
        )}
      </Column>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    callout: {
      padding: theme.spacing(1.75, 2),
      border: `1px solid ${theme.input.border}`,
      borderRadius: theme.borderRadius.md,
    },

    error: {
      borderColor: theme.palette.error.main,
    },
  }),
);

export default PlaybackReports;
