import * as R from '../clients/mira/types/Resource';

export default function createDefaultResource(
  opts: DeepPartial<R.Resource> = {},
): R.Resource {
  return {
    id: '',
    createdAt: '',
    updatedAt: '',
    deletedAt: null,
    parentFolderId: null,
    ...opts,
    profile: {
      id: '',
      email: '',
      name: '',
      thumbnailUrl: '',
      ...(opts.profile || {}),
    },
    domain: {
      id: '',
      name: '',
      description: '',
      ...(opts.domain || {}),
      adminProfile: {
        id: '',
        email: '',
        name: '',
        thumbnailUrl: '',
        ...((opts.domain || {}).adminProfile || {}),
      },
    },
    r: {
      resourceACLs: [],
      tags: [],
      ...(opts.r || {}),
    },
  };
}
