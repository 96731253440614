import Cookie from 'js-cookie';
import { MiraLogger } from 'mira-logger-js';
import config from './config';

const logger = new MiraLogger({
  token: config.logglyCustomerToken,
  service: config.service,
  environment: config.environment,
  revision: config.revision,
  enableFlushLogs: !config.disableFlushLogs,
  enableConsoleLogs: !config.disableConsoleLogs,
});

logger.setContext({
  source: 'browser',
  userAgent: navigator.userAgent,
  sessionId: Cookie.get(config.sessionCookieKey),
});

setInterval(async () => {
  await logger.flush();
}, 5000);

export default logger;
