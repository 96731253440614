import Heading from 'raydiant-elements/core/Heading';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Center from 'raydiant-elements/layout/Center';
import Text from 'raydiant-elements/typography/Text';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import {
  marginBetweenChildrenHorizontal,
  marginBetweenChildrenVertical,
} from 'raydiant-elements/mixins';
import React from 'react';
import { useSelector } from 'react-redux';
import config from '../../config';
import { isNotNullOrUndefined } from '../../utilities';
import { selectApplications } from '../../selectors/applications';
import ApplicationCard from '../../components/ApplicationCard';

interface LibraryEmptyStateProps {
  onApplicationClick: (applicationId: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4, 2),
      paddingBottom: theme.spacing(10),

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(4, 1),
      },
    },

    container: {
      flex: 1,
      flexShrink: 0,
    },

    headingText: {
      textAlign: 'center',
    },

    apps: {
      ...marginBetweenChildrenHorizontal(theme.spacing(2)),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',

      [theme.breakpoints.down('sm')]: {
        ...marginBetweenChildrenVertical(theme.spacing(2)),
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
  }),
);

const LibraryEmptyState = ({ onApplicationClick }: LibraryEmptyStateProps) => {
  const classes = useStyles();

  // Selectors

  const applications = useSelector(selectApplications);

  const flyersApp = applications.find((app) => app.id === config.flyersAppId);
  const picturesApp = applications.find(
    (app) => app.id === config.picturesAppId,
  );
  const youtubeApp = applications.find((app) => app.id === config.youtubeAppId);
  const apps = [flyersApp, picturesApp, youtubeApp].filter(
    isNotNullOrUndefined,
  );

  return (
    <Scrollable>
      <Center doubleMargin className={classes.root}>
        <div className={classes.headingText}>
          <Heading size={2} weight={500} color="primary">
            It’s quiet in here!
          </Heading>
          <Text small muted>
            Lets make something! It’s easy, trust me ;)
          </Text>
        </div>

        <div className={classes.apps}>
          {apps.map((app) => (
            <ApplicationCard
              key={app.id}
              application={app}
              onClick={() => onApplicationClick(app.id)}
            />
          ))}
        </div>
      </Center>
    </Scrollable>
  );
};

export default LibraryEmptyState;
