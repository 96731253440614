import { Playlist, ApiClientError } from '@raydiant/api-client-js';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import raydiant from '../clients/raydiant';
import * as playlistActions from '../actions/playlists';
import { keys } from '../queryClient';
import useProtectedMutation from './useProtectedMutation';

export default function useCopyPlaylist() {
  const dispatchRedux = useDispatch();
  const queryClient = useQueryClient();

  const mutation = useProtectedMutation<
    Playlist,
    ApiClientError,
    { playlistId: string; params: { name: string } }
  >(({ playlistId, params }) => raydiant.copyPlaylist(playlistId, params), {
    onSuccess: (playlist) => {
      // Update react-query cache.
      queryClient.setQueryData(keys.playlist(playlist.id), playlist);

      // Update redux cache for /screens and /library.
      dispatchRedux(playlistActions.copyPlaylistAsync.success(playlist));
    },
  });

  return mutation;
}
