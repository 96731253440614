import { FileUpload, FileUploadStep } from '@raydiant/api-client-js';
import { LocalFileUploads } from '../types';

export const getLastFileUploadStep = (fileUpload: FileUpload) => {
  let lastStep: FileUploadStep | undefined;

  fileUpload.completedSteps.forEach((step) => {
    if (!lastStep || lastStep.createdAt < step.createdAt) {
      lastStep = step;
    }
  });

  return lastStep;
};

export const hasFileUploadError = (
  fileUpload: FileUpload,
  localUploads: LocalFileUploads,
) => {
  const localUpload = localUploads[fileUpload.id];
  const localUploadHasError = localUpload && !!localUpload.error;
  const lastStep = getLastFileUploadStep(fileUpload);
  const lastStepHasError =
    !!lastStep &&
    typeof lastStep.error === 'object' &&
    lastStep.error &&
    Object.keys(lastStep.error).length > 0;

  const hasFailedUpload =
    !!lastStep && lastStep.stepName === 'file_upload_created' && !localUpload;

  return localUploadHasError || lastStepHasError || hasFailedUpload;
};

export const isFileTranscoding = (
  fileUpload: FileUpload,
  localUploads: LocalFileUploads,
) => {
  const hasError = hasFileUploadError(fileUpload, localUploads);
  if (hasError) return false;

  // The upload steps may be out of order so the file upload has
  // completed as long as the steps contain 'upload_completed'.
  const hasCompletedStep = fileUpload.completedSteps.some(
    (fu) => fu.stepName === 'upload_completed',
  );

  return !hasCompletedStep;
};

export const isFileUploading = (
  fileUpload: FileUpload,
  localUploads: LocalFileUploads,
) => {
  const localUpload = localUploads[fileUpload.id];
  const isUploadingLocalFile = !!localUpload && localUpload.isUploading;
  return isUploadingLocalFile || isFileTranscoding(fileUpload, localUploads);
};
