import * as P from '../clients/mira/types/Presentation';

export default function searchPresentations<T extends P.Presentation>(
  presentations: T[],
  searchQuery: string,
) {
  const searchQueryInsenstive = searchQuery.toLowerCase().trim();
  return presentations.filter(
    (p) =>
      p.name.toLowerCase().includes(searchQueryInsenstive) ||
      p.applicationName.toLowerCase().includes(searchQueryInsenstive),
  );
}
