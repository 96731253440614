import * as P from '../../clients/mira/types/Presentation';

const getPlaylistIds = (presentation: P.Presentation): string[] => {
  const playlistIds: string[] = [];

  // Traverse the top level of presentation properties for playlistIds.
  presentation.presentationProperties.forEach((prop) => {
    if (prop.type === 'playlist') {
      const playlistId = presentation.applicationVariables[prop.name];
      if (playlistId) {
        playlistIds.push(playlistId);
      }
    }
  });

  return playlistIds;
};

export default function comparePresentations(
  presentation1: P.Presentation,
  presentation2?: P.Presentation,
) {
  const p1PlaylistIds = getPlaylistIds(presentation1);
  if (!presentation2) return { newSelectedPlaylists: p1PlaylistIds };

  const p2PlaylistIds = getPlaylistIds(presentation2);

  const newSelectedPlaylists = p1PlaylistIds.filter(
    (playlistId) => p2PlaylistIds.indexOf(playlistId) === -1,
  );

  return { newSelectedPlaylists };
}
