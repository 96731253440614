import { Theme, ApiClientError } from '@raydiant/api-client-js';
import raydiant from '../clients/miraClient';
import useProtectedQuery from './useProtectedQuery';
import { keys } from '../queryClient';

export default function useThemes() {
  const query = useProtectedQuery<Theme[], ApiClientError>(
    keys.themes(),
    () => raydiant.getThemes(),
    {},
  );

  return query;
}
