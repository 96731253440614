import { UnauthorizedError, ForbiddenError } from '@raydiant/api-client-js';
import { Location } from 'history';
import { matchPath } from 'react-router';
import * as paths from '../routes/paths';
import auth0 from '../clients/auth0Client';
import {
  getRemoteAssistUser,
  setRemoteAssistUser,
} from '../utilities/remoteAssistUser';

export default function globalErrorHandler<E>(
  error: E | null,
  { pathname, search, hash }: Location,
) {
  if (error) {
    if (error instanceof UnauthorizedError) {
      // Check if current path is an unprotected path, meaning auth errors should not
      // trigger a redirect.
      const isPathUnprotectedPage = Object.values(paths).some(
        (path) =>
          path.meta.unprotected &&
          !!matchPath(pathname, { path: path.pattern, exact: true }),
      );

      // The current path is marked as a protected and should redirect to the login page
      // when an unauthorized error occurs.
      if (!isPathUnprotectedPage) {
        const backTo = [pathname, search, hash].join('');
        auth0.logout(backTo);
      }
    }

    if (error instanceof ForbiddenError && !!getRemoteAssistUser()) {
      setRemoteAssistUser('');
      // Full page reload to home page.
      window.location.href = paths.home();
    }
  }
}
