import { Profile, ApiClientError } from '@raydiant/api-client-js';
import raydiant from '../clients/raydiant';
import useProtectedQuery from './useProtectedQuery';
import { keys } from '../queryClient';

export default function useCurrentUser() {
  const query = useProtectedQuery<Profile, ApiClientError>(
    keys.profile(),
    () => raydiant.getProfile(),
    {
      // Cache the current user profile for the lifetime of the page.
      staleTime: Infinity,
    },
  );

  return query;
}
