import { useContext } from 'react';
import PlaylistPageContext from './PlaylistPageContext';

export default function usePlaylistPageContext() {
  const context = useContext(PlaylistPageContext);
  if (!context) {
    throw new Error(
      'usePlaylistPageContext must be wrapped by PlaylistPageContext.Provider',
    );
  }
  return context;
}
