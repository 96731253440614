import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import moment from 'moment-timezone';
import deepEqual from 'fast-deep-equal';
import { Playlist, Frequency } from '@raydiant/api-client-js';
import Text from 'raydiant-elements/core/Text';
import Link from 'raydiant-elements/core/Link';
import TimeField from 'raydiant-elements/core/TimeField';
import DateField from 'raydiant-elements/core/DateField';
import InputLabel from 'raydiant-elements/core/InputLabel';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import ToggleButtonGroup from 'raydiant-elements/core/ToggleButtonGroup';
import RecurrenceSelector from 'raydiant-elements/core/RecurrenceSelector';
import Switch from 'raydiant-elements/core/Switch';
import Row from 'raydiant-elements/layout/Row';
import PlaylistModalBody from './PlaylistModalBody';
import PlaylistModalFooter from './PlaylistModalFooter';
import usePlaylistPageContext from './usePlaylistPageContext';

interface PlaylistModalScheduleProps {
  playlist: Playlist;
  onClose: () => void;
}

const datetimeFormat = 'YYYY-MM-DDTHH:mm:ss';

const toTimeFieldValue = (value: string | null) => {
  return value ? moment(value).format('HH:mm') : '';
};

const getDatetime = (date: string, time: string) => {
  const datetime = moment(date);
  const [hour, minute] = time.split(':');
  datetime.hour(parseInt(hour, 10));
  datetime.minute(parseInt(minute, 10));
  return datetime.format(datetimeFormat);
};

const localTimezone = moment.tz.guess();

const PlaylistModalSchedule: FC<PlaylistModalScheduleProps> = ({
  playlist,
  onClose,
}) => {
  const { updatePlaylist } = usePlaylistPageContext();

  // State

  const [enableScheduling, setEnableScheduling] = useState(
    !!playlist.startDatetime,
  );

  const [scheduleType, setScheduleType] = useState(playlist.scheduleType);

  const [startTime, setStartTime] = useState(
    toTimeFieldValue(playlist.startDatetime),
  );

  const [startDate, setStartDate] = useState(playlist.startDatetime);

  const [endTime, setEndTime] = useState(
    toTimeFieldValue(playlist.endDatetime),
  );

  const [endDate, setEndDate] = useState(playlist.endDatetime);

  const [isRepeating, setIsRepeating] = useState(!!playlist.recurrenceRule);

  const [recurrenceRule, setRecurrenceRule] = useState(playlist.recurrenceRule);

  // Memoizers

  const minStartDate = useMemo(() => {
    const date = moment();
    date.date(date.date() - 7);
    return date.toDate();
  }, []);

  const minEndDate = useMemo(() => {
    const date = moment.tz(
      playlist.startDatetime || '',
      playlist.tzid || moment.tz.guess(),
    );
    return date.toDate();
  }, [playlist.startDatetime, playlist.tzid]);

  const isDirty = useMemo(() => {
    if (scheduleType !== playlist.scheduleType) return true;
    if (startDate !== playlist.startDatetime) return true;
    if (startTime !== toTimeFieldValue(playlist.startDatetime)) return true;
    if (endDate !== playlist.endDatetime) return true;
    if (endTime !== toTimeFieldValue(playlist.endDatetime)) return true;
    if (recurrenceRule && !playlist.recurrenceRule) return true;
    if (!recurrenceRule && playlist.recurrenceRule) return true;

    if (recurrenceRule && playlist.recurrenceRule) {
      const prevRRule = playlist.recurrenceRule;
      if (recurrenceRule.freq !== prevRRule.freq) return true;
      if (recurrenceRule.interval !== prevRRule.interval) return true;
      if (!deepEqual(recurrenceRule.byday, prevRRule.byday)) return true;
    }

    return false;
  }, [
    playlist,
    scheduleType,
    startDate,
    startTime,
    endDate,
    endTime,
    recurrenceRule,
  ]);

  const isInvalidDateRange = useMemo(() => {
    if (!startDate || !endDate) return false;
    return getDatetime(startDate, startTime) > getDatetime(endDate, endTime);
  }, [startDate, startTime, endDate, endTime]);

  // Callbacks

  const handleEnableSchedulingChange = useCallback((enabled: boolean) => {
    setEnableScheduling(enabled);

    // Set default values when scheduling is enabled, clear schedule when disabled.
    if (enabled) {
      const now = moment();
      now.seconds(0);
      now.milliseconds(0);

      const todayEnd = moment();
      todayEnd.hours(24);
      todayEnd.minutes(0);
      todayEnd.seconds(0);
      todayEnd.milliseconds(0);

      setScheduleType('join');
      setStartDate(now.format(datetimeFormat));
      setStartTime(toTimeFieldValue(now.format(datetimeFormat)));
      setEndDate(todayEnd.format(datetimeFormat));
      setEndTime(toTimeFieldValue(todayEnd.format(datetimeFormat)));
      setIsRepeating(false);
      setRecurrenceRule(null);
    } else {
      setIsRepeating(false);
      setRecurrenceRule(null);
      setStartDate(null);
      setStartTime('');
      setEndDate(null);
      setEndTime('');
    }
  }, []);

  const handleScheduleTypeChange = useCallback(
    (valueOrArray: string | string[]) => {
      // ToggleButtonGroup return type is string | string[] but is only string when
      // exclusive is true. We should clean up the types somehow.
      const value = Array.isArray(valueOrArray)
        ? valueOrArray[0]
        : valueOrArray;

      // Prevent unsetting the schedule type.
      if (value) {
        setScheduleType(value);
      }
    },
    [],
  );

  const handleIsRepeatingChange = useCallback((isRepeating: boolean) => {
    setIsRepeating(isRepeating);

    // Set the recurrence rule defaults if not set.
    if (isRepeating) {
      setRecurrenceRule({
        // dtstart is set to startDatetime when clicking done.
        dtstart: '',
        freq: Frequency.weekly,
        interval: 1,
      });
    } else {
      setRecurrenceRule(null);
    }
  }, []);

  const handleDone = () => {
    const params: Partial<Playlist> = {
      startDatetime: null,
      endDatetime: null,
      tzid: null,
      recurrenceRule: null,
    };

    if (enableScheduling) {
      params.scheduleType = scheduleType;

      if (startDate) {
        params.startDatetime = getDatetime(startDate, startTime);
      }

      if (endDate) {
        params.endDatetime = getDatetime(endDate, endTime);
      }

      if (isRepeating && recurrenceRule) {
        params.recurrenceRule = {
          ...recurrenceRule,
          // We don't show unique field for the reccurence start time versus the initial event's
          // start time so set the dtstart of the recurrence rule to be the same as the initial
          // event's start time.
          dtstart: params.startDatetime || '',
        };
      }

      params.tzid = localTimezone;
    }

    updatePlaylist(playlist.id, params);

    onClose();
  };

  // Side-effects

  // Update state when playlist changes.
  useEffect(() => {
    setEnableScheduling(!!playlist.startDatetime);
    setScheduleType(playlist.scheduleType);
    setStartTime(toTimeFieldValue(playlist.startDatetime));
    setStartDate(playlist.startDatetime);
    setEndTime(toTimeFieldValue(playlist.endDatetime));
    setEndDate(playlist.endDatetime);
    setIsRepeating(!!playlist.recurrenceRule);
    setRecurrenceRule(playlist.recurrenceRule);
  }, [playlist]);

  // Render

  const hasStartDateError =
    enableScheduling && (!startDate || isInvalidDateRange);
  const hasStartTimeError =
    enableScheduling && (!startTime || isInvalidDateRange);

  const hasEndDateError = enableScheduling && (!endDate || isInvalidDateRange);
  const hasEndTimeError = enableScheduling && (!endTime || isInvalidDateRange);

  const enableDoneButton =
    isDirty &&
    !hasStartDateError &&
    !hasStartTimeError &&
    !hasEndDateError &&
    !hasEndTimeError;

  return (
    <>
      <PlaylistModalBody>
        <Switch
          label="Enable Scheduling"
          helperText="Playlist will have a start and end time"
          checked={enableScheduling}
          onChange={handleEnableSchedulingChange}
        />

        {enableScheduling && (
          <>
            <ToggleButtonGroup
              exclusive
              label="Schedule Type"
              value={scheduleType}
              onChange={handleScheduleTypeChange}
              helperText={
                <>
                  Can join or override any existing content.{' '}
                  <Link
                    target="_blank"
                    href="https://support.raydiant.com/hc/en-us/articles/4405859588500-What-is-Nested-Playlists-#enhanced-scheduling"
                  >
                    Learn more
                  </Link>
                </>
              }
            >
              <ToggleButtonGroup.Button value="join">
                Join
              </ToggleButtonGroup.Button>
              <ToggleButtonGroup.Button value="override">
                Override
              </ToggleButtonGroup.Button>
            </ToggleButtonGroup>

            {playlist.tzid && localTimezone !== playlist.tzid && (
              <Text muted>All times in {playlist.tzid}</Text>
            )}

            <div>
              <InputLabel>Start</InputLabel>
              <Row>
                <DateField
                  value={startDate}
                  minDate={minStartDate}
                  onChange={setStartDate}
                  error={hasStartDateError}
                />
                <TimeField
                  value={startTime}
                  onChange={setStartTime}
                  error={!!hasStartTimeError}
                />
              </Row>
            </div>

            <div>
              <InputLabel>End</InputLabel>
              <Row>
                <DateField
                  value={endDate}
                  onChange={setEndDate}
                  minDate={minEndDate}
                  error={!!hasEndDateError}
                />
                <TimeField
                  value={endTime}
                  onChange={setEndTime}
                  error={!!hasEndTimeError}
                />
              </Row>
              {isInvalidDateRange && (
                <InputHelperText error>
                  End time must be later than start time
                </InputHelperText>
              )}
            </div>

            <Switch
              label="This is a repeating event"
              checked={isRepeating}
              onChange={handleIsRepeatingChange}
            />

            {isRepeating && (
              <Row>
                <RecurrenceSelector
                  recurrenceRule={recurrenceRule}
                  onChange={setRecurrenceRule}
                />
              </Row>
            )}
          </>
        )}
      </PlaylistModalBody>

      <PlaylistModalFooter
        enableDone={enableDoneButton}
        onCancel={onClose}
        onDone={handleDone}
      />
    </>
  );
};

export default PlaylistModalSchedule;
