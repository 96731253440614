import CreateIcon from '@material-ui/icons/Create';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { buttonReset } from 'raydiant-elements/mixins';
import Avatar from 'raydiant-elements/core/Avatar';
import Row from 'raydiant-elements/layout/Row';
import { Theme } from 'raydiant-elements/theme';
import cn from 'classnames';
import React, { FC } from 'react';
import * as R from '../../clients/mira/types/Resource';
import { domainRoleLabels } from '../../utilities';

interface MemberAccountItemProps {
  profile: R.ResourceProfile;
  selected?: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...buttonReset(),
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      border: `1px solid ${theme.divider.secondary}`,
      borderRadius: theme.borderRadius.sm,
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(4),
      marginBottom: theme.spacing(0.25),

      '&:focus': {
        borderColor: theme.divider.primary,
      },

      '&:hover $edit': {
        opacity: 1,
      },
    },

    selected: {
      borderColor: 'rgba(0, 0, 0, 0.8)',
      boxShadow: `inset 0px 0px 0px 2px rgba(0, 0, 0, 0.8)`,

      '& $edit': {
        opacity: 1,
      },
    },

    name: {
      marginTop: theme.spacing(0.75),
      color: theme.palette.text.headingSecondary,
      fontSize: theme.fontSizes.xl,
    },

    role: {
      color: theme.palette.text.secondary,
      fontSize: theme.fontSizes.sm,
      textTransform: 'uppercase',
      letterSpacing: 1,
      lineHeight: 1.26,
      fontWeight: 600,
      marginBottom: theme.spacing(0.5),
    },

    email: {
      color: theme.palette.text.secondary,
      fontSize: theme.fontSizes.sm,
      lineHeight: 1,
      letterSpacing: 0.08,
    },

    edit: {
      marginTop: theme.spacing(0.75),
      marginRight: theme.spacing(0.5),
      color: theme.palette.text.secondary,
      display: 'flex',
      flexDirection: 'column',
      opacity: 0.5,
    },
  }),
);

const MemberAccountItem: FC<MemberAccountItemProps> = ({
  profile,
  selected,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <button
      className={cn(classes.root, selected && classes.selected)}
      onClick={onClick}
    >
      <Row halfMargin>
        <Avatar thumbnailUrl={profile.thumbnailUrl} name={profile.name} />
        <div>
          <div className={classes.name}>{profile.name}</div>
          <div className={classes.role}>
            {domainRoleLabels[profile.domainRole]}
          </div>
          <div className={classes.email}>{profile.email}</div>
        </div>
      </Row>
      <div className={classes.edit}>
        <CreateIcon />
      </div>
    </button>
  );
};

export default MemberAccountItem;
