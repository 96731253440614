import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { Presentation, Theme, AffectedDevice } from '@raydiant/api-client-js';
import { SortThemeOptions } from '../../utilities';

export const loadPresentationPage = createStandardAction(
  '@PRESENTATION_PAGE/LOAD_PRESENTATION_PAGE',
)<{ presentationId: string }>();

export const loadPresentationPageAsync = createAsyncAction(
  '@PRESENTATION_PAGE/LOAD_PRESENTATION_PAGE_REQUEST',
  '@PRESENTATION_PAGE/LOAD_PRESENTATION_PAGE_SUCCESS',
  '@PRESENTATION_PAGE/LOAD_PRESENTATION_PAGE_FAILURE',
)<void, { affectedDevices: AffectedDevice[] }, Error>();

export const setUnsavedPresentation = createStandardAction(
  '@PRESENTATION_PAGE/SET_UNSAVED_PRESENTATION',
)<Presentation>();

export const clearUnsavedPresentation = createStandardAction(
  '@PRESENTATION_PAGE/CLEAR_UNSAVED_PRESENTATION',
)<string>();

export const clearAllUnsavedPresentations = createStandardAction(
  '@PRESENTATION_PAGE/CLEAR_ALL_UNSAVED_PRESENTATIONS',
)();

export const setUnsavedTheme = createStandardAction(
  '@PRESENTATION_PAGE/SET_UNSAVED_THEME',
)<Theme>();

export const clearUnsavedTheme = createStandardAction(
  '@PRESENTATION_PAGE/CLEAR_UNSAVED_THEME',
)<string>();

export const clearAllUnsavedThemes = createStandardAction(
  '@PRESENTATION_PAGE/CLEAR_ALL_UNSAVED_THEMES',
)();

export const setThemeSortOptions = createStandardAction(
  '@PRESENTATION_PAGE/SET_THEME_SORT_OPTIONS',
)<SortThemeOptions>();

export const setThemeSearchQuery = createStandardAction(
  '@PRESENTATION_PAGE/SET_THEME_EARCH_QUERY',
)<string>();
