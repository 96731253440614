import { UnauthorizedError } from '@raydiant/api-client-js';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import auth0 from 'auth0-js';
import config from '../config';
import * as paths from '../routes/paths';

const getAuth0SpaClient = (() => {
  let auth0Client: Auth0Client;
  let createAuth0ClientPromise: Promise<Auth0Client> | null = null;

  return async () => {
    if (!auth0Client) {
      // Memoize the createAuth0Client promise to prevent multiple calls to
      // auth/token on page load.
      if (!createAuth0ClientPromise) {
        createAuth0ClientPromise = createAuth0Client({
          domain: config.auth0Domain,
          client_id: config.auth0ClientId,
          audience: config.auth0Audience,
          redirect_uri: `${window.location.origin}${paths.loginCallback()}`,
        });
      }

      auth0Client = await createAuth0ClientPromise;
      createAuth0ClientPromise = null;
    }

    return auth0Client;
  };
})();

const auth0WebAuthClient = new auth0.WebAuth({
  domain: config.auth0Domain,
  clientID: config.auth0ClientId,
});

const auth0Client = {
  async loginWithRedirect(appState?: any) {
    try {
      const auth0Client = await getAuth0SpaClient();
      return await auth0Client.loginWithRedirect({
        appState,
        acr_values:
          'http://schemas.openid.net/pape/policies/2007/06/multi-factor',
      });
    } catch (err) {
      throw new Error(`Auth0 loginWithRedirect failed with '${err.message}'`);
    }
  },

  async signupWithRedirect(appState?: any) {
    try {
      const auth0Client = await getAuth0SpaClient();
      return await auth0Client.loginWithRedirect({
        appState,
        screen_hint: 'signup',
      });
    } catch (err) {
      throw new Error(`Auth0 signupWithRedirect failed with '${err.message}'`);
    }
  },

  async handleRedirectCallback() {
    try {
      const auth0Client = await getAuth0SpaClient();
      return await auth0Client.handleRedirectCallback();
    } catch (err) {
      throw new Error(
        `Auth0 handleRedirectCallback failed with '${err.message}'`,
      );
    }
  },

  async getTokenSilently() {
    try {
      const auth0Client = await getAuth0SpaClient();
      return await auth0Client.getTokenSilently();
    } catch (err) {
      if (err.error === 'login_required') {
        throw new UnauthorizedError();
      }
      throw new Error(`Auth0 getTokenSilently failed with '${err.message}'`);
    }
  },

  async getUser() {
    try {
      const auth0Client = await getAuth0SpaClient();
      return await auth0Client.getUser();
    } catch (err) {
      throw new Error(`Auth0 getUser failed with '${err.message}'`);
    }
  },

  async getIdTokenClaims() {
    try {
      const auth0Client = await getAuth0SpaClient();
      return await auth0Client.getIdTokenClaims();
    } catch (err) {
      throw new Error(`Auth0 getIdTokenClaims failed with '${err.message}'`);
    }
  },

  async logout(backTo?: string) {
    try {
      const auth0Client = await getAuth0SpaClient();
      return await auth0Client.logout({
        returnTo: `${window.location.origin}${paths.login({
          backTo,
        })}`,
      });
    } catch (err) {
      throw new Error(`Auth0 logout failed with '${err.message}'`);
    }
  },

  async sendChangePasswordEmail(email: string) {
    return new Promise((resolve, reject) => {
      auth0WebAuthClient.changePassword(
        {
          connection: config.auth0DbConnection,
          email,
        },
        (err) => {
          if (err) {
            return reject(
              new Error(
                `Auth0 sendChangePasswordEmail failed with '${err.error}'`,
              ),
            );
          }

          resolve();
        },
      );
    });
  },
};

export default auth0Client;
