import * as A from '../clients/mira/types/Application';
import * as P from '../clients/mira/types/Presentation';
import { Path } from '../types';

interface ApplicationVariablesWithPath {
  path: Path;
  applicationVariable: any; // eslint-next-line
}

export default function collectApplicationVariables(
  propType: string,
  presentation: P.Presentation,
  appVersion: A.ApplicationVersion,
) {
  const applicationVariablesWithPath: ApplicationVariablesWithPath[] = [];

  // Traverse presentation properties to find properties of the provided type.
  const traverse = (
    properties: P.PresentationProperty[],
    applicationVariables: P.ApplicationVariables,
    path: Path,
  ) => {
    properties.forEach((property) => {
      const propertyPath = [...path, property.name];
      const applicationVariable = applicationVariables[property.name];

      if (property.type === propType) {
        applicationVariablesWithPath.push({
          path: propertyPath,
          applicationVariable,
        });
      } else if (property.type === 'array') {
        // Traverse the array property's properties.
        if (Array.isArray(applicationVariable)) {
          applicationVariable.forEach((_, index) => {
            if (property.properties) {
              traverse(property.properties, applicationVariable[index], [
                ...propertyPath,
                index,
              ]);
            }
          });
        }
      }
    });
  };

  traverse(
    appVersion.presentationProperties,
    presentation.applicationVariables,
    [],
  );

  return applicationVariablesWithPath;
}
