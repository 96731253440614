import { createSelector } from 'reselect';
import { RootState } from '../../reducers';
import * as D from '../../clients/mira/types/Device';
import * as R from '../../clients/mira/types/Resource';
import {
  sortDevices,
  sortProfiles,
  searchDevices,
  searchProfiles,
} from '../../utilities';
import { selectDevicesByOwner } from '../../selectors/v2/devices';
import { selectPlaylistsById } from '../../selectors/v2/playlists';
import { selectOtherDomainProfiles } from '../../selectors/v2/domains';

export const selectSortOptions = (state: RootState) =>
  state.devicesPage.sortOptions;

export const selectSearchQuery = (state: RootState) =>
  state.devicesPage.searchQuery;

export const selectSharingSortOptions = (state: RootState) =>
  state.devicesPage.sharingSortOptions;

export const selectSharingSearchQuery = (state: RootState) =>
  state.devicesPage.sharingSearchQuery;

export const selectLastLoadedDate = (state: RootState) =>
  state.devicesPage.lastLoadedDate;

export const selectStatusPopoverDeviceId = (state: RootState) =>
  state.devicesPage.statusPopoverDeviceId;

export const selectDeviceResults = createSelector(
  [
    selectDevicesByOwner,
    selectSortOptions,
    selectSearchQuery,
    selectLastLoadedDate,
    selectPlaylistsById,
  ],
  (devicesByOwner, sortOptions, searchQuery, lastLoadedDate) => {
    const devicesByOwnerResults: {
      [profileId: string]: { profile: R.ResourceProfile; devices: D.Device[] };
    } = {};

    Object.entries(devicesByOwner).forEach(
      ([profileId, { profile, devices }]) => {
        const searchedDevices = searchDevices(devices, searchQuery);
        devicesByOwnerResults[profileId] = {
          profile,
          devices: sortDevices(searchedDevices, sortOptions, lastLoadedDate),
        };
      },
    );

    return devicesByOwnerResults;
  },
);

export const selectSharingProfileResults = createSelector(
  [
    selectOtherDomainProfiles,
    selectSharingSortOptions,
    selectSharingSearchQuery,
  ],
  (profiles, sortOptions, searchQuery) => {
    const searchedProfiles = searchProfiles(profiles, searchQuery);
    return sortProfiles(searchedProfiles, sortOptions);
  },
);
