import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Theme, Presentation } from '@raydiant/api-client-js';
import ColorField from 'raydiant-elements/core/ColorField';
import TextField from 'raydiant-elements/core/TextField';
import Text from 'raydiant-elements/core/Text';
import Form from 'raydiant-elements/core/Form';
import Hidden from 'raydiant-elements/layout/Hidden';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Column from 'raydiant-elements/layout/Column';
import TextStyleField from '../../components/TextStyleField';
import useStyles from './PresentationPage.styles';
import ThemeFormActions from './ThemeFormActions';

interface ThemeFormProps {
  preview: React.ReactNode;
  theme?: Theme;
  presentation: Presentation | undefined;
  onChange: (theme: Theme) => void;
}

const ThemeForm = ({
  preview,
  theme,
  presentation,
  onChange,
}: ThemeFormProps) => {
  const classes = useStyles();

  // State

  const [themeName, setThemeName] = useState<string | null>(null);

  // Refs

  const throttledRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // Callbacks

  const throttledOnChange = useCallback(
    (updatedTheme: Theme) => {
      if (throttledRef.current) {
        clearTimeout(throttledRef.current);
      }

      throttledRef.current = setTimeout(() => onChange(updatedTheme), 150);
    },
    [onChange],
  );

  // Effects

  // Update themeName state when theme is loaded
  useEffect(() => {
    if (!theme) return;
    setThemeName(theme.name);
  }, [theme]);

  // Clear throttle timeout on unmount.
  useEffect(() => {
    return () => {
      if (throttledRef.current) {
        clearTimeout(throttledRef.current);
      }
    };
  }, []);

  if (!theme) return null;

  return (
    <>
      <Scrollable>
        <Hidden smUp>{preview}</Hidden>

        <Form.Section>
          <Text muted>Theme Builder</Text>
        </Form.Section>

        <Column className={classes.scrollContents} doubleMargin>
          <TextField
            label="name"
            value={themeName ?? theme.name}
            onChange={setThemeName}
            onBlur={() => {
              if (themeName) {
                onChange({ ...theme, name: themeName });
              }
            }}
          />

          <TextStyleField
            label="Heading 1 Text"
            fontUrl={theme.headingFont}
            color={theme.headingTextColor}
            onFontChange={(headingFont) => {
              onChange({ ...theme, headingFont });
            }}
            onColorChange={(headingTextColor) => {
              throttledOnChange({ ...theme, headingTextColor });
            }}
          />

          <TextStyleField
            label="Heading 2 Text"
            fontUrl={theme.heading2Font}
            color={theme.heading2TextColor}
            onFontChange={(heading2Font) => {
              onChange({ ...theme, heading2Font });
            }}
            onColorChange={(heading2TextColor) => {
              throttledOnChange({ ...theme, heading2TextColor });
            }}
          />

          <TextStyleField
            label="Body Text"
            fontUrl={theme.bodyFont}
            color={theme.bodyTextColor}
            onFontChange={(bodyFont) => {
              onChange({ ...theme, bodyFont });
            }}
            onColorChange={(bodyTextColor) => {
              throttledOnChange({ ...theme, bodyTextColor });
            }}
          />

          <ColorField
            label="Background Color"
            value={theme.backgroundColor}
            onChange={(backgroundColor) =>
              throttledOnChange({ ...theme, backgroundColor })
            }
          />

          <ColorField
            label="Border Color"
            value={theme.borderColor}
            onChange={(borderColor) =>
              throttledOnChange({ ...theme, borderColor })
            }
          />
        </Column>
      </Scrollable>
      <ThemeFormActions
        presentation={presentation}
        theme={theme}
        onChange={onChange}
      />
    </>
  );
};

export default ThemeForm;
