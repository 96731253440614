import * as P from '../clients/mira/types/Presentation';
import { LocalFileUploads } from '../types';
import { RootState } from '../reducers';
import {
  hasFileUploadError,
  isFileUploading,
} from '../utilities/fileUploadUtils';

export const selectLocalUploads = (state: RootState) =>
  state.fileUploads.uploads;

export const selectUploadsByPresentationId = (state: RootState) =>
  state.fileUploads.uploadsByPresentationId;

export const selectLastFileUploadStep = (fileUpload: P.FileUpload) => {
  let lastStep: P.FileUploadStep | undefined;

  fileUpload.completedSteps.forEach((step) => {
    if (!lastStep || lastStep.createdAt < step.createdAt) {
      lastStep = step;
    }
  });

  return lastStep;
};

export const selectHasFileError = (
  fileUpload: P.FileUpload,
  localUploads: LocalFileUploads,
) => {
  return hasFileUploadError(fileUpload, localUploads);
};

export const selectIsFileUploading = (
  fileUpload: P.FileUpload,
  localUploads: LocalFileUploads,
) => {
  return isFileUploading(fileUpload, localUploads);
};
