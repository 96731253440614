export default class AnalyticsClient {
  constructor(private analytics: SegmentAnalytics.AnalyticsJS) {}

  trackRegisterDeviceRequest(p: { activationCode: string }) {
    this.analytics.track('Register Device Request', p);
  }

  trackRegisterDeviceSuccess(p: { deviceId: string; isAudioOnly: boolean }) {
    this.analytics.track('Register Device Success', p);
  }

  trackRegisterDeviceFailure(p: { activationCode: string }) {
    this.analytics.track('Register Device Failure', p);
  }

  trackCreatePresentationSuccess(p: {
    presentationId: string;
    applicationId: string;
    appVersionId: string;
  }) {
    this.analytics.track('Create Presentation Success', p);
  }

  trackUpdatePresentationSuccess(p: {
    presentationId: string;
    applicationId: string;
    appVersionId: string;
  }) {
    this.analytics.track('Update Presentation Success', p);
  }

  trackCreatePlaylistSuccess(p: { playlistId: string }) {
    this.analytics.track('Create Playlist Success', p);
  }

  trackUpdatePlaylistSuccess(p: {
    playlistId: string;
    didNameChange: boolean;
    nameBefore?: string;
    nameAfter?: string;
    addedPresentationIds: string[];
    addedApplicationIds: string[];
  }) {
    this.analytics.track('Update Playlist Success', p);
  }

  trackPublishPlaylistSuccess(p: { playlistId: string; deviceId: string }) {
    this.analytics.track('Publish Playlist Success', p);
  }

  trackCreateScheduledPlaylistSuccess(p: { playlistId: string }) {
    this.analytics.track('Create Scheduled Playlist Success', p);
  }

  trackUpdateScheduledPlaylistSuccess(p: {
    playlistId: string;
    didNameChange: boolean;
    nameBefore?: string;
    nameAfter?: string;
    addedPresentationIds: string[];
    addedApplicationIds: string[];
  }) {
    this.analytics.track('Update Scheduled Playlist Success', p);
  }

  trackDeleteScheduledPlaylistSuccess(p: { playlistId: string }) {
    this.analytics.track('Delete Scheduled Playlist Success', p);
  }

  trackSelectPlaylist(p: {
    playlistId: string;
    applicationId?: string;
    presentationId?: string;
    deviceId?: string;
  }) {
    this.analytics.track('Select Playlist Success', p);
  }
}
