import { Presentation, ApiClientError } from '@raydiant/api-client-js';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import raydiant from '../clients/raydiant';
import * as presentationActions from '../actions/presentations';
import { keys } from '../queryClient';
import useProtectedMutation from './useProtectedMutation';

export default function useCopyPlaylist() {
  const dispatchRedux = useDispatch();
  const queryClient = useQueryClient();

  const mutation = useProtectedMutation<
    Presentation,
    ApiClientError,
    { presentationId: string; params: { name: string } }
  >(
    ({ presentationId, params }) =>
      raydiant.copyPresentation(presentationId, params),
    {
      onSuccess: (presentation) => {
        // Update react-query cache.
        queryClient.setQueryData(
          keys.presentation(presentation.id),
          presentation,
        );

        // Update redux cache for /screens and /library.
        dispatchRedux(
          presentationActions.copyPresentationAsync.success(presentation),
        );
      },
    },
  );

  return mutation;
}
