import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    rule: {
      border: `1px solid ${theme.input.border}`,
      borderRadius: theme.borderRadius.sm,
      padding: theme.spacing(1.5, 2),
    },

    addRuleButtonContainer: {
      height: 40, // To align with select input height
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    addRuleButton: {
      height: 20,
      width: 20,
      backgroundColor: theme.palette.progress.main,
      color: theme.palette.progress.contrastText,
      borderRadius: 100,
      boxShadow: theme.shadows[1],

      '&:hover': {
        backgroundColor: theme.palette.progress.main,
        boxShadow: theme.shadows[2],
      },

      '&:disabled, &:disabled:hover': {
        backgroundColor: theme.button.backgroundDisabled,
        color: theme.button.foregroundDisabled,
        cursor: 'not-allowed',
      },
    },

    token: {
      cursor: 'pointer',
      letterSpacing: `0.25px`,
      lineHeight: `1em`,

      '&:hover': {
        color: '#000',
      },

      '& > span': {
        padding: theme.spacing(0.25, 0.15),
      },
    },

    tokenSelected: {
      color: '#fff',
      backgroundColor: '#000',

      '&:hover': {
        color: '#fff',
      },
    },

    tokenError: {
      '& $errorTarget': {
        backgroundColor: `rgba(237, 222, 79, 0.6)`,
        color: theme.palette.text.primary,
      },
    },

    errorTarget: {
      display: 'inline-block',
    },

    symbol: {
      position: 'relative',
      display: 'inline-block',
      width: 16,
      height: '1em',
      verticalAlign: 'text-top',

      '& svg': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 16,
        height: 18,
        padding: 1,
      },
    },

    helperWarning: {
      backgroundColor: `rgba(237, 222, 79, 0.6)`,
    },
  }),
);
