import { Theme } from 'raydiant-elements/theme';
import { makeStyles, createStyles } from 'raydiant-elements/styles';

export default makeStyles((_: Theme) => {
  return createStyles({
    root: {
      position: 'fixed',
      pointerEvents: 'none',
      zIndex: 9999, // Should always be on top
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
  });
});
