import React, { FC, ReactNode } from 'react';
import InputLabel from 'raydiant-elements/core/InputLabel';
import useStyles from './RuleTokenInput.styles';
import { RuleToken, RuleError } from './ruleTokens';
import RuleTokenButton from './RuleTokenButton';

export type SelectedRulePath = Array<string | number>;

export interface RuleTokenSelectProps {
  tokens: RuleToken[];
  errors: RuleError[];
  selectedIndex: number;
  onSelect: (index: number) => void;
}

const RuleTokenSelect: FC<RuleTokenSelectProps> = ({
  tokens,
  errors,
  selectedIndex,
  onSelect,
}) => {
  const classes = useStyles();

  const lines: ReactNode[][] = [];
  let currentLine: ReactNode[] = [];
  lines.push(currentLine);

  tokens.forEach((token, index) => {
    // Render 'And' and 'Or' on their own line.
    const isBlockToken = ['and', 'or'].includes(token.type);

    if (isBlockToken && currentLine.length > 0) {
      currentLine = [];
      lines.push(currentLine);
    }

    const ruleError = errors.find((e) => e.index === index);

    currentLine.push(
      <RuleTokenButton
        key={index}
        token={token}
        error={ruleError?.error}
        selected={selectedIndex === index}
        onClick={() => onSelect(index)}
      />,
    );

    if (isBlockToken) {
      currentLine = [];
      lines.push(currentLine);
    }
  });

  return (
    <>
      <InputLabel>Rule Formula</InputLabel>
      <div className={classes.rule}>
        {lines.map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </div>
    </>
  );
};

export default RuleTokenSelect;
