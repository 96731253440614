import React from 'react';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { FontFamily } from './fontFamilies';

export interface FontFamilyPreviewProps {
  fontFamily: FontFamily;
}

const FontFamilyPreview = ({ fontFamily }: FontFamilyPreviewProps) => {
  const classes = useStyles();
  return (
    <div
      className={classes.fontFamilyLabel}
      style={{ fontFamily: fontFamily.id }}
    >
      {fontFamily.name}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    fontFamilyLabel: {
      flex: 1,
      marginRight: theme.spacing(1),
      fontSize: theme.fontSizes.xl,
    },
  });
});

export default FontFamilyPreview;
