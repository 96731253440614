import orderBy from 'lodash/orderBy';
import { Theme } from '@raydiant/api-client-js';

export interface SortThemeOptions {
  property: 'name' | 'updatedAt';
  direction: 'asc' | 'desc';
}

export const sortThemes = (
  themes: Theme[],
  { property, direction }: SortThemeOptions,
) => {
  if (property === 'name') {
    return orderBy(themes, (p) => p.name.toLowerCase(), [direction]);
  }

  if (property === 'updatedAt') {
    return orderBy(themes, (t) => t.resource.updatedAt, [direction]);
  }

  return themes;
};
