// Applications

export const applicationVersions = (applicationId: string) => [
  'applications',
  applicationId,
  'versions',
];

// Playlists

export const playlists = () => 'playlists';
export const playlist = (playlistId: string) => ['playlists', playlistId];
export const playlistsById = (playlistsById: Record<string, true>) => [
  `playlists`,
  playlistsById,
];

// Presentations

export const presentation = (presentationId: string) => [
  'presentations',
  presentationId,
];

export const presentationsById = (presentationsById: Record<string, true>) => [
  `presentations`,
  presentationsById,
];

// Themes

export const themes = () => 'themes';

// Profile

export const profile = () => 'profile';

// Devices

export const device = (deviceId: string) => ['devices', deviceId];

export const affectedDevices = (resources: {
  presentationIds: string[];
  playlistIds: string[];
  folderIds: string[];
}) => [resources];
