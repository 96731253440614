import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { textTruncate, buttonReset } from 'raydiant-elements/mixins';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: theme.spacing(20),

      [theme.breakpoints.down('xs')]: {
        width: theme.spacing(19),
      },
    },

    name: {
      ...textTruncate(),
      fontSize: theme.fontSizes.sm,
      letterSpacing: 0.08,
    },

    thumbnail: {
      position: 'relative',
      paddingTop: `${100 / (16 / 9)}%`, // Preserve a 16:9 aspect ratio
      marginBottom: theme.spacing(0.25),
    },

    overlay: {
      ...buttonReset(),
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },

    center: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },

    button: {
      color: '#fff',

      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.15)',
      },
    },

    buttonBorder: {
      border: '1px solid #fff',
      borderRadius: 100,
    },

    buttonPrimary: {
      width: 64,
      height: 64,
      fontSize: 30,
    },

    buttonSecondary: {
      width: 22,
      height: 22,
      padding: 0,
      fontSize: 12,
    },

    checkboxPrimary: {
      width: 64,
      height: 64,
      fontSize: 30,
    },

    topLeft: {
      position: 'absolute',
      top: theme.spacing(1),
      left: theme.spacing(1),
    },

    lock: {
      position: 'absolute',
      bottom: theme.spacing(0.5),
      left: theme.spacing(0.5),
      fontSize: 16,
      color: '#ffffff',
      backgroundColor: theme.modal.overlayBackground,
      padding: theme.spacing(0.5),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 100,
    },

    errorIcon: {
      boxShadow: theme.shadows[3],
    },

    moreActions: {
      position: 'absolute',
      top: theme.spacing(0.25),
      right: theme.spacing(0.5),
    },

    moreActionsIcon: {
      filter: `drop-shadow(0px 1px 1px rgba(0,0,0,0.45))`,
    },

    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 14,
      width: 14,
      flexShrink: 0,
    },

    deleteConfirmation: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      padding: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  });
});
