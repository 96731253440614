import { useMemo } from 'react';
import useApplicationVersions from './useApplicationVersions';

export default function useApplicationVersion(
  applicationId: string | undefined,
  appVersionId: string | undefined,
) {
  const query = useApplicationVersions(applicationId);

  return {
    ...query,
    data: useMemo(() => {
      if (!query.data) return;
      return query.data.find((av) => av.id === appVersionId);
    }, [query.data, appVersionId]),
  };
}
