import Scrollable from 'raydiant-elements/layout/Scrollable';
import React, { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Folder,
  VirtualFolder,
  Presentation,
  Playlist,
  ResourceProfile,
} from '@raydiant/api-client-js';
import {
  hasDroppableNodeIds,
  createNodeId,
  FolderItem,
  canMoveFolderItems,
  canEditResource,
} from '../../utilities';
import {
  selectIsEnterpriseUser,
  selectUserProfile,
  selectIsNestedPlaylistsEnabled,
} from '../../selectors/user';
import { selectLocalUploads } from '../../selectors/fileUploads';
import { selectFileStatus } from '../../selectors/v2/presentations';
import PresentationCard from '../../components/PresentationCard';
import FolderCard from '../../components/FolderCard';
import PlaylistCard from '../../components/PlaylistCard';
import Grid from '../../components/Grid';
import GridItem from '../../components/Grid/GridItem';
import GridItemDraggable from '../../components/Grid/GridItemDraggable';

interface AddContentThumbnailViewProps {
  selectedNodeIds: string[];
  folder: Folder | VirtualFolder;
  folderItems: FolderItem<Presentation, Playlist, Folder>[];
  selectedProfile: ResourceProfile;
  onMove: (nodeIds: string[], folderId: string | null) => void;
  onOpenFolder: (folderId: string) => void;
  onOpenPresentation: (presentationId: string) => void;
  onOpenPlaylist: (playlistId: string) => void;
  onSelectNodeIds: (nodeIds: string[]) => void;
  onToggleNodeId: (nodeId: string) => void;
}

const AddContentThumbnailView: FC<AddContentThumbnailViewProps> = ({
  selectedNodeIds,
  folder,
  folderItems,
  selectedProfile,
  onMove,
  onOpenFolder,
  onOpenPlaylist,
  onOpenPresentation,
  onSelectNodeIds,
  onToggleNodeId,
}) => {
  // Selectors

  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);
  const isNestedPlaylistsEnabled = useSelector(selectIsNestedPlaylistsEnabled);
  const localUploads = useSelector(selectLocalUploads);
  const currentUser = useSelector(selectUserProfile);

  const isMoveable =
    currentUser && canMoveFolderItems(currentUser, selectedProfile);

  // State

  const [intersectedNodeIds, setIntersectedNodeIds] = useState<string[]>([]);

  // Callbacks

  const getDragStack = useCallback(
    (draggingNodeId: string) => {
      return [
        draggingNodeId,
        ...selectedNodeIds.filter((nodeId) => nodeId !== draggingNodeId),
      ];
    },
    [selectedNodeIds],
  );

  const onDrop = useCallback(
    (nodeIds: string[]) => {
      onMove(nodeIds, folder && 'id' in folder ? folder.id : null);
    },
    [onMove, folder],
  );

  const onCanDrop = useCallback(
    (nodeIds: string[]) => {
      if (!isMoveable) return false;
      if (!folder) return false;
      return hasDroppableNodeIds(nodeIds, folder, isEnterpriseUser);
    },
    [folder, isEnterpriseUser, isMoveable],
  );

  const isNodeSelected = useCallback(
    (nodeId: string) => {
      return (
        selectedNodeIds.includes(nodeId) || intersectedNodeIds.includes(nodeId)
      );
    },
    [selectedNodeIds, intersectedNodeIds],
  );

  // Render

  return (
    <Scrollable>
      {(containerRef) => (
        <Grid
          selectable
          containerRef={containerRef}
          selected={selectedNodeIds}
          getDragStack={getDragStack}
          onDrop={onDrop}
          onCanDrop={onCanDrop}
          onIntersect={setIntersectedNodeIds}
          onSelect={onSelectNodeIds}
        >
          {folderItems.map(({ presentation, playlist, folder: subFolder }) => {
            if (presentation) {
              const nodeId = createNodeId('presentation', presentation.id);
              const isEditable =
                currentUser &&
                canEditResource(currentUser, presentation.resource);

              return (
                <Scrollable.VisibilitySensor key={nodeId}>
                  {({ visibilityRef, isVisible }) => {
                    const lazyLoadedPresentation = isVisible
                      ? presentation
                      : {
                          ...presentation,
                          thumbnailUrl: '',
                          hasDynamicThumbnails: false,
                        };

                    const { isUploading, hasError } = selectFileStatus(
                      presentation,
                      localUploads,
                    );

                    const presentationEl = (
                      <PresentationCard
                        mode="select"
                        presentation={lazyLoadedPresentation}
                        folder={folder}
                        isSelected={isNodeSelected(nodeId)}
                        isLoading={isUploading}
                        hasError={hasError}
                        isLocked={!isEditable}
                        onSelect={() => onToggleNodeId(nodeId)}
                        onEdit={() => onOpenPresentation(presentation.id)}
                      />
                    );

                    if (!isMoveable) {
                      return (
                        <GridItem ref={visibilityRef}>
                          {presentationEl}
                        </GridItem>
                      );
                    }

                    return (
                      <GridItemDraggable ref={visibilityRef} nodeId={nodeId}>
                        {(dragRef) =>
                          React.cloneElement(presentationEl, { ref: dragRef })
                        }
                      </GridItemDraggable>
                    );
                  }}
                </Scrollable.VisibilitySensor>
              );
            }

            if (playlist && isNestedPlaylistsEnabled) {
              const nodeId = createNodeId('playlist', playlist.id);
              const isEditable =
                currentUser && canEditResource(currentUser, playlist.resource);

              const playlistEl = (
                <PlaylistCard
                  mode="select"
                  playlist={playlist}
                  folder={folder}
                  isSelected={isNodeSelected(nodeId)}
                  isLocked={!isEditable}
                  onSelect={() => onToggleNodeId(nodeId)}
                  onEdit={() => onOpenPlaylist(playlist.id)}
                />
              );

              if (!isMoveable) {
                return <GridItem key={nodeId}>{playlistEl}</GridItem>;
              }

              return (
                <GridItemDraggable key={nodeId} nodeId={nodeId}>
                  {(dragRef) =>
                    React.cloneElement(playlistEl, { ref: dragRef })
                  }
                </GridItemDraggable>
              );
            }

            if (subFolder) {
              const nodeId = createNodeId('folder', subFolder.id);

              const folderEl = (
                <FolderCard
                  mode="edit"
                  folder={subFolder}
                  isSelected={isNodeSelected(nodeId)}
                  onSelect={() => onToggleNodeId(nodeId)}
                  onClick={() => onOpenFolder(subFolder.id)}
                />
              );

              if (!isMoveable) {
                return <GridItem key={nodeId}>{folderEl}</GridItem>;
              }

              return (
                <GridItemDraggable
                  key={nodeId}
                  nodeId={nodeId}
                  onDrop={(nodeIds) => onMove(nodeIds, subFolder.id)}
                  onCanDrop={(nodeIds) =>
                    hasDroppableNodeIds(nodeIds, subFolder, isEnterpriseUser)
                  }
                >
                  {(dragRef) => React.cloneElement(folderEl, { ref: dragRef })}
                </GridItemDraggable>
              );
            }

            return null;
          })}
        </Grid>
      )}
    </Scrollable>
  );
};

export default AddContentThumbnailView;
