import { ActionType, getType } from 'typesafe-actions';
import * as pairingCodeActions from '../actions/pairingCodes';
import { mergeItemInCollection } from './shared/collections';
import {
  RequestState,
  setRequestItem,
  setRequestItemFailure,
  setRequestItemSuccess,
} from './shared/requests';

export type PairingCodeActions = ActionType<typeof pairingCodeActions>;

export interface PairingCodeItem {
  id: string;
  name?: string;
  pairingCode?: string;
}

export type PairingCodesState = Readonly<{
  items: PairingCodeItem[];
  itemsFetchStatus: RequestState[];
  itemsPairingCodeStatus: RequestState[];
}>;

const initialPairingCodesState: PairingCodesState = {
  items: [],
  itemsFetchStatus: [],
  itemsPairingCodeStatus: [],
};

export default function pairingCodeReducer(
  state = initialPairingCodesState,
  action: PairingCodeActions,
): PairingCodesState {
  switch (action.type) {
    // Generate Pairing Code
    case getType(pairingCodeActions.generatePairingCodeAsync.request):
      return {
        ...state,
        itemsPairingCodeStatus: setRequestItem(
          state.itemsPairingCodeStatus,
          action.payload,
        ),
      };

    case getType(pairingCodeActions.generatePairingCodeAsync.success):
      return {
        ...state,
        itemsPairingCodeStatus: setRequestItemSuccess(
          state.itemsPairingCodeStatus,
          action.payload.id,
        ),
        items: mergeItemInCollection(state.items, action.payload),
      };

    case getType(pairingCodeActions.generatePairingCodeAsync.failure):
      return {
        ...state,
        itemsPairingCodeStatus: setRequestItemFailure(
          state.itemsPairingCodeStatus,
          action.payload.id,
          action.payload.error.message,
        ),
      };

    default:
      return state;
  }
}
