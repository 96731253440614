import { createSelector } from 'reselect';
import { RootState } from '../../reducers';
import { selectApplications } from '../../selectors/applications';
import { selectUserProfile } from '../../selectors/user';
import { selectAllQueryParams } from '../../selectors/routing';
import { selectThemesById } from '../../selectors/v2/themes';
import { sortByResource } from '../../utilities';

export default createSelector(
  [
    selectApplications,
    selectUserProfile,
    selectThemesById,
    selectAllQueryParams([
      'backTo',
      'selectionId',
      'folderId',
      'createPlaylistTo',
    ]),
    (state: RootState) => state.applicationsPage.isLoading,
  ],
  (
    applications,
    currentUser,
    themesById,
    [backTo, selectionId, folderId, createPlaylistTo],
    isLoading,
  ) => ({
    applications: applications.sort(sortByResource('updatedAt', -1)),
    currentUser,
    themesById,
    backTo,
    selectionId,
    folderId,
    isLoading,
    createPlaylistTo,
  }),
);
