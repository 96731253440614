import { persistStore, Persistor } from 'redux-persist';
import rootSaga from '../sagas';
import configureStore from './configureStore';
import { StoreExtended } from './configureStore';

export default function createStore(): Promise<{
  store: StoreExtended;
  persistor: Persistor;
}> {
  return new Promise((resolve) => {
    const store = configureStore();

    store.run(rootSaga);

    const persistor = persistStore(store, undefined, () => {
      resolve({ store, persistor });
    });
  });
}
