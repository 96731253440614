import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as domainActions from '../../actions/domains';
import * as DM from '../../clients/mira/types/Domain';

export type DomainActions = ActionType<typeof domainActions>;

export type DomainsState = Readonly<{
  byId: {
    [domainId: string]: DM.Domain;
  };
}>;

const initialDomainsState: DomainsState = {
  byId: {},
};

export default function domainsReducer(
  state = initialDomainsState,
  action: DomainActions,
): DomainsState {
  switch (action.type) {
    case getType(domainActions.fetchDomainAsync.success): {
      const domain = action.payload;
      return produce(state, (draftState) => {
        draftState.byId[domain.id] = domain;
      });
    }

    case getType(domainActions.updateDomainAsync.success): {
      const domain = action.payload;
      return produce(state, (draftState) => {
        draftState.byId[domain.id] = domain;
      });
    }

    case getType(domainActions.setProfileDomainRoleAsync.request): {
      const { profileId, domainId, domainRole } = action.payload;
      return produce(state, (draftState) => {
        const domain = draftState.byId[domainId];
        if (!domain) return;

        const profile = domain.r.profiles.find((p) => p.id === profileId);
        if (!profile) return;

        profile.domainRole = domainRole;
      });
    }

    case getType(domainActions.setProfileDomainRoleAsync.success): {
      const updatedProfile = action.payload;
      return produce(state, (draftState) => {
        const domain = draftState.byId[updatedProfile.domainId];
        if (!domain) return;

        const profile = domain.r.profiles.find(
          (p) => p.id === updatedProfile.id,
        );
        if (!profile) return;

        profile.domainRole = updatedProfile.domainRole;
      });
    }

    default: {
      return state;
    }
  }
}
