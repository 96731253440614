import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import reducer from './reducer';

import ShareResource from './ShareResource';
export default ShareResource;

const shareResourceReducer = persistReducer(
  {
    key: 'ShareResource',
    whitelist: ['sortOptions'],
    storage,
  },
  reducer,
);

export { shareResourceReducer };
