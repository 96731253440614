export const tagData = {
  country: { label: 'Country' },
  end_date: { label: 'End Date' },
  end_time: { label: 'End Time' },
  days_of_week: { label: 'Day(s) of the week' },
  state: { label: 'State' },
  province: { label: 'Province' },
  territory: { label: 'Territory' },
  city: { label: 'City' },
  region: { label: 'Region' },
  area: { label: 'Area' },
  campus: { label: 'Campus' },
  building: { label: 'Building' },
  floor: { label: 'Floor' },
  room: { label: 'Room' },
  franchise: { label: 'Franchise' },
  space: { label: 'Space' },
  sale: { label: 'Sale' },
  promotion: { label: 'Promotion' },
  product: { label: 'Product' },
  initiative: { label: 'Initiative' },
  vertical: { label: 'Vertical' },
  client: { label: 'Client' },
  sponsor: { label: 'Sponsor' },
  brand: { label: 'Brand' },
  audience: { label: 'Audience' },
  meeting: { label: 'Meeting' },
  project: { label: 'Project' },
  start_date: { label: 'Start Date' },
  start_time: { label: 'Start Time' },
} as const;

export type TagKey = keyof typeof tagData;

export type TagOption = {
  name: string;
  groups: TagOptionGroup[];
};

export type TagOptionGroup = {
  name: string;
  options: TagKey[];
};

export const tagOptionGroups: Array<TagOptionGroup> = [
  {
    name: 'Location',
    options: [
      'country',
      'state',
      'province',
      'territory',
      'city',
      'region',
      'area',
      'campus',
      'building',
      'floor',
      'room',
      'franchise',
      'space',
    ],
  },
  {
    name: 'Campaign',
    options: [
      'sale',
      'promotion',
      'product',
      'initiative',
      'vertical',
      'client',
      'sponsor',
      'brand',
      'audience',
      'meeting',
      'project',
    ],
  },
  {
    name: 'Schedule',
    options: [
      'start_time',
      'end_time',
      'start_date',
      'end_date',
      'days_of_week',
    ],
  },
];
