import { createAsyncAction, createStandardAction } from 'typesafe-actions';

export const loadApplicationPage = createStandardAction(
  'LOAD_APPLICATION_PAGE',
)();

export const loadApplicationPageAsync = createAsyncAction(
  'LOAD_APPLICATION_PAGE_REQUEST',
  'LOAD_APPLICATION_PAGE_SUCCESS',
  'LOAD_APPLICATION_PAGE_FAILURE',
)<void, void, Error>();
