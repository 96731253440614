import * as PL from '../../clients/mira/types/Playlist';
import * as P from '../../clients/mira/types/Presentation';

interface PresentationCounts {
  [presentationId: string]: number;
}

const getPresentationCounts = (
  items: PL.PlaylistItem[],
): PresentationCounts => {
  return items.reduce((counts, item) => {
    if (!item.presentationId) {
      return counts;
    }

    return {
      ...counts,
      [item.presentationId]: (counts[item.presentationId] || 0) + 1,
    };
  }, {} as PresentationCounts);
};

interface PlaylistCompareResults {
  didNameChange: boolean;
  nameBefore?: string;
  nameAfter?: string;
  addedPresentationIds: string[];
  addedApplicationIds: string[];
}

export default function comparePlaylists(
  playlist1: Partial<PL.Playlist>,
  playlist2: Partial<PL.Playlist>,
  presentationsById: { [key: string]: P.Presentation },
): PlaylistCompareResults {
  const addedPresentationIds: string[] = [];
  const addedApplicationIds: string[] = [];
  const didNameChange = !!playlist1.name && playlist1.name !== playlist2.name;

  if (playlist1.items && playlist2.items) {
    const presentationCounts = getPresentationCounts(playlist1.items);
    const oldPresentationCounts = getPresentationCounts(playlist2.items);

    Object.entries(presentationCounts).forEach(([presentationId, count]) => {
      if (
        !oldPresentationCounts[presentationId] ||
        oldPresentationCounts[presentationId] < count
      ) {
        addedPresentationIds.push(presentationId);
      }
    });

    addedPresentationIds.forEach((presentationId) => {
      const presentation = presentationsById[presentationId];
      if (presentation) {
        addedApplicationIds.push(presentation.applicationId);
      }
    });
  }

  return didNameChange
    ? {
        didNameChange,
        addedPresentationIds,
        addedApplicationIds,
        nameBefore: playlist2.name,
        nameAfter: playlist1.name,
      }
    : {
        didNameChange,
        addedPresentationIds,
        addedApplicationIds,
      };
}
