import unionBy from 'lodash/unionBy';
import { ActionType, getType } from 'typesafe-actions';
import * as themeActions from '../actions/themes';
import * as T from '../clients/mira/types/Theme';

export type ThemeActions = ActionType<typeof themeActions>;

export type ThemesState = Readonly<{
  items: T.Theme[];
  isFetching: boolean;
  error: string;
}>;

const initialThemesState: ThemesState = {
  items: [],
  isFetching: false,
  error: '',
};

export default function themesReducer(
  state = initialThemesState,
  action: ThemeActions,
): ThemesState {
  switch (action.type) {
    case getType(themeActions.getThemesAsync.request):
    case getType(themeActions.updateThemeAsync.request):
    case getType(themeActions.createThemeAsync.request):
      return { ...state, isFetching: true, error: '' };
    case getType(themeActions.getThemesAsync.success):
      return {
        ...state,
        items: action.payload,
        isFetching: false,
      };
    case getType(themeActions.updateThemeAsync.success):
    case getType(themeActions.createThemeAsync.success):
      return {
        ...state,
        items: unionBy([action.payload], state.items, 'id'),
        isFetching: false,
      };
    case getType(themeActions.getThemesAsync.failure):
    case getType(themeActions.updateThemeAsync.failure):
    case getType(themeActions.createThemeAsync.failure):
      return {
        ...state,
        isFetching: false,
        error: action.payload.message,
      };
    default:
      return state;
  }
}
