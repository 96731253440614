import { ActionType, getType } from 'typesafe-actions';
import * as chargebeeActions from '../actions/chargebee';

export type ChargebeeActions = ActionType<typeof chargebeeActions>;

export type ChargebeeState = Readonly<{
  isLoading: boolean;
  isOpen: boolean;
  error: string;
}>;

const initialChargebeeState: ChargebeeState = {
  isLoading: false,
  isOpen: false,
  error: '',
};

export default function chargebeeReducer(
  state = initialChargebeeState,
  action: ChargebeeActions,
): ChargebeeState {
  switch (action.type) {
    case getType(chargebeeActions.openChargebeePortalAsync.request):
      return {
        ...state,
        isLoading: true,
        error: '',
      };

    case getType(chargebeeActions.openChargebeePortalAsync.success):
      return {
        ...state,
        isLoading: false,
        isOpen: true,
      };

    case getType(chargebeeActions.openChargebeePortalAsync.failure):
      return {
        ...state,
        isLoading: false,
        isOpen: false,
        error: action.payload.message,
      };

    case getType(chargebeeActions.closedChargebeePortal):
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
}
