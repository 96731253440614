import { createStandardAction } from 'typesafe-actions';

import { SortProfileOptions } from '../../utilities';

export const setSortOptions = createStandardAction(
  '@SHARE_RESOURCE/SET_SORT_OPTIONS',
)<SortProfileOptions>();

export const setSearchQuery = createStandardAction(
  '@SHARE_RESOURCE/SET_SEARCH_QUERY',
)<string>();
