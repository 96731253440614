import React, { FC, MouseEventHandler, forwardRef } from 'react';
import cn from 'classnames';
import { Presentation } from '@raydiant/api-client-js';
import Row from 'raydiant-elements/layout/Row';
import AlertIcon from 'raydiant-elements/core/AlertIcon';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Spacer from 'raydiant-elements/layout/Spacer';
import usePresentationFileStatus from '../../hooks/usePresentationFileStatus';
import PresentationIcon from '../../components/PresentationIcon';
import PlaylistItemDropIndicator from './PlaylistItemDropIndicator';
import PlaylistItemPresentationActions from './PlaylistItemPresentationActions';
import { playlistItemIndentWidth } from './PlaylistPage.styles';
import usePlaylistPageContext from './usePlaylistPageContext';

interface PlaylistItemPresentationProps {
  presentation: Presentation;
  indent?: number;
  selected?: boolean;
  actionsOpen?: boolean;
  disabled?: boolean;
  dropPosition?: 'top' | 'bottom';
  onClick?: MouseEventHandler;
  onMoreClick?: MouseEventHandler;
}

const PlaylistItemPresentation: FC<PlaylistItemPresentationProps> = (
  {
    presentation,
    indent = 0,
    selected = false,
    actionsOpen = false,
    disabled = false,
    dropPosition,
    onClick,
    onMoreClick,
  },
  ref,
) => {
  const { classes } = usePlaylistPageContext();
  const status = usePresentationFileStatus(presentation);

  return (
    <div
      ref={ref}
      className={classes.playlistItemOuter}
      style={{ marginLeft: indent * playlistItemIndentWidth }}
    >
      {dropPosition && <PlaylistItemDropIndicator position={dropPosition} />}
      <div
        className={cn(
          classes.playlistItem,
          selected && classes.playlistItemSelected,
          disabled && classes.playlistItemDisabled,
          selected && classes.playlistItemPresentationSelected,
        )}
        onClick={onClick}
      >
        {status.hasError && (
          <AlertIcon className={classes.playlistItemErrorIcon} />
        )}

        <Row halfMargin className={classes.playlistItemInfo}>
          <div className={classes.playlistItemIcon}>
            {status.isUploading ? (
              <CircularProgress size={16} />
            ) : (
              <PresentationIcon presentation={presentation} />
            )}
          </div>
          <div className={classes.presentationName}>{presentation.name}</div>
        </Row>

        <Spacer />

        <Row className={classes.playlistItemActionsContainer} inline center>
          <PlaylistItemPresentationActions
            moreActionsOpen={actionsOpen}
            onMoreClick={onMoreClick}
          />
        </Row>
      </div>
    </div>
  );
};

export default forwardRef(PlaylistItemPresentation);
