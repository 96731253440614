import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';

export type LibraryTreeActions = ActionType<typeof actions>;

export type LibraryTreeState = Readonly<{
  isLoading: boolean;
  selectedNodeIds: string[];
  expandedNodeIds: { [key: string]: boolean };
  // Separate collapsed items from expanded in order to preserve
  // the transition when collapsing a tree item.
  collapsedNodeIds: { [key: string]: boolean };
}>;

const initialLibraryTreeState: LibraryTreeState = {
  isLoading: true,
  selectedNodeIds: [],
  expandedNodeIds: {},
  collapsedNodeIds: {},
};

export default function libraryTreeReducer(
  state = initialLibraryTreeState,
  action: LibraryTreeActions,
): LibraryTreeState {
  switch (action.type) {
    case getType(actions.setSelectedNodeIds): {
      return produce(state, (draftState) => {
        draftState.selectedNodeIds = action.payload;
      });
    }

    case getType(actions.clearSelectedNodeIds): {
      return produce(state, (draftState) => {
        draftState.selectedNodeIds = [];
      });
    }

    case getType(actions.expandNode): {
      return produce(state, (draftState) => {
        draftState.expandedNodeIds[action.payload] = true;
        delete draftState.collapsedNodeIds[action.payload];
      });
    }

    case getType(actions.collapseNode): {
      return produce(state, (draftState) => {
        delete draftState.expandedNodeIds[action.payload];
        draftState.collapsedNodeIds[action.payload] = true;
      });
    }

    default: {
      return state;
    }
  }
}
