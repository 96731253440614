import {
  createAction,
  createAsyncAction,
  createStandardAction,
} from 'typesafe-actions';
import * as U from '../clients/mira/types/User';

export const getProfile = createAction('@USER/GET_PROFILE');

export const getProfileAsync = createAsyncAction(
  '@USER/GET_PROFILE_REQUEST',
  '@USER/GET_PROFILE_SUCCESS',
  '@USER/GET_PROFILE_FAILURE',
)<void, U.Profile, Error>();

export const updateProfile =
  createStandardAction('@USER/UPDATE_USER')<U.Profile>();

export const updateProfileAsync = createAsyncAction(
  '@USER/UPDATE_PROFILE_REQUEST',
  '@USER/UPDATE_PROFILE_SUCCESS',
  '@USER/UPDATE_PROFILE_FAILURE',
)<U.Profile, U.Profile, Error>();

export const getRolesAndPreferences = createAction(
  '@USER/GET_ROLES_AND_PREFERENCES',
);

export const getRolesAndPreferencesAsync = createAsyncAction(
  '@USER/GET_ROLES_AND_PREFERENCES_REQUEST',
  '@USER/GET_ROLES_AND_PREFERENCES_SUCCESS',
  '@USER/GET_ROLES_AND_PREFERENCES_FAILURE',
)<void, { roles: string[]; preferences: { homeTab?: string } }, Error>();

export const masqueradeUser = createStandardAction(
  '@USER/MASQUERADE_USER',
)<string>();

export const masqueradeUserAsync = createAsyncAction(
  '@USER/MASQUERADE_USER_REQUEST',
  '@USER/MASQUERADE_USER_SUCCESS',
  '@USER/MASQUERADE_USER_FAILURE',
)<string, void, Error>();

export const remoteAssist = createStandardAction(
  '@USER/REMOTE_ASSIST',
)<string>();

export const remoteAssistAsync = createAsyncAction(
  '@USER/REMOTE_ASSIST_REQUEST',
  '@USER/REMOTE_ASSIST_SUCCESS',
  '@USER/REMOTE_ASSIST_FAILURE',
)<string, void, Error>();

export const logoutUser = createStandardAction('@USER/LOGOUT')<{
  backTo?: string;
}>();

export const setRemoteAssistance = createStandardAction(
  '@USER/SET_REMOTE_ASSISTANCE',
)<{ grantLevel: string | null; expiresAt: string | null }>();

export const setRemoteAssistanceAsync = createAsyncAction(
  '@USER/SET_REMOTE_ASSISTANCE_REQUEST',
  '@USER/SET_REMOTE_ASSISTANCE_SUCCESS',
  '@USER/SET_REMOTE_ASSISTANCE_FAILURE',
)<void, { grantLevel: string | null; expiresAt: string | null }, Error>();
