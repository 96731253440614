import { Action, Location } from 'history';
import { createStandardAction } from 'typesafe-actions';

export interface LocationChangedPayload {
  location: Location;
  action: Action;
}

export const locationChanged = createStandardAction(
  '@HISTORY/LOCATION_CHANGED',
)<LocationChangedPayload>();

export interface UpdateHistory {
  push?: string;
  replace?: string;
}

export type HistoryMeta = {
  history?: UpdateHistory;
};

export const update = createStandardAction('@HISTORY/UPDATE')<UpdateHistory>();
