import cn from 'classnames';
import Paper from 'raydiant-elements/core/Paper';
import PaperModal from 'raydiant-elements/core/PaperModal';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Center from 'raydiant-elements/layout/Center';
import Heading from 'raydiant-elements/core/Heading';
import Text from 'raydiant-elements/typography/Text';
import React, { FC, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Page from '../../components/Page';
import LibraryDragLayer from '../../components/LibraryDragLayer';
import { selectUserProfile } from '../../selectors/user';
import { selectDevicesById } from '../../selectors/v2/devices';
import { selectOtherDomainProfiles } from '../../selectors/v2/domains';
import * as deviceActions from '../../actions/devices';
import { createNewId } from '../../utilities/identifiers';
import * as paths from '../../routes/paths';
import * as D from '../../clients/mira/types/Device';
import * as actions from './actions';
import {
  selectDeviceResults,
  selectLastLoadedDate,
  selectSearchQuery,
} from './selectors';
import DeviceCard from './DeviceCard';
import useStyles from './DevicesPage.styles';
import DeviceSettings from './DeviceSettings';
import DevicePlaylistSelector from './DevicePlaylistSelector';
import DevicesActionBar from './DevicesActionBar';
import DeviceEmptyState from './DeviceEmptyState';

interface DevicesPageProps {}

const DevicesPage: FC<DevicesPageProps> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const settingsMatch = useRouteMatch<{ deviceId?: string }>({
    path: paths.screenSettings.pattern,
  });
  const playlistMatch = useRouteMatch<{ deviceId?: string }>({
    path: paths.screenPlaylist.pattern,
  });

  // Selectors

  const deviceResults = useSelector(selectDeviceResults);
  const lastLoadedDate = useSelector(selectLastLoadedDate);
  const currentUser = useSelector(selectUserProfile);
  const devicesById = useSelector(selectDevicesById);
  const otherDomainProfiles = useSelector(selectOtherDomainProfiles);
  const searchQuery = useSelector(selectSearchQuery);

  // State

  const [selectedProfileId, setSelectedProfileId] = useState('');

  // Callbacks

  const publishDevice = useCallback(
    (device: D.Device) => {
      dispatch(deviceActions.publishDevice(device.id));
    },
    [dispatch],
  );

  const closeModal = useCallback(() => {
    history.push(paths.screens());
  }, [history]);

  const editSettings = useCallback(
    (device: D.Device) => {
      history.push(paths.screenSettings(device.id));
    },
    [history],
  );

  const selectPlaylist = useCallback(
    (device: D.Device) => {
      history.push(paths.screenPlaylist(device.id));
    },
    [history],
  );

  const editPlaylist = useCallback(
    (device: D.Device) => {
      if (device.playlistId) {
        history.push(
          paths.editPlaylist(device.playlistId, {
            deviceId: device.id,
            backTo: paths.screens(),
            saveTo: paths.screens(),
            backToLabel: 'Back to Screens',
            previewMode:
              device.screenOrientation === 'normal' ? 'horizontal' : 'vertical',
          }),
        );
      } else {
        history.push(paths.screenPlaylist(device.id));
      }
    },

    [history],
  );

  const newPlaylist = useCallback(
    (device: D.Device) => {
      history.push(
        paths.newPlaylist({
          playlistId: createNewId(),
          deviceId: device.id,
          backTo: paths.screenPlaylist(device.id),
          saveTo: paths.screens(),
          backToLabel: 'Back to Screens',
          previewMode:
            device.screenOrientation === 'normal' ? 'horizontal' : 'vertical',
        }),
      );
    },
    [history],
  );

  const newPresentation = useCallback(
    (device: D.Device, applicationId: string) => {
      history.push(
        paths.newPresentation({
          applicationId,
          backTo: paths.screenPlaylist(device.id),
          saveTo: paths.screenPlaylist(device.id),
          backToLabel: 'Back to Screens',
          previewMode:
            device.screenOrientation === 'normal' ? 'horizontal' : 'vertical',
        }),
      );
    },
    [history],
  );

  // Side-effects

  // Fetch devices on page load and when window gains focus to keep
  // metrics updated.
  useEffect(() => {
    const loadDevicesPage = () => {
      if (document.hidden) return;
      dispatch(actions.loadDevicesPage());
    };

    loadDevicesPage();

    window.addEventListener('visibilitychange', loadDevicesPage, false);
    return () => {
      window.removeEventListener('visibilitychange', loadDevicesPage, false);
    };
  }, [dispatch]);

  // Update selected profile to current user if not set.
  useEffect(() => {
    if (selectedProfileId) return;
    if (!currentUser) return;
    setSelectedProfileId(currentUser.id);
  }, [currentUser, selectedProfileId, setSelectedProfileId]);

  // Fetch recent errors every 2 minutes.
  // Turn off recent device errors until we can investigate the performance
  // issues happening in TimescaleDB.
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     dispatch(deviceActions.fetchRecentDeviceErrors());
  //   }, 2 * 60 * 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [dispatch]);

  // Render

  if (!lastLoadedDate || !currentUser) {
    return (
      <Page title="Screens">
        <Center>
          <CircularProgress size={30} />
        </Center>
      </Page>
    );
  }

  const totalDevices = Object.values(deviceResults).reduce(
    (a, b) => a + b.devices.length,
    0,
  );
  const hasNoDevices = !totalDevices && !searchQuery;

  const headerEl = (
    <div className={classes.actions}>
      <Paper color="light" className={classes.section}>
        <DevicesActionBar disabled={hasNoDevices} />
      </Paper>
    </div>
  );

  if (hasNoDevices) {
    return (
      <Page title="Screens">
        {headerEl}
        <Scrollable>
          <DeviceEmptyState />
        </Scrollable>
      </Page>
    );
  }

  const myDeviceResults = deviceResults[currentUser.id];
  const settingsDeviceId = settingsMatch?.params?.deviceId ?? '';
  const playlistDeviceId = playlistMatch?.params?.deviceId ?? '';
  const selectedDeviceId = settingsDeviceId || playlistDeviceId;
  const selectedSettingsDevice = devicesById[settingsDeviceId];
  const selectedPlaylistDevice = devicesById[playlistDeviceId];
  const isModalOpen = !!selectedSettingsDevice || !!selectedPlaylistDevice;

  return (
    <Page title="Screens">
      {headerEl}
      <Scrollable>
        <div className={classes.deviceList}>
          {myDeviceResults &&
            myDeviceResults.devices.map((device) => (
              <DeviceCard
                key={device.id}
                device={device}
                currentUser={currentUser}
                lastLoadedDate={lastLoadedDate}
                selected={device.id === selectedDeviceId}
                selectedPlaylist={
                  device.id === selectedDeviceId && !!selectedPlaylistDevice
                }
                onClick={() => editPlaylist(device)}
                onSettingsClick={() => editSettings(device)}
                onEditPlaylistClick={() => editPlaylist(device)}
                onSelectPlaylistClick={() => selectPlaylist(device)}
                onPublishClick={() => publishDevice(device)}
              />
            ))}

          {otherDomainProfiles.map((profile) => {
            const group = deviceResults[profile.id];
            if (!group) return null;

            const devices = group.devices;

            return (
              <div key={profile.id}>
                <div className={classes.title}>
                  <Heading>{profile.name}</Heading>
                  <Text xsmall muted>
                    {devices.length === 1
                      ? '1 screen'
                      : `${devices.length} screens`}
                  </Text>
                </div>
                {devices.map((device) => (
                  <DeviceCard
                    key={device.id}
                    device={device}
                    currentUser={currentUser}
                    lastLoadedDate={lastLoadedDate}
                    selected={device.id === selectedDeviceId}
                    onClick={() => editPlaylist(device)}
                    onSettingsClick={() => editSettings(device)}
                    onEditPlaylistClick={() => editPlaylist(device)}
                    onSelectPlaylistClick={() => selectPlaylist(device)}
                    onPublishClick={() => publishDevice(device)}
                  />
                ))}
              </div>
            );
          })}
        </div>
      </Scrollable>

      <PaperModal
        color="lightGrey"
        className={cn(
          classes.modal,
          selectedPlaylistDevice && 'tour-select-playlist',
        )}
        open={isModalOpen}
        onClose={closeModal}
      >
        {selectedSettingsDevice && (
          <DeviceSettings
            key={selectedSettingsDevice.id} // Clear state when selecting another device.
            device={selectedSettingsDevice}
            lastLoadedDate={lastLoadedDate}
            onClose={closeModal}
          />
        )}
        {selectedPlaylistDevice && (
          <DevicePlaylistSelector
            device={selectedPlaylistDevice}
            selectedProfileId={selectedProfileId}
            onSelectProfile={setSelectedProfileId}
            onNewPlaylist={() => newPlaylist(selectedPlaylistDevice)}
            onNewPresentation={(applicationId) =>
              newPresentation(selectedPlaylistDevice, applicationId)
            }
            onClose={closeModal}
          />
        )}
      </PaperModal>

      <LibraryDragLayer />
    </Page>
  );
};

export default DevicesPage;
