import React, { FC, useState, useEffect, useRef } from 'react';
import Text from 'raydiant-elements/core/Text';
import Row from 'raydiant-elements/layout/Row';
import TagIcon from 'raydiant-elements/icons/Tag';
import useStyles from './TagInput.styles';

export interface TagInputTextTypeProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
}

const TagInputTextType: FC<TagInputTextTypeProps> = ({
  label,
  value: initialValue,
  onChange,
}) => {
  const classes = useStyles();

  // Refs

  const inputRef = useRef<HTMLInputElement | null>(null);

  // State

  const [value, setValue] = useState(initialValue);

  // Effects

  // Update state if initialValue changes.
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // Auto focus input on mount.
  useEffect(() => {
    // Ensure DOM has updated before focusing and selecting text.
    const timeout = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    });

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // Render

  return (
    <div className={classes.root}>
      <Row halfMargin center>
        <TagIcon className={classes.icon} />
        <div className={classes.tag}>
          <span>{label}:</span>
          &nbsp;
          <Text
            ref={inputRef}
            editable
            value={value}
            className={classes.tagValueInput}
            onChange={setValue}
            onBlur={() => onChange(value)}
          />
        </div>
      </Row>
    </div>
  );
};

export default TagInputTextType;
