import * as A from '../clients/mira/types/Application';
import * as P from '../clients/mira/types/Presentation';
import * as T from '../clients/mira/types/Theme';
import createDefaultResource from './createDefaultResource';

export default function createPresentationFromAppVersion(
  appVersion: A.ApplicationVersion,
  profileId: string,
  theme?: T.Theme,
) {
  // collect default application vars for presentation
  const defaultAppVars = appVersion.presentationProperties.reduce(
    (vars, prop) => ({
      ...vars,
      [prop.name]: prop.default,
    }),
    {},
  );

  const presentation: P.Presentation = {
    // id and userId will be set on save in the api.
    id: '',
    name: appVersion.name,
    applicationVariables: defaultAppVars,
    applicationId: appVersion.applicationId,
    appVersionId: appVersion.id,
    applicationName: appVersion.name,
    presentationProperties: appVersion.presentationProperties,
    thumbnailUrl: appVersion.thumbnailUrl,
    applicationThumbnailUrl: appVersion.thumbnailUrl,
    iconUrl: appVersion.iconUrl,
    hasDynamicThumbnails: appVersion.dynamicThumbnails,
    sourceUrl: appVersion.sourceUrl,
    fileUploads: null,
    resource: createDefaultResource({ profile: { id: profileId } }),
  };

  if (theme) {
    presentation.themeId = theme.id;
  }

  if (appVersion.configurableDuration) {
    presentation.duration = appVersion.defaultDuration;
  }

  return presentation;
}
