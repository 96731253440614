import { all, call, fork, take, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { getMasqueradeUser } from '../../utilities';
import {
  getProfileAsync,
  logoutUser,
  updateProfileAsync,
} from '../../actions/user';
import * as U from '../../clients/mira/types/User';

function identify(profile: U.Profile) {
  // Don't identify the user when masquerading.
  if (getMasqueradeUser()) return;
  window.analytics.identify(profile.id, profile);
}

function* identifyInitial() {
  // identify the user only once after page load or login
  const { payload: profile }: { payload: U.Profile } = yield take(
    getType(getProfileAsync.success),
  );
  yield call(identify, profile);
}

function* identifyOnUpdate() {
  // re-identify the user after they update their details
  yield takeEvery(
    getType(updateProfileAsync.success),
    function* ({
      payload: user,
    }: ReturnType<typeof updateProfileAsync.success>) {
      yield call(identify, user);
    },
  );
}

function* reidentifyAfterLogout() {
  yield takeEvery(getType(logoutUser), identifyInitial);
}

export default all([
  fork(identifyInitial),
  fork(identifyOnUpdate),
  fork(reidentifyAfterLogout),
]);
