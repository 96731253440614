import { batchActions } from 'redux-batched-actions';
import { all, call, fork, put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as soundZoneActions from '../actions/soundZones';
import * as D from '../clients/mira/types/Device';
import miraClient from '../clients/miraClient';
import logger from '../logger';
import { SoundZone } from '../types';
import { isResourceDeleted } from '../utilities';

type FetchSoundZoneAction = ReturnType<typeof soundZoneActions.fetchSoundZone>;

const fetchSoundZone = function* (deviceId: string) {
  try {
    yield put(soundZoneActions.fetchSoundZoneAsync.request(deviceId));

    const soundZone: D.SYBSoundZone = yield call(() =>
      miraClient.getSYBSoundZone(deviceId),
    );

    yield put(
      soundZoneActions.fetchSoundZoneAsync.success({
        ...soundZone,
        deviceId: deviceId,
      }),
    );
  } catch (error) {
    logger.error(error);
    yield put(
      soundZoneActions.fetchSoundZoneAsync.failure({
        id: deviceId,
        error,
      }),
    );
  }
};

export const fetchAllSoundZones = function* () {
  // TODO: Remove when API has an endpoint to retrieve all soundzones.
  let devices: D.Device[] = yield call(() => miraClient.getDevices());
  devices = devices.filter((d) => !isResourceDeleted(d));

  try {
    yield put(
      batchActions(
        devices.map((device) =>
          soundZoneActions.fetchSoundZoneAsync.request(device.id),
        ),
      ),
    );

    const soundZones: SoundZone[] = yield all(
      devices.map((device) =>
        call(async (): Promise<SoundZone> => {
          try {
            const soundZone = await miraClient.getSYBSoundZone(device.id);
            return { ...soundZone, deviceId: device.id };
          } catch (error) {
            // SYB sometimes throws an error when fetching the sound zone.
            // When this happens, this saga will fail to load all soundzones.
            // Log the error and return an empty soundzone.
            logger.error(error);
            return {
              id: '',
              name: '',
              deviceId: device.id,
            };
          }
        }),
      ),
    );

    yield put(
      batchActions(
        soundZones.map((soundZone) =>
          soundZoneActions.fetchSoundZoneAsync.success(soundZone),
        ),
      ),
    );

    return soundZones;
  } catch (error) {
    logger.error(error);
    yield put(
      batchActions(
        devices.map((device) =>
          soundZoneActions.fetchSoundZoneAsync.failure({
            id: device.id,
            error,
          }),
        ),
      ),
    );
  }
};

const watchFetchSoundZone = function* () {
  while (true) {
    const { payload }: FetchSoundZoneAction = yield take(
      getType(soundZoneActions.fetchSoundZone),
    );
    yield fork(fetchSoundZone, payload);
  }
};

const watchFetchAllSoundZones = function* () {
  while (true) {
    yield take(getType(soundZoneActions.fetchAllSoundZones));
    yield fork(fetchAllSoundZones);
  }
};

export default all([fork(watchFetchSoundZone), fork(watchFetchAllSoundZones)]);
