import { ActionType, getType } from 'typesafe-actions';
import * as devicesPageActions from './actions';
import { SortDeviceOptions, SortProfileOptions } from '../../utilities';

export type DevicesPageActions = ActionType<typeof devicesPageActions>;

export type DevicesPageState = Readonly<{
  lastLoadedDate: string;
  sortOptions: SortDeviceOptions;
  searchQuery: string;
  sharingSortOptions: SortProfileOptions;
  sharingSearchQuery: string;
  error: string;
  statusPopoverDeviceId: string | null;
}>;

const initialDevicesPageState: DevicesPageState = {
  lastLoadedDate: '',
  sortOptions: { property: 'name', direction: 'asc' },
  searchQuery: '',
  sharingSortOptions: { property: 'name', direction: 'asc' },
  sharingSearchQuery: '',
  error: '',
  statusPopoverDeviceId: null,
};

export default function devicesPageReducer(
  state = initialDevicesPageState,
  action: DevicesPageActions,
): DevicesPageState {
  switch (action.type) {
    case getType(devicesPageActions.loadDevicesPageAsync.request):
      return {
        ...state,
        error: '',
      };

    case getType(devicesPageActions.loadDevicesPageAsync.success):
      return {
        ...state,
        lastLoadedDate: new Date().toISOString(),
      };

    case getType(devicesPageActions.loadDevicesPageAsync.failure):
      return {
        ...state,
        error: action.payload.message,
      };

    case getType(devicesPageActions.setSortOptions):
      return {
        ...state,
        sortOptions: action.payload,
      };

    case getType(devicesPageActions.setSearchQuery):
      return {
        ...state,
        searchQuery: action.payload,
      };

    case getType(devicesPageActions.setSharingSortOptions):
      return {
        ...state,
        sharingSortOptions: action.payload,
      };

    case getType(devicesPageActions.setSharingSearchQuery):
      return {
        ...state,
        sharingSearchQuery: action.payload,
      };

    case getType(devicesPageActions.setOpenContentWarningOnLoad):
      return {
        ...state,
        statusPopoverDeviceId: action.payload,
      };

    case getType(devicesPageActions.clearOpenStatusPopoverOnLoad):
      return {
        ...state,
        statusPopoverDeviceId: null,
      };

    default:
      return state;
  }
}
