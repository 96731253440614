import { createSelector } from 'reselect';
import { RootState } from '../../reducers';
import { selectOtherDomainProfiles } from '../../selectors/v2/domains';
import { sortProfiles, searchProfiles } from '../../utilities';

export const selectSharingSortOptions = (state: RootState) =>
  state.shareResource.sortOptions;

export const selectSharingSearchQuery = (state: RootState) =>
  state.shareResource.searchQuery;

export const selectSharingProfileResults = createSelector(
  [
    selectOtherDomainProfiles,
    selectSharingSortOptions,
    selectSharingSearchQuery,
  ],
  (profiles, sortOptions, searchQuery) => {
    const searchedProfiles = searchProfiles(profiles, searchQuery);
    return sortProfiles(searchedProfiles, sortOptions);
  },
);
