import orderBy from 'lodash/orderBy';
import * as P from '../clients/mira/types/Presentation';
import * as PL from '../clients/mira/types/Playlist';
import * as F from '../clients/mira/types/Folder';

export interface SortFolderOptions {
  property: 'name' | 'type' | 'updatedAt' | 'publishedAt';
  direction: 'asc' | 'desc';
}

export interface FolderItem<T1, T2, T3> {
  presentation?: T1;
  playlist?: T2;
  folder?: T3;
}

const toPresentationFolderItem = <T extends P.Presentation>(
  presentation: T,
) => ({ presentation });

const toPlaylistFolderItem = <T extends PL.Playlist>(playlist: T) => ({
  playlist,
});

const toNestedFolderItem = <T extends F.Folder>(folder: T) => ({ folder });

const toFolderItems = <
  T1 extends P.Presentation,
  T2 extends PL.Playlist,
  T3 extends F.Folder,
>(
  presentations: T1[],
  playlists: T2[],
  folders: T3[],
): FolderItem<T1, T2, T3>[] => {
  const folderItems: FolderItem<T1, T2, T3>[] = [];

  if (folders.length) {
    folderItems.push(...folders.map(toNestedFolderItem));
  }

  if (presentations.length) {
    folderItems.push(...presentations.map(toPresentationFolderItem));
  }

  if (playlists.length) {
    folderItems.push(...playlists.map(toPlaylistFolderItem));
  }

  return folderItems;
};

const nameSorter = (value?: string | null) => {
  return (value || '').toLowerCase();
};

export const sortFolder = <
  T1 extends P.Presentation,
  T2 extends PL.Playlist,
  T3 extends F.Folder,
>(
  presentations: T1[],
  playlists: T2[],
  folders: T3[],
  { property, direction }: SortFolderOptions,
): FolderItem<T1, T2, T3>[] => {
  if (property === 'type') {
    if (direction === 'desc') {
      return [
        ...orderBy(
          toFolderItems([], [], folders),
          (item) => nameSorter(item.folder?.name),
          [direction],
        ),
        ...orderBy(
          toFolderItems([], playlists, []),
          (item) => nameSorter(item.playlist?.name),
          [direction],
        ),
        ...orderBy(
          toFolderItems(presentations, [], []),
          [
            (item) => nameSorter(item.presentation?.applicationName),
            (item) => nameSorter(item.presentation?.name),
          ],
          [direction, direction],
        ),
      ];
    }
    return [
      ...orderBy(
        toFolderItems([], [], folders),
        (item) => nameSorter(item.folder?.name),
        [direction],
      ),
      ...orderBy(
        toFolderItems(presentations, [], []),
        [
          (item) => nameSorter(item.presentation?.applicationName),
          (item) => nameSorter(item.presentation?.name),
        ],
        [direction],
      ),
      ...orderBy(
        toFolderItems([], playlists, []),
        (item) => nameSorter(item.playlist?.name),
        [direction],
      ),
    ];
  }

  if (property === 'updatedAt') {
    return [
      ...orderBy(
        toFolderItems([], [], folders),
        (item) => item.folder?.resource.updatedAt || '',
        [direction],
      ),
      ...orderBy(
        toFolderItems(presentations, [], []),
        (item) => item.presentation?.resource.updatedAt || '',
        [direction],
      ),
      ...orderBy(
        toFolderItems([], playlists, []),
        (item) => item.playlist?.resource.updatedAt || '',
        [direction],
      ),
    ];
  }

  // Default to sort by name
  if (direction === 'desc') {
    return [
      ...orderBy(
        toFolderItems([], playlists, []),
        (item) => nameSorter(item.playlist?.name),
        [direction],
      ),
      ...orderBy(
        toFolderItems(presentations, [], []),
        (item) => nameSorter(item.presentation?.name),
        [direction],
      ),
      ...orderBy(
        toFolderItems([], [], folders),
        (item) => nameSorter(item.folder?.name),
        [direction],
      ),
    ];
  }

  return [
    ...orderBy(
      toFolderItems([], [], folders),
      (item) => nameSorter(item.folder?.name),
      [direction],
    ),
    ...orderBy(
      toFolderItems(presentations, [], []),
      (item) => nameSorter(item.presentation?.name),
      [direction],
    ),
    ...orderBy(
      toFolderItems([], playlists, []),
      (item) => nameSorter(item.playlist?.name),
      [direction],
    ),
  ];
};
