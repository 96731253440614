import { Presentation, Theme } from '@raydiant/api-client-js';
import * as paths from '../../routes/paths';
import { Path } from '../../types';

interface GetPageURLOptions {
  presentation: Presentation | undefined;
  theme?: Theme | undefined | null;
  themeManager?: boolean;
  queryParams:
    | paths.NewPresentationQueryParams
    | paths.EditPresentationQueryParams;
  playlistIdPath?: Path;
}

export default function getPageUrl({
  presentation,
  theme,
  themeManager,
  queryParams,
  playlistIdPath,
}: GetPageURLOptions) {
  if (!presentation) return '';

  let pageUrl = '';

  if (presentation.id) {
    // Explicitly test for undefined to allow passing a null theme
    // for creating the page url for a new theme.
    if (theme !== undefined) {
      if (theme?.id) {
        pageUrl = paths.editPresentationEditTheme(
          presentation.id,
          theme.id,
          queryParams,
        );
      } else {
        pageUrl = paths.editPresentationNewTheme(presentation.id, queryParams);
      }
    } else if (themeManager) {
      pageUrl = paths.editPresentationManageThemes(
        presentation.id,
        queryParams,
      );
    } else {
      pageUrl = paths.editPresentation(presentation.id, queryParams);
    }
  } else if (presentation.applicationId) {
    // Explicitly test for undefined to allow passing a null theme
    // for creating the page url for a new theme.
    if (theme !== undefined) {
      if (theme?.id) {
        pageUrl = paths.newPresentationEditTheme(theme.id, {
          applicationId: presentation.applicationId,
          ...queryParams,
        });
      } else {
        pageUrl = paths.newPresentationNewTheme({
          applicationId: presentation.applicationId,
          ...queryParams,
        });
      }
    } else if (themeManager) {
      pageUrl = paths.newPresentationManageThemes({
        applicationId: presentation.applicationId,
        ...queryParams,
      });
    } else {
      pageUrl = paths.newPresentation({
        applicationId: presentation.applicationId,
        ...queryParams,
      });
    }
  }

  if (playlistIdPath) {
    // {{playlistId}} is replaced by the actual playlistId by PlaylistPage
    // to set the correct application variable of the presentation.
    // TODO: Checking hash params happens in PresentationForm but should probably exist here.
    pageUrl = `${pageUrl}#${playlistIdPath.join('.')}={{playlistId}}`;
  }

  return pageUrl;
}
