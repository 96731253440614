import { batchActions } from 'redux-batched-actions';
import { all, call, fork, put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as appActions from '../../actions/applications';
import * as folderActions from '../../actions/folders';
import * as domainActions from '../../actions/domains';
import * as A from '../../clients/mira/types/Application';
import * as F from '../../clients/mira/types/Folder';
import * as DM from '../../clients/mira/types/Domain';
import miraClient from '../../clients/miraClient';
import logger from '../../logger';
import * as actions from './actions';

const watchLoadAddContentPage = function* () {
  while (true) {
    yield take(getType(actions.loadAddContentPage));

    try {
      yield put(actions.loadAddContentPageAsync.request());

      const [libraryFolder, applications, domain]: [
        F.VirtualFolder,
        A.Application[],
        DM.Domain | null,
      ] = yield all([
        call(() => miraClient.getLibraryFolder()),
        call(() => miraClient.getApplications()),
        call(() => miraClient.getDomain()),
      ]);

      yield put(
        batchActions([
          folderActions.fetchVirtualFolderAsync.success({
            id: 'library',
            ...libraryFolder,
          }),
          appActions.fetchApplicationsAsync.success(applications),
          actions.loadAddContentPageAsync.success(),
          ...(domain ? [domainActions.fetchDomainAsync.success(domain)] : []),
        ]),
      );
    } catch (error) {
      logger.error(error);
      yield put(actions.loadAddContentPageAsync.failure(error));
    }
  }
};

export default all([fork(watchLoadAddContentPage)]);
