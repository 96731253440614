import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import ThemeSelector from 'raydiant-elements/core/ThemeSelector';
import LoadingButton from '../../components/LoadingButton';
import { Playlist } from '@raydiant/api-client-js';
import * as paths from '../../routes/paths';
import usePlaylistPageContext from './usePlaylistPageContext';

interface PlaylistFooterProps {
  playlist?: Playlist;
  onCancel: () => void;
}

const PlaylistFooter: FC<PlaylistFooterProps> = ({ playlist, onCancel }) => {
  const history = useHistory();
  const { classes, submit, submitStatus, isDirty, hasError, queryParams } =
    usePlaylistPageContext();

  const handleSubmit = useCallback(async () => {
    if (!playlist) return;

    if (isDirty) {
      const result = await submit(queryParams.deviceId);
      if (result) {
        const savedRootPlaylist = result[playlist.id];
        // If the root playlist id changed after saving that means we created a playlist,
        // redirect to the edit playlist with the new id.
        if (savedRootPlaylist && savedRootPlaylist.id !== playlist.id) {
          history.replace(
            paths.editPlaylist(savedRootPlaylist.id, queryParams),
          );
        }
      }
    } else {
      // Replace {{playlistId}} in the saveTo redirect URL with the actual playlist id.
      // This is used when creating a new playlist to be assigned the MZ or Kiosk.
      history.push(queryParams.saveTo.replace('{{playlistId}}', playlist.id));
    }
  }, [submit, queryParams, history, playlist, isDirty]);

  return (
    <div className={classes.footer}>
      <ThemeSelector color="light">
        <ActionBar variant="floating" size="large">
          <ActionBar.Action
            color="primaryText"
            icon={<CloseIcon />}
            label="Cancel"
            onClick={onCancel}
            fullWidth
          />

          <LoadingButton
            color={isDirty ? 'progress' : 'default'}
            label={isDirty ? 'Save' : 'Done'}
            icon={isDirty ? null : <ArrowBack />}
            disabled={isDirty && hasError}
            iconAlignment="start"
            fullWidth
            status={submitStatus}
            successLabel="Saved"
            loadingLabel="Saving..."
            errorLabel="Failed!"
            delay={1000}
            onClick={handleSubmit}
          />
        </ActionBar>
      </ThemeSelector>
    </div>
  );
};

export default PlaylistFooter;
