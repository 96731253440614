import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as playlistActions from '../../actions/playlists';
import * as folderActions from '../../actions/folders';
import * as PL from '../../clients/mira/types/Playlist';

export type PlaylistActions = ActionType<typeof playlistActions>;
export type FolderActions = ActionType<typeof folderActions>;

export type PlaylistsState = Readonly<{
  byId: {
    [playlistId: string]: PL.Playlist;
  };
}>;

const initialPlaylistsState: PlaylistsState = {
  byId: {},
};

// Certain API endpoint doesn't return items in nested playlist. This prevents
// overriding an already fetched playlist's items with an empty array.
const setPlaylistIgnoreItems = (
  state: PlaylistsState,
  playlist: PL.Playlist,
) => {
  const currNestedPlaylist = state.byId[playlist.id];
  if (currNestedPlaylist) {
    state.byId[playlist.id] = {
      ...playlist,
      items: currNestedPlaylist.items,
    };
  } else {
    state.byId[playlist.id] = playlist;
  }
};

const setNestedPlaylists = (state: PlaylistsState, playlist: PL.Playlist) => {
  playlist.items.forEach(({ playlist }) => {
    if (!playlist) return;
    setPlaylistIgnoreItems(state, playlist);
  });
};

export default function playlistsReducer(
  state = initialPlaylistsState,
  action: PlaylistActions | FolderActions,
): PlaylistsState {
  switch (action.type) {
    // fetchPlaylistsAsync
    case getType(playlistActions.fetchPlaylistsAsync.success): {
      const playlists = action.payload;
      return produce(state, (draftState) => {
        playlists.forEach((playlist) => {
          draftState.byId[playlist.id] = playlist;
          setNestedPlaylists(draftState, playlist);
        });
      });
    }

    // createPlaylistAsync
    case getType(playlistActions.createPlaylistAsync.success): {
      return produce(state, (draftState) => {
        draftState.byId[action.payload.id] = action.payload;
      });
    }

    // updatePlaylistAsync
    case getType(playlistActions.updatePlaylistAsync.success): {
      return produce(state, (draftState) => {
        draftState.byId[action.payload.id] = action.payload;
      });
    }

    // deletePlaylistAsync
    case getType(playlistActions.deletePlaylistAsync.request): {
      return produce(state, (draftState) => {
        delete draftState.byId[action.payload];
      });
    }

    // copyPresentationAsync
    case getType(playlistActions.copyPlaylistAsync.success): {
      return produce(state, (draftState) => {
        draftState.byId[action.payload.id] = action.payload;
      });
    }

    // fetchFolderAsync
    case getType(folderActions.fetchFolderAsync.success):
    case getType(folderActions.fetchVirtualFolderAsync.success): {
      const { playlists } = action.payload;
      if (playlists.length === 0) return state;

      return produce(state, (draftState) => {
        playlists.forEach((playlist) => {
          setPlaylistIgnoreItems(draftState, playlist);
        });
      });
    }

    // movePlaylistToFolderAsync
    case getType(playlistActions.movePlaylistToFolderAsync.request): {
      const { playlist, parentFolderId } = action.payload;
      return produce(state, (draftState) => {
        const draftPlaylist = draftState.byId[playlist.id];
        if (!draftPlaylist) return;
        draftPlaylist.resource.parentFolderId = parentFolderId;
      });
    }

    default: {
      return state;
    }
  }
}
