import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { SortFolderOptions } from '../../utilities';

export const loadLibraryPage = createStandardAction('@LIBRARY_PAGE/LOAD')<{
  folderId?: string;
}>();

export const unloadLibraryPage = createStandardAction('@LIBRARY_PAGE/UNLOAD')();

export const loadLibraryPageAsync = createAsyncAction(
  '@LIBRARY_PAGE/LOAD_REQUEST',
  '@LIBRARY_PAGE/LOAD_SUCCESS',
  '@LIBRARY_PAGE/LOAD_FAILURE',
)<void, void, Error>();

export const setMainSortOptions = createStandardAction(
  '@LIBRARY_PAGE/SET_MAIN_SORT_OPTIONS',
)<SortFolderOptions>();

export const setSideSortOptions = createStandardAction(
  '@LIBRARY_PAGE/SET_SIDE_SORT_OPTIONS',
)<SortFolderOptions>();

export const setSearchQuery = createStandardAction(
  '@LIBRARY_PAGE/SET_SEARCH_QUERY',
)<string>();

export const setSelectedNodeIds = createStandardAction(
  '@LIBRARY_PAGE/SET_SELECTED_NODE_IDS',
)<string[]>();

export const clearSelectedNodeIds = createStandardAction(
  '@LIBRARY_PAGE/CLEAR_SELECTED_NODE_IDS',
)();
