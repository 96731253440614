import { useMemo } from 'react';
import { Playlist } from '@raydiant/api-client-js';

export default function usePlaylistsById(
  playlists: Playlist[] | undefined,
): Record<string, Playlist> {
  const playlistsById = useMemo(() => {
    if (!playlists) return {};

    return playlists.reduce<Record<string, Playlist>>((acc, playlist) => {
      acc[playlist.id] = playlist;
      return acc;
    }, {});
  }, [playlists]);

  return playlistsById;
}
