import AddCircleIcon from '@material-ui/icons/AddCircle';
import PlaylistPlaySharpIcon from '@material-ui/icons/PlaylistPlaySharp';
import FolderSharpIcon from '@material-ui/icons/FolderSharp';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import Row from 'raydiant-elements/layout/Row';
import React, { FC, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TreeView from '../../components/TreeView';
import * as P from '../../clients/mira/types/Presentation';
import * as PL from '../../clients/mira/types/Playlist';
import * as F from '../../clients/mira/types/Folder';
import * as R from '../../clients/mira/types/Resource';
import * as folderActions from '../../actions/folders';
import {
  selectFoldersById,
  selectLibraryByOwner,
  selectFolderStatusById,
} from '../../selectors/v2/folders';
import {
  selectUserProfile,
  selectIsEnterpriseUser,
} from '../../selectors/user';
import {
  createNodeId,
  parseNodeId,
  hasDroppableNodeIds,
  canMoveFolderItems,
  sortFolder,
  isResourceDeleted,
  canEditResource,
  searchPresentations,
  searchPlaylists,
  searchFolders,
  FolderItem,
  SortFolderOptions,
} from '../../utilities';
import * as libraryTreeActions from './actions';
import {
  selectExpandedNodeIds,
  selectCollapsedNodeIds,
  selectFetchedExpandedNodeIds,
  selectSelectedNodeIds,
} from './selectors';
import useStyles from './LibrayTree.styles';
import { stopPropagation } from 'raydiant-elements/helpers';

interface LibraryTreeProps {
  selectedProfile: R.ResourceProfile;
  selectMode: 'playlist' | 'multiple';
  sortOptions: SortFolderOptions;
  searchQuery?: string;
  onDrop?: () => void;
  onSelectPlaylist?: (playlistId: string) => void;
  onOpenPresentation?: (presentationId: string) => void;
  onOpenPlaylist?: (playlistId: string) => void;
}

type LibraryTreeFolderItem = FolderItem<P.Presentation, PL.Playlist, F.Folder>;

const LibraryTree: FC<LibraryTreeProps> = ({
  selectedProfile,
  selectMode,
  sortOptions,
  searchQuery = '',
  onDrop,
  onSelectPlaylist,
  onOpenPresentation,
  onOpenPlaylist,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Selectors

  const libraryByOwner = useSelector(selectLibraryByOwner);
  const foldersById = useSelector(selectFoldersById);
  const folderStatusById = useSelector(selectFolderStatusById);
  const currentUser = useSelector(selectUserProfile);
  const expandedNodeIds = useSelector(selectExpandedNodeIds);
  const collapsedNodeIds = useSelector(selectCollapsedNodeIds);
  const expandedFetchedNodeIds = useSelector(selectFetchedExpandedNodeIds);
  const selected = useSelector(selectSelectedNodeIds);
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);

  // Memoizers

  const libraryFolder = useMemo(
    () =>
      libraryByOwner[selectedProfile.id] || {
        presentations: [],
        playlists: [],
        folders: [],
      },
    [libraryByOwner, selectedProfile.id],
  );

  // Callbacks

  const getFolderItems = useCallback(
    (
      presentations: P.Presentation[],
      playlists: PL.Playlist[],
      folders: F.Folder[],
    ) => {
      return sortFolder(
        selectMode === 'playlist'
          ? []
          : searchPresentations(
              presentations.filter((p) => !isResourceDeleted(p)),
              searchQuery,
            ),
        searchPlaylists(
          playlists.filter((pl) => !isResourceDeleted(pl)),
          searchQuery,
        ),
        searchFolders(
          folders.filter((f) => !isResourceDeleted(f)),
          searchQuery,
        ),
        sortOptions,
      );
    },
    [selectMode, sortOptions, searchQuery],
  );

  const handleDrop = useCallback(
    (nodeIds: string[], parentFolderId: string | null) => {
      const presentationIds: string[] = [];
      const playlistIds: string[] = [];
      const folderIds: string[] = [];

      nodeIds.forEach((nodeId) => {
        const { type, id } = parseNodeId(nodeId);
        if (type === 'presentation') {
          presentationIds.push(id);
        } else if (type === 'playlist') {
          playlistIds.push(id);
        } else if (type === 'folder' && id !== parentFolderId) {
          folderIds.push(id);
        }
      });

      dispatch(
        folderActions.moveAllItemsToFolder(
          { presentationIds, playlistIds, folderIds, parentFolderId },
          {},
        ),
      );

      dispatch(libraryTreeActions.clearSelectedNodeIds());

      if (onDrop) {
        onDrop();
      }
    },
    [dispatch, onDrop],
  );

  const handleExpansion = useCallback(
    (shouldExpand: boolean, nodeId: string) => {
      if (shouldExpand) {
        dispatch(libraryTreeActions.expandNode(nodeId));
      } else {
        dispatch(libraryTreeActions.collapseNode(nodeId));
      }
    },
    [dispatch],
  );

  const setSelectedNodeIds = useCallback(
    (nodeIds: string[]) => {
      dispatch(libraryTreeActions.setSelectedNodeIds(nodeIds));
    },
    [dispatch],
  );

  const handleSelectPlaylist = useCallback(
    (playlistId: string) => {
      if (!onSelectPlaylist) return;
      onSelectPlaylist(playlistId);
      dispatch(libraryTreeActions.clearSelectedNodeIds());
    },
    [dispatch, onSelectPlaylist],
  );

  // Side-effects

  // Fetch expanded folders if we haven't before.
  useEffect(() => {
    Object.entries(expandedNodeIds).forEach(([nodeId, isExpanded]) => {
      if (!isExpanded) return;

      const { id, type } = parseNodeId(nodeId);
      if (type !== 'folder' || folderStatusById[id] === 'success') return;

      dispatch(folderActions.fetchFolder(id, {}));
    });
  }, [dispatch, expandedNodeIds, folderStatusById]);

  // Memoized

  const libraryFolderItems = useMemo(
    () =>
      getFolderItems(
        libraryFolder.presentations,
        libraryFolder.playlists,
        libraryFolder.folders,
      ),
    [libraryFolder, getFolderItems],
  );

  const openendFolderItems = useMemo(() => {
    const result: {
      [folderId: string]: LibraryTreeFolderItem[] | undefined;
    } = {};

    const openedNodeIds = [
      ...Object.keys(expandedNodeIds),
      ...Object.keys(collapsedNodeIds),
    ];

    openedNodeIds.forEach((nodeId) => {
      const { id, type } = parseNodeId(nodeId);
      if (type !== 'folder') return;

      const folder = foldersById[id];
      if (!folder) return;

      result[folder.id] = getFolderItems(
        folder.presentations,
        folder.playlists,
        folder.folders,
      );
    });

    return result;
  }, [expandedNodeIds, collapsedNodeIds, foldersById, getFolderItems]);

  // Render

  if (!libraryFolder) {
    return null;
  }

  const renderLabelWithAction = (
    name: string,
    icon: React.ReactNode,
    label: string,
    onClick: () => void,
  ) => {
    return (
      <Row halfMargin className={classes.labelWithButton}>
        <span>{name}</span>
        <ActionBar.Action
          className={classes.button}
          icon={icon}
          label={label}
          onClick={stopPropagation(onClick)}
        />
      </Row>
    );
  };

  const renderPresentationItem = (
    presentation: P.Presentation,
    isMoveable = false,
  ) => {
    const nodeId = createNodeId('presentation', presentation.id);
    let labelEl: React.ReactNode = presentation.name;

    const isEditable =
      currentUser && canEditResource(currentUser, presentation.resource);

    const renderOpen =
      isEditable &&
      selectMode === 'multiple' &&
      selected.length === 1 &&
      selected.includes(nodeId);

    if (renderOpen && onOpenPresentation) {
      labelEl = renderLabelWithAction(presentation.name, null, 'Open', () =>
        onOpenPresentation(presentation.id),
      );
    }

    return (
      <TreeView.Item
        key={presentation.id}
        nodeId={nodeId}
        icon={
          <img
            alt=""
            src={presentation.iconUrl}
            style={{
              width: 19,
              marginRight: 1,
              marginLeft: 1,
              borderRadius: 1,
            }}
          />
        }
        label={labelEl}
        draggable={isMoveable}
      />
    );
  };

  const renderPlaylistItem = (playlist: PL.Playlist, isMoveable = false) => {
    const nodeId = createNodeId('playlist', playlist.id);

    let labelEl: React.ReactNode = playlist.name;

    const renderAssignPlaylist =
      selectMode === 'playlist' &&
      selected.length === 1 &&
      selected.includes(nodeId);

    if (renderAssignPlaylist) {
      labelEl = renderLabelWithAction(
        playlist.name,
        <AddCircleIcon />,
        'Assign',
        () => handleSelectPlaylist(playlist.id),
      );
    }

    const isEditable =
      currentUser && canEditResource(currentUser, playlist.resource);

    const renderOpen =
      isEditable &&
      selectMode === 'multiple' &&
      selected.length === 1 &&
      selected.includes(nodeId);

    if (renderOpen && onOpenPlaylist) {
      labelEl = renderLabelWithAction(playlist.name, null, 'Open', () =>
        onOpenPlaylist(playlist.id),
      );
    }

    return (
      <TreeView.Item
        key={playlist.id}
        nodeId={createNodeId('playlist', playlist.id)}
        icon={<PlaylistPlaySharpIcon />}
        label={labelEl}
        draggable={isMoveable}
      />
    );
  };

  const renderFolderItem = (folder: F.Folder, isMoveable = false) => {
    const nodeId = createNodeId('folder', folder.id);
    const folderItems = openendFolderItems[folder.id] || [];

    const isLoading =
      folderStatusById[folder.id] === 'fetching' && folderItems.length === 0;

    return (
      <TreeView.Item
        key={folder.id}
        nodeId={nodeId}
        icon={<FolderSharpIcon />}
        label={folder.name}
        isLoading={isLoading}
        expandable
        onExpansion={(isExpanded) => handleExpansion(isExpanded, nodeId)}
        {...(isMoveable
          ? {
              draggable: true,
              onDrop: (nodeIds) => handleDrop(nodeIds, folder.id),
              onCanDrop: (nodeIds) =>
                hasDroppableNodeIds(nodeIds, folder, isEnterpriseUser),
            }
          : {})}
      >
        {renderFolderItems(
          folderItems,
          currentUser &&
            canMoveFolderItems(currentUser, folder.resource.profile),
        )}
      </TreeView.Item>
    );
  };

  const renderFolderItems = (
    folderItems: LibraryTreeFolderItem[],
    isMoveable = false,
  ) => {
    return folderItems.map(({ presentation, playlist, folder }) => {
      if (presentation) return renderPresentationItem(presentation, isMoveable);
      if (playlist) return renderPlaylistItem(playlist, isMoveable);
      if (folder) return renderFolderItem(folder, isMoveable);
      return null;
    });
  };

  return (
    <TreeView
      expanded={expandedFetchedNodeIds}
      selected={selected}
      onNodeSelect={setSelectedNodeIds}
      onDrop={(nodeIds) => handleDrop(nodeIds, null)}
      onCanDrop={(nodeIds) =>
        hasDroppableNodeIds(nodeIds, libraryFolder, isEnterpriseUser)
      }
    >
      {renderFolderItems(
        libraryFolderItems,
        currentUser && canMoveFolderItems(currentUser, selectedProfile),
      )}
    </TreeView>
  );
};

export default LibraryTree;
