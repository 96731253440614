import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ShareIcon from '@material-ui/icons/Share';
import { Theme } from '@raydiant/api-client-js';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import Checkbox from 'raydiant-elements/core/Checkbox';
import SwapVertReverseIcon from 'raydiant-elements/icons/SwapVertReverse';
import DuplicateIcon from 'raydiant-elements/icons/Duplicate';
import Hidden from 'raydiant-elements/layout/Hidden';
import Spacer from 'raydiant-elements/layout/Spacer';
import { useDispatch, useSelector } from 'react-redux';
import { getDuplicateResourceName, SortThemeOptions } from '../../utilities';
import * as themeActions from '../../actions/themes';
import { selectIsEnterpriseUser } from '../../selectors/user';
import { selectThemesById } from '../../selectors/v2/themes';
import useDuplicateSnackbar from '../../hooks/useDuplicateSnackbar';
import * as actions from './actions';
import { selectThemeSortOptions, selectThemeSearchQuery } from './selectors';
import DeleteThemeConfirmation from './DeleteThemeConfirmation';
import useCustomThemes from './useCustomThemes';

interface ThemeManagerActionBarProps {
  selectedThemeIds: Record<string, boolean>;
  onSelectAll: (selectAll: boolean) => void;
  onShare: (themeId: string) => void;
  onDirty: (isDirty: boolean) => void;
}

const ThemeManagerActionBar = ({
  selectedThemeIds,
  onSelectAll,
  onShare,
  onDirty,
}: ThemeManagerActionBarProps) => {
  const dispatch = useDispatch();
  const customThemes = useCustomThemes();
  const { enqueueDuplicateSnackbar } = useDuplicateSnackbar();

  // Selectors

  const sortOptions = useSelector(selectThemeSortOptions);
  const searchQuery = useSelector(selectThemeSearchQuery);
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);

  // State

  const [isSortMode, setIsSortMode] = useState(false);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isDeletePromptOpen, setIsDeletePromptOpen] = useState(false);
  const themesById = useSelector(selectThemesById);

  // Refs

  const deleteButtonRef = useRef<HTMLButtonElement | null>(null);

  // Memoizers

  const selectedThemes = useMemo(() => {
    const arr: Theme[] = [];

    Object.entries(selectedThemeIds).forEach(([themeId, selected]) => {
      if (selected) {
        const theme = themesById[themeId];
        if (theme) {
          arr.push(theme);
        }
      }
    });

    return arr;
  }, [selectedThemeIds, themesById]);

  // Callbacks

  const toggleSort = useCallback(
    (property: SortThemeOptions['property']) => {
      let direction: SortThemeOptions['direction'] =
        property === 'updatedAt' ? 'desc' : 'asc';

      if (property === sortOptions.property) {
        direction = sortOptions.direction === 'asc' ? 'desc' : 'asc';
      }

      dispatch(actions.setThemeSortOptions({ property, direction }));

      setIsSortMode(false);
    },
    [dispatch, setIsSortMode, sortOptions],
  );

  const setSearchQuery = useCallback(
    (value: string) => {
      dispatch(actions.setThemeSearchQuery(value));
    },
    [dispatch],
  );

  const deleteSelected = useCallback(() => {
    setIsDeletePromptOpen(false);
    dispatch(
      themeActions.deleteAllThemes(
        selectedThemes.map((t) => t.id),
        {},
      ),
    );
    onDirty(true);
  }, [selectedThemes, dispatch, onDirty]);

  const duplicateThemes = useCallback(() => {
    const themes: Theme[] = [];

    selectedThemes.forEach((theme) => {
      themes.push({
        ...theme,
        name: getDuplicateResourceName(theme.name, customThemes),
      });
    });

    if (themes.length > 0) {
      dispatch(
        themeActions.createAllThemes(themes, {
          onCreate: () => enqueueDuplicateSnackbar(false),
        }),
      );
    }
    onDirty(true);
  }, [
    dispatch,
    selectedThemes,
    onDirty,
    customThemes,
    enqueueDuplicateSnackbar,
  ]);

  const shareTheme = useCallback(() => {
    // We currently only support sharing one theme, not multiple.
    if (selectedThemes[0]) {
      onShare(selectedThemes[0].id);
    }
    onDirty(true);
  }, [selectedThemes, onShare, onDirty]);

  // Effects

  // Notify consumer of selectAll changes.
  useEffect(() => {
    onSelectAll(isSelectAll);
  }, [isSelectAll, onSelectAll]);

  // Clear search query on unmount
  useEffect(() => {
    return () => {
      dispatch(actions.setThemeSearchQuery(''));
    };
  }, [dispatch]);

  // Render

  let sortLabel = 'Sort';
  if (sortOptions.property === 'name') {
    sortLabel = `${sortLabel}: Name`;
  } else if (sortOptions.property === 'updatedAt') {
    sortLabel = `${sortLabel}: Date`;
  }

  const isSortByName = sortOptions.property === 'name';
  const isSortByDate = sortOptions.property === 'updatedAt';
  const isReverseSort = sortOptions.direction === 'desc';

  const sortActionOptions = (
    <>
      <ActionBar.SelectOption
        icon={
          isSortByName && isReverseSort ? (
            <SwapVertReverseIcon />
          ) : (
            <SwapVertIcon />
          )
        }
        label="Name"
        selected={isSortByName}
        onClick={() => toggleSort('name')}
      />
      <ActionBar.SelectOption
        icon={
          isSortByDate && isReverseSort ? (
            <SwapVertReverseIcon />
          ) : (
            <SwapVertIcon />
          )
        }
        label="Date"
        selected={isSortByDate}
        onClick={() => toggleSort('updatedAt')}
      />
    </>
  );

  const sortAction = (
    <>
      <Hidden mdDown>
        <ActionBar.Select
          icon={<FilterListIcon />}
          label={sortLabel}
          open={isSortMode}
          onOpen={setIsSortMode}
        >
          {sortActionOptions}
        </ActionBar.Select>
      </Hidden>
      <Hidden lgUp>
        <ActionBar.Select
          icon={<FilterListIcon />}
          open={isSortMode}
          onOpen={setIsSortMode}
        >
          {sortActionOptions}
        </ActionBar.Select>
      </Hidden>
    </>
  );

  const copyAction = (
    <ActionBar.Action
      icon={<DuplicateIcon />}
      disabled={selectedThemes.length < 1}
      onClick={duplicateThemes}
    />
  );

  const firstTheme = selectedThemes[0];
  // Disable share if more than one theme is selected or the selected theme is a
  // one-off theme. Bulk sharing is not yet supported.
  const disableShare =
    selectedThemes.length !== 1 || firstTheme?.presentationId !== null;

  const shareAction = (
    <ActionBar.Action
      icon={<ShareIcon />}
      disabled={disableShare}
      onClick={shareTheme}
    />
  );

  const disableDelete = selectedThemes.length < 1;

  const deleteAction = (
    <ActionBar.Action
      ref={deleteButtonRef}
      icon={<DeleteIcon />}
      disabled={disableDelete}
      onClick={() => setIsDeletePromptOpen(true)}
    />
  );

  const searchAction = isSearchMode ? (
    <ActionBar.Input
      autoFocus
      type="search"
      label="Search"
      maxWidth={145}
      icon={
        <ActionBar.Action
          icon={<CloseIcon />}
          onClick={() => setIsSearchMode(false)}
        />
      }
      value={searchQuery}
      onChange={(value) => setSearchQuery(value)}
    />
  ) : (
    <ActionBar.Action
      icon={<SearchIcon />}
      onClick={() => setIsSearchMode(true)}
    />
  );

  return (
    <ActionBar>
      <Checkbox checked={isSelectAll} onChange={setIsSelectAll} />
      {sortAction}
      {!isSearchMode && copyAction}
      {!isSearchMode && isEnterpriseUser && shareAction}
      {!isSearchMode && deleteAction}
      <Spacer />
      {searchAction}

      <DeleteThemeConfirmation
        open={isDeletePromptOpen}
        anchorEl={deleteButtonRef.current}
        themes={selectedThemes}
        onClose={() => setIsDeletePromptOpen(false)}
        onDelete={deleteSelected}
      />
    </ActionBar>
  );
};

export default ThemeManagerActionBar;
