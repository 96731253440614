import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) => {
  return createStyles({
    domainAccountSelector: {
      alignSelf: 'flex-start',
      maxWidth: 320,

      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(1),
        maxWidth: 'none',
      },
    },

    library: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden', // Needed to show inner scroll bar.
      borderRadius: theme.borderRadius.md,

      [theme.breakpoints.down('xs')]: {
        flex: 'auto',
        margin: theme.spacing(0, 1),
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },

    libraryMain: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden', // Needed to show inner scroll bar.
      marginTop: theme.spacing(4),

      [theme.breakpoints.down('xs')]: {
        flex: 'auto',
        marginTop: 0,
      },
    },

    header: {
      padding: theme.spacing(0, 4),
      paddingBottom: theme.spacing(1),

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
      },
    },

    folderNameRow: {
      height: 36, // Align with "All Folders"
    },

    folderNameHelperText: {
      paddingLeft: 62, // Align with folder name
    },
  });
});
