import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) => {
  return createStyles({
    thumbnailImage: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: '#000',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundClip: 'padding-box',
      backgroundImage:
        'url(https://assets.raydiant.com/playlist-thumbnail.svg)',
    },

    thumbnailImageDark: {
      '&:after': {
        content: '" "',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: theme.modal.overlayBackground,
      },
    },

    outline: {
      position: 'absolute',
      top: -4,
      left: -4,
      width: 'calc(100% + 8px)',
      height: 'calc(100% + 8px)',
      pointerEvents: 'none',
    },

    icon: {
      width: '100%',
      height: '100%',
    },
  });
});
