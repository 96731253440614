import { createSelector } from 'reselect';
import { RootState } from '../../reducers';
import {
  selectFoldersById,
  selectFolderStatusById,
} from '../../selectors/v2/folders';
import { parseNodeId } from '../../utilities';
import * as F from '../../clients/mira/types/Folder';

export const selectIsLoading = (state: RootState) =>
  state.libraryTree.isLoading;

export const selectSelectedNodeIds = (state: RootState) =>
  state.libraryTree.selectedNodeIds;

export const selectExpandedNodeIds = (state: RootState) =>
  state.libraryTree.expandedNodeIds;

export const selectCollapsedNodeIds = (state: RootState) =>
  state.libraryTree.collapsedNodeIds;

const hasFolderItems = (folder?: F.Folder) => {
  return (
    !!folder &&
    (folder.presentations.length > 0 ||
      folder.playlists.length > 0 ||
      folder.folders.length > 0)
  );
};

export const selectFetchedExpandedNodeIds = createSelector(
  [selectExpandedNodeIds, selectFolderStatusById, selectFoldersById],
  (expandedNodeIds, folderStatusById, foldersById) => {
    return Object.keys(expandedNodeIds).filter((nodeId) => {
      const { type, id } = parseNodeId(nodeId);

      // Delay opening the folder until items have been fetched.
      const isFetchedFolder =
        type === 'folder' &&
        (folderStatusById[id] === 'success' || hasFolderItems(foldersById[id]));

      const isProfile = type === 'profile';

      return isFetchedFolder || isProfile;
    });
  },
);
