import theme from 'raydiant-elements/theme';

type Sizes = number | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export default function isViewportAboce(size: Sizes) {
  // It doesn't matter if we use theme.light, theme.grey or theme.dark.
  // In order to use with matchMedia we need to strip @media.
  const breakpoint = theme.light.breakpoints.up(size).replace('@media', '');
  return !!window.matchMedia(breakpoint).matches;
}
