import React, { FC, useState } from 'react';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import { Tag } from '@raydiant/api-client-js';
import Column from 'raydiant-elements/layout/Column';
import Row from 'raydiant-elements/layout/Row';
import TagInput from '../TagInput';
import {
  tagData,
  tagOptionGroups,
  TagKey,
  TagOption,
  TagOptionGroup,
} from './tagManagerData';
import Select from 'raydiant-elements/core/Select';
import InputLabel from 'raydiant-elements/core/InputLabel';
import { MenuItem } from '@material-ui/core';
import { Hidden } from 'raydiant-elements/layout/Hidden/Hidden';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import NestedMenuItem from '../NestedMenuItem';
import useCurrentUserRoles from '../../hooks/useCurrentUserRoles';
export interface TagManagerProps {
  tags: Tag[];
  onChange: (value: Tag[]) => void;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: '200px',
    },
  });
});

const TagManager: FC<TagManagerProps> = ({ tags, onChange }) => {
  const classes = useStyles();
  // State

  const [selectedTagKey, setSelectedTagKey] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  const roles = useCurrentUserRoles();
  const isRulesAndTagsDevEnabled = roles.rulesAndTagsDev;

  // Callbacks

  const handleDelete = (tag: Tag) => {
    const updatedTags = tags.filter((t) => t.key !== tag.key);
    onChange(updatedTags);
  };

  const handleEdit = (tag: Tag, value: string) => {
    const updatedTags = tags.map((t) => {
      if (t.key === tag.key) {
        return { ...t, value };
      }
      return t;
    });

    onChange(updatedTags);
  };

  const handleAddTag = (key: TagKey) => {
    const updatedTags: Tag[] = [
      ...tags,
      {
        key,
        label: tagData[key].label,
        value: '',
        type: 'text',
        // NOTE: These values are populated by the API and technically not required but
        // here to appease TS. There's probably a better way to structure the types.
        id: '',
        resourceId: '',
        createdAt: '',
      },
    ];
    onChange(updatedTags);
    setSelectedTagKey(key);
  };

  const toggleMenu = () => setOpen(open ? false : true);

  const showAllRootMenuItems = (group: TagOptionGroup) =>
    isRulesAndTagsDevEnabled ? true : group.name !== 'Schedule';
  // Render

  const renderMenuItem = (
    option: TagOption | TagOptionGroup,
    index: number,
  ) => {
    if ('groups' in option) {
      return (
        <NestedMenuItem
          className={classes.menuItem}
          key={index}
          label={option.name}
          parentMenuOpen={open}
        >
          {option.groups.map(renderMenuItem)}
        </NestedMenuItem>
      );
    } else {
      return (
        <NestedMenuItem
          className={classes.menuItem}
          key={index}
          label={option.name}
          parentMenuOpen={open}
        >
          {option.options.map((tagKey, index) => (
            <MenuItem
              disabled={isTagAdded(tagKey)}
              key={index}
              value={tagKey}
              selected={tagKey === selectedTagKey}
              onClick={() => {
                handleAddTag(tagKey);
                toggleMenu();
              }}
            >
              {tagData[tagKey].label}
            </MenuItem>
          ))}
        </NestedMenuItem>
      );
    }
  };

  const renderDesktopMenu = () => (
    <div>
      <InputLabel>Add a Tag</InputLabel>
      <Select
        native={false}
        open={open}
        onChange={toggleMenu}
        onClose={toggleMenu}
        onOpen={toggleMenu}
        value={selectedTagKey ?? ''}
      >
        {tagOptionGroups.filter(showAllRootMenuItems).map(renderMenuItem)}
      </Select>
    </div>
  );

  const renderMobileMenuItem = (
    option: TagOption | TagOptionGroup,
    index: number,
  ): any => {
    if ('groups' in option) {
      return option.groups.map(renderMobileMenuItem);
    } else {
      return option.options.map((tagKey) => (
        <option disabled={isTagAdded(tagKey)} key={index} value={tagKey}>
          {tagData[tagKey].label}
        </option>
      ));
    }
  };

  const renderMobileMenu = () => (
    <div>
      <InputLabel>Add a Tag</InputLabel>
      <Select
        native={true}
        onChange={(event) => handleAddTag(event as TagKey)}
        value=""
      >
        <option value=""></option>
        {tagOptionGroups.filter(showAllRootMenuItems).map(renderMobileMenuItem)}
      </Select>
    </div>
  );

  const isTagAdded = (key: string) => {
    return tags.some((t) => t.key === key);
  };

  return (
    <Column doubleMargin>
      {tags.length > 0 && (
        <Column>
          {tags.map((tag) => (
            <Row key={tag.key} halfMargin center>
              <TagInput
                label={tag.label}
                value={tag.value}
                type={tag.type}
                editing={selectedTagKey === tag.key}
                onEdit={(editing) =>
                  setSelectedTagKey(editing ? tag.key : null)
                }
                onChange={(value) => handleEdit(tag, value)}
              />
              <div>
                <IconButton
                  size="small"
                  edge="end"
                  onClick={() => handleDelete(tag)}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </div>
            </Row>
          ))}
        </Column>
      )}
      <>
        <Hidden xsDown>{renderDesktopMenu()}</Hidden>
        <Hidden smUp>{renderMobileMenu()}</Hidden>
      </>
    </Column>
  );
};

export default TagManager;
