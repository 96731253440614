import * as D from '../clients/mira/types/Device';
import { RootState } from '../reducers';

export const selectPairingCodes = (state: RootState): D.SYBPairingCode[] => {
  return state.pairingCodes.items
    .filter(({ id }) => !!id)
    .map(({ id, pairingCode = '' }) => ({ id, pairingCode }));
};

export const selectPairingCodesGenerating = (state: RootState): string[] => {
  return state.pairingCodes.itemsPairingCodeStatus
    .filter(({ isRequesting }) => isRequesting)
    .map(({ id }) => id);
};
