import * as D from '../clients/mira/types/Device';

export default function searchDevices(
  devices: D.Device[],
  searchQuery: string,
) {
  const searchQueryInsenstive = searchQuery.toLowerCase().trim();
  return devices.filter(
    (device) =>
      device.name.toLowerCase().includes(searchQueryInsenstive) ||
      device.resource.profile.name
        .toLowerCase()
        .includes(searchQueryInsenstive),
  );
}
