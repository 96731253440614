import {
  Presentation,
  Playlist,
  Folder,
  Device,
  Theme,
  ResourceProfile,
} from '@raydiant/api-client-js';

interface Resources<
  T1 extends Presentation,
  T2 extends Playlist,
  T3 extends Folder,
  T4 extends Device,
  T5 extends Theme,
> {
  presentations?: T1[];
  playlists?: T2[];
  folders?: T3[];
  devices?: T4[];
  themes?: T5[];
}

export const groupResourcesByOwner = <
  T1 extends Presentation,
  T2 extends Playlist,
  T3 extends Folder,
  T4 extends Device,
  T5 extends Theme,
>({
  presentations = [],
  playlists = [],
  folders = [],
  devices = [],
  themes = [],
}: Resources<T1, T2, T3, T4, T5>) => {
  const resourcesByOwner: {
    [profileId: string]: {
      profile: ResourceProfile;
      presentations: T1[];
      playlists: T2[];
      folders: T3[];
      devices: T4[];
      themes: T5[];
    };
  } = {};

  const ensureGroup = (profile: ResourceProfile) => {
    if (!resourcesByOwner[profile.id]) {
      resourcesByOwner[profile.id] = {
        profile,
        presentations: [],
        playlists: [],
        folders: [],
        devices: [],
        themes: [],
      };
    }
    return resourcesByOwner[profile.id];
  };

  presentations.forEach((p) => {
    ensureGroup(p.resource.profile).presentations.push(p);
  });

  playlists.forEach((pl) => {
    ensureGroup(pl.resource.profile).playlists.push(pl);
  });

  folders.forEach((f) => {
    ensureGroup(f.resource.profile).folders.push(f);
  });

  devices.forEach((d) => {
    ensureGroup(d.resource.profile).devices.push(d);
  });

  themes.forEach((d) => {
    ensureGroup(d.resource.profile).themes.push(d);
  });

  return resourcesByOwner;
};
