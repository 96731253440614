import { Presentation } from '@raydiant/api-client-js';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUserThemes } from './selectors';

export default function useCustomThemes(presentation?: Presentation) {
  const userThemes = useSelector(selectUserThemes);

  return useMemo(() => {
    return userThemes.filter(
      (theme) =>
        theme.presentationId === presentation?.id ||
        theme.presentationId === null,
    );
  }, [presentation, userThemes]);
}
