import { RouteComponentProps } from 'react-router-dom';
import { RootState } from '../reducers';

export const selectRouteParam =
  (key: string) =>
  (_: RootState, props: RouteComponentProps<{ [key: string]: string }>) => {
    return props.match.params[key] || '';
  };

export const selectQueryParam =
  (key: string) => (_: RootState, props: RouteComponentProps) => {
    const urlParams = new URLSearchParams(props.location.search);
    return urlParams.get(key) || '';
  };

export const selectAllQueryParams =
  (keys: string[]) => (_: RootState, props: RouteComponentProps) => {
    const urlParams = new URLSearchParams(props.location.search);
    return keys.map((key) => urlParams.get(key) || '');
  };
