import OpenLibraryIcon from 'raydiant-elements/icons/OpenLibrary';
import Select from 'raydiant-elements/core/Select';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { buttonReset } from 'raydiant-elements/mixins';
import { Theme } from 'raydiant-elements/theme';
import cn from 'classnames';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectOtherDomainProfiles } from '../../selectors/v2/domains';
import { selectUserProfile } from '../../selectors/user';

interface DomainAccountSelectorProps {
  className?: string;
  selectedProfileId: string;
  onSelect: (selectedProfileId: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
    },

    icon: {
      ...buttonReset(),
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(2),
      fontSize: 24,
      opacity: 0.6,

      '&:hover': {
        opacity: 0.8,
      },
    },
  }),
);

const DomainAccountSelector: FC<DomainAccountSelectorProps> = ({
  className,
  selectedProfileId,
  onSelect,
}) => {
  const classes = useStyles();

  // Selectors

  const currentUser = useSelector(selectUserProfile);
  const otherDomainProfiles = useSelector(selectOtherDomainProfiles);

  if (!currentUser) {
    return (
      <div className={cn(classes.root, className)}>
        <button className={classes.icon}>
          <OpenLibraryIcon fontSize="inherit" />
        </button>

        <Select value="">
          <option value="">My Library</option>
        </Select>
      </div>
    );
  }

  return (
    <div className={cn(classes.root, className)}>
      <button className={classes.icon} onClick={() => onSelect(currentUser.id)}>
        <OpenLibraryIcon fontSize="inherit" />
      </button>
      <Select value={selectedProfileId} onChange={onSelect}>
        <option value={currentUser.id}>My Library</option>
        {otherDomainProfiles.map((profile) => (
          <option key={profile.id} value={profile.id}>
            {profile.name}
          </option>
        ))}
      </Select>
    </div>
  );
};

export default DomainAccountSelector;
