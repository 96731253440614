import React, { useCallback, useRef, useLayoutEffect, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Text from 'raydiant-elements/core/Text';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import ThemeSelector from 'raydiant-elements/core/ThemeSelector';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import OneThirdLayout from 'raydiant-elements/layout/OneThirdLayout';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Center from 'raydiant-elements/layout/Center';
import { isNewId } from '../../utilities/identifiers';
import Page from '../../components/Page';
import usePlaylistsById from '../../hooks/usePlaylistsById';
import usePlaylists from '../../hooks/usePlaylists';
import usePresentations from '../../hooks/usePresentations';
import useCurrentUser from '../../hooks/useCurrentUser';
import * as addContentPageActions from '../AddContentPage/actions';
import { selectItemsToAdd } from '../AddContentPage/selectors';
import usePlaylistPageState from './usePlaylistPageState';
import PlaylistPageContext from './PlaylistPageContext';
import PlaylistHeader from './PlaylistHeader';
import PlaylistItemList from './PlaylistItemList';
import PlaylistItemMoreActions from './PlaylistItemMoreActions';
import PlaylistFooter from './PlaylistFooter';
import PlaylistPreview from './PlaylistPreview';
import PlaylistItemModal from './PlaylistItemModal';
import { deserializeIDPath } from './usePlaylistPageState/utilities';
import { ItemIDPath } from './playlistPageTypes';

const PlaylistPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const page = usePlaylistPageState();
  const { rootPlaylistId, isNewPlaylist, queryParams } = page;

  if (!rootPlaylistId) {
    throw new Error('PlaylistPage is missing playlistId.');
  }

  // Selectors

  const itemsToAdd = useSelector(selectItemsToAdd);
  const playlistPageItemsToAdd = itemsToAdd['PlaylistPage'];

  // Queries

  const { status: currentUserStatus, data: currentUser } = useCurrentUser();

  // Only batch fetch playlists on page load since this status is used to
  // show the main loading spinner.
  const initialPlaylistIds = useRef([
    ...(isNewPlaylist ? [] : [rootPlaylistId]),
    ...Object.keys(page.state.expandedPlaylistIds).filter((id) => !isNewId(id)),
    ...Object.keys(page.state.addedPlaylistIds).filter((id) => !isNewId(id)),
  ]);
  const { status: playlistsStatus, data: playlists } = usePlaylists(
    initialPlaylistIds.current,
    {
      enabled: initialPlaylistIds.current.length > 0,
    },
  );

  // We only want to batch fetch presentations on page load.
  const initialPresentationIds = useRef([
    ...Object.keys(page.state.addedPresentationIds).filter(
      (id) => !isNewId(id),
    ),
  ]);
  const { status: presentationsStatus, data: presentations } = usePresentations(
    initialPresentationIds.current,
    {
      enabled: initialPresentationIds.current.length > 0,
    },
  );

  const didPageError =
    playlistsStatus === 'error' ||
    presentationsStatus === 'error' ||
    currentUserStatus === 'error';

  const isPageLoading =
    playlistsStatus === 'loading' ||
    presentationsStatus === 'loading' ||
    currentUserStatus === 'loading';

  let didPageLoad = false;
  if (
    initialPlaylistIds.current.length > 0 &&
    initialPresentationIds.current.length > 0
  ) {
    // We are loading playlists and presentations so we have to wait for both to succeed.
    didPageLoad =
      playlistsStatus === 'success' &&
      presentationsStatus === 'success' &&
      currentUserStatus === 'success';
  } else if (initialPlaylistIds.current.length > 0) {
    // We are only loading playlists, not presentations.
    didPageLoad =
      playlistsStatus === 'success' && currentUserStatus === 'success';
  } else if (initialPresentationIds.current.length > 0) {
    // We are only presentations, not playlists.
    didPageLoad =
      presentationsStatus === 'success' && currentUserStatus === 'success';
  } else {
    // We aren't loading presentations or playlists, so only wait for the current user. This
    // means we are creating a new playlists and haven't added any items yet.
    didPageLoad = currentUserStatus === 'success';
  }

  // Callbacks

  const handleBack = useCallback(() => {
    history.push(queryParams.backTo);
  }, [history, queryParams.backTo]);

  // Effects

  // Update form state with initial playlists.
  const setSavedPlaylists = page.setSavedPlaylists;
  // useLayoutEffect is used to synchronously update form state before rendering.
  // This avoids an extra render where playlists are done loading but not yet set
  // in form state.
  useLayoutEffect(() => {
    if (!playlists) return;
    setSavedPlaylists(playlists);
  }, [playlists, setSavedPlaylists]);

  // Update form state with initial presentations.
  const setSavedPresentations = page.setSavedPresentations;
  useLayoutEffect(() => {
    if (!presentations) return;
    setSavedPresentations(presentations);
  }, [presentations, setSavedPresentations]);

  // Set new unsaved playlist if not already set for new playlist.
  const createPlaylist = page.createPlaylist;
  // Track whether we created the new playlist already. We only want to do this once
  // on page load. This also prevents a new playlist from being created again after
  // saving before we redirect to the edit page.
  const didCreateNewPlaylist = useRef(false);
  useLayoutEffect(() => {
    if (!isNewPlaylist) return;
    if (!currentUser) return;
    if (didCreateNewPlaylist.current) return;

    createPlaylist(rootPlaylistId, currentUser.id);
    didCreateNewPlaylist.current = true;
  }, [
    rootPlaylistId,
    createPlaylist,
    currentUser,
    isNewPlaylist,
    didCreateNewPlaylist,
  ]);

  // Add items that were selected from AddContentPage.
  const addPlaylistItems = page.addPlaylistItems;
  useEffect(() => {
    if (!playlistPageItemsToAdd || playlistPageItemsToAdd.length === 0) return;

    // Add items at the path provided in query parameters or the root playlist
    // path if not provided.
    const addItemsAfterPath = queryParams.addItemsAfterPath
      ? deserializeIDPath(queryParams.addItemsAfterPath)
      : [];

    addPlaylistItems(addItemsAfterPath, playlistPageItemsToAdd);

    // Clear items to add.
    dispatch(
      addContentPageActions.clearItemsToAdd({ selectionId: 'PlaylistPage' }),
    );
  }, [
    dispatch,
    addPlaylistItems,
    playlistPageItemsToAdd,
    queryParams.addItemsAfterPath,
  ]);

  // Show cycle error snackbar message when a cycle is detected.
  const cycleIDPath = page.cycleIDPath;
  const prevCycleIDPathRef = useRef<ItemIDPath | null>(null);
  useEffect(() => {
    if (cycleIDPath && !prevCycleIDPathRef.current) {
      enqueueSnackbar('Cycle detected', { variant: 'error' });
    }

    prevCycleIDPathRef.current = cycleIDPath;
  }, [cycleIDPath, enqueueSnackbar]);

  // Render

  const playlistsById = usePlaylistsById(playlists);

  // Use the saved playlist name as the page title.
  const pageTitle = isNewPlaylist
    ? 'New Playlist'
    : playlistsById[rootPlaylistId]?.name;

  const pagePlaylist = rootPlaylistId
    ? page.getPlaylist(rootPlaylistId)
    : undefined;

  return (
    <Page title={pageTitle} backTo={queryParams.backTo}>
      <PlaylistPageContext.Provider value={page}>
        <OneThirdLayout>
          <OneThirdLayout.ColumnSmall>
            <PlaylistHeader playlist={pagePlaylist} />

            {didPageError && (
              <Center>
                <Text muted>Oops! Something went wrong.</Text>
              </Center>
            )}

            {isPageLoading && (
              <Center>
                <CircularProgress size={30} />
              </Center>
            )}

            {didPageLoad && (
              <Scrollable
                shadowClassName={page.classes.playlistItemsScrollShadow}
              >
                {pagePlaylist && <PlaylistItemList playlist={pagePlaylist} />}
              </Scrollable>
            )}

            {pagePlaylist && <PlaylistItemMoreActions />}

            <PlaylistFooter playlist={pagePlaylist} onCancel={handleBack} />
          </OneThirdLayout.ColumnSmall>
          <OneThirdLayout.ColumnLarge>
            <div className={page.classes.previewActions}>
              <ThemeSelector color="light">
                <ActionBar variant="floating" />
              </ThemeSelector>
            </div>

            <PlaylistPreview
              presentationId={page.state.previewItem?.presentationId}
              previewMode={queryParams.previewMode}
            />
          </OneThirdLayout.ColumnLarge>
        </OneThirdLayout>

        <PlaylistItemModal />
      </PlaylistPageContext.Provider>
    </Page>
  );
};

export default PlaylistPage;
