import { ActionType, getType } from 'typesafe-actions';
import * as deviceActions from '../actions/devices';
import * as D from '../clients/mira/types/Device';

export type DeviceActions = ActionType<typeof deviceActions>;

export type MetricsState = Readonly<{
  metricsByDeviceId: {
    [deviceId: string]: D.DeviceMetrics | null;
  };
}>;

const initialMetricsState: MetricsState = {
  metricsByDeviceId: {},
};

export default function metricsReducer(
  state = initialMetricsState,
  action: DeviceActions,
): MetricsState {
  switch (action.type) {
    case getType(deviceActions.fetchDevicesAsync.success): {
      const devices = action.payload;
      const metricsByDeviceId = { ...state.metricsByDeviceId };

      devices.forEach((device) => {
        metricsByDeviceId[device.id] = device.metrics;
      });

      return {
        ...state,
        metricsByDeviceId,
      };
    }

    case getType(deviceActions.fetchDeviceAsync.success): {
      const device = action.payload;
      const metricsByDeviceId = { ...state.metricsByDeviceId };
      metricsByDeviceId[device.id] = device.metrics;

      return {
        ...state,
        metricsByDeviceId,
      };
    }

    case getType(deviceActions.registerDeviceAsync.success): {
      const device = action.payload;
      const metricsByDeviceId = { ...state.metricsByDeviceId };
      metricsByDeviceId[device.id] = device.metrics;

      return {
        ...state,
        metricsByDeviceId,
      };
    }

    default: {
      return state;
    }
  }
}
