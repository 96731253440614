import { Theme } from '@raydiant/api-client-js';

export default function searchThemes<T extends Theme>(
  themes: T[],
  searchQuery: string,
) {
  const searchQueryInsenstive = searchQuery.toLowerCase().trim();
  return themes.filter(
    (t) =>
      t.name.toLowerCase().includes(searchQueryInsenstive) ||
      t.resource.profile.name.toLowerCase().includes(searchQueryInsenstive) ||
      t.resource.profile.email.toLowerCase().includes(searchQueryInsenstive),
  );
}
