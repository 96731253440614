import React, { FC } from 'react';
import { Presentation } from '@raydiant/api-client-js';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export interface PresentationIconProps {
  presentation: Presentation;
}

export const PresentationIcon: FC<PresentationIconProps> = ({
  presentation,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{ backgroundImage: `url(${presentation.iconUrl})` }}
    />
  );
};

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  }),
);

export default PresentationIcon;
