import { useMemo } from 'react';
import { useQueryClient, UseQueryOptions } from 'react-query';
import { Presentation, ApiClientError } from '@raydiant/api-client-js';
import raydiant from '../clients/miraClient';
import useProtectedQuery from './useProtectedQuery';
import { keys } from '../queryClient';
import { setPresentationQueryCache } from '../queryClient';

export default function usePresentations(
  presentationIds: string[] | undefined,
  opts: UseQueryOptions<Presentation[], ApiClientError> = {},
) {
  const queryClient = useQueryClient();

  // Array item order matters in react-qery so convert presentationIds to an object to avoid
  // refetching the same presentationIds if they are a different order.
  const presentationsById = useMemo(() => {
    if (!presentationIds) return {};
    return presentationIds.reduce<Record<string, true>>((acc, playlistId) => {
      acc[playlistId] = true;
      return acc;
    }, {});
  }, [presentationIds]);

  const query = useProtectedQuery<Presentation[], ApiClientError>(
    keys.presentationsById(presentationsById),
    () =>
      presentationIds
        ? raydiant.getPresentations({ ids: presentationIds })
        : [],
    {
      enabled: presentationIds !== undefined && opts.enabled,
      onSuccess: (presentations) => {
        // Set query data for each presentation to prevent refetching a single presentation.
        presentations.forEach((presentation) => {
          setPresentationQueryCache(queryClient, presentation);
        });
      },
    },
  );

  return query;
}
