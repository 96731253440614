import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as presentationActions from '../../actions/presentations';
import * as playlistActions from '../../actions/playlists';
import * as folderActions from '../../actions/folders';
import * as P from '../../clients/mira/types/Presentation';

export type PresentationActions = ActionType<typeof presentationActions>;
export type FolderActions = ActionType<typeof folderActions>;
export type PlaylistActions = ActionType<typeof playlistActions>;

export type PresentationsState = Readonly<{
  byId: {
    [presentationId: string]: P.Presentation;
  };
}>;

const initialPresentationsState: PresentationsState = {
  byId: {},
};

export default function presentationsReducer(
  state = initialPresentationsState,
  action: PresentationActions | FolderActions | PlaylistActions,
): PresentationsState {
  switch (action.type) {
    // fetchPresentationsAsync
    case getType(presentationActions.fetchPresentationsAsync.success): {
      const presentations = action.payload;
      return produce(state, (draftState) => {
        presentations.forEach((presentation) => {
          draftState.byId[presentation.id] = presentation;
        });
      });
    }

    // createPresentationAsync
    case getType(presentationActions.createPresentationAsync.success): {
      return produce(state, (draftState) => {
        draftState.byId[action.payload.id] = action.payload;
      });
    }

    // updatePresentationAsync
    case getType(presentationActions.updatePresentationAsync.success): {
      return produce(state, (draftState) => {
        draftState.byId[action.payload.id] = action.payload;
      });
    }

    // deletePresentationAsync
    case getType(presentationActions.deletePresentationAsync.request): {
      return produce(state, (draftState) => {
        delete draftState.byId[action.payload];
      });
    }

    // copyPresentationAsync
    case getType(presentationActions.copyPresentationAsync.success): {
      return produce(state, (draftState) => {
        draftState.byId[action.payload.id] = action.payload;
      });
    }

    // fetchFolderAsync
    case getType(folderActions.fetchFolderAsync.success):
    case getType(folderActions.fetchVirtualFolderAsync.success): {
      const { presentations } = action.payload;
      if (presentations.length === 0) return state;

      return produce(state, (draftState) => {
        presentations.forEach((presentation) => {
          draftState.byId[presentation.id] = presentation;
        });
      });
    }

    // movePresentationToFolderAsync
    case getType(presentationActions.movePresentationToFolderAsync.request): {
      const { presentation, parentFolderId } = action.payload;
      return produce(state, (draftState) => {
        const draftPresentation = draftState.byId[presentation.id];
        if (!draftPresentation) return;
        draftPresentation.resource.parentFolderId = parentFolderId;
      });
    }

    // fetchPlaylistsAsync
    case getType(playlistActions.fetchPlaylistsAsync.success): {
      const playlists = action.payload;
      return produce(state, (draftState) => {
        playlists.forEach((playlist) => {
          playlist.items.forEach(({ presentation }) => {
            if (!presentation) return;
            draftState.byId[presentation.id] = presentation;
          });
        });
      });
    }

    default: {
      return state;
    }
  }
}
