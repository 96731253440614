import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const FolderCardOutline: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 166 98">
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#000"
        strokeOpacity=".7"
        strokeWidth="3"
        d="M44.716-1.5l11.856 7.444h95.83l3.557 1.924v4.578h3.153l1.594 1.49c.507.472.794 1.134.794 1.826h0v72.272l-5.796 4.879c-.45.379-1.02.587-1.61.587h0H1c-.69 0-1.315-.28-1.768-.732C-1.22 92.315-1.5 91.69-1.5 91h0V1c0-.69.28-1.315.732-1.768C-.315-1.22.31-1.5 1-1.5h43.716z"
        transform="translate(-2340 -646) translate(2343 649)"
      />
      <path
        stroke="#FFF"
        d="M44.14.5l11.856 7.444h95.9l2.063 1.116v5.386h4.364l1.018.951.159 71.706-5.084 4.28L1 91.5.5 1 44.14.5z"
        transform="translate(-2340 -646) translate(2343 649)"
      />
    </g>
  </SvgIcon>
);

export default FolderCardOutline;
