import FilterListIcon from '@material-ui/icons/FilterList';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import SearchIcon from '@material-ui/icons/Search';
import SwapVertReverseIcon from 'raydiant-elements/icons/SwapVertReverse';
import Heading5 from 'raydiant-elements/typography/Heading5';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import Form from 'raydiant-elements/core/Form';
import Button from 'raydiant-elements/core/Button';
import InputLabel from 'raydiant-elements/core/InputLabel';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Input from 'raydiant-elements/core/Input';
import Column from 'raydiant-elements/layout/Column';
import Spacer from 'raydiant-elements/layout/Spacer';
import Row from 'raydiant-elements/layout/Row';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import React, { FC, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import miraClient from '../../clients/miraClient';
import logger from '../../logger';
import { selectDomainForCurrentUser } from '../../selectors/v2/domains';
import { SortProfileOptions } from '../../utilities';
import * as actions from './actions';
import {
  selectProfileIds,
  selectMemberListSortOptions,
  selectMemberListSearchQuery,
} from './selectors';
import MemberAccountItem from './MemberAccountItem';

interface MemberAccountsProps {
  selectedProfileId: string | null;
  onEditProfile: (profileId: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      marginBottom: theme.spacing(1),
    },
  }),
);

const MemberAccounts: FC<MemberAccountsProps> = ({
  selectedProfileId,
  onEditProfile,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Selectors

  const sortOptions = useSelector(selectMemberListSortOptions);
  const searchQuery = useSelector(selectMemberListSearchQuery);
  const profileIds = useSelector(selectProfileIds);
  const domain = useSelector(selectDomainForCurrentUser);

  // State

  const [sortedProfileIds, setSortedProfileIds] =
    useState<string[]>(profileIds);
  const [inviteeEmail, setInviteeEmail] = useState<string | null>(null);
  const [inviteStatus, setInviteeStatus] = useState<
    '' | 'pending' | 'success' | 'error'
  >('');
  const [inviteErrorCode, setInviteeError] = useState<number | null>(null);
  const [isSortMode, setIsSortMode] = useState<boolean>(false);

  // Callbacks

  const sendDomainInvite = useCallback(async () => {
    try {
      if (!inviteeEmail) return;
      setInviteeStatus('pending');
      await miraClient.inviteToDomain(inviteeEmail);
      setInviteeStatus('success');
      setInviteeEmail(null);
    } catch (err) {
      logger.error(err);
      setInviteeStatus('error');
      setInviteeError(err.status);
    }
  }, [inviteeEmail]);

  const toggleSort = useCallback(
    (property: SortProfileOptions['property']) => {
      let direction: SortProfileOptions['direction'] = 'asc';

      if (property === sortOptions.property) {
        direction = sortOptions.direction === 'asc' ? 'desc' : 'asc';
      }

      dispatch(actions.setSortOptions({ property, direction }));
      setIsSortMode(false);
    },
    [dispatch, sortOptions],
  );

  const setSearchQuery = useCallback(
    (value: string) => {
      dispatch(actions.setSearchQuery(value));
    },
    [dispatch],
  );

  // Side-effects

  // Preserve sorting until sortOptions or searchQuery changes.
  useEffect(() => {
    setSortedProfileIds(profileIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOptions.property, sortOptions.direction, searchQuery]);

  // Reset error when masquerade email changes.
  useEffect(() => {
    setInviteeStatus('');
    setInviteeError(null);
  }, [inviteeEmail]);

  // Render

  let inviteeHelperText = 'Invite an account to join your Domain';
  if (inviteErrorCode === 404) {
    inviteeHelperText = 'A user with this email does not exist';
  }
  if (inviteErrorCode === 400) {
    inviteeHelperText = 'Could not invite user to domain';
  }
  if (inviteStatus === 'success') {
    inviteeHelperText = 'Invite sent!';
  }

  let sortLabel = 'Sort';
  if (sortOptions.property === 'name') {
    sortLabel = `${sortLabel}: Name`;
  } else if (sortOptions.property === 'email') {
    sortLabel = `${sortLabel}: Email`;
  } else if (sortOptions.property === 'domainRole') {
    sortLabel = `${sortLabel}: Role`;
  }

  const isSortByName = sortOptions.property === 'name';
  const isSortByEmail = sortOptions.property === 'email';
  const isSortByRole = sortOptions.property === 'domainRole';
  const isReverseSort = sortOptions.direction === 'desc';

  return (
    <>
      <Heading5 overline>Member Accounts</Heading5>
      <Column>
        <Form onSubmit={sendDomainInvite}>
          <InputLabel>Invitee email</InputLabel>
          <Row halfMargin>
            <Input
              type="email"
              value={inviteeEmail ?? ''}
              error={inviteStatus === 'error'}
              onChange={setInviteeEmail}
            />
            <Button
              label="Send"
              color="progress"
              type="submit"
              disabled={
                (inviteeEmail ?? '').length === 0 || inviteStatus === 'pending'
              }
            />
          </Row>
          <InputHelperText indent error={inviteStatus === 'error'}>
            {inviteeHelperText}
          </InputHelperText>
        </Form>
        <br />
        <div>
          <InputLabel>Enterprise members list</InputLabel>

          <ActionBar className={classes.actions}>
            <ActionBar.Select
              icon={<FilterListIcon />}
              label={sortLabel}
              open={isSortMode}
              onOpen={setIsSortMode}
            >
              <ActionBar.SelectOption
                icon={
                  isSortByName && isReverseSort ? (
                    <SwapVertReverseIcon />
                  ) : (
                    <SwapVertIcon />
                  )
                }
                label="Name"
                selected={isSortByName}
                onClick={() => toggleSort('name')}
              />
              <ActionBar.SelectOption
                icon={
                  isSortByEmail && isReverseSort ? (
                    <SwapVertReverseIcon />
                  ) : (
                    <SwapVertIcon />
                  )
                }
                label="Email"
                selected={isSortByEmail}
                onClick={() => toggleSort('email')}
              />
              <ActionBar.SelectOption
                icon={
                  isSortByRole && isReverseSort ? (
                    <SwapVertReverseIcon />
                  ) : (
                    <SwapVertIcon />
                  )
                }
                label="Role"
                selected={isSortByRole}
                onClick={() => toggleSort('domainRole')}
              />
            </ActionBar.Select>

            <Spacer />

            <ActionBar.Input
              type="search"
              label="Search"
              icon={<SearchIcon />}
              value={searchQuery}
              maxWidth={144}
              onChange={(value) => setSearchQuery(value)}
            />
          </ActionBar>

          {sortedProfileIds.map((profileId) => {
            if (!domain) return null;

            // Use the profile from the domain to render any updated profiles
            // without affecting sort order.
            const profile = domain.r.profiles.find((p) => p.id === profileId);

            if (!profile) return null;

            return (
              <MemberAccountItem
                key={profile.id}
                profile={profile}
                selected={selectedProfileId === profile.id}
                onClick={() => onEditProfile(profile.id)}
              />
            );
          })}
        </div>
      </Column>
    </>
  );
};

export default MemberAccounts;
