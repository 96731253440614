import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Center from 'raydiant-elements/layout/Center';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import mapStateToProps from './selectors';
import * as paths from '../../routes/paths';
import Page from '../../components/Page';

interface HomePageProps extends RouteComponentProps {
  homeTab: string | null;
}

interface HomePageState {}

class HomePage extends React.Component<HomePageProps, HomePageState> {
  render() {
    const { homeTab } = this.props;

    // homeTab is null if preferences is still loading.
    if (homeTab === null) {
      return (
        <Page>
          <Center>
            <CircularProgress size={30} />
          </Center>
        </Page>
      );
    }

    if (homeTab === 'library') {
      return <Redirect to={paths.library()} />;
    }

    if (homeTab === 'account') {
      return <Redirect to={paths.account()} />;
    }

    return <Redirect to={paths.screens()} />;
  }
}

export default connect(mapStateToProps)(HomePage);
