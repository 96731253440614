import { all } from 'redux-saga/effects';
import { applicationPageSagas } from '../pages/ApplicationPage';
import { applicationsPageSagas } from '../pages/ApplicationsPage';
import { devicesPageSagas } from '../pages/DevicesPage';
import { libraryPageSagas } from '../pages/LibraryPage';
import { presentationPageSagas } from '../pages/PresentationPage';
import { addContentPageSagas } from '../pages/AddContentPage';
import { accountPageSagas } from '../pages/AccountPage';
import analyticsSagas from './analytics';
import chargebeeSagas from './chargebee';
import deviceSagas from './device';
import fileUploadSagas from './fileUpload';
import folderSagas from './folders';
import historySagas from './history';
import pairingCodeSagas from './pairingCode';
import playlistSagas from './playlists';
import presentationSagas from './presentation';
import applicationSagas from './application';
import applicationVersionSagas from './applicationVersions';
import soundZoneSagas from './soundZone';
import themesSagas from './themes';
import unauthorizedRequestsSagas from './unauthorizedRequests';
import userSagas from './user';
import domainsSagas from './domains';
import playbackReportSagas from './playbackReports';

export default function* rootSaga() {
  yield all([
    analyticsSagas,
    applicationPageSagas,
    applicationsPageSagas,
    devicesPageSagas,
    libraryPageSagas,
    presentationPageSagas,
    addContentPageSagas,
    accountPageSagas,
    chargebeeSagas,
    deviceSagas,
    fileUploadSagas,
    folderSagas,
    historySagas,
    pairingCodeSagas,
    presentationSagas,
    applicationSagas,
    applicationVersionSagas,
    playlistSagas,
    soundZoneSagas,
    themesSagas,
    unauthorizedRequestsSagas,
    userSagas,
    domainsSagas,
    playbackReportSagas,
  ]);
}
