import { call, put, fork, take, all } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as applicationsActions from '../actions/applications';
import * as A from '../clients/mira/types/Application';
import miraClient from '../clients/miraClient';
import logger from '../logger';

export const fetchApplications = function* () {
  try {
    yield put(applicationsActions.fetchApplicationsAsync.request());
    const data: A.Application[] = yield call(() =>
      miraClient.getApplications(),
    );
    yield put(applicationsActions.fetchApplicationsAsync.success(data));
    return data;
  } catch (error) {
    logger.error(error);
    yield put(applicationsActions.fetchApplicationsAsync.failure(error));
  }
};

const watchFetchApplications = function* () {
  while (true) {
    yield take(getType(applicationsActions.fetchApplications));
    yield fork(fetchApplications);
  }
};

export default all([fork(watchFetchApplications)]);
