import { ApiClientError } from '@raydiant/api-client-js';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import raydiant from '../clients/raydiant';
import * as playlistActions from '../actions/playlists';
import { keys } from '../queryClient';
import useProtectedMutation from './useProtectedMutation';

export default function useDeletePlaylist() {
  const dispatchRedux = useDispatch();
  const queryClient = useQueryClient();

  const mutation = useProtectedMutation<
    // TODO: Why does deletePlaylist return a string?
    string,
    ApiClientError,
    { playlistId: string }
  >(({ playlistId }) => raydiant.deletePlaylist(playlistId), {
    onSuccess: (_, { playlistId }) => {
      // Update react-query cache.
      queryClient.removeQueries(keys.playlist(playlistId));

      // Update redux cache for /screens and /library.
      dispatchRedux(playlistActions.deletePlaylistAsync.success(playlistId));
    },
  });

  return mutation;
}
