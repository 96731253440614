import unionBy from 'lodash/unionBy';

export type RequestState = Readonly<{
  id: string;
  isRequesting: boolean;
  error: string;
}>;

const initialState = (id: string) => ({
  id,
  isRequesting: false,
  error: '',
});

export const setRequest = (state: RequestState) => ({
  ...state,
  isRequesting: true,
  error: '',
});

export const setRequestSuccess = (state: RequestState) => ({
  ...state,
  isRequesting: false,
  error: '',
});

export const setRequestFailure = (state: RequestState, error: string) => ({
  ...state,
  isRequesting: false,
  error,
});

export const setRequestItem = (state: RequestState[], id: string) => {
  const item = state.find((request) => request.id === id) || initialState(id);
  return unionBy([setRequest(item)], state, 'id');
};

export const setRequestItemSuccess = (state: RequestState[], id: string) => {
  const item = state.find((request) => request.id === id) || initialState(id);
  return unionBy([setRequestSuccess(item)], state, 'id');
};

export const setRequestItemFailure = (
  state: RequestState[],
  id: string,
  error: string,
) => {
  const item = state.find((request) => request.id === id) || initialState(id);
  return unionBy([setRequestFailure(item, error)], state, 'id');
};
