import { Theme } from 'raydiant-elements/theme';
import { makeStyles, createStyles } from 'raydiant-elements/styles';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `3px dashed ${theme.dropzone.border}`,
      borderRadius: theme.borderRadius.sm,
      margin: theme.spacing(4),

      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(2),
      },
    },

    isOver: {
      backgroundColor: theme.palette.action.selected,
      borderColor: theme.palette.action.selected,
    },
  });
});
