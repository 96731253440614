import { PlaybackReport } from '@raydiant/api-client-js';
import { RootState } from '../../reducers';

export interface PlaybackReportsById {
  [reportId: string]: PlaybackReport | undefined;
}

export const selectPlaybackReportsById = (
  state: RootState,
): PlaybackReportsById => state.v2PlaybackReports.byId;
