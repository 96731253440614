import * as React from 'react';
import { connect } from 'react-redux';
import {
  Application,
  Profile,
  ApplicationVariables,
} from '@raydiant/api-client-js';
import history from '../../history';
import * as presentationPageActions from '../PresentationPage/actions';
import * as paths from '../../routes/paths';
import { ThemesById } from '../../selectors/v2/themes';
import {
  createPresentationFromAppVersion,
  getDefaultThemeForUser,
} from '../../utilities';
import * as applicationPageActions from './actions';
import mapStateToProps from './selectors';
import ApplicationInvalidPermissions from './ApplicationInvalidPermissions';

export interface ApplicationPageProps {
  isLoading: boolean;
  application?: Application;
  applicationId?: string;
  currentUser?: Profile;
  themesById: ThemesById;
  applicationVariables?: ApplicationVariables;
  loadApplicationPage: () => void;
  setUnsavedPresentation: typeof presentationPageActions.setUnsavedPresentation;
  clearUnsavedPresentation: typeof presentationPageActions.clearUnsavedPresentation;
}

class ApplicationPage extends React.Component<ApplicationPageProps> {
  componentDidMount() {
    this.props.loadApplicationPage();
    this.checkApplicationAndRedirect();
  }

  componentDidUpdate() {
    this.checkApplicationAndRedirect();
  }

  checkApplicationAndRedirect() {
    const {
      application,
      isLoading,
      applicationVariables,
      setUnsavedPresentation,
      currentUser,
      clearUnsavedPresentation,
      themesById,
    } = this.props;

    if (isLoading || !application || !currentUser) {
      return null;
    }

    if (applicationVariables) {
      const defaultPresentation = createPresentationFromAppVersion(
        application.currentAppVersion,
        currentUser.id,
        getDefaultThemeForUser(currentUser, themesById),
      );

      setUnsavedPresentation({
        ...defaultPresentation,
        applicationVariables: {
          ...defaultPresentation.applicationVariables,
          ...applicationVariables,
        },
      });
    }

    clearUnsavedPresentation(application.id);

    history.replace(
      paths.newPresentation({
        applicationId: application.id,
        backTo: paths.library(),
        backToLabel: 'Back to Library',
        saveTo: paths.library(),
      }),
    );
  }

  render() {
    const { application, applicationId, isLoading, currentUser } = this.props;
    if (!isLoading && currentUser && !application) {
      return <ApplicationInvalidPermissions applicationId={applicationId} />;
    }

    return null;
  }
}

export default connect(mapStateToProps, {
  ...presentationPageActions,
  ...applicationPageActions,
})(ApplicationPage);
