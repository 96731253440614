import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useQueryParams() {
  const location = useLocation();
  return useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return {
      applicationId: searchParams.get('applicationId') ?? '',
      version: searchParams.get('version') ?? '',
      folderId: searchParams.get('folderId') ?? '',
      selectionId: searchParams.get('selectionId') ?? '',
      saveTo: searchParams.get('saveTo') ?? '',
      backTo: searchParams.get('backTo') ?? '',
      backToLabel: searchParams.get('backToLabel') ?? '',
      previewMode:
        searchParams.get('previewMode') === 'vertical'
          ? 'vertical'
          : 'horizontal',
      didSave: searchParams.get('didSave') === 'true',
      sessionId: searchParams.get('sessionId') ?? undefined,
    };
  }, [location.search]);
}
