import { all, call, fork, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { locationChanged } from '../../actions/history';

function* page() {
  yield call(() => analytics.page());
}

function* pageOnLocationChanged() {
  while (true) {
    yield take(getType(locationChanged));
    yield call(page);
  }
}

export default all([fork(page), fork(pageOnLocationChanged)]);
