import React, { FC, MouseEventHandler } from 'react';
import Row from 'raydiant-elements/layout/Row';
import usePlaylistPageContext from './usePlaylistPageContext';
import PlaylistItemMoreActionsButton from './PlaylistItemMoreActionsButton';

interface PlaylistItemPresentationActionsProps {
  moreActionsOpen?: boolean;
  onMoreClick?: MouseEventHandler<HTMLButtonElement>;
}

const PlaylistItemPresentationActions: FC<
  PlaylistItemPresentationActionsProps
> = ({ moreActionsOpen, onMoreClick }) => {
  const { classes } = usePlaylistPageContext();

  return (
    <Row inline halfMargin center className={classes.playlistItemActions}>
      {onMoreClick && (
        <div>
          <PlaylistItemMoreActionsButton
            selected={moreActionsOpen}
            onClick={onMoreClick}
          />
        </div>
      )}
    </Row>
  );
};

export default PlaylistItemPresentationActions;
