import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { SortProfileOptions } from '../../utilities';

export type ShareResourceActions = ActionType<typeof actions>;

export type DevicesPageState = Readonly<{
  sortOptions: SortProfileOptions;
  searchQuery: string;
}>;

const initialDevicesPageState: DevicesPageState = {
  sortOptions: { property: 'name', direction: 'asc' },
  searchQuery: '',
};

export default function shareResourceReducer(
  state = initialDevicesPageState,
  action: ShareResourceActions,
): DevicesPageState {
  switch (action.type) {
    case getType(actions.setSortOptions):
      return {
        ...state,
        sortOptions: action.payload,
      };

    case getType(actions.setSearchQuery):
      return {
        ...state,
        searchQuery: action.payload,
      };

    default:
      return state;
  }
}
