import { createSelector } from 'reselect';
import { RootState } from '../../reducers';
import * as PL from '../../clients/mira/types/Playlist';
import { isNotNullOrUndefined, groupResourcesByOwner } from '../../utilities';
import { LocalFileUploads } from '../../types';
import {
  selectFileStatus as selectPresentationFileStatus,
  PresentationsById,
} from './presentations';
import { ThemesById } from './themes';

export interface PlaylistsById {
  [playlistId: string]: PL.Playlist | undefined;
}

export const selectPlaylistsById = (state: RootState): PlaylistsById =>
  state.v2Playlists.byId;

export const selectPlaylists = createSelector(
  [selectPlaylistsById],
  (playlistById): PL.Playlist[] => {
    return Object.values(playlistById).filter(isNotNullOrUndefined);
  },
);

export const selectNonScheduledPlaylists = createSelector(
  [selectPlaylists],
  (playlists): PL.Playlist[] => {
    return playlists.filter((pl) => !pl.startDatetime);
  },
);

const maxDateString = (a: string, b: string) => (a > b ? a : b);

export const selectPlaylistUpdatedAt = (
  playlistId: string,
  playlistsById: PlaylistsById,
  presentationsById: PresentationsById,
  themesById: ThemesById,
  traversedPlaylists: { [playlistId: string]: boolean } = {},
): string => {
  const minDate = new Date('1/1/1900').toISOString();
  const playlist = playlistsById[playlistId];

  if (!playlist || traversedPlaylists[playlist.id]) return minDate;

  // Prevent infinite loop traversing playlists.
  traversedPlaylists[playlist.id] = true;

  // Traverse playlist items to find any updated presentations or playlists
  const itemsUpdatedAt = playlist.items
    .map((item) => {
      if (item.presentationId) {
        const presentation = presentationsById[item.presentationId];
        if (!presentation) return minDate;

        // Traverse presentation properties to find any updated playlist.
        const propsUpdatedAt = presentation.presentationProperties
          .map((prop) => {
            const propValue = presentation.applicationVariables[prop.name];

            if (prop.type === 'playlist') {
              return selectPlaylistUpdatedAt(
                propValue,
                playlistsById,
                presentationsById,
                themesById,
                traversedPlaylists,
              );
            } else if (
              prop.type === 'array' &&
              Array.isArray(prop.properties) &&
              Array.isArray(propValue)
            ) {
              const arrayPlaylistProps = prop.properties.filter(
                (p) => p.type === 'playlist',
              );

              if (arrayPlaylistProps.length > 0 && propValue.length > 0) {
                // Traverse all playlists properties inside the array input.
                return arrayPlaylistProps
                  .map((arrayPlaylistProp) => {
                    // Traverse all playlist values for the playlist property.
                    return propValue
                      .map((arrayPropValue) => {
                        const arrayPlaylistPropValue =
                          arrayPropValue[arrayPlaylistProp.name];

                        return selectPlaylistUpdatedAt(
                          arrayPlaylistPropValue,
                          playlistsById,
                          presentationsById,
                          themesById,
                          traversedPlaylists,
                        );
                      })
                      .reduce(maxDateString, minDate);
                  })
                  .reduce(maxDateString, minDate);
              }
            }

            return minDate;
          })
          .reduce(maxDateString, minDate);

        // Use the presentation's deletedAt value if it exists as the updatedAt value.
        const presentationUpdatedAt =
          presentation.resource.deletedAt || presentation.resource.updatedAt;

        const theme = presentation.themeId && themesById[presentation.themeId];
        const themeUpdatedAt = theme ? theme.resource.updatedAt : minDate;

        return [presentationUpdatedAt, themeUpdatedAt, propsUpdatedAt].reduce(
          (a, b) => (a > b ? a : b),
        );
      } else if (item.playlistId) {
        return selectPlaylistUpdatedAt(
          item.playlistId,
          playlistsById,
          presentationsById,
          themesById,
          traversedPlaylists,
        );
      }
      return minDate;
    })
    .reduce(maxDateString, minDate);

  return playlist.resource.updatedAt > itemsUpdatedAt
    ? playlist.resource.updatedAt
    : itemsUpdatedAt;
};

export const selectFileStatus = (
  playlist: PL.Playlist,
  presentationsById: PresentationsById,
  localUploads: LocalFileUploads,
) => {
  let isUploading = false;
  let hasError = false;

  for (const { presentationId } of playlist.items) {
    if (!presentationId) continue;

    const presentation = presentationsById[presentationId];
    if (!presentation) continue;
    if (presentation.resource.deletedAt !== null) continue;

    const fileStatus = selectPresentationFileStatus(presentation, localUploads);

    isUploading = isUploading || fileStatus.isUploading;
    hasError = hasError || fileStatus.hasError;
  }

  return { isUploading, hasError };
};

export const selectPresentationIds = (
  playlist: PL.Playlist,
  playlistsById: PlaylistsById,
) => {
  const presentationIds: string[] = [];

  playlist.items.forEach((item) => {
    if (item.presentationId) {
      presentationIds.push(item.presentationId);
    }

    if (item.playlistId) {
      const nestedPlaylist = playlistsById[item.playlistId];
      if (!nestedPlaylist) return;

      nestedPlaylist.items.forEach((nestedItem) => {
        if (nestedItem.presentationId) {
          presentationIds.push(nestedItem.presentationId);
        }
      });
    }
  });

  return presentationIds;
};

export const selectPlaylistsByOwner = createSelector(
  [selectPlaylistsById],
  (playlistsById) => {
    return groupResourcesByOwner({
      playlists: Object.values(playlistsById) as PL.Playlist[],
    });
  },
);

export const selectNonScheduledPlaylistsByOwner = createSelector(
  [selectNonScheduledPlaylists],
  (playlists) => {
    return groupResourcesByOwner({ playlists });
  },
);
