import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  Device,
  RegisterDevice,
  ResourceACL,
  RecentDeviceError,
} from '@raydiant/api-client-js';

export interface MetaDevice {
  onUpdate?: (device: Device) => void;
  onDelete?: () => void;
  onMove?: () => void;
  onRegister?: (device: Device) => void;
  onError?: (error: any) => void;
  onShare?: (resourceACL: ResourceACL) => void;
}

export const clearPublishing = createStandardAction(
  '@DEVICE/CLEAR_PUBLISHING',
)();

export const publishAll = createStandardAction('@DEVICE/PUBLISH_ALL')<
  string[]
>();

export const clearRegisterDeviceError = createStandardAction(
  '@DEVICE/CLEAR_REGISTER_ERROR',
)();

export const fetchDevice = createStandardAction('@DEVICE/FETCH')<string>();

export const publishDevice = createStandardAction('@DEVICE/PUBLISH')<string>();

export const registerDevice = createStandardAction('@DEVICE/REGISTER')<
  RegisterDevice & { isAudioOnly: boolean },
  MetaDevice
>();

export const updateDevice = createStandardAction('@DEVICE/UPDATE')<
  Device,
  MetaDevice
>();

export const restartDevice = createStandardAction('@DEVICE/RESTART')<string>();

export const fetchDevices = createStandardAction('@DEVICE/FETCH_DEVICES')();

export const fetchDevicesAsync = createAsyncAction(
  '@DEVICE/FETCH_ALL_REQUEST',
  '@DEVICE/FETCH_ALL_SUCCESS',
  '@DEVICE/FETCH_ALL_FAILURE',
)<void, Device[], Error>();

export const fetchDeviceAsync = createAsyncAction(
  '@DEVICE/FETCH_REQUEST',
  '@DEVICE/FETCH_SUCCESS',
  '@DEVICE/FETCH_FAILURE',
)<string, Device, { error: Error; id: string }>();

export const publishDeviceAsync = createAsyncAction(
  '@DEVICE/PUBLISH_REQUEST',
  '@DEVICE/PUBLISH_SUCCESS',
  '@DEVICE/PUBLISH_FAILURE',
)<string, Device, { error: Error; id: string }>();

export const registerDeviceAsync = createAsyncAction(
  '@DEVICE/REGISTER_REQUEST',
  '@DEVICE/REGISTER_SUCCESS',
  '@DEVICE/REGISTER_FAILURE',
)<RegisterDevice, Device, RegisterDevice & { error: Error }>();

export const updateDeviceAsync = createAsyncAction(
  '@DEVICE/UPDATE_REQUEST',
  '@DEVICE/UPDATE_SUCCESS',
  '@DEVICE/UPDATE_FAILURE',
)<Device, Device, { error: Error; id: string }>();

export const restartDeviceAsync = createAsyncAction(
  '@DEVICE/RESTART_REQUEST',
  '@DEVICE/RESTART_SUCCESS',
  '@DEVICE/RESTART_FAILURE',
)<string, string, { id: string; error: Error }>();

export const moveDeviceToFolderAsync = createAsyncAction(
  '@DEVICE/MOVE_REQUEST',
  '@DEVICE/MOVE_SUCCESS',
  '@DEVICE/MOVE_FAILURE',
)<string, string, Error>();

export const getPublishStatus = createStandardAction(
  '@DEVICE/GET_PUBLISH_STATUS',
)();

export const shareDevice = createStandardAction('@DEVICE/SHARE_DEVICE')<
  {
    deviceId: string;
    profileId: string;
  },
  MetaDevice
>();

export const addDeviceResourceACL = createStandardAction(
  '@DEVICE/CREATE_RESOURCE_ACL',
)<{
  deviceId: string;
  resourceACL: ResourceACL;
}>();

export const removeDeviceResourceACL = createStandardAction(
  '@DEVICE/REMOVE_DEVICE_RESOURCE_ACL',
)<{
  deviceId: string;
  aclId: string;
}>();

export const removeDevice = createStandardAction('@DEVICE/REMOVE_DEVICE')<{
  deviceId: string;
}>();

export const pollDevicesWithPendingPublish = createStandardAction(
  '@DEVICE/POLL_DEVICES_WITH_PENDING_PUBLISH',
)<{ deviceIds: string[]; lastLoadedDate: string }>();

export const fetchRecentDeviceErrors = createStandardAction(
  '@DEVICE/FETCH_RECENT_ERRORS',
)();

export const fetchRecentDeviceErrorsAsync = createAsyncAction(
  '@DEVICE/FETCH_RECENT_ERRORS_REQUEST',
  '@DEVICE/FETCH_RECENT_ERRORS_SUCCESS',
  '@DEVICE/FETCH_RECENT_ERRORS_FAILURE',
)<void, RecentDeviceError[], Error>();
