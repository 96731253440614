import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import { Device, RecentDeviceError } from '@raydiant/api-client-js';
import Icon from 'raydiant-elements/core/Icon';
import Link from 'raydiant-elements/core/Link';
import SVGIcon from 'raydiant-elements/core/SVGIcon';
import Text from 'raydiant-elements/typography/Text';
import Row from 'raydiant-elements/layout/Row';
import EthernetIcon from 'raydiant-elements/icons/Ethernet';
import AlertIcon from 'raydiant-elements/core/AlertIcon';
import EthernetAbsentIcon from 'raydiant-elements/icons/EthernetAbsent';
import LTEIcon from 'raydiant-elements/icons/LTE';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { buttonReset } from 'raydiant-elements/mixins';
import { Theme } from 'raydiant-elements/theme';
import React, { useRef, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { getDeviceConnectionStatus } from '../../utilities';
import { ContentWarning } from './getDeviceContentWarning';
import DeviceStatusPopover from './DeviceStatusPopover';
import * as errorTypes from './deviceErrorTypes';

interface DeviceInfoProps {
  device: Device;
  lastLoadedDate: string;
  muted?: boolean;
  contentWarning: ContentWarning | null;
  deviceErrors: RecentDeviceError[];
  openStatusPopover?: boolean;
  onOpenStatusPopover?: (show: boolean) => void;
}

const DeviceInfo = ({
  device,
  lastLoadedDate,
  muted,
  contentWarning,
  deviceErrors,
  openStatusPopover = false,
  onOpenStatusPopover,
}: DeviceInfoProps) => {
  const classes = useStyles();

  // Refs

  const statusPopoverAnchorRef = useRef<HTMLButtonElement | null>(null);

  // Memoizers

  const deviceError = useMemo(() => {
    // A window_end_error takes precendence over a preload error.
    const windowEndError = deviceErrors.find(
      (e) => e.event === errorTypes.windowEndError,
    );
    if (windowEndError) return windowEndError;
    const windowPreloadError = deviceErrors.find(
      (e) => e.event === errorTypes.windowPreloadError,
    );
    if (windowPreloadError) return windowPreloadError;
    return null;
  }, [deviceErrors]);

  // Callbacks

  const openConnectivityWizard = useCallback(() => {
    window.Intercom('showNewMessage');
  }, []);

  // Render

  const {
    isEthernet,
    isLTE,
    isOnline,
    signalStrength = 0,
    lastMetricTime,
  } = getDeviceConnectionStatus(device, lastLoadedDate);

  const isSignalStrengthWeak =
    !device.isLite && !isEthernet && signalStrength < 60;

  const isPreBelenaDevice = !device.resinUuid && !device.isLite;
  if (isPreBelenaDevice) return <>&nbsp;</>;

  const renderSignalStrength = () => {
    if (isEthernet) {
      if (!isOnline) return <EthernetAbsentIcon className={classes.muted} />;
      return <EthernetIcon className={classes.muted} />;
    }

    if (isLTE) {
      if (!isOnline) return <LTEIcon className={classes.muted} />;
      if (signalStrength > 80)
        return <Icon icon="lteFull" className={classes.muted} />;
      if (signalStrength > 40)
        return <Icon icon="lteMedium" className={classes.muted} />;
      if (signalStrength > 0)
        return <Icon icon="lteLow" className={classes.muted} />;
      if (signalStrength === 0)
        return <Icon icon="lteNone" className={classes.muted} />;
    }

    if (!isOnline) return <WifiOffIcon className={classes.muted} />;
    if (signalStrength > 80)
      return <SVGIcon icon="wifiFull" className={classes.muted} />;
    if (signalStrength > 50)
      return <SVGIcon icon="wifiHigh" className={classes.muted} />;
    if (signalStrength > 20)
      return <SVGIcon icon="wifiMedium" className={classes.muted} />;
    if (signalStrength > 0)
      return <SVGIcon icon="wifiLow" className={classes.muted} />;
    if (signalStrength === 0)
      return <SVGIcon icon="wifiNone" className={classes.muted} />;

    // TODO: Unknown connection status, possibly still fetching or failed.
    // Consider showing a loading indicator.
    return <SVGIcon icon="wifiNone" className={classes.muted} />;
  };

  const renderStatusIcon = () => {
    if (
      !isOnline ||
      deviceError?.event === errorTypes.windowEndError ||
      contentWarning?.severity === 'error'
    ) {
      return <AlertIcon color="error" />;
    } else if (
      isSignalStrengthWeak ||
      deviceError?.event === errorTypes.windowPreloadError ||
      contentWarning?.severity === 'warning'
    ) {
      return <AlertIcon color="warning" />;
    } else {
      return <CheckCircleIcon className={classes.muted} />;
    }
  };

  const renderStatusText = () => {
    if (!isOnline) {
      return (
        <Text small className={classes.muted}>
          Offline{' '}
          <Link
            className={cn('intercom-connectivity-wizard', classes.link)}
            onClick={openConnectivityWizard}
          >
            Need Help?
          </Link>
        </Text>
      );
    } else if (isSignalStrengthWeak) {
      return (
        <Text small className={classes.muted}>
          Online, but weak {isLTE ? 'LTE' : isEthernet ? '' : 'wifi'} connection
        </Text>
      );
    } else {
      return (
        <Text small className={classes.muted}>
          Online
        </Text>
      );
    }
  };

  const renderStatusPopover = () => {
    if (!onOpenStatusPopover) return null;

    return (
      <DeviceStatusPopover
        open={openStatusPopover}
        device={device}
        isOnline={isOnline}
        lastMetricTime={lastMetricTime}
        contentWarning={contentWarning}
        deviceError={deviceError}
        anchorEl={statusPopoverAnchorRef.current}
        onClose={() => onOpenStatusPopover(false)}
      />
    );
  };

  return (
    <Row
      halfMargin
      center
      className={cn(classes.root, muted && classes.isMuted)}
    >
      {!device.isLite && renderSignalStrength()}

      {onOpenStatusPopover ? (
        <button
          ref={statusPopoverAnchorRef}
          className={classes.statusButton}
          onClick={() => onOpenStatusPopover(true)}
        >
          {renderStatusIcon()}
        </button>
      ) : (
        renderStatusIcon()
      )}

      {renderStatusText()}
      {renderStatusPopover()}
    </Row>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      color: 'inherit',
    },

    isMuted: {
      '& $muted': {
        opacity: 0.6,
      },
    },

    muted: {},

    info: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(0.5),
      position: 'relative',
    },

    link: {
      color: 'inherit',
      fontSize: theme.fontSizes.xs,
      marginLeft: theme.spacing(0.5),
    },

    weak: {
      color: '#f4eba1',
    },

    statusButton: {
      display: 'flex',
      ...buttonReset(),
    },

    // TODO: Remove duplication from DeviceSharing.tsx
    popover: {
      backgroundColor: theme.modal.overlayBackground,

      '& $popoverPaper': {
        borderRadius: theme.borderRadius.md,
        padding: theme.spacing(2),
        maxWidth: 300,
      },
    },

    popoverPaper: {},
  });
});

export default DeviceInfo;
