import { Tag } from '@raydiant/api-client-js';
import deepEqual from 'fast-deep-equal';

export const areTagsEqual = (
  left: Array<DeepPartial<Tag>>,
  right: Array<DeepPartial<Tag>>,
) => {
  // We only need to compare the tag key and value to know if tags have changed.
  const leftTags = left.map((t) => [t.key, t.value]);
  const rightTags = right.map((t) => [t.key, t.value]);
  return deepEqual(leftTags, rightTags);
};

export const hasValidTags = (tags: Tag[]) =>
  tags.findIndex((t) => !t.value) === -1;
