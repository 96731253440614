import { createSelector } from 'reselect';
import { selectDomainForCurrentUser } from '../../selectors/v2/domains';
import {
  sortProfiles,
  searchProfiles,
  canEditResource,
  isDevicePlaybackEventsSupported,
} from '../../utilities';
import { RootState } from '../../reducers';
import { selectDevicesByOwner } from '../../selectors/v2/devices';
import { selectUserProfile } from '../../selectors/user';
import { selectOtherDomainProfiles } from '../../selectors/v2/domains';

export const selectMemberListSortOptions = (state: RootState) =>
  state.accountPage.memberListSortOptions;

export const selectMemberListSearchQuery = (state: RootState) =>
  state.accountPage.memberListSearchQuery;

export const selectProfileIds = createSelector(
  [
    selectDomainForCurrentUser,
    selectMemberListSortOptions,
    selectMemberListSearchQuery,
  ],
  (domain, sortOptions, searchQuery) => {
    if (!domain) return [];
    const profiles = searchProfiles(domain.r.profiles, searchQuery);
    return sortProfiles(profiles, sortOptions).map((p) => p.id);
  },
);

export const selectReportableDevices = createSelector(
  [selectUserProfile, selectDevicesByOwner],
  (currentUser, devicesByOwner) => {
    if (!currentUser) return [];
    return (devicesByOwner[currentUser.id]?.devices || []).filter(
      (device) =>
        device.resource.deletedAt === null &&
        isDevicePlaybackEventsSupported(device),
    );
  },
);

export const selectReportableDevicesByProfile = createSelector(
  [selectUserProfile, selectOtherDomainProfiles, selectDevicesByOwner],
  (currentUser, domainProfiles, devicesByOwner) => {
    return domainProfiles.map((profile) => {
      return {
        profile,
        devices: (devicesByOwner[profile.id]?.devices || []).filter(
          (device) =>
            device.resource.deletedAt === null &&
            isDevicePlaybackEventsSupported(device) &&
            currentUser &&
            canEditResource(currentUser, device.resource),
        ),
      };
    });
  },
);
