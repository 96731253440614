import { createSelector } from 'reselect';
import { RootState } from '../../reducers';
import { Device, RecentDeviceError } from '@raydiant/api-client-js';
import { groupResourcesByOwner, canEditResource } from '../../utilities';
import { selectUserProfile } from '../user';
import { selectLocalUploads } from '../fileUploads';
import { selectPresentationsById } from './presentations';
import { selectThemesById } from './themes';
import {
  selectPlaylistsById,
  selectFileStatus,
  selectPlaylistUpdatedAt,
} from './playlists';

export interface DevicesById {
  [deviceId: string]: Device | undefined;
}

export type DeviceStatus =
  | 'fetching'
  | 'updating'
  | 'publishing'
  | 'success'
  | 'error';

export interface DeviceStatusById {
  [deviceId: string]: DeviceStatus;
}

export interface DeviceErrorsById {
  [deviceId: string]: RecentDeviceError[];
}

export const selectDevicesById = (state: RootState): DevicesById =>
  state.v2Devices.byId;

export const selectDeviceStatusById = (state: RootState): DeviceStatusById =>
  state.v2Devices.statusById;

export const selectDeviceErrorsById = (state: RootState): DeviceErrorsById =>
  state.v2Devices.recentErrorsById;

export const selectDevicesByOwner = createSelector(
  [selectDevicesById],
  (devicesById) => {
    return groupResourcesByOwner({
      devices: Object.values(devicesById) as Device[],
    });
  },
);

export type PlaylistStatus =
  | 'pendingUpload'
  | 'uploadError'
  | 'readyForPublish';

export interface PlaylistStatusById {
  [deviceId: string]: PlaylistStatus;
}

export const selectPlaylistStatusByDeviceId = createSelector(
  [
    selectDevicesById,
    selectPlaylistsById,
    selectPresentationsById,
    selectThemesById,
    selectLocalUploads,
    selectUserProfile,
  ],
  (
    devicesById,
    playlistsById,
    presentationsById,
    themesById,
    localUploads,
    currentUser,
  ): PlaylistStatusById => {
    const playlistStatusByDeviceId: PlaylistStatusById = {};

    Object.values(devicesById).forEach((device) => {
      if (!device || !currentUser) return;
      if (!canEditResource(currentUser, device.resource)) return;
      if (device.isAudioOnly) return;

      const devicePublishedAt =
        device.publishedAt || new Date('1/1/1900').toISOString();

      const deviceNeedsPublish = device.resource.updatedAt > devicePublishedAt;

      let playlistsNeedsPublish = false;
      let playlistHasPendingUpload = false;
      let playlistHasUploadError = false;

      if (device.playlistId && playlistsById) {
        const playlistUpdatedAt = selectPlaylistUpdatedAt(
          device.playlistId,
          playlistsById,
          presentationsById,
          themesById,
        );

        const playlist = playlistsById[device.playlistId];
        if (playlist) {
          const { hasError, isUploading } = selectFileStatus(
            playlist,
            presentationsById,
            localUploads,
          );

          playlistHasPendingUpload = isUploading;
          playlistHasUploadError = hasError;
        }

        playlistsNeedsPublish = playlistUpdatedAt > devicePublishedAt;
      }

      if (playlistHasUploadError) {
        playlistStatusByDeviceId[device.id] = 'uploadError';
      } else if (playlistHasPendingUpload) {
        playlistStatusByDeviceId[device.id] = 'pendingUpload';
      } else if (deviceNeedsPublish || playlistsNeedsPublish) {
        playlistStatusByDeviceId[device.id] = 'readyForPublish';
      }
    });

    return playlistStatusByDeviceId;
  },
);

export const selectHasPublishableDevices = createSelector(
  [selectPlaylistStatusByDeviceId],
  (playlistStatusById) => {
    return (
      Object.values(playlistStatusById).filter(
        (status) => status === 'readyForPublish',
      ).length > 0
    );
  },
);
