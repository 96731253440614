import { State } from '../playlistPageTypes';

const initialState: State = {
  sessionId: '',
  updatedPlaylistsById: {},
  updatedPresentationsById: {},
  newPlaylistsById: {},
  savedPlaylistsById: {},
  savedPartialPlaylistsById: {},
  savedPresentationsById: {},
  pendingPlaylistItems: {},
  expandedPlaylistIds: {},
  expandedItems: {},
  selectedItems: {},
  addedPresentationIds: {},
  addedPlaylistIds: {},
  lastSelectedPath: null,
  itemIndexesByItemIdPath: {},
  itemIdsByPlaylistId: {},
  itemIdsByPresentationId: {},
  isDirtyById: {},
  errorsById: {},
  previewItem: null,
  moreActionsItem: null,
  moreActionsItemPath: null,
  moreActionsAnchorEl: null,
  moreActionsParentPlaylist: null,
  modalItem: null,
  modalItemPath: null,
  modalItemMode: null,
  itemEditNamePath: null,
};

export default initialState;
