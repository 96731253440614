import * as A from '../../clients/mira/types/Application';

export default function getMimeTypesForAppVersion(
  appVersion: A.ApplicationVersion,
) {
  const fileTypeProperty = appVersion.presentationProperties.find(
    (prop) => prop.type === 'file',
  );

  const contentTypes = fileTypeProperty?.constraints?.['content-types'];

  return contentTypes || [];
}
