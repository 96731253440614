import { createStandardAction } from 'typesafe-actions';
import { SortFolderOptions } from '../../utilities';

export const setSortOptions = createStandardAction(
  '@PLAYLIST_SELECtOR/SET_SORT_OPTIONS',
)<SortFolderOptions>();

export const setSearchQuery = createStandardAction(
  '@PLAYLIST_SELECtOR/SET_SEARCH_QUERY',
)<string>();
