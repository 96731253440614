import Logo from 'raydiant-elements/core/Logo';
import { Theme } from 'raydiant-elements/theme';
import * as React from 'react';
import withStyles, { createStyles, WithStyles } from '../withStyles';

interface FloatingLogoProps extends WithStyles<typeof styles> {
  children?: React.ReactNode;
}

const FloatingLogo: React.SFC<FloatingLogoProps> = ({ classes }) => (
  <div className={classes.root}>
    <Logo />
  </div>
);

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      position: 'absolute',
      top: 0,
      left: 0,
    },
  });

export default withStyles(styles)(FloatingLogo);
