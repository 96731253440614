import React, { FC } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import InputLabel from 'raydiant-elements/core/InputLabel';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './RuleTokenInput.styles';

interface AddRuleButtonProps {
  disabled?: boolean;
  onClick: () => void;
}

const AddRuleButton: FC<AddRuleButtonProps> = ({ disabled, onClick }) => {
  const classes = useStyles();
  return (
    <>
      {/* Use an input label with a whitespace to align with other labels in the row. */}
      <InputLabel>&nbsp;</InputLabel>

      <div className={classes.addRuleButtonContainer}>
        <ButtonBase
          className={classes.addRuleButton}
          disabled={disabled}
          onClick={onClick}
        >
          <AddIcon />
        </ButtonBase>
      </div>
    </>
  );
};

export default AddRuleButton;
