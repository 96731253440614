import { ApiClientError } from '@raydiant/api-client-js';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import raydiant from '../clients/raydiant';
import * as presentationActions from '../actions/presentations';
import { keys } from '../queryClient';
import useProtectedMutation from './useProtectedMutation';

export default function useDeletePlaylist() {
  const dispatchRedux = useDispatch();
  const queryClient = useQueryClient();

  const mutation = useProtectedMutation<
    void,
    ApiClientError,
    { presentationId: string }
  >(({ presentationId }) => raydiant.deletePresentation(presentationId), {
    onSuccess: (_, { presentationId }) => {
      // Update react-query cache.
      queryClient.removeQueries(keys.presentation(presentationId));

      // Update redux cache for /screens and /library.
      dispatchRedux(
        presentationActions.deletePresentationAsync.success(presentationId),
      );
    },
  });

  return mutation;
}
