import { ApplicationVersion, ApiClientError } from '@raydiant/api-client-js';
import raydiant from '../clients/miraClient';
import useProtectedQuery from './useProtectedQuery';
import { keys } from '../queryClient';

export default function useApplicationVersions(
  applicationId: string | undefined,
) {
  const query = useProtectedQuery<ApplicationVersion[], ApiClientError>(
    applicationId ? keys.applicationVersions(applicationId) : [],
    () => (applicationId ? raydiant.getApplicationVersions(applicationId) : []),
    {
      enabled: applicationId !== undefined,
      // Application versions don't change often so cache them for the lifetime of the page.
      staleTime: Infinity,
    },
  );

  return query;
}
