import { ActionType, getType } from 'typesafe-actions';
import * as applicationsPageActions from './actions';

export type ApplicationsPageActions = ActionType<
  typeof applicationsPageActions
>;

export type ApplicationsPageState = Readonly<{
  isLoading: boolean;
  error: string;
}>;

const initialApplicationsPageState: ApplicationsPageState = {
  isLoading: false,
  error: '',
};

export default function applicationsPageReducer(
  state = initialApplicationsPageState,
  action: ApplicationsPageActions,
): ApplicationsPageState {
  switch (action.type) {
    case getType(applicationsPageActions.loadApplicationsPageAsync.request):
      return { ...state, isLoading: true, error: '' };

    case getType(applicationsPageActions.loadApplicationsPageAsync.success):
      return { ...state, isLoading: false };

    case getType(applicationsPageActions.loadApplicationsPageAsync.failure):
      return {
        ...state,
        isLoading: false,
        error: action.payload.message,
      };

    default:
      return state;
  }
}
