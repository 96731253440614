import { batchActions } from 'redux-batched-actions';
import { all, call, fork, put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { Application, Theme } from '@raydiant/api-client-js';
import * as appActions from '../../actions/applications';
import * as themeActions from '../../actions/themes';
import miraClient from '../../clients/miraClient';
import logger from '../../logger';
import * as actions from './actions';

const watchLoadApplicationPage = function* () {
  while (true) {
    yield take(getType(actions.loadApplicationPage));

    try {
      yield put(actions.loadApplicationPageAsync.request());

      const [applications, themes]: [Application[], Theme[]] = yield all([
        call(() => miraClient.getApplications()),
        call(() => miraClient.getThemes()),
      ]);

      yield put(
        batchActions([
          actions.loadApplicationPageAsync.success(),
          appActions.fetchApplicationsAsync.success(applications),
          themeActions.getThemesAsync.success(themes),
        ]),
      );
    } catch (error) {
      logger.error(error);
      yield put(actions.loadApplicationPageAsync.failure(error));
    }
  }
};

export default all([fork(watchLoadApplicationPage)]);
