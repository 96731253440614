import orderBy from 'lodash/orderBy';
import * as D from '../clients/mira/types/Device';
import { isDeviceOnline } from './deviceUtils';

export interface SortDeviceOptions {
  property: 'name' | 'isOnline' | 'publishedAt';
  direction: 'asc' | 'desc';
}

export const sortDevices = (
  devices: D.Device[],
  { property, direction }: SortDeviceOptions,
  lastLoadedDate: string,
) => {
  if (property === 'name') {
    return orderBy(devices, (d) => d.name.toLowerCase(), [direction]);
  }

  if (property === 'isOnline') {
    return orderBy(
      devices,
      [
        (d) => {
          const deviceIsOnline = isDeviceOnline(d, lastLoadedDate);
          if (deviceIsOnline === true) return 0;
          if (deviceIsOnline === false) return 1;
          return 2;
        },
        (d) => d.name,
      ],
      [direction, direction],
    );
  }

  if (property === 'publishedAt') {
    return orderBy(devices, (d) => d.publishedAt, [direction]);
  }

  return devices;
};
