import React, { FC } from 'react';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

interface SectionIndentProps {}

const SectionIndent: FC<SectionIndentProps> = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  });
});

export default SectionIndent;
