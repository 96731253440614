import { createSelector } from 'reselect';
import { RootState } from '../../reducers';
import * as DM from '../../clients/mira/types/Domain';
import * as R from '../../clients/mira/types/Resource';
import { selectUserProfile } from '../user';

export interface DomainsById {
  [domainId: string]: DM.Domain | undefined;
}

export const selectDomainsById = (state: RootState): DomainsById =>
  state.v2Domains.byId;

export const selectDomainForCurrentUser = createSelector(
  [selectDomainsById, selectUserProfile],
  (domainsById, currentUser): DM.Domain | undefined => {
    if (!currentUser?.domainId) return;
    return domainsById[currentUser.domainId];
  },
);

export const selectOtherDomainProfiles = createSelector(
  [selectDomainForCurrentUser, selectUserProfile],
  (domain, currentUser): R.ResourceProfile[] => {
    if (!domain || !currentUser) return [];

    return domain.r.profiles
      .filter((profile) => profile.id !== currentUser.id)
      .sort((a, b) => a.name.localeCompare(b.name));
  },
);
