import React from 'react';
import cn from 'classnames';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export interface PopoverListItemProps {
  badge?: boolean;
  label?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}

const PopoverListItem = ({
  badge,
  label,
  icon,
  disabled,
  children,
  onClick,
}: PopoverListItemProps) => {
  const classes = useStyles();
  return (
    <ListItem
      button
      dense
      classes={{ root: classes.root }}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && (
        <div className={cn(classes.icon, badge && classes.badge)}>{icon}</div>
      )}
      {label || children}
    </ListItem>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: theme.fontSizes.xxs,
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: 1.42,
      lineHeight: 1.11,
      padding: theme.spacing(1),
      marginTop: theme.spacing(0.5),
    },

    icon: {
      fontSize: 20,
      width: 20,
      flexShrink: 0,
      marginRight: theme.spacing(0.75),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    badge: {
      position: 'relative',

      '&:after': {
        content: '" "',
        position: 'absolute',
        top: -1,
        left: -1,
        width: 4,
        height: 4,
        borderRadius: 100,
        opacity: 0.6,
        backgroundColor: theme.palette.text.secondary,
      },
    },
  }),
);

export default PopoverListItem;
