import { ActionType, getType } from 'typesafe-actions';
import { SortProfileOptions } from '../../utilities';
import * as accountPageActions from './actions';
export type AccountPageActions = ActionType<typeof accountPageActions>;

export type AccountPageState = Readonly<{
  isLoading: boolean;
  memberListSortOptions: SortProfileOptions;
  memberListSearchQuery: string;
  error: string;
}>;

const initialAccountPageState: AccountPageState = {
  isLoading: false,
  memberListSortOptions: {
    property: 'domainRole',
    direction: 'asc',
  },
  memberListSearchQuery: '',
  error: '',
};

export default function accountPageReducer(
  state = initialAccountPageState,
  action: AccountPageActions,
): AccountPageState {
  switch (action.type) {
    case getType(accountPageActions.loadAccountPageAsync.request):
      return {
        ...state,
        isLoading: true,
      };

    case getType(accountPageActions.loadAccountPageAsync.success):
      return {
        ...state,
        isLoading: false,
      };

    case getType(accountPageActions.loadAccountPageAsync.failure):
      return {
        ...state,
        isLoading: false,
        error: action.payload.message,
      };

    case getType(accountPageActions.setSortOptions):
      return {
        ...state,
        memberListSortOptions: action.payload,
      };

    case getType(accountPageActions.setSearchQuery):
      return {
        ...state,
        memberListSearchQuery: action.payload,
      };

    default:
      return state;
  }
}
