import * as F from '../clients/mira/types/Folder';

export default function searchFolders(
  folders: F.Folder[],
  searchQuery: string,
) {
  const searchQueryInsenstive = searchQuery.toLowerCase().trim();
  return folders.filter((f) =>
    f.name.toLowerCase().includes(searchQueryInsenstive),
  );
}
