import produce from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import { ApplicationVersion } from '@raydiant/api-client-js';
import * as applicationActions from '../../actions/applications';
import * as applicationVersionActions from '../../actions/applicationVersions';

export type ApplicationVersionActions = ActionType<
  typeof applicationVersionActions
>;
export type ApplicationActions = ActionType<typeof applicationActions>;

export type ApplicationVersionsState = Readonly<{
  byId: {
    [appVersionId: string]: ApplicationVersion;
  };
}>;

const initialApplicationVersionsState: ApplicationVersionsState = {
  byId: {},
};

export default function applicationVersionsReducer(
  state = initialApplicationVersionsState,
  action: ApplicationVersionActions | ApplicationActions,
): ApplicationVersionsState {
  switch (action.type) {
    // fetchApplicationVersionsAsync
    case getType(
      applicationVersionActions.fetchApplicationVersionsAsync.success,
    ): {
      const applicationVersions = action.payload;
      return produce(state, (draftState) => {
        applicationVersions.forEach((applicationVersion) => {
          draftState.byId[applicationVersion.id] = applicationVersion;
        });
      });
    }

    // fetchApplicationsAsync
    case getType(applicationActions.fetchApplicationsAsync.success): {
      const applications = action.payload;
      return produce(state, (draftState) => {
        applications.forEach((application) => {
          const appVersion = application.currentAppVersion;
          if (appVersion) {
            draftState.byId[appVersion.id] = appVersion;
          }
        });
      });
    }

    default: {
      return state;
    }
  }
}
