import { batchActions } from 'redux-batched-actions';
import { all, call, fork, put, take, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as playlistActions from '../actions/playlists';
import * as folderActions from '../actions/folders';
import * as deviceActions from '../actions/devices';
import { Playlist, Profile } from '@raydiant/api-client-js';
import miraClient from '../clients/miraClient';
import { selectDevicesById, DevicesById } from '../selectors/v2/devices';
import { selectUserProfile } from '../selectors/user';
import { selectPlaylistsById, PlaylistsById } from '../selectors/v2/playlists';
import logger from '../logger';
import { canDeleteResource } from '../utilities';

type FetchPlaylistsAction = ReturnType<typeof playlistActions.fetchPlaylists>;
type CreatePlaylistAction = ReturnType<typeof playlistActions.createPlaylist>;
type UpdatePlaylistAction = ReturnType<typeof playlistActions.updatePlaylist>;
type DeletePlaylistAction = ReturnType<typeof playlistActions.deletePlaylist>;
type DeleteAllPlaylistsAction = ReturnType<
  typeof playlistActions.deleteAllPlaylists
>;
type CopyPlaylistAction = ReturnType<typeof playlistActions.copyPlaylist>;

export const fetchPlaylists = function* (ids: string[] = []) {
  try {
    yield put(playlistActions.fetchPlaylistsAsync.request());
    const playlists: Playlist[] = yield call(() =>
      miraClient.getPlaylists(ids.length > 0 ? { ids } : undefined),
    );
    yield put(playlistActions.fetchPlaylistsAsync.success(playlists));
    return playlists;
  } catch (error) {
    logger.error(error);
    yield put(playlistActions.fetchPlaylistsAsync.failure(error));
  }
};

export const createPlaylist = function* (
  params: Playlist,
  folderId?: string | null,
  deviceId?: string,
  onCreate?: (playlist: Playlist) => void,
) {
  try {
    yield put(playlistActions.createPlaylistAsync.request(params));
    const playlist: Playlist = yield call(() =>
      miraClient.createPlaylist(params),
    );
    yield put(playlistActions.createPlaylistAsync.success(playlist));

    if (folderId) {
      yield call(() => miraClient.movePlaylistToFolder(playlist.id, folderId));
    }

    yield put(
      folderActions.moveAllItemsToFolder(
        {
          playlistIds: [playlist.id],
          parentFolderId: folderId || null,
        },
        {},
      ),
    );

    const callOnCreate = () => {
      if (onCreate) {
        onCreate(playlist);
      }
    };

    if (deviceId) {
      const devicesById: DevicesById = yield select(selectDevicesById);
      const device = devicesById[deviceId];
      if (device) {
        yield put(
          deviceActions.updateDevice(
            { ...device, playlistId: playlist.id },
            {
              onUpdate: callOnCreate,
            },
          ),
        );
      } else {
        callOnCreate();
      }
    } else {
      callOnCreate();
    }

    return playlist;
  } catch (error) {
    logger.error(error);
    yield put(playlistActions.createPlaylistAsync.failure(error));
  }
};

export const updatePlaylist = function* (
  params: Partial<Playlist> & { id: string },
  onUpdate?: (playlist: Playlist) => void,
) {
  try {
    yield put(playlistActions.updatePlaylistAsync.request(params));
    const playlist: Playlist = yield call(() =>
      miraClient.updatePlaylist(params.id, params),
    );
    yield put(playlistActions.updatePlaylistAsync.success(playlist));

    if (onUpdate) {
      onUpdate(playlist);
    }

    return playlist;
  } catch (error) {
    logger.error(error);
    yield put(playlistActions.updatePlaylistAsync.failure(error));
  }
};

const deletePlaylist = function* (playlistId: string, onDelete?: () => void) {
  try {
    yield put(playlistActions.deletePlaylistAsync.request(playlistId));
    const playlist: Playlist = yield call(() =>
      miraClient.deletePlaylist(playlistId),
    );
    yield put(playlistActions.deletePlaylistAsync.success(playlistId));

    if (onDelete) {
      onDelete();
    }

    return playlist;
  } catch (error) {
    logger.error(error);
    yield put(playlistActions.deletePlaylistAsync.failure(error));
  }
};

const deleteAllPlaylists = function* (ids: string[], onDelete?: () => void) {
  try {
    const currentUser: Profile = yield select(selectUserProfile);
    const playlistsById: PlaylistsById = yield select(selectPlaylistsById);

    const deleteableIds = ids.filter((id) => {
      const presentation = playlistsById[id];
      return (
        !!presentation &&
        currentUser &&
        canDeleteResource(currentUser, presentation.resource)
      );
    });

    yield put(
      batchActions(
        deleteableIds.map((id) =>
          playlistActions.deletePlaylistAsync.request(id),
        ),
      ),
    );

    yield all(
      deleteableIds.map((id) => call(() => miraClient.deletePlaylist(id))),
    );

    yield put(
      batchActions(
        deleteableIds.map((id) =>
          playlistActions.deletePlaylistAsync.success(id),
        ),
      ),
    );

    if (onDelete) {
      onDelete();
    }
  } catch (error) {
    logger.error(error);
    yield put(
      batchActions(
        ids.map(() => playlistActions.deletePlaylistAsync.failure(error)),
      ),
    );
  }
};

const watchFetchPlaylists = function* () {
  while (true) {
    const action: FetchPlaylistsAction = yield take(
      getType(playlistActions.fetchPlaylists),
    );

    yield fork(fetchPlaylists, action.payload);
  }
};

const watchCreatePlaylist = function* () {
  while (true) {
    const action: CreatePlaylistAction = yield take(
      getType(playlistActions.createPlaylist),
    );

    yield fork(
      createPlaylist,
      action.payload,
      action.meta?.folderId,
      action.meta?.deviceId,
      action.meta?.onCreate,
    );
  }
};

const watchUpdatePlaylist = function* () {
  while (true) {
    const action: UpdatePlaylistAction = yield take(
      getType(playlistActions.updatePlaylist),
    );

    yield fork(updatePlaylist, action.payload, action.meta?.onUpdate);
  }
};

const watchDeletePlaylist = function* () {
  while (true) {
    const action: DeletePlaylistAction = yield take(
      getType(playlistActions.deletePlaylist),
    );

    yield fork(deletePlaylist, action.payload, action.meta?.onDelete);
  }
};

const watchDeleteAllPlaylists = function* () {
  while (true) {
    const action: DeleteAllPlaylistsAction = yield take(
      getType(playlistActions.deleteAllPlaylists),
    );
    yield fork(deleteAllPlaylists, action.payload, action.meta?.onDelete);
  }
};

const copyPlaylist = function* (
  params: { playlistId: string; copyName?: string },
  onSuccess?: (playlist: Playlist) => void,
) {
  try {
    yield put(playlistActions.copyPlaylistAsync.request());

    const playlist: Playlist = yield call(() =>
      miraClient.copyPlaylist(params.playlistId, { name: params.copyName }),
    );

    yield put(playlistActions.copyPlaylistAsync.success(playlist));

    // Move playlist to it's folder
    yield put(
      playlistActions.movePlaylistToFolderAsync.request({
        playlist,
        parentFolderId: playlist.resource.parentFolderId,
      }),
    );

    if (onSuccess) {
      onSuccess(playlist);
    }
  } catch (error) {
    logger.error(error);
    yield put(playlistActions.copyPlaylistAsync.failure(error));
  }
};

const watchCopyPlaylist = function* () {
  while (true) {
    const action: CopyPlaylistAction = yield take(
      getType(playlistActions.copyPlaylist),
    );
    yield fork(copyPlaylist, action.payload, action.meta.onSuccess);
  }
};

export default all([
  fork(watchFetchPlaylists),
  fork(watchCreatePlaylist),
  fork(watchUpdatePlaylist),
  fork(watchDeletePlaylist),
  fork(watchDeleteAllPlaylists),
  fork(watchCopyPlaylist),
]);
