import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import PlaylistSelector from './PlaylistSelector';
import reducer from './reducer';

const playlistSelectorReducer = persistReducer(
  {
    key: 'PlaylistSelector',
    whitelist: ['sortOptions'],
    storage,
  },
  reducer,
);

export default PlaylistSelector;
export { playlistSelectorReducer };
