import FilterListIcon from '@material-ui/icons/FilterList';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import Popover from '@material-ui/core/Popover';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import SwapVertReverseIcon from 'raydiant-elements/icons/SwapVertReverse';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import Hidden from 'raydiant-elements/layout/Hidden';
import Spacer from 'raydiant-elements/layout/Spacer';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import React, { FC, useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserProfile } from '../../selectors/user';
import {
  selectHasPublishableDevices,
  selectDeviceStatusById,
  selectPlaylistStatusByDeviceId,
} from '../../selectors/v2/devices';
import * as deviceActions from '../../actions/devices';
import {
  canEditResource,
  SortDeviceOptions,
  isDevicePublishable,
} from '../../utilities';
import activationScreenPng from '../../assets/activation-screen.png';
import RegisterDevice from '../../components/RegisterDevice';
import {
  selectSortOptions,
  selectSearchQuery,
  selectDeviceResults,
} from './selectors';
import * as actions from './actions';

interface DevicesActionBarProps {
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),

      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
      },
    },

    newScreenIcon: {
      color: theme.palette.progress.contrastText,
      backgroundColor: theme.palette.progress.main,
      borderRadius: 100,
      boxShadow: theme.shadows[1],
    },

    addDevicePopover: {
      backgroundColor: theme.modal.overlayBackground,

      '& $addDevicePopoverPaper': {
        width: '100%',
        maxWidth: 400,
        borderRadius: theme.borderRadius.lg,
        padding: theme.spacing(4),
      },

      [theme.breakpoints.down('xs')]: {
        '& $addDevicePopoverPaper': {
          maxWidth: 'none',
          width: `calc(100% - ${theme.spacing(4)}px)`,
          padding: theme.spacing(4, 2),
        },
      },
    },

    addDevicePopoverPaper: {},
  }),
);

const DevicesActionBar: FC<DevicesActionBarProps> = ({ disabled }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Selectors

  const deviceResults = useSelector(selectDeviceResults);
  const currentUser = useSelector(selectUserProfile);
  const sortOptions = useSelector(selectSortOptions);
  const searchQuery = useSelector(selectSearchQuery);
  const hasPublishableDevices = useSelector(selectHasPublishableDevices);
  const deviceStatusById = useSelector(selectDeviceStatusById);
  const playlistStatusByDeviceId = useSelector(selectPlaylistStatusByDeviceId);

  // State

  const [isSortMode, setIsSortMode] = useState(false);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [isAddNewDeviceOpen, setIsAddNewDeviceOpen] = useState(false);

  // Callbacks

  const toggleSort = useCallback(
    (property: SortDeviceOptions['property']) => {
      let direction: SortDeviceOptions['direction'] =
        property === 'publishedAt' ? 'desc' : 'asc';

      if (property === sortOptions.property) {
        direction = sortOptions.direction === 'asc' ? 'desc' : 'asc';
      }

      dispatch(actions.setSortOptions({ property, direction }));

      setIsSortMode(false);
    },
    [dispatch, setIsSortMode, sortOptions],
  );

  const setSearchQuery = useCallback(
    (value: string) => {
      dispatch(actions.setSearchQuery(value));
    },
    [dispatch],
  );

  const publishAll = useCallback(() => {
    const deviceIds = Object.values(deviceResults).reduce((a, b) => {
      const publishableDevices = b.devices.filter((device) => {
        const deviceStatus = deviceStatusById[device.id];
        const playlistStatus = playlistStatusByDeviceId[device.id];
        return (
          currentUser &&
          canEditResource(currentUser, device.resource) &&
          isDevicePublishable(device, deviceStatus, playlistStatus)
        );
      });
      return [...a, ...publishableDevices.map((d) => d.id)];
    }, [] as string[]);

    dispatch(deviceActions.publishAll(deviceIds));
  }, [
    dispatch,
    deviceResults,
    currentUser,
    deviceStatusById,
    playlistStatusByDeviceId,
  ]);

  // Side-effects

  // Reset search query when exiting search mode.
  useEffect(() => {
    if (isSearchMode) return;
    dispatch(actions.setSearchQuery(''));
  }, [isSearchMode, dispatch]);

  // Render

  let sortLabel = 'Sort';
  if (sortOptions.property === 'name') {
    sortLabel = `${sortLabel}: Name`;
  } else if (sortOptions.property === 'isOnline') {
    sortLabel = `${sortLabel}: Online`;
  } else if (sortOptions.property === 'publishedAt') {
    sortLabel = `${sortLabel}: Date`;
  }

  const isSortByName = sortOptions.property === 'name';
  const isSortByOnline = sortOptions.property === 'isOnline';
  const isSortByDate = sortOptions.property === 'publishedAt';
  const isReverseSort = sortOptions.direction === 'desc';

  const sortActionOptions = (
    <>
      <ActionBar.SelectOption
        icon={
          isSortByName && isReverseSort ? (
            <SwapVertReverseIcon />
          ) : (
            <SwapVertIcon />
          )
        }
        label="Name"
        selected={isSortByName}
        onClick={() => toggleSort('name')}
      />
      <ActionBar.SelectOption
        icon={
          isSortByOnline && isReverseSort ? (
            <SwapVertReverseIcon />
          ) : (
            <SwapVertIcon />
          )
        }
        label="Online"
        selected={isSortByOnline}
        onClick={() => toggleSort('isOnline')}
      />
      <ActionBar.SelectOption
        icon={
          isSortByDate && isReverseSort ? (
            <SwapVertReverseIcon />
          ) : (
            <SwapVertIcon />
          )
        }
        label="Date"
        selected={isSortByDate}
        onClick={() => toggleSort('publishedAt')}
      />
    </>
  );

  const shouldDisableSort = disabled;

  const sortAction = (
    <>
      <Hidden xsDown>
        <ActionBar.Select
          icon={<FilterListIcon />}
          label={sortLabel}
          open={isSortMode}
          onOpen={setIsSortMode}
          disabled={shouldDisableSort}
        >
          {sortActionOptions}
        </ActionBar.Select>
      </Hidden>
      <Hidden smUp>
        <ActionBar.Select
          icon={<FilterListIcon />}
          open={isSortMode}
          onOpen={setIsSortMode}
        >
          {sortActionOptions}
        </ActionBar.Select>
      </Hidden>
    </>
  );

  const shouldDisableAdd = disabled;
  const addDeviceRef = useRef<HTMLElement | null>(null);

  const addDeviceAction = (
    <>
      <Hidden xsDown>
        <ActionBar.Action
          ref={addDeviceRef}
          icon={<AddIcon className={classes.newScreenIcon} />}
          label="Add Screen"
          onClick={() => setIsAddNewDeviceOpen(true)}
          disabled={shouldDisableAdd}
        />
      </Hidden>
      <Hidden smUp>
        <ActionBar.Action
          ref={addDeviceRef}
          icon={<AddIcon className={classes.newScreenIcon} />}
          onClick={() => setIsAddNewDeviceOpen(true)}
          disabled={shouldDisableAdd}
        />
      </Hidden>

      <Popover
        className={classes.addDevicePopover}
        classes={{ paper: classes.addDevicePopoverPaper }}
        open={isAddNewDeviceOpen}
        anchorEl={addDeviceRef.current}
        onClose={() => setIsAddNewDeviceOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <link rel="preload" as="image" href={activationScreenPng} />
        <RegisterDevice onRegister={() => setIsAddNewDeviceOpen(false)} />
      </Popover>
    </>
  );

  const shouldDisableSearch = disabled;

  const searchAction = isSearchMode ? (
    <ActionBar.Input
      autoFocus
      label="Search"
      maxWidth={145}
      icon={
        <ActionBar.Action
          icon={<CloseIcon />}
          onClick={() => setIsSearchMode(false)}
        />
      }
      value={searchQuery}
      onChange={(value) => setSearchQuery(value)}
    />
  ) : (
    <ActionBar.Action
      icon={<SearchIcon />}
      onClick={() => setIsSearchMode(true)}
      disabled={shouldDisableSearch}
    />
  );

  const shouldDisablePublish = disabled || !hasPublishableDevices;

  const publishAllAction = (
    <>
      <Hidden xsDown>
        <ActionBar.Action
          icon={<PublishIcon />}
          label="Publish All"
          onClick={publishAll}
          disabled={shouldDisablePublish}
        />
      </Hidden>
      <Hidden smUp>
        <ActionBar.Action
          icon={<PublishIcon />}
          onClick={publishAll}
          disabled={shouldDisablePublish}
        />
      </Hidden>
    </>
  );

  return (
    <ActionBar>
      <Hidden smDown>
        {sortAction}
        {addDeviceAction}
        <Spacer />
        {searchAction}
        {publishAllAction}
      </Hidden>

      <Hidden mdUp>
        {sortAction}
        {!isSearchMode && addDeviceAction}
        {!isSearchMode && publishAllAction}
        {searchAction}
      </Hidden>
    </ActionBar>
  );
};

export default DevicesActionBar;
