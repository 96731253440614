import * as F from '../clients/mira/types/Folder';
export type NodeType = 'presentation' | 'playlist' | 'folder' | 'profile';

export const createNodeId = (type: NodeType, id: string) => {
  if (
    !['presentation', 'playlist', 'folder', 'profile'].includes(type) ||
    !id
  ) {
    throw new Error(`Cannot create nodeId for type='${type}', id='${id}'`);
  }

  return `${type}|${id}`;
};

export const parseNodeId = (nodeId: string) => {
  const [type, id] = nodeId.split('|');

  if (
    !['presentation', 'playlist', 'folder', 'profile'].includes(type) ||
    !id
  ) {
    throw new Error(`Invalid nodeId '${nodeId}'`);
  }

  return { type: type as NodeType, id };
};

export const hasDroppableNodeIds = (
  nodeIds: string[],
  folder: F.Folder | F.VirtualFolder,
  isEnterpriseUser: boolean,
) => {
  return !nodeIds.every((nodeId) => {
    const { id, type } = parseNodeId(nodeId);
    const folderItemIds = [
      ...folder.presentations.map((r) => r.id),
      ...folder.playlists.map((r) => r.id),
      ...folder.folders.map((r) => r.id),
    ];

    const targetFolderPath = 'id' in folder ? folder.path.map((p) => p.id) : [];
    const isNodeInItems = folderItemIds.includes(id);
    const isParent = targetFolderPath.includes(id);
    const willCreateNestedFolder = type === 'folder' && targetFolderPath.length;
    return (
      isParent || isNodeInItems || (willCreateNestedFolder && !isEnterpriseUser)
    );
  });
};

export const getResourceIdsFromNodeIds = (nodeIds: string[]) => {
  const presentationIds: string[] = [];
  const playlistIds: string[] = [];
  const folderIds: string[] = [];

  nodeIds.forEach((nodeId) => {
    const { type, id } = parseNodeId(nodeId);
    if (type === 'presentation') {
      presentationIds.push(id);
    } else if (type === 'playlist') {
      playlistIds.push(id);
    } else if (type === 'folder') {
      folderIds.push(id);
    }
  });

  return {
    presentationIds,
    playlistIds,
    folderIds,
  };
};
