import * as R from '../clients/mira/types/Resource';
import * as U from '../clients/mira/types/User';

export default function searchProfiles(
  profiles: Array<U.Profile | R.ResourceProfile>,
  searchQuery: string,
) {
  const searchQueryInsenstive = searchQuery.toLowerCase().trim();
  return profiles.filter(
    (profile) =>
      profile.name.toLowerCase().includes(searchQueryInsenstive) ||
      profile.email.toLowerCase().includes(searchQueryInsenstive) ||
      profile.domainRole.toLowerCase().includes(searchQueryInsenstive),
  );
}
