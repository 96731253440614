import * as R from '../clients/mira/types/Resource';

interface ResourceObject {
  resource: R.Resource;
}

export default function isResourceDeleted<T extends ResourceObject>(
  resourceObject: T | undefined,
): boolean {
  if (!resourceObject) return true;
  return resourceObject.resource.deletedAt !== null;
}
