import { useMemo, useCallback } from 'react';
import logger from '../logger';

export default function useSessionStorage<T = any>(
  key: string,
): [T | null, (value: T) => void, () => void] {
  const value = useMemo(() => {
    try {
      const valueStr = sessionStorage.getItem(key);
      if (!valueStr) return null;
      return JSON.parse(valueStr) as T;
    } catch (err) {
      logger.error(`Failed to parse session storage at key '${key}'`);
      return null;
    }
  }, [key]);

  const setValue = useCallback(
    (value: T) => {
      sessionStorage.setItem(key, JSON.stringify(value));
    },
    [key],
  );

  const clearValue = useCallback(() => {
    delete sessionStorage[key];
  }, [key]);

  return [value, setValue, clearValue];
}
