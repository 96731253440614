import Hidden from 'raydiant-elements/layout/Hidden';
import Center from 'raydiant-elements/layout/Center';
import Text from 'raydiant-elements/typography/Text';
import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as F from '../../clients/mira/types/Folder';
import * as R from '../../clients/mira/types/Resource';
import { hasDroppableNodeIds, canMoveFolderItems } from '../../utilities';
import {
  selectIsEnterpriseUser,
  selectUserProfile,
} from '../../selectors/user';
import DropZone from '../../components/DropZone';

interface LibraryFolderEmptyStateProps {
  folder?: F.Folder | F.VirtualFolder;
  selectedProfile: R.ResourceProfile;
  onMove: (nodeIds: string[], folderId: string | null) => void;
}

const LibraryFolderEmptyState: FC<LibraryFolderEmptyStateProps> = ({
  folder,
  selectedProfile,
  onMove,
}) => {
  // Selectors

  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);
  const currentUser = useSelector(selectUserProfile);

  const isMoveable =
    currentUser && canMoveFolderItems(currentUser, selectedProfile);

  // Callbacks

  const onDrop = useCallback(
    (nodeIds: string[]) => {
      onMove(nodeIds, folder && 'id' in folder ? folder.id : null);
    },
    [onMove, folder],
  );

  const onCanDrop = useCallback(
    (nodeIds: string[]) => {
      if (!isMoveable) return false;
      if (!folder) return false;
      return hasDroppableNodeIds(nodeIds, folder, isEnterpriseUser);
    },
    [folder, isEnterpriseUser, isMoveable],
  );

  // Render

  if (!isMoveable) {
    return null;
  }

  return (
    <>
      <Hidden mdDown>
        <DropZone onDrop={onDrop} onCanDrop={onCanDrop}>
          Drag presentations, playlists or folders here from the right pane.
        </DropZone>
      </Hidden>
      <Hidden smUp>
        <Center>
          <Text>
            You can use Manage or simply drag and drop items to add to your
            folder.
          </Text>
        </Center>
      </Hidden>
    </>
  );
};

export default LibraryFolderEmptyState;
