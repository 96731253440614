import { ActionType, getType } from 'typesafe-actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as userActions from '../actions/user';
import * as U from '../clients/mira/types/User';

export type UserActions = ActionType<typeof userActions>;

export type UserState = Readonly<{
  profile?: U.Profile;
  roles: string[];
  preferences: {
    homeTab?: string;
  } | null;
  isFetching: boolean;
  error: string;
}>;

const initialUserState: UserState = {
  roles: [],
  preferences: null,
  isFetching: false,
  error: '',
};

const reducer = (state = initialUserState, action: UserActions): UserState => {
  switch (action.type) {
    // getProfile
    case getType(userActions.getProfileAsync.request):
      return {
        ...state,
        isFetching: true,
        error: '',
      };
    case getType(userActions.getProfileAsync.success):
      return {
        ...state,
        profile: action.payload,
        isFetching: false,
      };
    case getType(userActions.getProfileAsync.failure):
      return {
        ...state,
        isFetching: false,
        error: action.payload.message,
      };

    // updateProfile
    case getType(userActions.updateProfileAsync.request):
      return {
        ...state,
        isFetching: true,
        error: '',
      };
    case getType(userActions.updateProfileAsync.success):
      return {
        ...state,
        profile: action.payload,
        isFetching: false,
      };
    case getType(userActions.updateProfileAsync.failure):
      return {
        ...state,
        isFetching: false,
        error: action.payload.message,
      };

    // getRolesAndPreferencesAndPreferences
    case getType(userActions.getRolesAndPreferencesAsync.request):
      return {
        ...state,
        isFetching: true,
        error: '',
      };
    case getType(userActions.getRolesAndPreferencesAsync.success):
      return {
        ...state,
        roles: action.payload.roles,
        preferences: action.payload.preferences,
        isFetching: false,
      };
    case getType(userActions.getRolesAndPreferencesAsync.failure):
      return {
        ...state,
        isFetching: false,
        error: action.payload.message,
      };

    // setRemoteAssistance
    case getType(userActions.setRemoteAssistanceAsync.success):
      return {
        ...state,
        profile: state.profile
          ? {
              ...state.profile,
              remoteAssistanceGrantLevel: action.payload.grantLevel,
              remoteAssistanceExpiresAt: action.payload.expiresAt,
            }
          : undefined,
      };

    default:
      return state;
  }
};

// Persist user preferences to local storage to speed up initial page load
// of the home page redirect. If the preference changes between logging in
// then the old preference will be used until the next page load.
export default persistReducer(
  {
    key: 'user',
    whitelist: ['preferences'],
    storage,
  },
  reducer,
);
