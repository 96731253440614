import { createAction, createAsyncAction } from 'typesafe-actions';

export const openChargebeePortal = createAction('OPEN_CHARGEBEE_PORTAL');

export const openChargebeePortalAsync = createAsyncAction(
  'OPEN_CHARGEBEE_PORTAL_REQUEST',
  'OPEN_CHARGEBEE_PORTAL_SUCCESS',
  'OPEN_CHARGEBEE_PORTAL_FAILURE',
)<void, void, Error>();

export const closedChargebeePortal = createAction('CLOSED_CHARGEBEE_PORTAL');
