import { batchActions } from 'redux-batched-actions';
import { all, call, fork, put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import {
  Presentation,
  AffectedDevice,
  Application,
  Theme,
  Domain,
} from '@raydiant/api-client-js';
import * as appActions from '../../actions/applications';
import * as presActions from '../../actions/presentations';
import * as themeActions from '../../actions/themes';
import * as domainActions from '../../actions/domains';
import miraClient from '../../clients/miraClient';
import logger from '../../logger';
import * as actions from './actions';

type LoadPresentationPageAction = ReturnType<
  typeof actions.loadPresentationPage
>;

const watchLoadPresentationPage = function* () {
  while (true) {
    const { payload }: LoadPresentationPageAction = yield take(
      getType(actions.loadPresentationPage),
    );

    const { presentationId } = payload;

    try {
      yield put(actions.loadPresentationPageAsync.request());

      const [presentations, affectedDevices, applications, themes, domain]: [
        Presentation[],
        AffectedDevice[],
        Application[],
        Theme[],
        Domain | null,
      ] = yield all([
        call(() =>
          presentationId
            ? miraClient.getPresentations({ ids: [presentationId] })
            : [],
        ),
        call(() =>
          presentationId
            ? miraClient.getAffectedDevices({
                presentationIds: [presentationId],
              })
            : [],
        ),
        call(() => miraClient.getApplications()),
        call(() => miraClient.getThemes()),
        call(() => miraClient.getDomain()),
      ]);

      yield put(
        batchActions([
          actions.loadPresentationPageAsync.success({ affectedDevices }),
          appActions.fetchApplicationsAsync.success(applications),
          presActions.fetchPresentationsAsync.success(presentations),
          themeActions.getThemesAsync.success(themes),
          ...(domain ? [domainActions.fetchDomainAsync.success(domain)] : []),
        ]),
      );
    } catch (error) {
      logger.error(error);
      yield put(actions.loadPresentationPageAsync.failure(error));
    }
  }
};

export default all([fork(watchLoadPresentationPage)]);
