import { Playlist, ApiClientError } from '@raydiant/api-client-js';
import { UseQueryOptions } from 'react-query';
import raydiant from '../clients/raydiant';
import { keys } from '../queryClient';
import useProtectedQuery from './useProtectedQuery';
import { removeDeletedItems } from '../utilities/playlistUtils';

export default function usePlaylist(
  playlistId: string | undefined,
  opts: UseQueryOptions<Playlist, ApiClientError> = {},
) {
  const query = useProtectedQuery<Playlist | undefined, ApiClientError>(
    playlistId ? keys.playlist(playlistId) : [],
    async () => {
      if (!playlistId) return;
      const playlists = await raydiant.getPlaylists({ ids: [playlistId] });
      const playlist = playlists.find((pl) => pl.id === playlistId);
      if (!playlist) return;

      return removeDeletedItems(playlist);
    },
    {
      enabled: playlistId !== undefined && opts.enabled,
      refetchOnMount: opts.refetchOnMount,
    },
  );

  return query;
}
