import { createSelector } from 'reselect';
import { RootState } from '../../reducers';
import * as F from '../../clients/mira/types/Folder';
import * as D from '../../clients/mira/types/Device';
import * as P from '../../clients/mira/types/Presentation';
import * as PL from '../../clients/mira/types/Playlist';
import { isNotNullOrUndefined, groupResourcesByOwner } from '../../utilities';
import { FolderWithFullNested, VirtualFolderWithFullNested } from '../../types';
import { selectDevicesById, DevicesById } from './devices';
import { selectPlaylistsById, PlaylistsById } from './playlists';
import { selectPresentationsById, PresentationsById } from './presentations';

export interface FoldersById {
  [folderId: string]: FolderWithFullNested | undefined;
}

export interface FolderStatusById {
  [folderId: string]: 'fetching' | 'updating' | 'success' | 'error';
}

export const selectFolderStatusById = (state: RootState): FolderStatusById =>
  state.v2Folders.statusById;

export const selectFoldersById = createSelector(
  [
    selectDevicesById,
    selectPresentationsById,
    selectPlaylistsById,
    (state: RootState) => state.v2Folders.byId,
  ],
  (devicesById, presentationsById, playlistsById, foldersById): FoldersById => {
    const foldersByIdWithItems: FoldersById = {};
    Object.entries(foldersById).forEach(([folderId, folder]) => {
      foldersByIdWithItems[folderId] = {
        ...folder,
        devices: selectDeviceItems(folder, devicesById),
        presentations: mapPresentationItems(folder, presentationsById),
        playlists: mapPlaylistItems(folder, playlistsById),
        folders: mapFolderItems(folder, foldersById),
      };
    });
    return foldersByIdWithItems;
  },
);

export const selectDevicesFolder = createSelector(
  [
    selectDevicesById,
    (state: RootState) => state.v2Folders.virtualById['devices'],
  ],
  (devicesById, folder): F.VirtualFolder | undefined => {
    if (!folder) return;

    return {
      ...folder,
      devices: selectDeviceItems(folder, devicesById),
    };
  },
);

export const selectLibraryFolder = createSelector(
  [
    selectPresentationsById,
    selectPlaylistsById,
    (state: RootState) => state.v2Folders.byId,
    (state: RootState) => state.v2Folders.virtualById['library'],
  ],
  (
    presentationsById,
    playlistsById,
    foldersById,
    folder,
  ): VirtualFolderWithFullNested | undefined => {
    if (!folder) return;
    return {
      ...folder,
      presentations: mapPresentationItems(folder, presentationsById),
      playlists: mapPlaylistItems(folder, playlistsById),
      folders: mapFolderItems(folder, foldersById),
    };
  },
);

const selectDeviceItems = <T extends F.VirtualFolder>(
  folder: T,
  devicesById: DevicesById,
): D.Device[] =>
  folder.devices.map((d) => devicesById[d.id]).filter(isNotNullOrUndefined);

const mapPresentationItems = <T extends F.VirtualFolder>(
  folder: T,
  presentationsById: PresentationsById,
): P.Presentation[] =>
  folder.presentations
    .map((p) => presentationsById[p.id])
    .filter(isNotNullOrUndefined);

const mapPlaylistItems = <T extends F.VirtualFolder>(
  folder: T,
  playlistsById: PlaylistsById,
): PL.Playlist[] =>
  folder.playlists
    .map((pl) => playlistsById[pl.id])
    .filter(isNotNullOrUndefined);

const mapFolderItems = <T extends F.VirtualFolder>(
  folder: T,
  foldersById: { [key: string]: F.Folder },
): F.Folder[] =>
  folder.folders.map((f) => foldersById[f.id]).filter(isNotNullOrUndefined);

export const selectFolderItemIds = (
  folder: F.Folder | F.VirtualFolder,
): string[] => {
  return [
    ...folder.presentations.map((p) => p.id),
    ...folder.playlists.map((pl) => pl.id),
    ...folder.folders.map((f) => f.id),
  ];
};

export const selectLibraryByOwner = createSelector(
  [selectLibraryFolder],
  (libraryFolder) => {
    if (!libraryFolder) return {};
    return groupResourcesByOwner(libraryFolder);
  },
);
