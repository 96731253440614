import React, { FC, useCallback } from 'react';
import Row from 'raydiant-elements/layout/Row';
import TagIcon from 'raydiant-elements/icons/Tag';
import useStyles from './TagInput.styles';
import TagInputTextType from './TagInputTextType';

export interface TagInputProps {
  label: string;
  value: string;
  type: 'text';
  editing?: boolean;
  onEdit: (editing: boolean) => void;
  onChange: (value: string) => void;
}

const TagInput: FC<TagInputProps> = ({
  label,
  value,
  type,
  editing,
  onEdit,
  onChange,
}) => {
  const classes = useStyles();

  // Callbacks

  const handleChange = useCallback(
    (value: string) => {
      onChange(value);
      // Exit edit mode after updating the value.
      onEdit(false);
    },
    [onChange, onEdit],
  );

  // Render

  if (editing) {
    if (type === 'text') {
      return (
        <TagInputTextType label={label} value={value} onChange={handleChange} />
      );
    }
    // Only support editing text tag types for now.
    return null;
  }

  return (
    <button
      className={classes.root}
      onClick={() => onEdit(true)}
      onBlur={() => onEdit(false)}
    >
      <Row halfMargin center>
        <TagIcon className={classes.icon} />
        <div className={classes.tag}>
          {label}: {value}
        </div>
      </Row>
    </button>
  );
};

export default TagInput;
