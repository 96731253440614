import * as R from '../clients/mira/types/Resource';

interface ResourceObject {
  resource: R.Resource;
}

export default function sortByResource<T extends ResourceObject>(
  field: 'updatedAt' | 'createdAt',
  dir: number,
) {
  return (a: T, b: T) => {
    if (a.resource[field] < b.resource[field]) return dir * -1;
    if (a.resource[field] > b.resource[field]) return dir * 1;
    return 0;
  };
}
