import { createStandardAction, createAsyncAction } from 'typesafe-actions';
import { ApplicationVersion } from '../clients/mira/types/Application';

export const fetchApplicationVersions = createStandardAction(
  '@APPLICATION_VERSIONS/FETCH',
)<string>();

export const fetchApplicationVersionsAsync = createAsyncAction(
  '@APPLICATION_VERSIONS/FETCH_REQUEST',
  '@APPLICATION_VERSIONS/FETCH_SUCCESS',
  '@APPLICATION_VERSIONS/FETCH_FAILURE',
)<void, ApplicationVersion[], Error>();
