import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export default makeStyles((theme: Theme) => {
  return createStyles({
    domainLibraries: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(0, 2),
      margin: theme.spacing(0, 2),
      marginBottom: theme.spacing(1),

      [theme.breakpoints.down('xs')]: {
        flexShrink: 0, // Fix for iOS
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        margin: theme.spacing(0, 1),
        marginBottom: theme.spacing(1),
      },
    },

    domainAccountSelector: {
      maxWidth: 360,
      padding: theme.spacing(2),

      [theme.breakpoints.down('xs')]: {
        maxWidth: 'none',
        padding: theme.spacing(1),
      },
    },

    library: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      margin: theme.spacing(0, 2),
      overflow: 'hidden', // Needed to show inner scroll bar.
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,

      [theme.breakpoints.down('xs')]: {
        flex: 'auto',
        margin: theme.spacing(0, 1),
      },
    },

    libraryMain: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden', // Needed to show inner scroll bar.
      marginTop: theme.spacing(4),

      [theme.breakpoints.down('xs')]: {
        flex: 'auto',
        marginTop: 0,
      },
    },

    librarySide: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden', // Needed to show inner scroll bar.
      width: 416,
      marginTop: theme.spacing(4),
      borderLeft: `1px solid rgba(0, 0, 0, 0.1)`,
    },

    header: {
      padding: theme.spacing(0, 4),
      paddingBottom: theme.spacing(1),

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
      },
    },

    folderNameRow: {
      height: 36, // Align with "All Folders"
    },

    folderNameHelperText: {
      paddingLeft: 62, // Align with folder name
    },

    libraryTree: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3, 4),
    },

    libraryTreeSkeleton: {
      flex: 1,
      backgroundImage: `url(${
        require('../../assets/library-tree-skeleton.svg').default
      })`,
      backgroundPosition: 'top center',
      backgroundRepeat: 'repeat',
      backgroundSize: '100%',
    },
  });
});
