import React, { FC } from 'react';
import { Application } from '@raydiant/api-client-js';
import REApplicationCard from 'raydiant-elements/application/ApplicationCard';
import { localizeAppStrings } from '../../utilities';

interface ApplicationCardProps {
  application: Application;
  onClick?: () => void;
  auto?: boolean;
}

const ApplicationCard: FC<ApplicationCardProps> = ({
  application,
  onClick,
  auto,
}) => {
  return (
    <REApplicationCard
      application={{
        ...application,
        currentAppVersion: {
          ...application.currentAppVersion,
          strings: localizeAppStrings(application.currentAppVersion.strings),
        },
      }}
      onClick={onClick}
      auto={auto}
    />
  );
};

export default ApplicationCard;
