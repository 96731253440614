import { all, call, fork, put, take, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { batchActions } from 'redux-batched-actions';
import { Theme, ResourceACL, Profile } from '@raydiant/api-client-js';
import * as themeActions from '../actions/themes';
import miraClient from '../clients/miraClient';
import logger from '../logger';
import { ThemesById, selectThemesById } from '../selectors/v2/themes';
import { selectUserProfile } from '../selectors/user';
import { canDeleteResource } from '../utilities';

type UpdateThemeAction = ReturnType<typeof themeActions.updateTheme>;
type CreateThemeAction = ReturnType<typeof themeActions.createTheme>;
type ShareThemeAction = ReturnType<typeof themeActions.shareTheme>;
type RemoveThemeResourceACLAction = ReturnType<
  typeof themeActions.removeThemeResourceACL
>;
type DeleteAllThemesAction = ReturnType<typeof themeActions.deleteAllThemes>;
type CreateAllThemesAction = ReturnType<typeof themeActions.createAllThemes>;

const getThemes = function* () {
  try {
    yield put(themeActions.getThemesAsync.request());
    const themes: Theme[] = yield call(() => miraClient.getThemes());
    yield put(themeActions.getThemesAsync.success(themes));
    return themes;
  } catch (error) {
    logger.error(error);
    yield put(themeActions.getThemesAsync.failure(error));
  }
};

const updateTheme = function* (
  theme: Theme,
  onUpdate?: (theme: Theme) => void,
) {
  yield put(themeActions.updateThemeAsync.request(theme));
  try {
    const updatedTheme: Theme = yield call(() =>
      miraClient.updateTheme(theme.id, theme),
    );

    yield put(themeActions.updateThemeAsync.success(updatedTheme));

    if (onUpdate) {
      onUpdate(updatedTheme);
    }
  } catch (error) {
    logger.error(error);
    yield put(themeActions.updateThemeAsync.failure(error));
  }
};

const createTheme = function* (
  theme: Theme,
  onCreate?: (theme: Theme) => void,
) {
  yield put(themeActions.createThemeAsync.request(theme));
  try {
    const newTheme: Theme = yield call(() => miraClient.createTheme(theme));

    yield put(themeActions.createThemeAsync.success(newTheme));

    if (onCreate) {
      onCreate(newTheme);
    }
  } catch (error) {
    logger.error(error);
    yield put(themeActions.updateThemeAsync.failure(error));
  }
};

const watchGetThemes = function* () {
  while (true) {
    yield take(getType(themeActions.getThemes));
    yield call(getThemes);
  }
};

const watchUpdateTheme = function* () {
  while (true) {
    const action: UpdateThemeAction = yield take(
      getType(themeActions.updateTheme),
    );
    yield call(updateTheme, action.payload, action.meta?.onUpdate);
  }
};

const watchCreateTheme = function* () {
  while (true) {
    const action: CreateThemeAction = yield take(
      getType(themeActions.createTheme),
    );
    yield call(createTheme, action.payload, action.meta?.onCreate);
  }
};

const watchShareTheme = function* () {
  while (true) {
    const action: ShareThemeAction = yield take(
      getType(themeActions.shareTheme),
    );
    const { themeId, profileId } = action.payload;
    const { onSuccess, onError } = action.meta;

    try {
      const themesById: ThemesById = yield select(selectThemesById);
      const device = themesById[themeId];
      if (!device) return;

      const resourceACL: ResourceACL = yield call(() =>
        miraClient.createResourceACL({
          resourceId: device.resource.id,
          grantProfileId: profileId,
          grants: ['read', 'update'],
        }),
      );

      yield put(themeActions.addThemeResourceACL({ themeId, resourceACL }));

      if (onSuccess) {
        onSuccess(resourceACL);
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
      logger.error(error);
    }
  }
};

const watchRemoveThemeResourceACL = function* () {
  while (true) {
    const action: RemoveThemeResourceACLAction = yield take(
      getType(themeActions.removeThemeResourceACL),
    );
    const { aclId } = action.payload;

    try {
      // The store is optimistically updated via RemoveDeviceResourceACL so we
      // don't need to fire any additional actions.
      yield call(() => miraClient.deleteResourceACL(aclId));
    } catch (error) {
      logger.error(error);
    }
  }
};

const deleteAllThemes = function* (ids: string[], onSuccess?: () => void) {
  try {
    const currentUser: Profile = yield select(selectUserProfile);
    const themesById: ThemesById = yield select(selectThemesById);

    const deleteableIds = ids.filter((id) => {
      const theme = themesById[id];
      return (
        !!theme && currentUser && canDeleteResource(currentUser, theme.resource)
      );
    });

    yield put(
      batchActions(
        deleteableIds.map((id) => themeActions.deleteThemeAsync.request(id)),
      ),
    );

    yield all(
      deleteableIds.map((id) => call(() => miraClient.deleteTheme(id))),
    );

    yield put(
      batchActions(
        deleteableIds.map((id) => themeActions.deleteThemeAsync.success(id)),
      ),
    );

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    logger.error(error);
    yield put(themeActions.deleteThemeAsync.failure(error));
  }
};

const watchDeleteAllThemes = function* () {
  while (true) {
    const action: DeleteAllThemesAction = yield take(
      getType(themeActions.deleteAllThemes),
    );
    yield fork(deleteAllThemes, action.payload);
  }
};

const createAllThemes = function* (
  themes: Theme[],
  onSuccess?: (themes: Theme[]) => void,
) {
  try {
    yield put(
      batchActions(
        themes.map((theme) => themeActions.createThemeAsync.request(theme)),
      ),
    );

    const newThemes: Theme[] = yield all(
      themes.map((theme) => call(() => miraClient.createTheme(theme))),
    );

    yield put(
      batchActions(
        newThemes.map((theme) => themeActions.createThemeAsync.success(theme)),
      ),
    );

    if (onSuccess) {
      onSuccess(newThemes);
    }
  } catch (error) {
    logger.error(error);
    yield put(themeActions.createThemeAsync.failure(error));
  }
};

const watchCreateAllThemes = function* () {
  while (true) {
    const action: CreateAllThemesAction = yield take(
      getType(themeActions.createAllThemes),
    );
    yield fork(createAllThemes, action.payload, action.meta.onCreate);
  }
};

export default all([
  fork(watchGetThemes),
  fork(watchUpdateTheme),
  fork(watchCreateTheme),
  fork(watchShareTheme),
  fork(watchRemoveThemeResourceACL),
  fork(watchDeleteAllThemes),
  fork(watchCreateAllThemes),
]);
