import React, { FC } from 'react';
import PaperModal from 'raydiant-elements/core/PaperModal';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Column from 'raydiant-elements/layout/Column';
import usePlaylistPageContext from './usePlaylistPageContext';

const PlaylistModalBody: FC = ({ children }) => {
  const { classes } = usePlaylistPageContext();

  return (
    <Scrollable>
      <PaperModal.Body className={classes.playlistModalContents}>
        <Column doubleMargin>{children}</Column>
      </PaperModal.Body>
    </Scrollable>
  );
};

export default PlaylistModalBody;
