import { PlaylistItem } from '@raydiant/api-client-js';

export interface PlaylistItemDragItem {
  type: 'PlaylistItem';
  stack: PlaylistItem[];
  x: number;
  y: number;
  width: number;
}

export default function createPlaylistItemDragItem(
  stack: PlaylistItem[] = [],
  x: number = 0,
  y: number = 0,
  width: number = 0,
): PlaylistItemDragItem {
  return {
    type: 'PlaylistItem',
    stack,
    x,
    y,
    width,
  };
}
