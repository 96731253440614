import React, { FC, forwardRef } from 'react';
import cn from 'classnames';
import useStyles from './PlaylistCard.styles';

export interface PlaylistCardThumbnailProps {
  isDark?: boolean;
}

export const PlaylistCardThumbnail: FC<PlaylistCardThumbnailProps> = (
  { isDark },
  ref,
) => {
  const classes = useStyles();

  return (
    <div
      ref={ref}
      className={cn(
        classes.thumbnailImage,
        isDark && classes.thumbnailImageDark,
      )}
    />
  );
};

export default forwardRef(PlaylistCardThumbnail);
