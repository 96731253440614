import { PlaylistPlaybackContent } from '@raydiant/api-client-js';

export default function combinePlaybackContent(
  allPlaybackContent: PlaylistPlaybackContent[],
): PlaylistPlaybackContent {
  return allPlaybackContent.reduce<PlaylistPlaybackContent>(
    (acc, playbackContent) => {
      return {
        presentations: {
          ...acc.presentations,
          ...playbackContent.presentations,
        },
        applicationVersions: {
          ...acc.applicationVersions,
          ...playbackContent.applicationVersions,
        },
        applications: {
          ...acc.applications,
          ...playbackContent.applications,
        },
        playlists: {
          ...acc.playlists,
          ...playbackContent.playlists,
        },
        themes: {
          ...acc.themes,
          ...playbackContent.themes,
        },
      };
    },
    {},
  );
}
