import {
  useCallback,
  useReducer,
  useEffect,
  useRef,
  useMemo,
  useLayoutEffect,
} from 'react';
import { PlaylistItem } from '@raydiant/api-client-js';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import * as paths from '../../../routes/paths';
import { keys as queryKeys } from '../../../queryClient';
import * as deviceActions from '../../../actions/devices';
import * as devicesPageActions from '../../../pages/DevicesPage/actions';
import * as playlistActions from '../../../actions/playlists';
import useSessionState from '../../../hooks/useSessionState';
import useProtectedMutation from '../../../hooks/useProtectedMutation';
import * as presentationPageActions from '../../PresentationPage/actions';
import usePlaylistPageQueryParams from '../usePlaylistPageQueryParams';
import { State, ContextValue, Actions } from '../playlistPageTypes';
import savePlaylist from '../savePlaylist';
import {
  deserializeIDPath,
  serializeIDPath,
  getPlaylistItemAtIDPath,
  getFullPlaylistsById,
  getErrorsById,
  getOrderedSelectedPaths,
  isIDPathEqual,
  mergePlaylists,
  mergePresentationTags,
} from './utilities';
import initialState from './initialState';
import reducer from './reducer';
import stateKeys from './stateKeys';
import makeStyles from '../PlaylistPage.styles';

export default function usePlaylistPageState(): ContextValue {
  // Styling

  const classes = makeStyles();

  // Routing

  const routeParams = useParams<{ playlistId?: string }>();
  const history = useHistory();
  const isNewPlaylist = !!useRouteMatch({ path: paths.newPlaylist.pattern });
  const queryParams = usePlaylistPageQueryParams();

  const rootPlaylistId = isNewPlaylist
    ? queryParams.playlistId
    : routeParams.playlistId;

  // Session state

  let [sessionState, setSessionState] =
    useSessionState<Partial<State>>('PlaylistPageState');

  // The sessionId in storage doesn't match the current session id, create
  // a new session by clearing the old one.
  if (
    queryParams.sessionId &&
    queryParams.sessionId !== sessionState?.sessionId
  ) {
    sessionState = null;
  }

  // Reducer

  const [state, dispatch] = useReducer<State, Actions>(reducer, {
    ...initialState,
    ...sessionState,
  });

  // Redux interop

  const dispatchRedux = useDispatch();

  // Mutations

  const queryCache = useQueryClient();

  const {
    mutateAsync,
    status: submitStatus,
    reset: resetSubmitStatus,
  } = useProtectedMutation(savePlaylist, {
    onSuccess: (result) => {
      for (const playlist of Object.values(result.playlistsById)) {
        // Update react-query cache.
        queryCache.setQueryData(queryKeys.playlist(playlist.id), playlist);

        // Update redux cache with updated playlists to update the screens page.
        // TODO: Remove when screens and library page are migrated to react-query.
        dispatchRedux(playlistActions.updatePlaylistAsync.success(playlist));
      }

      if (result.device) {
        // Update react-query cache.
        queryCache.setQueryData(
          queryKeys.device(result.device.id),
          result.device,
        );

        // Update redux cache with updated device to update the screens page.
        // TODO: Remove when screens page is migrated to react-query.
        dispatchRedux(deviceActions.updateDeviceAsync.success(result.device));
      }
    },
  });

  // Refs

  // Track form state without re-creating dependent callbacks. This should only be used
  // by callbacks that are used as event handlers (ie. onClick, onSubmit, etc...) _not_ for
  // callbacks that are executed during render.
  const stateRef = useRef(state);
  useLayoutEffect(() => {
    stateRef.current = state;
  }, [state]);

  // Memoizers

  const fullPlaylistsById = useMemo(() => {
    return getFullPlaylistsById({
      savedPlaylistsById: state.savedPlaylistsById,
      updatedPlaylistsById: state.updatedPlaylistsById,
      newPlaylistsById: state.newPlaylistsById,
    });
  }, [
    state.savedPlaylistsById,
    state.updatedPlaylistsById,
    state.newPlaylistsById,
  ]);

  const isDirty = useMemo(() => {
    return Object.keys(state.isDirtyById).length > 0;
  }, [state.isDirtyById]);

  const hasError = useMemo(() => {
    return Object.keys(state.errorsById).length > 0;
  }, [state.errorsById]);

  const cycleIDPath = useMemo(() => {
    const cycleError = Object.entries(state.errorsById).find(([_, errors]) => {
      return Object.values(errors).some((error) => error === 'cycle');
    });

    if (!cycleError) return null;

    return deserializeIDPath(cycleError[0]);
  }, [state.errorsById]);

  // Callbacks

  const setSavedPlaylists = useCallback<ContextValue['setSavedPlaylists']>(
    (playlists) => {
      if (!rootPlaylistId) return;
      if (!playlists.length) return;
      dispatch({ type: 'setSavedPlaylists', playlists, rootPlaylistId });
    },
    [rootPlaylistId],
  );

  const setSavedPlaylist = useCallback<ContextValue['setSavedPlaylist']>(
    (playlist) => {
      if (!rootPlaylistId) return;
      dispatch({ type: 'setSavedPlaylist', playlist, rootPlaylistId });
    },
    [rootPlaylistId],
  );

  const setSavedPresentations = useCallback<
    ContextValue['setSavedPresentations']
  >((presentations) => {
    if (!presentations.length) return;
    dispatch({
      type: 'setSavedPresentations',
      presentations,
    });
  }, []);

  const setSavedPresentation = useCallback<
    ContextValue['setSavedPresentation']
  >((presentation) => {
    dispatch({ type: 'setSavedPresentation', presentation });
  }, []);

  const updatePlaylist = useCallback<ContextValue['updatePlaylist']>(
    (playlistId, params) => {
      if (!rootPlaylistId) return;
      dispatch({ type: 'updatePlaylist', playlistId, params, rootPlaylistId });
    },
    [rootPlaylistId],
  );

  const updatePresentation = useCallback<ContextValue['updatePresentation']>(
    (presentationId, params) => {
      dispatch({
        type: 'updatePresentation',
        presentationId,
        params,
      });
    },
    [],
  );

  const createPlaylist = useCallback<ContextValue['createPlaylist']>(
    (playlistId, profileId) => {
      if (!rootPlaylistId) return;
      dispatch({
        type: 'createPlaylist',
        playlistId,
        profileId,
        rootPlaylistId,
      });
    },
    [rootPlaylistId],
  );

  const toggleExpanded = useCallback<ContextValue['toggleExpanded']>(
    (path, playlistId) => {
      dispatch({ type: 'toggleExpanded', path, playlistId });
    },
    [],
  );

  const isExpanded = useCallback<ContextValue['isExpanded']>(
    (path) => {
      return !!state.expandedItems[serializeIDPath(path)];
    },
    [state.expandedItems],
  );

  const toggleSelected = useCallback<ContextValue['toggleSelected']>((path) => {
    dispatch({ type: 'toggleSelected', path });
  }, []);

  const setInitialSelected = useCallback<ContextValue['setInitialSelected']>(
    (path) => {
      dispatch({ type: 'setInitialSelected', path });
    },
    [],
  );

  const setSelectedEnd = useCallback<ContextValue['setSelectedEnd']>(
    (path) => {
      if (!rootPlaylistId) return;
      dispatch({ type: 'setSelectedEnd', path, rootPlaylistId });
    },
    [rootPlaylistId],
  );

  const isSelected = useCallback<ContextValue['isSelected']>(
    (path) => {
      return !!state.selectedItems[serializeIDPath(path)];
    },
    [state.selectedItems],
  );

  const getSelectedItems = useCallback<ContextValue['getSelectedItems']>(
    (ignorePath = []) => {
      if (!rootPlaylistId) return [];

      const items: PlaylistItem[] = [];
      const ignorePathStr = serializeIDPath(ignorePath);

      for (const pathStr of Object.keys(state.selectedItems)) {
        if (pathStr === ignorePathStr) continue;
        const [playlistItem] = getPlaylistItemAtIDPath(
          fullPlaylistsById,
          rootPlaylistId,
          deserializeIDPath(pathStr),
        );
        if (playlistItem) items.push(playlistItem);
      }

      return items;
    },
    [state.selectedItems, fullPlaylistsById, rootPlaylistId],
  );

  const moveSelectedItems = useCallback<ContextValue['moveSelectedItems']>(
    (destinationPath, dropPosition) => {
      if (!rootPlaylistId) return;
      dispatch({
        type: 'moveSelectedItems',
        destinationPath,
        dropPosition,
        rootPlaylistId,
      });
    },
    [rootPlaylistId],
  );

  const submit = useCallback<ContextValue['submit']>(
    async (assignToDeviceId) => {
      if (!rootPlaylistId) return null;

      const errorsById = getErrorsById(
        getFullPlaylistsById(stateRef.current),
        rootPlaylistId,
      );

      const hasError = Object.keys(errorsById).length !== 0;
      if (hasError) {
        dispatch({ type: 'submitError', errorsById });
        return null;
      }

      try {
        const result = await mutateAsync({
          rootPlaylistId,
          assignToDeviceId,
          savedPlaylistsById: stateRef.current.savedPlaylistsById,
          savedPresentationsById: stateRef.current.savedPresentationsById,
          updatedPlaylistsById: stateRef.current.updatedPlaylistsById,
          updatedPresentationsById: stateRef.current.updatedPresentationsById,
          newPlaylistsById: stateRef.current.newPlaylistsById,
          addToFolderId: queryParams.folderId,
        });

        dispatch({
          type: 'submitSuccess',
          playlistsById: result.playlistsById,
          rootPlaylistId,
        });

        // Show any content warnings for device on next load of the screens page.
        if (assignToDeviceId) {
          dispatchRedux(
            devicesPageActions.setOpenContentWarningOnLoad(assignToDeviceId),
          );
        }

        return result.playlistsById;
      } catch (err) {
        console.error('Failed to save playlist', err);
        return null;
      }
    },
    [mutateAsync, rootPlaylistId, dispatchRedux, queryParams.folderId],
  );

  const getPlaylistNameError = useCallback<
    ContextValue['getPlaylistNameError']
  >((path) => state.errorsById[path]?.[stateKeys.name()], [state.errorsById]);

  const getPlaylistStartDatetimeError = useCallback<
    ContextValue['getPlaylistStartDatetimeError']
  >(
    (playlistId) => state.errorsById[playlistId]?.[stateKeys.startDatetime()],
    [state.errorsById],
  );

  const getPlaylistEndDatetimeError = useCallback<
    ContextValue['getPlaylistEndDatetimeError']
  >(
    (playlistId) => state.errorsById[playlistId]?.[stateKeys.endDatetime()],
    [state.errorsById],
  );

  const getItemError = useCallback<ContextValue['getItemError']>(
    (path) => state.errorsById[serializeIDPath(path)]?.[stateKeys.item()],
    [state.errorsById],
  );

  const setPreviewItem = useCallback<ContextValue['setPreviewItem']>((item) => {
    dispatch({ type: 'setPreviewItem', item });
  }, []);

  const openMoreActions = useCallback<ContextValue['openMoreActions']>(
    (item, path, anchorEl) => {
      if (!rootPlaylistId) return;
      dispatch({
        type: 'openMoreActions',
        item,
        path,
        anchorEl,
        rootPlaylistId,
      });
    },
    [rootPlaylistId],
  );

  const closeMoreActions = useCallback<ContextValue['closeMoreActions']>(() => {
    dispatch({ type: 'closeMoreActions' });
  }, []);

  const isMoreActionsOpen = useCallback<ContextValue['isMoreActionsOpen']>(
    (path) => {
      if (!state.moreActionsItemPath) return false;
      return isIDPathEqual(path, state.moreActionsItemPath);
    },
    [state.moreActionsItemPath],
  );

  const addPlaylistItems = useCallback<ContextValue['addPlaylistItems']>(
    (destinationPath, items) => {
      if (!rootPlaylistId) return;
      dispatch({
        type: 'addPlaylistItems',
        destinationPath,
        items,
        rootPlaylistId,
      });
    },
    [rootPlaylistId],
  );

  const removePlaylistItem = useCallback<ContextValue['removePlaylistItem']>(
    (path) => {
      if (!rootPlaylistId) return;
      dispatch({ type: 'removePlaylistItem', path, rootPlaylistId });
    },
    [rootPlaylistId],
  );

  const removeAllPlaylistItemsForPresentation = useCallback<
    ContextValue['removeAllPlaylistItemsForPresentation']
  >(
    (presentationId) => {
      if (!rootPlaylistId) return;
      dispatch({
        type: 'removeAllPlaylistItemsForPresentation',
        presentationId,
        rootPlaylistId,
      });
    },
    [rootPlaylistId],
  );

  const removeAllPlaylistItemsForPlaylist = useCallback<
    ContextValue['removeAllPlaylistItemsForPlaylist']
  >(
    (playlistId) => {
      if (!rootPlaylistId) return;
      dispatch({
        type: 'removeAllPlaylistItemsForPlaylist',
        playlistId,
        rootPlaylistId,
      });
    },
    [rootPlaylistId],
  );

  const getSelection = useCallback<ContextValue['getSelection']>(() => {
    return getOrderedSelectedPaths(stateRef.current).map(
      ({ idPath }) => idPath,
    );
  }, []);

  const openModal = useCallback<ContextValue['openModal']>(
    (item, path, mode) => {
      dispatch({ type: 'openModal', item, path, mode });
    },
    [],
  );

  const closeModal = useCallback<ContextValue['closeModal']>(() => {
    dispatch({ type: 'closeModal' });
  }, []);

  const setEditItemName = useCallback<ContextValue['setEditItemName']>(
    (path) => {
      dispatch({ type: 'setEditItemName', path });
    },
    [],
  );

  const resetEditItemName = useCallback<
    ContextValue['resetEditItemName']
  >(() => {
    dispatch({ type: 'resetEditItemName' });
  }, []);

  const isNameEditable = useCallback<ContextValue['isNameEditable']>(
    (path) => {
      if (!state.itemEditNamePath) return false;
      return isIDPathEqual(path, state.itemEditNamePath);
    },
    [state.itemEditNamePath],
  );

  const getPlaylist = useCallback<ContextValue['getPlaylist']>(
    (playlistId) => {
      if (playlistId in state.newPlaylistsById) {
        // Playlist id is a new playlist, return the full playlist from newPlaylistsById.
        return state.newPlaylistsById[playlistId];
      }

      if (playlistId in state.updatedPlaylistsById) {
        if (playlistId in state.savedPlaylistsById) {
          // Playlist id is an updated playlist, return the saved playlist merged with the unsaved
          // playlist params.
          return mergePlaylists(
            state.savedPlaylistsById[playlistId],
            state.updatedPlaylistsById[playlistId],
          );
        } else if (playlistId in state.savedPartialPlaylistsById) {
          // Playlist id is an updated playlist but the full playlist (with it's items) hasn't been fetched
          // yet, return the saved playlist merged with the unsaved playlist params.
          return mergePlaylists(
            state.savedPartialPlaylistsById[playlistId],
            state.updatedPlaylistsById[playlistId],
          );
        }
      }

      if (playlistId in state.savedPlaylistsById) {
        return state.savedPlaylistsById[playlistId];
      }

      if (playlistId in state.savedPartialPlaylistsById) {
        return state.savedPartialPlaylistsById[playlistId];
      }
    },
    [
      state.newPlaylistsById,
      state.updatedPlaylistsById,
      state.savedPlaylistsById,
      state.savedPartialPlaylistsById,
    ],
  );

  const getPresentation = useCallback<ContextValue['getPresentation']>(
    (presentationId) => {
      if (
        presentationId in state.updatedPresentationsById &&
        presentationId in state.savedPresentationsById
      ) {
        return mergePresentationTags(
          state.savedPresentationsById[presentationId],
          state.updatedPresentationsById[presentationId],
        );
      }

      return state.savedPresentationsById[presentationId];
    },
    [state.savedPresentationsById, state.updatedPresentationsById],
  );

  const getPageUrl = useCallback<ContextValue['getPageUrl']>(
    (queryParams) => {
      if (isNewPlaylist && 'playlistId' in queryParams) {
        return paths.newPlaylist(queryParams);
      }

      if (!isNewPlaylist && rootPlaylistId) {
        return paths.editPlaylist(rootPlaylistId, queryParams);
      }

      return '';
    },
    [rootPlaylistId, isNewPlaylist],
  );

  const rootPlaylist = rootPlaylistId && fullPlaylistsById[rootPlaylistId];
  const editPresentation = useCallback<ContextValue['editPresentation']>(
    (presentationId) => {
      if (!rootPlaylist) return;

      const pageUrl = getPageUrl(queryParams);

      dispatchRedux(
        presentationPageActions.clearUnsavedPresentation(presentationId),
      );

      history.push(
        paths.editPresentation(presentationId, {
          backTo: pageUrl,
          backToLabel: `Back to ${rootPlaylist.name}`,
          saveTo: pageUrl,
          sessionId: queryParams.sessionId,
        }),
      );
    },
    [rootPlaylist, queryParams, dispatchRedux, history, getPageUrl],
  );

  // Effects

  // Persist subset of state to session storage
  useEffect(() => {
    setSessionState({
      sessionId: queryParams.sessionId,
      updatedPlaylistsById: state.updatedPlaylistsById,
      newPlaylistsById: state.newPlaylistsById,
      pendingPlaylistItems: state.pendingPlaylistItems,
      expandedItems: state.expandedItems,
      expandedPlaylistIds: state.expandedPlaylistIds,
      addedPlaylistIds: state.addedPlaylistIds,
      addedPresentationIds: state.addedPresentationIds,
    });
  }, [
    queryParams.sessionId,
    state.updatedPlaylistsById,
    state.newPlaylistsById,
    state.pendingPlaylistItems,
    state.expandedItems,
    state.expandedPlaylistIds,
    state.addedPlaylistIds,
    state.addedPresentationIds,
    setSessionState,
  ]);

  return {
    classes,
    state,
    queryParams,
    rootPlaylistId,
    isNewPlaylist,
    getPlaylist,
    getPresentation,
    createPlaylist,
    setSavedPlaylists,
    setSavedPlaylist,
    setSavedPresentations,
    setSavedPresentation,
    updatePlaylist,
    updatePresentation,
    toggleExpanded,
    isExpanded,
    toggleSelected,
    setInitialSelected,
    setSelectedEnd,
    isSelected,
    getSelectedItems,
    moveSelectedItems,
    submit,
    submitStatus,
    resetSubmitStatus,
    getPlaylistNameError,
    getPlaylistStartDatetimeError,
    getPlaylistEndDatetimeError,
    getItemError,
    getSelection,
    setPreviewItem,
    openMoreActions,
    closeMoreActions,
    isMoreActionsOpen,
    addPlaylistItems,
    removePlaylistItem,
    removeAllPlaylistItemsForPresentation,
    removeAllPlaylistItemsForPlaylist,
    openModal,
    closeModal,
    isDirty,
    hasError,
    setEditItemName,
    resetEditItemName,
    isNameEditable,
    getPageUrl,
    editPresentation,
    cycleIDPath,
  };
}
