import React from 'react';
import { Theme } from '@raydiant/api-client-js';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme as RETheme } from 'raydiant-elements/theme';

interface ThemeItemProps {
  theme: Theme;
}

const ThemeItem = ({ theme }: ThemeItemProps) => {
  const classes = useStyles();

  return (
    <div
      className={classes.swatch}
      style={{ background: theme.backgroundColor }}
    >
      <div
        className={classes.swatchSecondary}
        style={{ background: theme.headingTextColor }}
      ></div>
    </div>
  );
};

const useStyles = makeStyles((theme: RETheme) => {
  const swatchSize = 16;

  return createStyles({
    swatch: {
      flexShrink: 0,
      height: swatchSize,
      width: swatchSize,
      position: 'relative',
      overflow: 'hidden',
      boxShadow: theme.shadows[1],
    },

    swatchSecondary: {
      position: 'absolute',
      height: swatchSize,
      width: swatchSize,
      transform: 'translate(50%, 50%) rotate(45deg)',
    },
  });
});

export default ThemeItem;
