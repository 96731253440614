import { all, call, fork, put, take, select } from 'redux-saga/effects';
import { batchActions } from 'redux-batched-actions';
import { getType } from 'typesafe-actions';
import {
  selectPresentationsById,
  PresentationsById,
} from '../selectors/v2/presentations';
import { selectPlaylistsById, PlaylistsById } from '../selectors/v2/playlists';
import { selectFoldersById, FoldersById } from '../selectors/v2/folders';
import { selectIsEnterpriseUser, selectUserProfile } from '../selectors/user';
import * as folderActions from '../actions/folders';
import * as presActions from '../actions/presentations';
import * as playlistActions from '../actions/playlists';
import * as F from '../clients/mira/types/Folder';
import * as U from '../clients/mira/types/User';
import miraClient from '../clients/miraClient';
import { isNotNullOrUndefined } from '../utilities';
import logger from '../logger';
import { canDeleteResource } from '../utilities';

type CreateFolderAction = ReturnType<typeof folderActions.createFolder>;
type UpdateFolderAction = ReturnType<typeof folderActions.updateFolder>;
type MoveAllItemsToFolderAction = ReturnType<
  typeof folderActions.moveAllItemsToFolder
>;
type FetchFolderAction = ReturnType<typeof folderActions.fetchFolder>;
type DeleteFolderAction = ReturnType<typeof folderActions.deleteFolder>;
type DeleteAllFoldersAction = ReturnType<typeof folderActions.deleteAllFolders>;

const createFolder = function* (
  params: F.CreateFolder,
  onCreate?: (folder: F.Folder) => void,
) {
  try {
    yield put(folderActions.createFolderAsync.request(params));
    const folder: F.Folder = yield call(() => miraClient.createFolder(params));
    yield put(folderActions.createFolderAsync.success(folder));

    if (onCreate) {
      onCreate(folder);
    }

    return folder;
  } catch (error) {
    logger.error(error);
    yield put(folderActions.createFolderAsync.failure(error));
  }
};

const watchCreateFolder = function* () {
  while (true) {
    const action: CreateFolderAction = yield take(
      getType(folderActions.createFolder),
    );

    yield fork(createFolder, action.payload, action.meta?.onCreate);
  }
};

const updateFolder = function* (
  folderId: string,
  params: Partial<F.Folder>,
  onUpdate?: (folder: F.Folder) => void,
) {
  try {
    yield put(folderActions.updateFolderAsync.request(folderId));
    const folder: F.Folder = yield call(() =>
      miraClient.updateFolder(folderId, params),
    );
    yield put(folderActions.updateFolderAsync.success(folder));

    if (onUpdate) {
      onUpdate(folder);
    }

    return folder;
  } catch (error) {
    logger.error(error);
    yield put(folderActions.updateFolderAsync.failure(error));
  }
};

const watchUpdateFolder = function* () {
  while (true) {
    const action: UpdateFolderAction = yield take(
      getType(folderActions.updateFolder),
    );

    yield fork(
      updateFolder,
      action.payload.id,
      action.payload,
      action.meta?.onUpdate,
    );
  }
};

const moveAllItemsToFolder = function* (
  presentationIds: string[],
  playlistIds: string[],
  folderIds: string[],
  parentFolderId: string | null,
  onMove?: () => void,
) {
  try {
    const [presentationsById, playlistsById, foldersById]: [
      PresentationsById,
      PlaylistsById,
      FoldersById,
    ] = yield all([
      select(selectPresentationsById),
      select(selectPlaylistsById),
      select(selectFoldersById),
    ]);

    const parentFolder = parentFolderId ? foldersById[parentFolderId] : null;
    const parentFolderPath = parentFolder
      ? parentFolder.path.map((p) => p.id)
      : [];

    const folderIdsWithoutCyclicDeps = folderIds.filter((folderId) => {
      return !parentFolderPath.includes(folderId);
    });

    // Optimistically update UI when moving items into a folder.
    const movePresentationRequests = presentationIds
      .map((presentationId) => {
        const presentation = presentationsById[presentationId];
        if (!presentation) return null;
        return presActions.movePresentationToFolderAsync.request({
          presentation,
          parentFolderId,
        });
      })
      .filter(isNotNullOrUndefined);

    const movePlaylistRequests = playlistIds
      .map((playlistId) => {
        const playlist = playlistsById[playlistId];
        if (!playlist) return null;
        return playlistActions.movePlaylistToFolderAsync.request({
          playlist,
          parentFolderId,
        });
      })
      .filter(isNotNullOrUndefined);

    let moveFolderRequests = folderIdsWithoutCyclicDeps
      .map((folderId) => {
        const folder = foldersById[folderId];
        if (!folder) return null;
        return folderActions.moveFolderToFolderAsync.request({
          folder,
          parentFolderId,
        });
      })
      .filter(isNotNullOrUndefined);

    // Only enterprise users can move folders to another folder.
    const isEnterpriseUser: boolean = yield select(selectIsEnterpriseUser);
    if (!isEnterpriseUser && parentFolderId) {
      moveFolderRequests = [];
    }

    yield put(
      batchActions([
        ...movePresentationRequests,
        ...movePlaylistRequests,
        ...moveFolderRequests,
      ]),
    );

    // Make API calls to move items.
    const movePresentations = presentationIds.map((presentationId) =>
      call(() =>
        miraClient.movePresentationToFolder(presentationId, parentFolderId),
      ),
    );

    const movePlaylists = playlistIds.map((playlistId) =>
      call(() => miraClient.movePlaylistToFolder(playlistId, parentFolderId)),
    );

    const moveFolders = folderIdsWithoutCyclicDeps.map((folderId) =>
      call(() => miraClient.moveFolderToFolder(folderId, parentFolderId)),
    );

    yield all([...movePresentations, ...movePlaylists, ...moveFolders]);

    if (onMove) {
      onMove();
    }
  } catch (error) {
    logger.error(error);
  }
};

const watchMoveAllItemsToFolder = function* () {
  while (true) {
    const action: MoveAllItemsToFolderAction = yield take(
      getType(folderActions.moveAllItemsToFolder),
    );

    yield fork(
      moveAllItemsToFolder,
      action.payload.presentationIds || [],
      action.payload.playlistIds || [],
      action.payload.folderIds || [],
      action.payload.parentFolderId,
      action.meta?.onMove,
    );
  }
};

const fetchFolder = function* (folderId: string) {
  try {
    yield put(folderActions.fetchFolderAsync.request(folderId));
    const folder: F.Folder = yield call(() => miraClient.getFolder(folderId));
    yield put(folderActions.fetchFolderAsync.success(folder));

    return folder;
  } catch (error) {
    logger.error(error);
    yield put(folderActions.fetchFolderAsync.failure(error));
  }
};

const watchFetchFolder = function* () {
  while (true) {
    const action: FetchFolderAction = yield take(
      getType(folderActions.fetchFolder),
    );

    yield fork(fetchFolder, action.payload);
  }
};

const deleteFolder = function* (folderId: string, onDelete?: () => void) {
  try {
    yield put(folderActions.deleteFolderAsync.request(folderId));
    const playlist = yield call(() => miraClient.deleteFolder(folderId));
    yield put(folderActions.deleteFolderAsync.success(folderId));

    if (onDelete) {
      onDelete();
    }

    return playlist;
  } catch (error) {
    logger.error(error);
    yield put(folderActions.deleteFolderAsync.failure(error));
  }
};

const watchDeleteFolder = function* () {
  while (true) {
    const action: DeleteFolderAction = yield take(
      getType(folderActions.deleteFolder),
    );

    yield fork(deleteFolder, action.payload, action.meta?.onDelete);
  }
};

const deleteAllFolders = function* (ids: string[], onDelete?: () => void) {
  try {
    const currentUser: U.Profile = yield select(selectUserProfile);
    const foldersById: PlaylistsById = yield select(selectFoldersById);

    const deleteableIds = ids.filter((id) => {
      const presentation = foldersById[id];
      return (
        !!presentation &&
        currentUser &&
        canDeleteResource(currentUser, presentation.resource)
      );
    });

    yield put(
      batchActions(
        deleteableIds.map((id) => folderActions.deleteFolderAsync.request(id)),
      ),
    );

    yield all(
      deleteableIds.map((id) => call(() => miraClient.deleteFolder(id))),
    );

    yield put(
      batchActions(
        deleteableIds.map((id) => folderActions.deleteFolderAsync.success(id)),
      ),
    );

    if (onDelete) {
      onDelete();
    }
  } catch (error) {
    logger.error(error);
    yield put(
      batchActions(
        ids.map(() => playlistActions.deletePlaylistAsync.failure(error)),
      ),
    );
  }
};

const watchDeleteAllFolders = function* () {
  while (true) {
    const action: DeleteAllFoldersAction = yield take(
      getType(folderActions.deleteAllFolders),
    );
    yield fork(deleteAllFolders, action.payload, action.meta?.onDelete);
  }
};

const fetchLibraryFolder = function* () {
  try {
    const virtualFolderId = 'library';
    yield put(folderActions.fetchVirtualFolderAsync.request(virtualFolderId));
    const libraryFolder: F.Folder = yield call(() =>
      miraClient.getLibraryFolder(),
    );
    yield put(
      folderActions.fetchVirtualFolderAsync.success({
        ...libraryFolder,
        id: virtualFolderId,
      }),
    );

    return libraryFolder;
  } catch (error) {
    logger.error(error);
    yield put(folderActions.fetchFolderAsync.failure(error));
  }
};

const watchFetchLibraryFolder = function* () {
  while (true) {
    yield take(getType(folderActions.fetchLibraryFolder));

    yield fork(fetchLibraryFolder);
  }
};

export default all([
  fork(watchCreateFolder),
  fork(watchMoveAllItemsToFolder),
  fork(watchFetchFolder),
  fork(watchUpdateFolder),
  fork(watchDeleteFolder),
  fork(watchDeleteAllFolders),
  fork(watchFetchLibraryFolder),
]);
