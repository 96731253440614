import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as devicesPageActions from './actions';
import DevicesPage from './DevicesPage';
import reducer from './reducer';
import devicesPageSagas from './sagas';

const devicesPageReducer = persistReducer(
  {
    key: 'DevicesPage',
    whitelist: ['sortOptions', 'sharingSortOptions'],
    storage,
  },
  reducer,
);

export default DevicesPage;
export { devicesPageReducer, devicesPageSagas, devicesPageActions };
