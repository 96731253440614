import React, { FC } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import useStyles from './RuleEvaluationInput.styles';
import InputLabel from 'raydiant-elements/core/InputLabel';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Link from 'raydiant-elements/core/Link';

interface RuleEvaluationInputProps {
  value: boolean;
  onChange: (event: React.MouseEvent<HTMLElement>, value: boolean) => void;
}

const RuleEvaluationInput: FC<RuleEvaluationInputProps> = ({
  value,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <div>
      <InputLabel>Rule Evaluation</InputLabel>
      <ToggleButtonGroup
        className={classes.toggle}
        exclusive
        onChange={onChange}
        value={value}
      >
        <ToggleButton value={false}>
          <p className={classes.toggleText}>Apply to this playlist</p>
        </ToggleButton>
        <ToggleButton value={true}>
          <p className={classes.toggleText}>Apply to playlist items</p>
        </ToggleButton>
      </ToggleButtonGroup>
      <InputHelperText indent>
        Will evaluate tags on this playlist.{' '}
        <Link href="#" target="_blank">
          Learn more
        </Link>
      </InputHelperText>
    </div>
  );
};

export default RuleEvaluationInput;
