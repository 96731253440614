import Hidden from 'raydiant-elements/layout/Hidden';
import Heading from 'raydiant-elements/core/Heading';
import * as React from 'react';
import withStyles, { createStyles, WithStyles } from '../withStyles';

interface UnsubscribeConfirmationTitleProps extends WithStyles<typeof styles> {
  children?: React.ReactNode;
}

const UnsubscribeConfirmationTitle: React.SFC<
  UnsubscribeConfirmationTitleProps
> = ({ children }) => (
  <>
    <Hidden xsDown>
      <Heading gutterBottom>{children}</Heading>
    </Hidden>
    <Hidden smUp>
      <Heading size={2} color="primary" gutterBottom>
        {children}
      </Heading>
    </Hidden>
  </>
);

const styles = () =>
  createStyles({
    root: {},
  });

export default withStyles(styles)(UnsubscribeConfirmationTitle);
