import { RootState } from '../reducers';
import { SoundZone } from '../types';
import { isNotNullOrUndefined } from '../utilities';

export const selectSoundZones = (state: RootState): SoundZone[] => {
  return state.soundZones.items.filter(isNotNullOrUndefined);
};

export const selectSoundZonesFetching = (state: RootState): string[] => {
  return state.soundZones.itemsFetchStatus
    .filter(({ isRequesting }) => isRequesting)
    .map(({ id }) => id);
};
