import React, { useCallback } from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Theme } from '@raydiant/api-client-js';
import Button from 'raydiant-elements/core/Button';
import Text from 'raydiant-elements/core/Text';
import Spacer from 'raydiant-elements/layout/Spacer';
import Column from 'raydiant-elements/layout/Column';
import Row from 'raydiant-elements/layout/Row';
import ThemeSelector from 'raydiant-elements/core/ThemeSelector';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import Popover from '../../components/Popover';
import { Theme as RETheme } from 'raydiant-elements/theme';

interface DeleteThemeConfirmationProps {
  open: boolean;
  themes: Theme[];
  anchorEl: HTMLButtonElement | null;
  onDelete: () => void;
  onClose: () => void;
}

const DeleteThemeConfirmation = ({
  open,
  anchorEl,
  themes,
  onDelete,
  onClose,
}: DeleteThemeConfirmationProps) => {
  const classes = useStyles();

  // Callbacks

  const handleDelete = useCallback(() => {
    onDelete();
    onClose();
  }, [onDelete, onClose]);

  // Render

  let promptMessage =
    'Deleting this theme will permanently remove it from your list but not from currently published presentations.';

  if (themes.length > 1) {
    promptMessage =
      'Deleting these themes will permanently remove them from your list but not from currently published presentations.';
  }

  return (
    <ThemeSelector color="light">
      <Popover
        variant="alert"
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Column>
          <Row halfMargin>
            <ErrorIcon className={classes.alert} />
            <Text small>{promptMessage}</Text>
          </Row>

          <Row>
            <Spacer />
            <Button label="Cancel" onClick={onClose} />
            <Button
              icon={<DeleteForeverIcon />}
              label="Delete"
              color="destructive"
              onClick={handleDelete}
            />
          </Row>
        </Column>
      </Popover>
    </ThemeSelector>
  );
};

const useStyles = makeStyles((theme: RETheme) =>
  createStyles({
    alert: {
      color: theme.palette.warning.main,
      backgroundColor: '#000',
      borderRadius: 100,
    },
  }),
);

export default DeleteThemeConfirmation;
