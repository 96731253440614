import React from 'react';
import Divider from '@material-ui/core/Divider';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme as RETheme } from 'raydiant-elements/theme';

interface OptionHeaderProps {
  label?: React.ReactNode;
}

const OptionHeader = ({ label }: OptionHeaderProps) => {
  const classes = useStyles();

  return (
    <li className={classes.root}>
      <Divider />
    </li>
  );
};

const useStyles = makeStyles((theme: RETheme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

export default Object.assign(OptionHeader, { muiName: 'ListSubheader' });
