import { applyMiddleware, createStore } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import createSagaMiddleware from 'redux-saga';
import rootReducer, { RootState } from '../reducers';
import { StoreExtended } from './configureStore';

export default function configureStore(
  initialState?: RootState,
): StoreExtended {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    // We need "as typeof rootReducer" to workaround weird enableBatching type error.
    enableBatching(rootReducer) as typeof rootReducer,
    initialState,
    applyMiddleware(sagaMiddleware),
  );

  return { ...store, run: sagaMiddleware.run };
}
