import { Device } from '@raydiant/api-client-js';
import { DeviceStatus, PlaylistStatus } from '../selectors/v2/devices';

export const getSignalStrength = (device: Device): number | undefined => {
  const rssi = device.features.lte
    ? device.metrics?.lte?.rssi
    : device.metrics?.wifi?.rssi;
  return rssi && Math.min(2 * (rssi + 100), 100);
};

export const isWifiOnline = (device: Device, lastLoadedDate: string) => {
  if (!device.metrics?.wifi?.lastMetricTime) return false;

  const lastMetricTime = +new Date(device.metrics.wifi.lastMetricTime);
  const lastCheckedTime = +new Date(lastLoadedDate || 0);
  const oneMinute = 1000 * 60;

  return lastCheckedTime - lastMetricTime <= 5 * oneMinute;
};

export const isLTEOnline = (device: Device, lastLoadedDate: string) => {
  if (!device.metrics?.lte?.lastMetricTime) return false;

  const lastMetricTime = +new Date(device.metrics.lte.lastMetricTime);
  const lastCheckedTime = +new Date(lastLoadedDate || 0);
  const oneMinute = 1000 * 60;

  return lastCheckedTime - lastMetricTime <= 28 * oneMinute;
};

export const isSystemOnline = (device: Device, lastLoadedDate: string) => {
  if (!device.metrics?.system?.lastMetricTime) return false;

  const lastMetricTime = +new Date(device.metrics.system.lastMetricTime);
  const lastCheckedTime = +new Date(lastLoadedDate || 0);
  const oneMinute = 1000 * 60;

  return lastCheckedTime - lastMetricTime <= 5 * oneMinute;
};

export const isHeartbeatOnline = (device: Device, lastLoadedDate: string) => {
  if (!device.lastHeartbeatAt) return false;

  const lastHeartbeatTime = +new Date(device.lastHeartbeatAt);
  const lastCheckedTime = +new Date(lastLoadedDate || 0);
  const oneMinute = 1000 * 60;

  return lastCheckedTime - lastHeartbeatTime <= 5 * oneMinute;
};

export const isDeviceOnline = (
  device: Device,
  lastLoadedDate: string,
): boolean => {
  return (
    isHeartbeatOnline(device, lastLoadedDate) ||
    isWifiOnline(device, lastLoadedDate) ||
    isLTEOnline(device, lastLoadedDate) ||
    isSystemOnline(device, lastLoadedDate)
  );
};

export const isDeviceEthernet = (
  device: Device,
  lastLoadedDate: string,
): boolean => {
  return (
    !isWifiOnline(device, lastLoadedDate) &&
    !isLTEOnline(device, lastLoadedDate) &&
    isSystemOnline(device, lastLoadedDate)
  );
};

export const getDeviceConnectionStatus = (
  device: Device,
  lastLoadedDate: string,
) => ({
  signalStrength: getSignalStrength(device),
  isOnline: isDeviceOnline(device, lastLoadedDate),
  isEthernet: isDeviceEthernet(device, lastLoadedDate),
  isLTE: device.features.lte,
  wifiSsid: device.metrics?.wifi?.ssid,
  lastMetricTime: device.metrics?.lastMetricTime,
});

export const isDevicePlaybackEventsSupported = (device: Device): boolean => {
  if (device.isLite) return false;
  if (device.model === 'SecondScreen') return false;
  return true;
};

export type PublishPlaybackStatus =
  | ''
  | 'waitingForAck'
  | 'ackReceived'
  | 'playbackComplete';

export function getDevicePublishPlaybackStatus(
  device: Device,
): PublishPlaybackStatus {
  // Device does not support playback events.
  if (!isDevicePlaybackEventsSupported(device)) return '';
  // Device has never been published.
  if (device.publishedAt === null) return '';

  // Publish playback has completed if there is a success or an error.
  // publishPlaybackError is true when any error has occurred since the
  // last publish and does not mean the publish has failed.
  const publishPlaybackComplete =
    device.publishPlaybackSuccess || device.publishPlaybackError;

  if (!device.publishAck && !publishPlaybackComplete) return 'waitingForAck';
  if (device.publishAck && !publishPlaybackComplete) return 'ackReceived';
  if (device.publishAck && publishPlaybackComplete) return 'playbackComplete';

  return '';
}

export function isDevicePublishing(device: Device): boolean {
  const status = getDevicePublishPlaybackStatus(device);
  return status === 'waitingForAck' || status === 'ackReceived';
}

export function isDevicePublishable(
  device: Device,
  deviceStatus: DeviceStatus,
  playlistStatus: PlaylistStatus,
): boolean {
  return (
    playlistStatus === 'readyForPublish' &&
    deviceStatus !== 'publishing' &&
    !!device.playlistId &&
    !device.isAudioOnly
  );
}
