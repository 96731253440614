import { AnyAction, combineReducers } from 'redux';
import { getType, StateType } from 'typesafe-actions';
import * as userActions from '../actions/user';
import { libraryTreeReducer as libraryTree } from '../components/LibraryTree';
import { playlistSelectorReducer as playlistSelector } from '../components/PlaylistSelector';
import { shareResourceReducer as shareResource } from '../components/ShareResource';
import { applicationPageReducer as applicationPage } from '../pages/ApplicationPage';
import { applicationsPageReducer as applicationsPage } from '../pages/ApplicationsPage';
import { devicesPageReducer as devicesPage } from '../pages/DevicesPage';
import { libraryPageReducer as libraryPage } from '../pages/LibraryPage';
import { presentationPageReducer as presentationPage } from '../pages/PresentationPage';
import { addContentPageReducer as addContentPage } from '../pages/AddContentPage';
import { accountPageReducer as accountPage } from '../pages/AccountPage';
import analytics from './analytics';
import applications from './applications';
import chargebee from './chargebee';
import devices from './devices';
import fileUploads from './fileUploads';
import metrics from './metrics';
import pairingCodes from './pairingCodes';
import soundZones from './soundZones';
import themes from './themes';
import user from './user';
import v2Devices from './v2/devices';
import v2Folders from './v2/folders';
import v2Playlists from './v2/playlists';
import v2Presentations from './v2/presentations';
import v2Domains from './v2/domains';
import v2Themes from './v2/themes';
import v2Applications from './v2/applications';
import v2ApplicationVersions from './v2/applicationVersions';
import v2PlaybackReports from './v2/playbackReports';

const rootReducer = combineReducers({
  user,

  // Pages
  devicesPage,
  applicationsPage,
  libraryPage,
  presentationPage,
  addContentPage,
  applicationPage,
  accountPage,

  // Components
  libraryTree,
  playlistSelector,
  shareResource,

  // Database
  v2Devices,
  v2Folders,
  v2Playlists,
  v2Presentations,
  v2Domains,
  v2Themes,
  v2Applications,
  v2ApplicationVersions,
  v2PlaybackReports,

  // Legacy db
  devices, // TODO: Remove
  applications, // TODO: Remove
  themes, // TODO: Remove
  fileUploads,

  // Integrations
  chargebee,
  pairingCodes,
  soundZones,
  metrics,
  analytics,
});

export type RootState = StateType<typeof rootReducer>;

export default function createRootReducer(
  state: StateType<typeof rootReducer> | undefined,
  action: AnyAction,
) {
  if (action.type === getType(userActions.logoutUser)) state = undefined;
  return rootReducer(state, action);
}
