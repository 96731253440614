import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { SortFolderOptions } from '../../utilities';

export type LibraryPageActions = ActionType<typeof actions>;

export type LibraryPageState = Readonly<{
  lastLoadedDate: string;
  mainSortOptions: SortFolderOptions;
  sideSortOptions: SortFolderOptions;
  searchQuery: string;
  selectedNodeIds: string[];
  error: string;
}>;

const initialLibraryPageState: LibraryPageState = {
  lastLoadedDate: '',
  mainSortOptions: {
    property: 'name',
    direction: 'asc',
  },
  sideSortOptions: {
    property: 'name',
    direction: 'asc',
  },
  searchQuery: '',
  selectedNodeIds: [],
  error: '',
};

export default function libraryPageReducer(
  state = initialLibraryPageState,
  action: LibraryPageActions,
): LibraryPageState {
  switch (action.type) {
    case getType(actions.loadLibraryPageAsync.request):
      return {
        ...state,
        error: '',
      };

    case getType(actions.loadLibraryPageAsync.success):
      return {
        ...state,
        lastLoadedDate: new Date().toISOString(),
      };

    case getType(actions.loadLibraryPageAsync.failure):
      return {
        ...state,
        error: action.payload.message,
      };

    case getType(actions.setMainSortOptions):
      return {
        ...state,
        mainSortOptions: action.payload,
      };

    case getType(actions.setSideSortOptions):
      return {
        ...state,
        sideSortOptions: action.payload,
      };

    case getType(actions.setSearchQuery):
      return {
        ...state,
        searchQuery: action.payload,
      };

    case getType(actions.setSelectedNodeIds):
      return {
        ...state,
        selectedNodeIds: action.payload,
      };

    case getType(actions.clearSelectedNodeIds):
      return {
        ...state,
        selectedNodeIds: [],
      };

    default:
      return state;
  }
}
