import { fork, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { locationChanged, LocationChangedPayload } from '../../actions/history';

const { REACT_APP_GA_ID } = process.env;

const gtagConfig = ({
  payload: { location },
}: {
  type: string;
  payload: LocationChangedPayload;
}) => {
  // TODO: This is a naive implementation of page tracking. We aren't capturing
  // the content modal pages on desktop because it's rendered via the query string.
  // On mobile it does track the content modal pages so the analytics will be skewed
  // for those pages.
  //
  // We should remove this listener in favour of each page component pushing a tracking
  // event on mount so the modal on desktop fires a tracking event.
  //
  // We should also create an analytics client and mock it (ie. clients/chargebee) for
  // testing. We should ensure we don't break analytics in the future with integration tests.
  window.gtag('config', REACT_APP_GA_ID, {
    page_location: window.location.href,
    page_path: location.pathname,
  });
};

function* gtagConfigOnLocationChanged() {
  yield takeEvery(getType(locationChanged), gtagConfig);
}

export default fork(gtagConfigOnLocationChanged);
