import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import xor from 'lodash/xor';
import Paper from 'raydiant-elements/core/Paper';
import Button from 'raydiant-elements/core/Button';
import ActionBarLegacy from 'raydiant-elements/core/ActionBar';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Center from 'raydiant-elements/layout/Center';
import Spacer from 'raydiant-elements/layout/Spacer';
import Row from 'raydiant-elements/layout/Row';
import Hidden from 'raydiant-elements/layout/Hidden';
import Title from 'raydiant-elements/typography/Title';
import Text from 'raydiant-elements/typography/Text';
import React, {
  FC,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  useState,
  useLayoutEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Resource,
  ResourceProfile,
  PlaylistItem,
} from '@raydiant/api-client-js';
import Page from '../../components/Page';
import LibraryDragLayer from '../../components/LibraryDragLayer';
import BreadcrumbDivider from '../../components/BreadcrumbDivider';
import ReadResourceWarning from '../../components/ReadResourceWarning';
import {
  selectUserProfile,
  selectIsEnterpriseUser,
} from '../../selectors/user';
import {
  selectLibraryByOwner,
  selectFoldersById,
  selectFolderStatusById,
} from '../../selectors/v2/folders';
import { selectPlaylistsById } from '../../selectors/v2/playlists';
import { selectPresentationsById } from '../../selectors/v2/presentations';
import { selectDomainForCurrentUser } from '../../selectors/v2/domains';
import * as folderActions from '../../actions/folders';
import * as presentationActions from '../../actions/presentations';
import {
  sortFolder,
  searchPresentations,
  searchPlaylists,
  searchFolders,
  isResourceDeleted,
  getResourceIdsFromNodeIds,
  createNodeId,
  parseNodeId,
  canReadResource,
  createDefaultPlaylist,
} from '../../utilities';
import { createNewId } from '../../utilities/identifiers';
import DomainAccountSelector from '../../components/DomainAccountSelector';
import * as libraryTreeActions from '../../components/LibraryTree/actions';
import * as paths from '../../routes/paths';
import * as presentationPageActions from '../PresentationPage/actions';
import LibraryEmptyState from '../../components/LibraryEmptyState';
import {
  selectSortOptions,
  selectSearchQuery,
  selectLastLoadedDate,
  selectSelectedItems,
} from './selectors';
import useStyles from './AddContentPage.styles';
import AddContentActionBar from './AddContentActionBar';
import AddContentThumbnailView from './AddContentThumbnailView';
import * as actions from './actions';

interface AddContentPageProps {
  isModal: boolean;
}

const AddContentPage: FC<AddContentPageProps> = ({ isModal }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  // Selectors

  const libraryByOwner = useSelector(selectLibraryByOwner);
  const foldersById = useSelector(selectFoldersById);
  const folderStatusById = useSelector(selectFolderStatusById);
  const sortOptions = useSelector(selectSortOptions);
  const searchQuery = useSelector(selectSearchQuery);
  const lastLoadedDate = useSelector(selectLastLoadedDate);
  const currentUser = useSelector(selectUserProfile);
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);
  const domain = useSelector(selectDomainForCurrentUser);
  const selectedItemsBySelectionId = useSelector(selectSelectedItems);
  const playlistsById = useSelector(selectPlaylistsById);
  const presentationsById = useSelector(selectPresentationsById);

  // State

  const [isReadResourceWarningOpen, setIsReadResourceWarningOpen] =
    useState(false);

  // Memoizers

  const queryParams = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return {
      saveTo: searchParams.get('saveTo') ?? '',
      backTo: searchParams.get('backTo') ?? '',
      selectionId: searchParams.get('selectionId') ?? '',
      addToLabel: searchParams.get('addToLabel') ?? '',
      folderId: searchParams.get('folderId') ?? '',
      profileId: searchParams.get('profileId') ?? '',
      playlistId: searchParams.get('playlistId') ?? '',
      sessionId: searchParams.get('sessionId') ?? undefined,
      createPlaylist: searchParams.get('createPlaylist') === 'true',
    };
  }, [location.search]);

  const selectedItems = useMemo(
    () => selectedItemsBySelectionId[queryParams.selectionId] || [],
    [selectedItemsBySelectionId, queryParams.selectionId],
  );

  const selectedNodeIds = useMemo(() => {
    return selectedItems.map(({ id, type }) => createNodeId(type, id));
  }, [selectedItems]);

  const selectedFolder = foldersById[queryParams.folderId];

  const selectedProfileId = selectedFolder
    ? selectedFolder.resource.profile.id
    : queryParams.profileId || currentUser?.id || '';

  let selectedProfile: ResourceProfile | undefined;

  if (isEnterpriseUser && domain) {
    selectedProfile = domain.r.profiles.find((p) => p.id === selectedProfileId);
  } else if (!isEnterpriseUser && currentUser) {
    // Map full user profile to a resource profile.
    selectedProfile = {
      id: currentUser.id,
      email: currentUser.email,
      name: currentUser.name,
      thumbnailUrl: currentUser.thumbnailUrl || '',
      domainId: currentUser.domainId,
      domainRole: currentUser.domainRole,
    };
  }

  const libraryFolder = libraryByOwner[selectedProfileId];
  const folder = queryParams.folderId ? selectedFolder : libraryFolder;

  const folderItems = useMemo(() => {
    if (!folder) return [];

    return sortFolder(
      searchPresentations(
        folder.presentations.filter((p) => !isResourceDeleted(p)),
        searchQuery,
      ),
      searchPlaylists(
        folder.playlists.filter((pl) => !isResourceDeleted(pl)),
        searchQuery,
      ),
      searchFolders(
        folder.folders.filter((f) => !isResourceDeleted(f)),
        searchQuery,
      ),
      sortOptions,
    );
  }, [folder, sortOptions, searchQuery]);

  const itemsToAdd = useMemo<PlaylistItem[]>(() => {
    const items: PlaylistItem[] = [];

    if (queryParams.createPlaylist && currentUser) {
      const playlist = createDefaultPlaylist({
        id: createNewId(),
        name: 'New Empty Playlist',
        resource: { profile: { id: currentUser.id } },
      });
      items.push({
        id: createNewId(),
        presentationId: null,
        playlistId: playlist.id,
        playlist,
      });
    }

    for (const { id, type } of selectedItems) {
      if (type === 'presentation') {
        const presentation = presentationsById[id];
        if (presentation) {
          items.push({
            id: createNewId(),
            presentationId: presentation.id,
            playlistId: null,
            presentation,
          });
        }
      } else if (type === 'playlist') {
        const playlist = playlistsById[id];
        if (playlist) {
          items.push({
            id: createNewId(),
            presentationId: null,
            playlistId: playlist.id,
            playlist,
          });
        }
      }
    }

    return items;
  }, [
    playlistsById,
    presentationsById,
    selectedItems,
    currentUser,
    queryParams.createPlaylist,
  ]);

  // Callbacks

  const moveNodeIdsToFolder = useCallback(
    (nodeIds: string[], folderId: string | null) => {
      const { presentationIds, playlistIds, folderIds } =
        getResourceIdsFromNodeIds(nodeIds);

      dispatch(
        folderActions.moveAllItemsToFolder(
          {
            presentationIds,
            playlistIds,
            folderIds,
            parentFolderId: folderId,
          },
          {},
        ),
      );

      dispatch(actions.clearSelectedNodeIds());
      dispatch(libraryTreeActions.clearSelectedNodeIds());
    },
    [dispatch],
  );

  const openFolder = useCallback(
    (folderId: string) => {
      history.push(
        paths.presentations({
          ...queryParams,
          folderId,
        }),
      );
    },
    [history, queryParams],
  );

  const backToParentFolder = useCallback(() => {
    if (!selectedFolder) return;
    if (selectedFolder.resource.parentFolderId) {
      openFolder(selectedFolder.resource.parentFolderId);
    } else {
      const { folderId, ...rest } = queryParams;
      history.push(paths.presentations(rest));
    }
  }, [history, selectedFolder, openFolder, queryParams]);

  const editPlaylist = useCallback(
    (playlistId: string) => {
      history.push(
        paths.editPlaylist(playlistId, {
          folderId: queryParams.folderId,
          backTo: paths.presentations(queryParams),
          backToLabel: 'Back to Library',
          saveTo: paths.presentations(queryParams),
          sessionId: queryParams.sessionId,
        }),
      );
    },
    [history, queryParams],
  );

  const editPresentation = useCallback(
    (presentationId: string) => {
      dispatch(
        presentationPageActions.clearUnsavedPresentation(presentationId),
      );

      history.push(
        paths.editPresentation(presentationId, {
          backTo: paths.presentations(queryParams),
          backToLabel: 'Back to Library',
          saveTo: paths.presentations(queryParams),
          selectionId: queryParams.selectionId,
          sessionId: queryParams.sessionId,
        }),
      );
    },
    [history, queryParams, dispatch],
  );

  const newPresentation = useCallback(
    (applicationId: string) => {
      dispatch(presentationPageActions.clearUnsavedPresentation(applicationId));

      history.push(
        paths.newPresentation({
          applicationId,
          backTo: paths.presentations(queryParams),
          backToLabel: 'Back to Library',
          saveTo: paths.presentations(queryParams),
          selectionId: queryParams.selectionId,
          sessionId: queryParams.sessionId,
        }),
      );
    },
    [dispatch, history, queryParams],
  );

  const selectProfile = useCallback(
    (profileId: string) => {
      if (profileId === currentUser?.id) {
        const { folderId, profileId, ...rest } = queryParams;
        history.push(paths.presentations(rest));
      } else {
        const { folderId, ...rest } = queryParams;
        history.push(paths.presentations({ ...rest, profileId }));
      }
    },
    [history, currentUser, queryParams],
  );

  const resolveReadResourceWarning = useRef<((result: boolean) => void) | null>(
    null,
  );

  const showReadResourceWarning = async (): Promise<boolean> => {
    return new Promise((resolve) => {
      resolveReadResourceWarning.current = (result: boolean) => {
        resolveReadResourceWarning.current = null;
        setIsReadResourceWarningOpen(false);
        resolve(result);
      };

      setIsReadResourceWarningOpen(true);
    });
  };

  const setSelectedNodeIds = useCallback(
    async (nodeIds: string[]) => {
      const selectedItems = nodeIds.map((nodeId) => {
        const { id, type } = parseNodeId(nodeId);
        return { id, type };
      });

      // Determine if we need to show the read resource warning for
      // any of the selected presentations. Only show the warning once
      // per selection to prevent multiple confirmations when selecting
      // multiple presentations.
      let userConfirmedAdd;

      const parentPlaylist = playlistsById[queryParams.playlistId];
      if (parentPlaylist) {
        for (const { id, type } of selectedItems) {
          let resource: Resource | null = null;
          if (type === 'presentation') {
            const presentation = presentationsById[id];
            if (!presentation) continue;
            resource = presentation.resource;
          } else if (type === 'playlist') {
            const playlist = playlistsById[id];
            if (!playlist) continue;
            resource = playlist.resource;
          }

          if (
            resource &&
            userConfirmedAdd === undefined &&
            !canReadResource(parentPlaylist.resource.profile, resource)
          ) {
            userConfirmedAdd = await showReadResourceWarning();
            if (!userConfirmedAdd) return;
          }
        }
      }

      dispatch(
        actions.setSelectedItems({
          selectionId: queryParams.selectionId,
          items: selectedItems,
        }),
      );
    },
    [
      dispatch,
      queryParams.selectionId,
      queryParams.playlistId,
      presentationsById,
      playlistsById,
    ],
  );

  const toggleNodeId = useCallback(
    (nodeId: string) => {
      setSelectedNodeIds(xor(selectedNodeIds, [nodeId]));
    },
    [setSelectedNodeIds, selectedNodeIds],
  );

  const goBack = useCallback(() => {
    dispatch(
      actions.clearSelectedItems({
        selectionId: queryParams.selectionId,
      }),
    );
    history.push(queryParams.backTo);
  }, [dispatch, history, queryParams]);

  const addPlaylistItems = useCallback(() => {
    dispatch(
      actions.setItemsToAdd({
        selectionId: queryParams.selectionId,
        items: itemsToAdd,
      }),
    );

    history.push(queryParams.saveTo);
  }, [dispatch, history, queryParams, itemsToAdd]);

  const openAppSelector = useCallback(() => {
    history.push(
      paths.applications({
        backTo: paths.presentations(queryParams),
        selectionId: queryParams.selectionId,
        // When clicking "Create Playlist" from the add content flow, instead of opening the builder
        // for a new playlist we want to redirect back to the builder for the top-level playlist with
        // a new nested playlist.
        createPlaylistTo: paths.presentations({
          ...queryParams,
          createPlaylist: true,
        }),
        ...(folder && 'id' in folder ? { folderId: folder.id } : {}),
      }),
    );
  }, [history, folder, queryParams]);

  // Side-effects

  // Immediately call addPlaylistItems before rendering if we are creating a new nested playlist.
  // addPlaylistItems will redirect back to the builder page with the new items.
  useLayoutEffect(() => {
    if (!queryParams.createPlaylist) return;
    // Make sure the current user is set before calling addPlaylistItems to ensure we create a new playlist.
    if (!currentUser) return;
    addPlaylistItems();
  }, [queryParams.createPlaylist, addPlaylistItems, currentUser]);

  // Fetch library on page load.
  useEffect(() => {
    dispatch(actions.loadAddContentPage());
  }, [dispatch]);

  // Fetch selected folder.
  useEffect(() => {
    if (!queryParams.folderId) return;
    dispatch(folderActions.fetchFolder(queryParams.folderId, {}));
  }, [dispatch, queryParams.folderId]);

  // Poll presentations with file uploads when folder items changes.
  useEffect(() => {
    const presentationIds: string[] = [];

    folderItems.forEach((item) => {
      if (item.presentation) {
        presentationIds.push(item.presentation.id);
      }
    });

    dispatch(
      presentationActions.pollPresentationsWithFileUploads(presentationIds),
    );
  }, [dispatch, folderItems]);

  // Stop polling when unmounting.
  useEffect(() => {
    return () => dispatch(presentationActions.stopPollingPresentations());
  }, [dispatch]);

  // Render

  const totalFolderItems = folderItems.length;

  const hasNoLibraryItems =
    !totalFolderItems && !searchQuery && !queryParams.folderId;

  const hasNoFolderItems =
    !totalFolderItems && !searchQuery && !!queryParams.folderId;

  let pageTitle: string;
  if (selectedItems.length === 0) {
    pageTitle = folder && 'id' in folder ? folder.name : 'Select items';
  } else {
    pageTitle = `Selected ${selectedItems.length} ${
      selectedItems.length === 1 ? 'item' : 'items'
    }`;
  }

  let pageBackTo: string = queryParams.backTo;
  if (folder && 'id' in folder) {
    pageBackTo = paths.presentations({
      ...queryParams,
      folderId: folder.resource.parentFolderId || '',
    });
  }

  const pageProps = {
    title: pageTitle,
    backTo: pageBackTo,
    hideNavigation: isModal,
  };

  const isLoadingLibrary =
    (hasNoLibraryItems && !lastLoadedDate) || !currentUser;

  if (isLoadingLibrary) {
    return (
      <Page {...pageProps}>
        <Paper color="light" className={classes.library}>
          <Center>
            <CircularProgress size={30} />
          </Center>
        </Paper>
      </Page>
    );
  }

  const libraryTitle =
    selectedProfile && selectedProfile.id !== currentUser?.id
      ? `${selectedProfile.name} Library`
      : 'My Library';

  const isLoadingFolder =
    queryParams.folderId &&
    folderStatusById[queryParams.folderId] === 'fetching' &&
    hasNoFolderItems;

  const renderLibraryEmptyState =
    !!hasNoLibraryItems && selectedProfileId === currentUser?.id;
  const renderFolderEmptyState = !isLoadingFolder && hasNoFolderItems;

  const renderThumbnailView = () => {
    if (isLoadingLibrary || isLoadingFolder) {
      return (
        <Center>
          <CircularProgress size={30} />
        </Center>
      );
    }

    if (renderFolderEmptyState) {
      // TODO: Probably want some "folder is empty text"
      return <Spacer />;
    }

    if (renderLibraryEmptyState) {
      return <LibraryEmptyState onApplicationClick={newPresentation} />;
    }

    if (selectedProfile && folder) {
      return (
        <AddContentThumbnailView
          selectedNodeIds={selectedNodeIds}
          folder={folder}
          folderItems={folderItems}
          selectedProfile={selectedProfile}
          onMove={moveNodeIdsToFolder}
          onOpenFolder={openFolder}
          onOpenPlaylist={editPlaylist}
          onOpenPresentation={editPresentation}
          onSelectNodeIds={setSelectedNodeIds}
          onToggleNodeId={toggleNodeId}
        />
      );
    }

    return <Spacer />;
  };

  return (
    <Page {...pageProps}>
      <Paper color="light" className={classes.library}>
        <div className={classes.libraryMain}>
          <div className={classes.header}>
            <Row>
              <Hidden xsDown>
                {!selectedFolder && (
                  <div>
                    <Title>{libraryTitle}</Title>
                    {isEnterpriseUser && (
                      <Text xsmall muted>
                        {domain?.name}
                      </Text>
                    )}
                  </div>
                )}

                {selectedFolder && (
                  <div>
                    <Title>
                      <Row halfMargin center className={classes.folderNameRow}>
                        <ArrowBackIcon
                          fontSize="large"
                          onClick={backToParentFolder}
                          style={{ cursor: 'pointer' }}
                        />
                        <BreadcrumbDivider />
                        <Text>{selectedFolder.name}</Text>
                      </Row>
                    </Title>
                    {isEnterpriseUser && (
                      <Text
                        xsmall
                        muted
                        className={classes.folderNameHelperText}
                      >
                        {libraryTitle}
                      </Text>
                    )}
                  </div>
                )}
              </Hidden>

              {isEnterpriseUser && (
                <>
                  <Spacer />
                  <DomainAccountSelector
                    className={classes.domainAccountSelector}
                    selectedProfileId={selectedProfileId}
                    onSelect={selectProfile}
                  />
                </>
              )}
            </Row>
            <AddContentActionBar
              folder={folder}
              selectedProfile={selectedProfile}
              sortDisabled={hasNoLibraryItems || hasNoFolderItems}
              onCreate={openAppSelector}
            />
          </div>

          {renderThumbnailView()}
        </div>
      </Paper>

      <ActionBarLegacy color="light">
        <Hidden xsDown>
          <span>
            {pageTitle} to add to "{queryParams.addToLabel}"
          </span>
          <Spacer />
        </Hidden>
        <Button label="Cancel" onClick={goBack} />
        <Hidden smUp>
          <Spacer />
        </Hidden>
        <Button
          color="progress"
          label="Add to Playlist"
          onClick={addPlaylistItems}
          disabled={selectedItems.length === 0}
        />
      </ActionBarLegacy>

      <LibraryDragLayer />

      <ReadResourceWarning
        ownerResourceType="playlist"
        resourceType="presentation"
        open={isReadResourceWarningOpen}
        onConfirm={resolveReadResourceWarning.current}
      />
    </Page>
  );
};

export default AddContentPage;
