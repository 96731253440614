import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { SoundZone } from '../types';

export const fetchSoundZone = createStandardAction('FETCH_SOUNDZONE')<string>();

export const fetchSoundZoneAsync = createAsyncAction(
  'FETCH_SOUNDZONE_REQUEST',
  'FETCH_SOUNDZONE_SUCCESS',
  'FETCH_SOUNDZONE_FAILURE',
)<string, SoundZone, { id: string; error: Error }>();

export const fetchAllSoundZones = createStandardAction(
  'FETCH_ALL_SOUNDZONES',
)();
