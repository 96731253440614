import { Theme } from 'raydiant-elements/theme';
import { makeStyles, createStyles } from 'raydiant-elements/styles';

export default makeStyles((theme: Theme) => {
  return createStyles({
    isDragging: {
      opacity: 0.4,
    },

    isOver: {
      backgroundColor: theme.palette.action.selected,
      borderRadius: theme.borderRadius.sm,
    },
  });
});
